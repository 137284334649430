import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { CountdownModule } from "ngx-countdown";

import { ComponentsModule } from "../../components/components.module";
import { HeaderModule } from "../../layouts/header/header.module";
import { FooterModule } from "src/app/layouts/footer/footer.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Import your library
import { SlickCarouselModule } from "ngx-slick-carousel";

import { HomeComponent } from "./home/home.component";

import { NgxSpinnerModule } from "ngx-spinner";
import { FilterSerachModule } from "../filter-serach/filter-serach.module";

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    CountdownModule,

    ComponentsModule,

    // Specify your library as an import
    SlickCarouselModule,
    HeaderModule,
    FooterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    FilterSerachModule
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
