<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="offering-communication"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true loginRedirectUrl="offering-communication"></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Offering Communication" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content profile-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-offerings-sidebar></app-offerings-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <div class="container" *ngIf="this.NoDataFlag === true">
          No active communication window.
        </div>
        <div *ngIf="this.NoDataFlag === false" class="off-comm-wrapper-scroll-y off-comm-scrollbar-table">
          <div class="container">
          <table class="table table-bordered">
            <thead>
              <tr>
                <table class="table border-0">
                  <tr class="border-0">
                <th width="5%">&nbsp;&nbsp;&nbsp;&nbsp;#</th>
                <th width="40%">Offer Title</th>
                <th width="10%">Schedule Type</th>
                <th width="15%">Start&nbsp;Date</th>
                <th width="15%">End&nbsp;Date</th>
                <th width="10%">New Comments?</th>
                <th width="5%">Broadcast Message</th>
              </tr>
              </table>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of preparedDataVal">
                <td class= "control center">
                  <table class="table border-0">
                    <tbody>
                      <tr class="border-0">
                        <td width="5%">
                          <a class="border-0" data-toggle="collapse" href="#offerDetail_{{data['offer_id']}}_{{data['schedule_frequency']}}_{{data['start_date']}}_{{data['end_date']}}" id="offerorDesc" role="button" aria-expanded="false">
                          <button type="button" class="btn-sm border-0">
                            <span class="fas fa-chevron-down"></span>
                          </button>
                        </a>
                        </td>
                        <td width="25%">&nbsp;{{data["offer_title"]}}</td>
                        <td width="15%">
                          {{data["schedule_frequency"]}}
                        </td>
                        <td width="15%">
                          {{data["start_date"]}}
                        </td>
                        <td width="15%">
                          {{data["end_date"]}}
                        </td>
                        <td width="15%">
                          <i *ngIf="data['unread_messages'] > 0" class="fa fa-comment chat-icon-color-new"></i>
                          <i *ngIf="data['unread_messages'] == null || data['unread_messages'] == 0" class="fa fa-comment chat-icon-color"></i>
                        </td>
                        <td width="10%">
                        <!-- <a (click)="broadcastMessages(data)"> -->
                          <ng-template #offerBroadcast let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Broadcast Message</h4>
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="container">
                                <div class="row">
                                  <div class="col-sm">
                                    <textarea class="textarea" [(ngModel)]="broadcastMessage"></textarea>
                                  </div>
                                  <div class="col-sm">
                                    <button class="button is-black m-2" (click)='broadcastMessages(data, broadcastMessage, modal)'>
                                      <span>Send</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                            <a (click)="openModalFirst(offerBroadcast, 'broadcast')"><i class="fa fa-podcast"></i></a>
                        <!-- </a> -->
                        </td>
                      </tr>
                      </tbody>
                      </table>
                      <table class="table border-0">
                        <tbody>
                          <tr class="border-0">
                            <td>
                              <div class="col-md-12 icon-box border-0" data-aos="fade-up" data-aos-delay="500">
                              <div class="collapse border-0" id="offerDetail_{{data['offer_id']}}_{{data['schedule_frequency']}}_{{data['start_date']}}_{{data['end_date']}}">
                                <div class="card card-body border-0">
                                  <table class="table-striped table top-table">
                                    <thead>
                                      <th>Offeree Name</th>
                                      <th>Send Message</th>
                                      <th>Additional information</th>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let lock of data['lock_ids']">
                                        <td>
                                          {{lock['offeree_name']}}
                                        </td>
                                        <td>
                                          <a [routerLink]="['/offering-chat/', lock.lock_id]"><i *ngIf="lock['unread_messages'] > 0" class="fa fa-comment chat-icon-color-new"></i>
                          <i *ngIf="lock['unread_messages'] == null || lock['unread_messages'] == 0" class="fa fa-comment chat-icon-color"></i></a>
                                        </td>
                                        <td>
                                          <ng-template #offerLockContent let-modal>
                                            <div class="modal-header">
                                              <h4 class="modal-title" id="modal-basic-title">Additional Details</h4>
                                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                            </div>
                                            <div class="modal-body">
                                                <table class="table border-0">
                                                    <tbody>
                                                      <tr class="border-0">
                                                        <td>
                                                          <div class="col-md-12 icon-box border-0" data-aos="fade-up" data-aos-delay="500">
                                                          <div class="border-0" [id]="'offerorDetail_' + productLockDetails['id']">
                                                            <div class="card card-body border-0">
                                                              <table class="w-auto border-0">
                                                                <tr class="border-0">
                                                                  <th width="30%">Start&nbsp;date:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['start_date'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%">End&nbsp;date:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['end_date'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%">Additional&nbsp;Guidelines:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['schedule_guidelines'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%">Maximum&nbsp;offer&nbsp;count:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['product_count'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%">Approval&nbsp;days&nbsp;for&nbsp;Offer:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['buffer_days'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%">Full&nbsp;refund&nbsp;before:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['full_refund_date'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%">Half&nbsp;refund&nbsp;before:</th>
                                                                  <td width="70%" valign="bottom">{{ productLockDetails['half_refund_date'] }}</td>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <th width="30%" colspan="2">Slot(s):</th>
                                                                </tr>
                                                                <tr class="border-0">
                                                                  <td width="70%" valign="bottom" colspan="2">
                                                                    <div class="table-wrapper-scroll-y chat-slot-scrollbar-table">
                                                                    <table class="table table-bordered table-striped">
                                                                        <tr>
                                                                          <th>&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                          <th>Duration</th>
                                                                          <th>Start&nbsp;time</th>
                                                                          <th>End&nbsp;time</th>
                                                                        </tr>
                                                                      <tbody>
                                                                        <tr *ngFor="let slot of productLockDetails['slots']">
                                                                          <td>&nbsp;{{slot["actual_date"]}}</td>
                                                                          <td>
                                                                            {{slot["slot_hours"]}}&nbsp;hour&nbsp;{{slot["slot_minutes"]}}&nbsp;minute
                                                                          </td>
                                                                          <td>
                                                                            <ng-template [ngIf]="slot['start_hour'].toString().length < 2">0{{slot["start_hour"]}}</ng-template><ng-template [ngIf]="slot['start_hour'].toString().length >= 2">{{slot["start_hour"]}}</ng-template>:<ng-template [ngIf]="slot['start_minute'].toString().length < 2">0{{slot["start_minute"]}}</ng-template><ng-template [ngIf]="slot['start_minute'].toString().length >= 2">{{slot["start_minute"]}}</ng-template>
                                                                          </td>
                                                                          <td>
                                                                            <ng-template [ngIf]="slot['end_hour'].toString().length < 2">0{{slot["end_hour"]}}</ng-template><ng-template [ngIf]="slot['end_hour'].toString().length >= 2">{{slot["end_hour"]}}</ng-template>:<ng-template [ngIf]="slot['end_minute'].toString().length < 2">0{{slot["end_minute"]}}</ng-template><ng-template [ngIf]="slot['end_minute'].toString().length >= 2">{{slot["end_minute"]}}</ng-template>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                </div>
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </div>
                                                          </div>
                                                        </div>
                                                          </td>
                                                          </tr>
                                                          </tbody>
                                                          </table>
                                            </div>
                                          </ng-template>
                                        &nbsp;&nbsp;<a class="ps-2" (click)="openModalFirst(offerLockContent, 'loadLockSlots', lock.lock_id)"><i class="fas fa-info-circle text-secondary"></i></a>
                                        </td>
                                      </tr></tbody></table>

                                </div></div></div></td></tr></tbody></table>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>
<app-back-to-top></app-back-to-top>
