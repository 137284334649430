import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class RegisterNewCouponCodeMutation extends Mutation {

  document = gql`
    mutation RegisterNewCouponCodeMutation(
        $coupon_code: String!
        $discount_percentage: Float!
        $start_date: String!
        $end_date: String!
        $category_id: Int
        $category_group_id: Int
        $offer_id: Int
    ) {
      registerNewCouponCode(
        coupon_code: $coupon_code
        discount_percentage: $discount_percentage
        start_date: $start_date
        end_date: $end_date
        category_id: $category_id
        category_group_id: $category_group_id
        offer_id: $offer_id
      )
    }
  `;
}
