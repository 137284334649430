import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";

@Injectable({
  providedIn: "root",
})
export class GetUserQuery extends Query {
  getUserData: any;
  
  private _getuserDataLoaded = new BehaviorSubject<string>(
    EventConstants.CONST_USER_LOADED
  );

  __getUserDataLoadedObservble = this. _getuserDataLoaded.asObservable();

  document = gql`
    query getUserQuery($email: String, $idToken: String, $userId: Int, $userHashId: String) {
      getUserDetails(
        user_email: $email, 
        id_token: $idToken, 
        user_id: $userId,
        user_hash_id: $userHashId
        ) {
        first_name
        user_id
        last_name
        email
        phone
        adhaar_card
        pan_card
        description
        markdown_description
        status
        address_id
        birth_date
        terms_accepted
        referral_code
        profile_image_name
        account_number
        bank_code 
        address_line1
        address_line2
        landmark
        latitude
        longitude
        area_name
        pin_code
        city
        district
        state
        country
        country_abbreviation
      }
    }
  `;
  
  populateGetUser(data) {                                                    
    this.getUserData = data["getUserDetails"];
    this. _getuserDataLoaded.next(EventConstants.CONST_USER_LOADED);
  }
}
