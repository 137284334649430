import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'any'
})
export class CalendartoggleService {
  private _loaderSource:any = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());
  public loader = this._loaderSource.asObservable();

  constructor() { }

   //set the loader value
   setLoader(load){
    this._loaderSource.next(load);
  }
}
