import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { ApplicationConstants, EventConstants } from "../../constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class GetCategoryQuery extends Query {
  productCategory = [];
  categoryGroup = [];
  categoryGroupHeader = [];
  categoryData = [];
  categoryGroupData = [];
  addressCategoryGroup=[];
  productIdGroup=[];
  imgCategoryGroup:any;
  public filteredCount: number = 0;
  public selectedCategory: any;

  private _categoryDataLoaded = new BehaviorSubject<string>(
    EventConstants.CONST_CATEGORY_LOADED
  );
  _categoryDataLoadedObservble = this._categoryDataLoaded.asObservable();

  document = gql`
    query getProductCategoriesQuery($category: String, $category_id: Int) {
      getProductCategories(category: $category, category_id: $category_id) {
        category
        sub_category1
        sub_category2
        category_group
        category_id
        default_image_link
        address_required
        address_show
        age_required
        technical_specs_required
        deposit_required
        multiple_quantity_possible
        video_conferencing_required
        Quantity_limit
        default_image_link
      }
    }
  `;
  imgCategoryGroupData: any;
  populateCategoryAndGroup(data) {
    this.categoryData = data; // all category data stored here
    let productCategoryArr = [];
    let productGroupArr = [];
    let productimageArr=[];
    let productaddressArr=[];
    let productIdArr=[];
    let datacat = data["getProductCategories"];
    datacat.forEach((item) => {
      productCategoryArr.push(item["category"]);
      productGroupArr.push(item["category_group"]);
      productIdArr.push(item["category_id"]);
      productaddressArr.push(item["address_required"]);
      productimageArr.push(item["default_image_link"]);
    });
    this.productCategory = productCategoryArr.filter(this.onlyUnique); // segregated product category from data
    this.categoryGroup = productGroupArr.filter(this.onlyUnique); // segregated category group from data
    this.productIdGroup = productIdArr.filter(this.onlyUnique);
    this.addressCategoryGroup = productaddressArr.filter(this.onlyUnique);
    this.imgCategoryGroup = productimageArr.filter(this.onlyUnique);
    this._categoryDataLoaded.next(EventConstants.CONST_CATEGORY_LOADED);
  }

  populateCategoryGroup(data) {
    this.categoryGroupData = data; // all category data stored here
    let productGroupArr = [];
    let datacat = data["getProductCategories"];
    datacat.forEach((item) => {
      productGroupArr.push(item["category_group"]);
    });
    this.categoryGroupHeader = productGroupArr.filter(this.onlyUnique); // segregated category group from data
  }

  filterCategoryByGroupCategory(
    groupCategory: String,
    category: string = "",
    subCategory1: string = "",
    subCategory2: string = ""
  ): Array<string> {
    let allCategoryData = this.categoryData["getProductCategories"];
    let filteredData = [];

    this.filteredCount = 0;
    this.selectedCategory = null;
    allCategoryData.forEach((item) => {
      if(subCategory2 !== "") {
        if (
          item["category_group"] === groupCategory &&
          item["category"] === category &&
          item["sub_category1"] === subCategory1 && 
          item["sub_category2"] === subCategory2
        ) {
          this.filteredCount++;
          this.selectedCategory = item;
        }
      }else if (subCategory1 !== "") {
        if (
          item["category_group"] === groupCategory &&
          item["category"] === category &&
          item["sub_category1"] === subCategory1
        ) {
          this.filteredCount++;
          if (item["sub_category2"]) filteredData.push(item["sub_category2"]);
          this.selectedCategory = item;
        }
      } else if (category !== "") {
        if (
          item["category_group"] === groupCategory &&
          item["category"] === category
        ) {
          this.filteredCount++;
          if (item["sub_category1"]) filteredData.push(item["sub_category1"]);
          this.selectedCategory = item;
        }
      } else if (item["category_group"] === groupCategory) {
        this.filteredCount++;
        filteredData.push(item["category"]);
        this.selectedCategory = item;
      }
    });
    return filteredData.filter(this.onlyUnique);
  }

  /**
   * @description filtering unique values from array
   * @param value
   * @param index
   * @param self
   */
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
