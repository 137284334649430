<div class="has-background-white" style="margin: 15px; z-index: 1099 !important; background-color: white;">
      <!-- <input type="text" class="form-control" name="Test" [(ngModel)]="content"> -->
      <md-editor #md="ngModel" name="Content" (onEditorLoaded)="onEditorLoaded($event)"
      [preRender]="preRender" [postRender]="postRender"
      (onPreviewDomChanged)="onPreviewDomChanged($event)"
      [(ngModel)]="content" [mode]="mode" [options]="options" required maxlength="3000" height="300px"></md-editor>
    <div class="well well-sm">
      <button class="is-small is-black m-2" (click)="togglePreviewPanel()">
        {{ options?.showPreviewPanel ? ' Hide' : ' Show' }} Preview Panel 
      </button>
      &nbsp;&nbsp;
      <button class="is-small is-black m-2" (click)="changeMode()"> Change Mode (Current: {{ mode }}) </button>
      &nbsp;&nbsp;
      <button class="is-small is-black m-2" (click)="toggleResizeAble()">
        {{ options?.resizable ? ' Disable' : ' Enable' }} Resizeable 
      </button>
    </div>
  </div>