import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  constructor() {}

  constructDayTemplateData(dayTemplateData: any): any {
    try {
      if(dayTemplateData != null) {
        var dayTemplateSlotArr = [];
        dayTemplateData.forEach(dayTemplate => {
          dayTemplate["slots"].forEach(slot => {
            dayTemplateSlotArr[slot["slot_id"]] = slot;
          });
        });
        return dayTemplateSlotArr;
      }
      return [];
    } catch (e) {
      console.error("Error constructing schedule data", e);
    }
  }
}
