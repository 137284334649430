import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSerachComponent } from './filter-serach/filter-serach.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownModule } from 'ngx-countdown';
import { ComponentsModule } from 'src/app/components/components.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [FilterSerachComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    CountdownModule,

    ComponentsModule,

    // Specify your library as an import
    SlickCarouselModule,
    HeaderModule,
    FooterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [FilterSerachComponent]
})
export class FilterSerachModule { }
