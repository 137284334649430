<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Reviews Policy</h1>
            <p class="has-text-grey-light">
                Last Updated: Jan 06, 2023
            </p>
            <br />
            <p class="box">
                One of the ways Zigwik fosters trust between Offeror and Offeree is through our review process for Offers, which helps our community make informed booking and Offer creation decisions and provides Offerees and Offerors with honest feedback to help them improve. Our reviews policy is intended to help ensure the feedback provided through our review system is authentic, trustworthy, and useful to our community.
                <br /><br />
                Reviews must be unbiased, contain relevant information reflecting the actual experience of the reviewer during the stay or Experience, and follow our Content Policy.
            </p>
            <br />
            <h3>1. Reviews should be unbiased</h3>
            <span class="box">
                <ul>
                    <li>
                        Members of the Zigwik community may not coerce, intimidate, extort, threaten, incentivize or manipulate another person in an attempt to influence a review, like promising compensation in exchange for a positive review or threatening consequences in the event of a negative review.
                    </li>
                    <li>
                        Reviews may not be provided or withheld in exchange for something of value—like a discount, refund, reciprocal review, or promise not to take negative action against the reviewer. They also may not be used as an attempt to mislead or deceive Zigwik or another person. For example, Offerees should not write biased or inauthentic reviews as a form of retaliation against an Offeror who enforces a policy or rule.
                    </li>
                    <li>
                        Reviews may only be provided in connection with a genuine experience. For example, Offerors are not allowed to accept a fake booking in exchange for a positive review, use a second account to leave themselves a review, or coordinate with others to manipulate the review system.
                    </li>
                    <li>
                        Reviews may not be used for the purpose of harming competition. For example, Offerors are not allowed to post biased reviews of Offers they are affiliated with or directly compete with.
                    </li>
                </ul>
            </span>
            <br />
            <h3>2. Reviews should be relevant</h3>
            <span class="box">
                <ul>
                    <li>
                        Reviews must provide relevant information about the reviewer's experience with the Offeror, Offeree, or Offer that would help other community members make informed booking and Offer creation decisions.
                    </li>
                    <li>
                        If an Offeree never arrived after booking the Offer, or had to cancel due to circumstances unrelated to that Offe, their review may be removed.
                    </li>
                </ul>
            </span>
            <br />
            <h3>3. Reviews should follow our content policy</h3>
            <p class="box">
                Reviews may not contain explicit, discriminatory, harmful, fraudulent, illegal or other content that violates our <a [routerLink]="'/content-policy'">Content Policy</a>.
            </p>
            <br />
            <h3>4. Reporting reviews under this policy</h3>
            <p class="box">
                To report a review for violating this policy, <a [routerLink]="'/contact-us'">contact us</a>.
                <br /><br />
                If a review violates this policy, we may remove that review, including any associated ratings and other content. We take the removal of any review seriously and only do so where there is a clear violation of this policy. Depending on the nature of the violation, we may also restrict, suspend, or remove the associated Zigwik account.
                <br /><br />
                This policy may be applied differently in different places to reflect what local law permits or requires.
            </p>
            <br />
            <h3>5. Removing a review you wrote</h3>
            <p class="box">
                Once a review you've written has been published, you can <a [routerLink]="'/contact-us'">contact us</a> to request for it to be removed.
            </p>
        </div>
    </div>
</div>


<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
