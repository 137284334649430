import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

import { AppRoutingModule } from "./app-routing.module";
import { ProductRoutingModule } from "./pages/search/product/product-routing.module"
import { AppComponent } from "./app.component";

import { ComponentsModule } from "./components/components.module";
import { HeaderModule } from "./layouts/header/header.module";
import { FooterModule } from "./layouts/footer/footer.module";
import { SidebarModule } from "./layouts/sidebar/sidebar.module";

import { HomeModule } from "./pages/home/home.module";

import { CartModule } from "./pages/cart/cart.module";
import { UserAccountModule } from "./pages/user-account/user-account.module";
import { BlogModule } from "./pages/blog/blog.module";
import { FaqModule } from "./pages/faq/faq.module";

import { SessionModule } from "./pages/session/session.module";

import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { NotfoundComponent } from "./pages/notfound/notfound.component";

import { AboutModule } from "./pages/about/about.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ToastrModule } from "ngx-toastr";

import { LightboxModule } from "ngx-lightbox";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { GraphQLModule } from "./graphql.module";
import { OfferingsModule } from "./pages/offerings/offerings.module";
import { SearchModule } from "./pages/search/search.module";
// import { ProductModule } from "./pages/search/product/product.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterSerachModule } from "./pages/filter-serach/filter-serach.module";

@NgModule({
  declarations: [AppComponent, CheckoutComponent, NotfoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(), // ToastrModule added
    NgxImageZoomModule,
    NgxSpinnerModule,
    LightboxModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ProductRoutingModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    // ProductModule,
    SidebarModule,
    HomeModule,

    CartModule,
    UserAccountModule,
    BlogModule,

    SessionModule,
    AboutModule,
    GraphQLModule,
    OfferingsModule,
    SearchModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    FilterSerachModule  
  ],
  providers: [Apollo],
  bootstrap: [AppComponent],
  exports: [CheckoutComponent, NotfoundComponent],
})
export class AppModule {}
