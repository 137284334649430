import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";

import { ComponentsModule } from "../../components/components.module";

import { HeaderComponent } from "./header/header.component";
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderMobileComponent } from "./header-mobile/header-mobile.component";
import { HeaderStickyComponent } from "./header-sticky/header-sticky.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderCategoriesComponent } from './header-categories/header-categories.component';
@NgModule({
  declarations: [HeaderComponent, HeaderMobileComponent, HeaderStickyComponent, HeaderCategoriesComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ComponentsModule, 
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  
  exports: [HeaderComponent, HeaderMobileComponent, HeaderStickyComponent],
})
export class HeaderModule {}
