import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class SendCouponCodeMutation extends Mutation {
  document = gql`
    mutation SendCouponCodeMutation(
        $email: String!
        $coupon_code: String!
        $discount_percentage: Float!
        $start_date: String!
        $end_date: String!
        $offer_id: Int
        $offer_title: String
        $offer_name: String
        $category: String
        $sub_category1: String
        $sub_category2: String
        $category_group: String
        $for_all_offers: Boolean
        $category_id: Int
        $category_group_id: Int
    )
    {
      sendCouponCode(
        email: $email
        coupon_code: $coupon_code
        discount_percentage: $discount_percentage
        start_date: $start_date
        end_date: $end_date
        offer_id: $offer_id
        offer_title: $offer_title
        offer_name: $offer_name
        category: $category
        sub_category1: $sub_category1
        sub_category2: $sub_category2
        category_group: $category_group
        for_all_offers: $for_all_offers
        category_id: $category_id
        category_group_id: $category_group_id
    )
    }
    `;
}
