import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { TranslationWidth } from '@angular/common';
import { Apollo } from "apollo-angular";
import {NgbCalendar, NgbDate, NgbDateStruct, NgbDatepickerI18n, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { ElementRef, ViewChild } from '@angular/core';
import { CalendartoggleService } from '../../services/calendartoggle.service';
import { GetProductSchedulesQuery } from "../../services/graphql/getProductSchedules.graphql";
import { BehaviorSubject } from "rxjs";
import { CurencyPipe } from '../../pipes/curency/curency.pipe';


@Component({
  selector: 'app-date-picker-calendar',
  templateUrl: './date-picker-calendar.component.html',
  styleUrls: ['./date-picker-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CurencyPipe]
})
export class DatePickerCalendarComponent implements OnInit {
  model: NgbDateStruct;
  @Input() productId: any;

  @ViewChild("dt") datepicker: NgbDatepicker;
  weekDays = TranslationWidth.Wide;
  outsideDays = "visible";
  public schedulelist = [];
  public showContainer = new Map<string, boolean>();
  hour_price: any = "";
  day_price: any = "";
  week_price: any = "";
  month_price: any = "";
  month_font_size: string;
  currentDate = new Date();
  assignedDate = null;
  minDate: any;
  private _prodScheduleDataLoaded = new BehaviorSubject<string>(
    ""
  );
  __prodScheduleDataLoadedObservable = this._prodScheduleDataLoaded.asObservable();

  constructor(
    private calendar: NgbCalendar,
    public i18n: NgbDatepickerI18n,
    private _calendarToggleService: CalendartoggleService,
    private getProductSchedulesQuery: GetProductSchedulesQuery,
    private apollo: Apollo,
    private changeDetector : ChangeDetectorRef,
    private currencyPipe: CurencyPipe,
    private el: ElementRef) { }

    navi = (date: NgbDate) => {
      var monthSelection = document.querySelector('.month-price-placeholder');
      if(monthSelection !== null) {
        monthSelection.innerHTML = this.currencyPipe.transform(this.month_price);
        if(this.month_price.toString().length > 3) {
          (this.el.nativeElement as HTMLElement).style.setProperty('--month-font-size', "min(0.5rem, 2.4vw)");
        } else {
          (this.el.nativeElement as HTMLElement).style.setProperty('--month-font-size', "min(0.6rem, 3vw)");
        }
      }
    }

  isDisabled = (date: NgbDate, current: {month: number, year: number}) => date.month !== current.month;
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;
  isFirstDay = (date: NgbDate) =>  this.calendar.getWeekday(date) == 1;
  priceExists = (date: NgbDate) => {
    this.__prodScheduleDataLoadedObservable.subscribe(
      (data) => {
        if (data === "success") {
          var jsDate = new Date(date.year, date.month - 1, date.day);
          var jsDate2 = new Date(date.year, date.month - 1, date.day);
          if(jsDate.getDay() == 0) {
            var first = jsDate.getDate() - jsDate.getDay() - 6;
          } else {
            var first = jsDate.getDate() - jsDate.getDay() + 1;
          }
          var last = first + 6; // last day is the first day + 6

          var firstday = new Date((new Date(date.year, date.month - 1, date.day)).setDate(first));
          var lastday = new Date((new Date(date.year, date.month - 1, date.day)).setDate(last));
          this.hour_price = "";
          this.day_price = "";
          this.week_price = "";
          this.month_price = "";
          var month = date.month;
          var year = date.year;

          for(let schedule of this.schedulelist) {
            if(schedule.schedule_frequency == "daily" || schedule.schedule_frequency == "hourly") {
              for(let slot of schedule.slots) {
                let scheduleDate = new Date(<number>slot.date.split("-")[0], <number>slot.date.split("-")[1] - 1, <number>slot.date.split("-")[2])
                let jsDate = new Date(date.year, date.month - 1, date.day);
                if(jsDate.valueOf() == scheduleDate.valueOf()) {
                  if(schedule.schedule_frequency == "daily") {
                    this.day_price = schedule.calculated_price;
                  } else if(schedule.schedule_frequency == "hourly") {
                    this.hour_price = schedule.calculated_price;
                  }
                }
              }
            } else if(schedule.schedule_frequency == "weekly") {
              for(let slot of schedule.slots) {
                let scheduleDate = new Date(<number>slot.date.split("-")[0], <number>slot.date.split("-")[1] - 1, <number>slot.date.split("-")[2])
                if(firstday.valueOf() <= scheduleDate.valueOf() && scheduleDate.valueOf() <= lastday.valueOf()) {
                  this.week_price = schedule.calculated_price;
                }
              }
            } else if(schedule.schedule_frequency == "monthly") {
              for(let slot of schedule.slots) {
                var scheduleMonth = slot.date.split("-")[1];
                var scheduleYear = slot.date.split("-")[0];
                if(scheduleMonth == month && scheduleYear == year) {
                  this.month_price = schedule.calculated_price;
                }
              }
            }
          }

          if(this.week_price.toString().length > 3) {
            (this.el.nativeElement as HTMLElement).style.setProperty('--week-font-size', "min(0.5rem, 2.4vw)");
          } else {
            (this.el.nativeElement as HTMLElement).style.setProperty('--week-font-size', "min(0.6rem, 3vw)");
          }
        }
      }
    );
    return true;
  }

  populateSchedules(data) {
    this.schedulelist = data;
  }

  @ViewChild("productdatepicker", { static: false }) set content(content: ElementRef) {
    if(content) {
      this.schedulelist = [];

      this.apollo
      .query({
        query: this.getProductSchedulesQuery.document,
        variables: {
          productId: <number>this.productId,
          getDaySlots: false
        }
      }).subscribe(
        ({ data }) => {
          if (data["getProductSchedules"]) {
            this.populateSchedules(data["getProductSchedules"]);
            if(this.schedulelist.length > 0) {
              for(let schedule of this.schedulelist) {
                let scheduleStartDate = new Date(<number>schedule.start_date.split("-")[0], <number>schedule.start_date.split("-")[1] - 1, <number>schedule.start_date.split("-")[2])
                if(this.assignedDate == null || (scheduleStartDate.getFullYear() < this.assignedDate.getFullYear()) || 
                (scheduleStartDate.getFullYear() == this.assignedDate.getFullYear() && scheduleStartDate.getMonth() < this.assignedDate.getMonth())) {
                  this.assignedDate = scheduleStartDate;
                }
              }

              if(this.assignedDate == null || (this.assignedDate.getFullYear() < this.currentDate.getFullYear()) || 
              (this.assignedDate.getFullYear() == this.currentDate.getFullYear() && this.assignedDate.getMonth() < this.currentDate.getMonth())) {
                this.assignedDate = this.currentDate;
              }

              this.datepicker.navigateTo({year: this.assignedDate.getFullYear(), month: (<number>this.assignedDate.getMonth() + 1)});

              this.minDate = { year: this.assignedDate.getFullYear(), month: 
                this.assignedDate.getMonth() + 1, day: 1 };

              this._prodScheduleDataLoaded.next("success");
            }
          }
        });

      let ele = content
      .nativeElement
      .firstChild
      .children[1]
      .firstElementChild
      .firstElementChild
      .firstElementChild
      .firstElementChild;
  
      let di = document.createElement("div");
      let sel = content.nativeElement.firstChild.children[0].children[0].children[1];

      di.innerHTML = "<p class='month-price-placeholder'></p>"
      di.className += " month-box "
      di.className += " blue "
      
      ele.className += " ngb-first-dp-day ";
      ele.parentElement.insertBefore(di, ele)
    }
  }

  ngOnInit(): void {
    this._calendarToggleService.loader.subscribe(load => { this.showContainer = load });
  }

  toggle(componentName: string) {

    let eleVar = Array.prototype.slice.call(document.querySelectorAll('div')).filter(function(element) {
      var match = element.id.match(/viewonly-calendar-/gi);
      if (match == null) {
        return false;
      }
      if (match == void 0) {
        return false;
      }
      
      return true;
    });

    eleVar.forEach(element => {
      if(element.id != "viewonly-calendar-" + componentName) {
        element.style.display = "none";
        this.showContainer.set(element.id.replace('viewonly-calendar-', ''), false);
      }
    });

    if(this.showContainer.has(componentName)) {
      if(this.showContainer.get(componentName) == false) {
        this.showContainer.set(componentName, true);
      } else {
        this.showContainer.set(componentName, false);
      }
    } else {
      this.showContainer.set(componentName, true);
    }
    this._calendarToggleService.setLoader(this.showContainer);
    this.changeDetector.detectChanges();
  }
}
