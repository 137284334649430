<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Non-discrimination Policy</h1>
            <p class="has-text-grey-light">
                Last Updated: Jan 06, 2023
            </p>
            <br />
            <span class="box">
                <p>
                    Zigwik is, at its core, an open community dedicated to bringing the world closer together by fostering meaningful, shared experiences among people from all parts of the world. We are an incredibly diverse community, drawing together individuals of different cultures, values, and norms.
                    <br /><br />
                    The Zigwik community is committed to building a world where people from every background feel welcome and respected, no matter how far they have traveled from home. This commitment rests on two foundational principles that apply both to Zigwik's Offerors and Offerees: inclusion and respect. Our shared commitment to these principles enables all members of our community to feel welcome on the Zigwik platform no matter who they are, where they come from, how they worship, or whom they love. Zigwik recognizes that some jurisdictions permit, or require, distinctions among individuals based on factors such as national origin, gender, marital status or sexual orientation, and it does not require Offerors to violate local laws or take actions that may subject them to legal liability. Zigwik will provide additional guidance and adjust this nondiscrimination policy to reflect such permissions and requirements in the jurisdictions where they exist.
                    <br /><br />
                    While we do not believe that one company can mandate harmony among all people, we do believe that the Zigwik community can promote empathy and understanding across all cultures. We are all committed to doing everything we can to help eliminate all forms of unlawful bias, discrimination, and intolerance from our platform. We want to promote a culture within the Zigwik Offerors, Offerees and people just considering whether to use our platform—that goes above and beyond mere compliance. To that end, all of us, Zigwik employees, Offerors and Offerees alike, agree to read and act in accordance with the following policy to strengthen our community and realize our mission of ensuring that everyone can belong, and feels welcome, anywhere.
                </p>
                <ul>
                    <li>
                        <b>Inclusion</b> - We welcome people of all backgrounds with authentic hospitality and open minds. Joining Zigwik, as an Offeror or Offeree, means becoming part of a community of inclusion. Bias, prejudice, racism, and hatred have no place on our platform or in our community. While Offerors are required to follow all applicable laws that prohibit discrimination based on such factors as race, religion, national origin, and others listed below, we commit to do more than comply with the minimum requirements established by law.
                    </li>
                    <li>
                        <b>Respect</b> - We are respectful of each other in our interactions and encounters. Zigwik appreciates that local laws and cultural norms vary around the world and expects Offerors and Offeree to abide by local laws, and to engage with each other respectfully, even when views may not reflect their beliefs or upbringings. Zigwik's members bring to our community an incredible diversity of background experiences, beliefs, and customs. By connecting people from different backgrounds, Zigwik fosters greater understanding and appreciation for the common characteristics shared by all human beings and undermines prejudice rooted in misconception, misinformation, or misunderstanding.
                    </li>
                </ul>
            </span>
            <br />
            <h3>1. What happens when a Offeror does not comply with our policies in this area?</h3>
            <p class="box">
                If a particular Offer contains language contrary to this nondiscrimination policy, the Offeror will be asked to remove the language and affirm his or her understanding and intent to comply with this policy and its underlying principles. Zigwik may also, in its discretion, take steps up to and including suspending the Offeror from the Zigwik platform.
                <br /><br />
                If the Offeror improperly rejects Offerees on the basis of protected class, or uses language demonstrating that his or her actions were motivated by factors prohibited by this policy, Zigwik will take steps to enforce this policy, up to and including suspending the Offeror from the platform.
                <br /><br />
                As the Zigwik community grows, we will continue to ensure that Zigwik's policies and practices align with our most important goal: To ensure that Offerees and Offerors feel welcome and respected in all of their interactions using the Zigwik platform. The public, our community, and we ourselves, expect no less than this.
            </p>
            <br />
            <h3>2. When Offerees are turned down</h3>
            <p class="box">
                Offerors should keep in mind that no one likes to be turned down. While an Offeror may have, and articulate, lawful and legitimate reasons for turning down a potential Offeree, it may cause that member of our community to feel unwelcome or excluded. Offerors should make every effort to be welcoming to Offerees of all backgrounds. Offerors who demonstrate a pattern of rejecting Offerees from a protected class (even while articulating legitimate reasons) undermine the strength of our community by making potential Offerees feel unwelcome, and Zigwik may suspend Offerors who have demonstrated such a pattern from the Zigwik platform.
            </p>
        </div>
    </div>
</div>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
