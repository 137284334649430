import { NgModule } from "@angular/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { ApolloClientOptions, InMemoryCache } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { HttpClientModule, HttpHeaders } from "@angular/common/http";

const uri = "https://zigwik.com/api/core";
// const uri = "http://127.0.0.1:8000/core";
const headers = new HttpHeaders().append(
  "x-ref-key",
  "cadb8e9a-cd87-4f18-9d68-1bc6506c105a"
);
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({
      uri: uri,
      headers: headers,
    }),

    cache: new InMemoryCache(),
  };
}
@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    HttpLink,
  ],
})
export class GraphQLModule {}
