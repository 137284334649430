import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MustMatch } from "../../../helpers/validators/mustmatch.validator";
import { Apollo } from "apollo-angular";
import { ForgetPasswordMutation } from "../../../services/graphql/forgetpassword.graphql";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted = false;
  isPasswordResetSuccess = false;
  isPasswordResetFail = false;

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private forgetPasswordMutation: ForgetPasswordMutation,
    private router: Router
  ) {}

  get f() {
    return this.changePasswordForm.controls;
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group(
      {
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
        confirmemail: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
      },
      {
        validators: MustMatch("email", "confirmemail"),
      }
    );
  }

  changePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.apollo
      .mutate({
        mutation: this.forgetPasswordMutation.document,
        variables: {
          email: this.changePasswordForm.controls.email.value,
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["resetUserPassword"] === "True")
            this.isPasswordResetSuccess = true;
          else {
            this.submitted = false;
            this.isPasswordResetFail = true;
            this.resetForm();
          }
        },
        (error) => {
          this.submitted = false;
          this.isPasswordResetFail = true;
          this.resetForm();
          console.log(error);
        }
      );
  }

  private resetForm() {
    this.changePasswordForm.reset();
  }

  gotoLogin() {
    this.router.navigate(["/login"]);
  }
}
