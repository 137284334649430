import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class ValidateAITextMutation extends Mutation {

  document = gql`
    mutation ValidateAITextMutation(
      $offeror_email: String!
      $id_token: String!
      $input_text: String
      $check_phone_and_email: Boolean
    ) {
      validateAIText(
        offeror_email: $offeror_email,
        id_token: $id_token,
        input_text: $input_text,
        check_phone_and_email: $check_phone_and_email
      )
    }
  `;
}
