import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class AddOfferLockRatingMutation extends Mutation {
  document = gql`
    mutation addOfferLockRatingMutation(
      $user_email: String
      $id_token: String!
      $lock_id: Int!
      $rating: Int!
      $comment: String
    ) {
      productLockRating(
        user_email: $user_email
        id_token: $id_token
        lock_id: $lock_id
        rating: $rating
        comment: $comment
      )
    }
  `;
}
