<div class="dropdown nav">
<button [ngClass]="showWhiteCart ? 'btn-white is-outlined' : ''" class="btn dropdown-toggle" type="button" id="headerOneCartButton"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" > 
    <i class="fas fa-shopping-cart"></i>
    <span class="badge badge-secondary">{{(this.productAddedToCart | async)?.length}}</span>
</button> 
<div class="dropdown-menu dropdown-menu-right" aria-labelledby="headerOneCartButton">
    <ul class="shopping-cart-items" *ngIf="(this.productAddedToCart | async)?.length >= 0">
      <li *ngFor="let data of this.productAddedToCart | async">
        <div class="item-thumb">
          
          <div *ngIf="data.product_image_link != null" class="image">
            <img class="img-fluid" src="{{data.product_image_link}}" alt="Product Image">
          </div>
          <div *ngIf="data.product_image_link == null" class="image">
            <img class="img-fluid" src="{{defaultProductImage}}" alt="Product Image">
          </div>
        </div>
        <div class="item-detail">
            <h2>{{data.title}}</h2>
<ng-template [ngIf]="data.discounted_price != null && data.discounted_price != 0">
              <div class="item-s">{{data.discounted_price | curency}} <i class="fas fa-trash" (click)="removeProductSoftLock(data.id)"></i></div>
            </ng-template>
            <ng-template [ngIf]="data.discounted_price == null || data.discounted_price == 0">
            <div class="item-s">{{data.price | curency}} <i class="fas fa-trash" (click)="removeProductSoftLock(data.id)"></i></div>
</ng-template>
            
        </div>
      </li>
      
      <li>
          <span class="item-summary">Total&nbsp;:&nbsp;<span>{{productTotal | async | curency}}</span>
          </span>
      </li>
      <li>
          <button class="btn btn-secondary btn-block  swipe-to-top" [routerLink]="'/cart'">{{'View Cart' | translate}}</button>
      </li>
    </ul>
    
    <ul class="shopping-cart-items" *ngIf="(this.productAddedToCart | async)?.length==0">
      <li>
        {{'Your cart is currently empty' | translate}}
      </li>
    </ul>
  </div></div>