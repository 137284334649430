import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GetOfferMessageChecksQuery } from "src/app/services/graphql/getOfferMessageChecks.graphql";
import { GetOfferMessagesQuery } from "src/app/services/graphql/getOfferMessages.graphql";
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';
import { SendOfferMessageMutation } from 'src/app/services/graphql/sendOfferMessage.graphql';
import { MarkOfferMessageMutation } from 'src/app/services/graphql/markOfferMessage.graphql';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from "rxjs";
import { SessionService } from 'src/app/services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-offering-chat',
  templateUrl: './offering-chat.component.html',
  styleUrls: ['./offering-chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferingChatComponent implements OnInit {

  messageChecks = [];
  productLockId = null;
  userType = null;
  selectedUser = null;
  selectedMessageCheck = null;
  showLoadMessageButton = false;
  messageOffset = 0;
  messageCount = 10;
  textMessage = "";
  closeOfferLockResult = "";
  productLockDetails = null;

  showUnreadMessageMap = new Map<number, boolean>();
  showUnreadMessageFlags = new BehaviorSubject<Map<number, boolean>>(
    new Map()
  );

  messages = [];

  constructor(
    private getOfferMessageChecksQuery: GetOfferMessageChecksQuery,
    private getOfferMessagesQuery: GetOfferMessagesQuery,
    private getProductLocksQuery: GetProductLocksQuery,
    private apollo: Apollo,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private sendOfferMessageMutation: SendOfferMessageMutation,
    private modalService: NgbModal,
    private markOfferMessageMutation: MarkOfferMessageMutation,
    private toastr: ToastrService,
    public router: Router
    ) { }

  ngOnInit(): void {
  }

  fetchOfferMessageChecks(value) {
    this.route.paramMap.subscribe(
      params => {
        this.productLockId = +params.get('id');
      }
    );

    this.showUnreadMessageMap = new Map<number, boolean>();
    this.messageOffset = 0;

    var element = document.getElementById("chat-check-div");
    if(!element?.classList.contains('hidden-chat')) {
      element.classList.add("hidden-chat");
    }
    this.userType = value
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .query({
        query: this.getOfferMessageChecksQuery.document,
        variables: {
          userEmail: email,
          idToken: token,
          userType: this.userType
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          if(data["getMessageChecks"].length > 0) {
            var checkShowFlag = "false";
            data["getMessageChecks"].forEach(messageCheck => {
              if(this.productLockId != null && this.productLockId != "" && this.productLockId != 0 && messageCheck['lock_id'] == this.productLockId) {
                checkShowFlag = "true";
              }
            });
            if(this.productLockId == null || this.productLockId == "" || this.productLockId == 0) {
              checkShowFlag = "true";
            }
            if(checkShowFlag === "true") {
              this.messageChecks = data["getMessageChecks"];
              var element = document.getElementById("chat-check-div");
              if(element?.classList.contains('hidden-chat')) {
                element.classList.remove("hidden-chat");
              }
  
              if(this.productLockId == null || this.productLockId == 0) {
                this.selectedMessageCheck = data["getMessageChecks"][0];
                this.productLockId = data["getMessageChecks"][0]["lock_id"];
                this.setSelectedUser(data["getMessageChecks"][0]);
  
                this.getOfferLockDetails(data["getMessageChecks"][0]["lock_id"]);
              
                this.loadMoreMessages(true);
              } else {
                data["getMessageChecks"].forEach(messageCheck => {
                  if(messageCheck['lock_id'] == this.productLockId) {
                    this.selectedMessageCheck = messageCheck;
                    this.setSelectedUser(messageCheck);
                  }
                });
                this.getOfferLockDetails(this.productLockId);
              
                this.loadMoreMessages(true);
              }
              this.markMessage(this.productLockId);
            } else {
              this.toastr.error("No active communication window. Please try selecting a different user type.");
            }
          } else {
            this.toastr.error("No active communication window.");
          }

        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  setSelectedUser(messageCheckValue) {
    if(this.userType == "offeror") {
      this.selectedUser = {
        "user_full_name": messageCheckValue["offeror_full_name"],
        "user_id": messageCheckValue["offeror_id"],
        "user_profile_link": messageCheckValue["offeror_image_url"],
        "offer_title": messageCheckValue["product_title"],
        "offer_id": messageCheckValue["product_id"],
        "other_user_id": messageCheckValue["offeree_id"],
        "other_user_full_name": messageCheckValue["offeree_full_name"],
        "other_user_profile_link": messageCheckValue["offeree_image_url"]
      }
    } 
    
    if(this.userType == "offeree") {
      this.selectedUser = {
        "user_full_name": messageCheckValue["offeree_full_name"],
        "user_id": messageCheckValue["offeree_id"],
        "user_profile_link": messageCheckValue["offeree_image_url"],
        "offer_title": messageCheckValue["product_title"],
        "offer_id": messageCheckValue["product_id"],
        "other_user_id": messageCheckValue["offeror_id"],
        "other_user_full_name": messageCheckValue["offeror_full_name"],
        "other_user_profile_link": messageCheckValue["offeror_image_url"]
      }
    }
  }

  changeUserChatWindow(messageCheckValue) {
    this.productLockId = messageCheckValue["lock_id"];
    this.messageOffset = 0;
    this.selectedMessageCheck = messageCheckValue;
    this.setSelectedUser(messageCheckValue);
    this.getOfferLockDetails(messageCheckValue["lock_id"]);
    this.loadMoreMessages(true);
    this.markMessage(messageCheckValue["lock_id"]);
  }

  getOfferLockDetails(lockId) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
              .query({
                query: this.getProductLocksQuery.document,
                variables: {
                  id_token: token,
                  user_email: email,
                  user_type: this.userType,
                  lock_id: lockId
                },
                fetchPolicy: "network-only"
              })
              .subscribe(
                ({ data }) => {
                  this.productLockDetails = data["getProductLocks"][0];
                },
                (error) => {
                }
              );
  }

  loadMoreMessages(fresh = false) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    var ul = document.getElementById("chat-ul");
    if(fresh == true) {
      ul.innerHTML = "";
    }

    this.apollo
              .query({
                query: this.getOfferMessagesQuery.document,
                variables: {
                  userEmail: email,
                  idToken: token,
                  lockId: this.productLockId,
                  offset: this.messageOffset,
                  count: this.messageCount,
                  sortOrder: 'desc'
                },
                fetchPolicy: "network-only"
              })
              .subscribe(
                ({ data }) => {
                  if(data["getMessages"].length > 0) {
                    this.messageOffset = this.messageOffset + this.messageCount;
                    var ul = document.getElementById("chat-ul");
                    if(fresh == true) {
                      ul.innerHTML = "";
                    }
                    data["getMessages"].forEach(message => {
                      var li = document.createElement("li");
                      li.classList.add("clearfix");
                  
                      var div = document.createElement("div");
                      div.classList.add("message-data");

                      var div2 = document.createElement("div");
                      div2.classList.add("message");
                  
                      var span = document.createElement("span");
                      span.classList.add("message-data-time");
                      span.appendChild(document.createTextNode(message["created_at"]));
                      div.appendChild(span);
                      var oImg = document.createElement("img");
                      oImg.setAttribute('alt', 'avatar');
                      if(this.userType == message['user_type']) {
                        oImg.setAttribute('src', this.selectedUser['user_profile_link']);
                        div.appendChild(oImg);
                        div.classList.add("text-right");
                        div2.classList.add("other-message");
                        div2.classList.add("float-right");
                      } else {
                        oImg.setAttribute('src', this.selectedUser['other_user_profile_link']);
                        div.appendChild(oImg);
                        div2.classList.add("my-message");
                      }

                      if(message["broadcast_flag"]) {
                        div2.classList.add("broadcast-same-user"); 
                      }

                      li.appendChild(div);
                      div2.appendChild(document.createTextNode(message["message"]));
                      li.appendChild(div2);
                      ul.insertBefore(li, ul.firstChild);
                    });

                    if(data["getMessages"].length >= 10) {
                      this.showLoadMessageButton = true;
                    } else {
                      this.showLoadMessageButton = false;
                    }
                  } else {
                    this.toastr.success("No messages.");
                  }
                },
                (error) => {
                  this.toastr.error(error.message);
                }
              );
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeOfferLockResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeOfferLockResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  markMessage(lockId) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .mutate({
        mutation: this.markOfferMessageMutation.document,
        variables: {
          user_email: email,
          id_token: token,
          lock_id: lockId,
          user_type: this.userType
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.showUnreadMessageMap.set(lockId, false);
          this.showUnreadMessageFlags.next(this.showUnreadMessageMap);
        },
        (error) => {
          
        }
      );
  }

  sendMessage() {

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    if(this.selectedMessageCheck != null) {
      this.apollo
      .mutate({
        mutation: this.sendOfferMessageMutation.document,
        variables: {
          user_email: email,
          id_token: token,
          order_id: this.selectedMessageCheck["order_id"],
          lock_id: this.selectedMessageCheck["lock_id"],
          message: this.textMessage,
          communication_window_check_id: this.selectedMessageCheck["communication_window_check_id"],
          invoice_id: this.selectedMessageCheck["invoice_id"],
          offeror_id: this.selectedMessageCheck["offeror_id"],
          offeree_id: this.selectedMessageCheck["offeree_id"],
          user_type: this.userType
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.markMessage(this.selectedMessageCheck["lock_id"]);
          var ul = document.getElementById("chat-ul");
          var li = document.createElement("li");
          li.classList.add("clearfix");
      
          var div = document.createElement("div");
          div.classList.add("message-data");

          var div2 = document.createElement("div");
          div2.classList.add("message");
      
          var span = document.createElement("span");
          span.classList.add("message-data-time");
          span.appendChild(document.createTextNode("Now"));
          div.appendChild(span);
          var oImg = document.createElement("img");
          oImg.setAttribute('alt', 'avatar');
          oImg.setAttribute('src', this.selectedUser['user_profile_link']);
          div.appendChild(oImg);
          div.classList.add("text-right");
          div2.classList.add("other-message");
          div2.classList.add("float-right");

          li.appendChild(div);
          div2.appendChild(document.createTextNode(this.textMessage));
          li.appendChild(div2);
          ul.appendChild(li);
          this.textMessage = "";
        },
        (error) => {
          
        }
      );
    }
  }

}
