import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Apollo } from "apollo-angular";
import { GetLenderProductListQuery } from "src/app/services/graphql/getlenderproductlist.graphql";
import { SessionService } from "src/app/services/session.service";
import { ActivateProductMutation } from "../../../services/graphql/activateProduct.graphql";
import { DeactivateProductMutation } from "../../../services/graphql/deactivateProduct.graphql";
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-my-offerings",
  templateUrl: "./my-offerings.component.html",
  styleUrls: ["./my-offerings.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MyOfferingsComponent implements OnInit {
  productList = [];
  user_id = null;
  closeProductLinkResult = "";
  loc = "";

  constructor(
    private getLenderProductListQuery: GetLenderProductListQuery,
    private apollo: Apollo,
    private modalService: NgbModal,
    private sessionService: SessionService,
    private activateProductMutation: ActivateProductMutation,
    private deactivateProductMutation: DeactivateProductMutation,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.loc = window.location.host;
    this.user_id = this.sessionService.getKeyValues("token", "id");
    this.callGetLenderProducts();
  }

  toggleProductStatus(productId, productStatus) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let activateProduct = "false";

    if(productStatus == 1) {
      activateProduct = "false";
    } else {
      activateProduct = "true";
    }

    if(activateProduct == "true") {
      this.apollo.mutate({
        mutation: this.activateProductMutation.document,
        variables: {
          product_id: <number>productId,
          id_token: token,
          lender_email: email
        }
      }).subscribe(
        ({ data }) => {
          if(data["activateProduct"] == "True") {
            this.callGetLenderProducts();
            this.toastr.success("Request completed.");
          } else {
            this.toastr.error("Request was not completed successfully.");
          }
        },
        (error) => {
          this.toastr.error(error.message);
        });
    } else if(activateProduct == "false") {
      this.apollo
      .mutate({
        mutation: this.deactivateProductMutation.document,
        variables: {
          product_id: <number>productId,
          id_token: token,
          lender_email: email
        }
      }).subscribe(
        ({ data }) => {
          if(data["forcedDeactivateProduct"] == "true") {
            this.callGetLenderProducts();
            this.toastr.success("Request completed."); 
          } else {
            this.toastr.error("Request was not completed successfully.");
          }
        },
        (error) => {
          this.toastr.error(error.message);
        });
    }
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeProductLinkResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeProductLinkResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  callGetLenderProducts() {
    let id_token = this.sessionService.getKeyValues("token", "id_token");
    let emailId = this.sessionService.getLocalKeyValues("user", "email");
    this.apollo
      .query({
        query: this.getLenderProductListQuery.document,
        fetchPolicy: "network-only",
        variables: {
          email: emailId,
          id_token: id_token,
        },
      })
      .subscribe(
        ({ data }) => {
          this.productList = data["getLenderProductList"];
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
