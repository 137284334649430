<!-- Footer Mobile -->

<footer id="footerMobile" class="footer-area footer-mobile d-lg-none d-xl-none">
      
    <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="single-footer">
              
              <div class="pro-about">
                  <a href=""  data-toggle="collapse" data-target="#collapseFooter" aria-expanded="false" aria-controls="collapseFooter">Store</a>
                  <div class="collapse" id="collapseFooter">
                      <div class="card card-body">
                  <ul class="pl-0 mb-0">
                    <li>
                      <app-logo></app-logo>
                    </li>
                    <li> <span>{{'401, Snehi Sadan, Opp G.I.D.C., Antalia, Navsari, Gujarat: 396325' | translate}}</span> </li>
                    <li><a aria-label="Chat on WhatsApp" href="https://wa.me/+918490097007?text=Hi,%20I%20need%20some%20support"> <img alt="Chat on WhatsApp" src="assets/images/miscellaneous/WhatsAppButtonGreenLarge.png" /></a></li>
                    <li><span>+918490097007</span><span><a href="#">team@zigwik.com</a> </span> </li>
              
                  </ul>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2">
            
              <div class="single-footer">
                  <a href=""  data-toggle="collapse" data-target="#collapseFooterOne" aria-expanded="false" aria-controls="collapseFooterOne">Info</a>
                  <div class="collapse" id="collapseFooterOne">
                      <div class="card card-body">
                      <ul class="pl-0 mb-0">
                          <li> <a [routerLink]="'/'">{{'Home' | translate}}</a> </li>
                          <li> <a [routerLink]="'/blog'">{{'Blog' | translate}}</a> </li>
                          <li> <a [routerLink]="'/about-us'">{{'About Us' | translate}}</a> </li>
                          <li> <a [routerLink]="'/contact-us'">{{'Contact Us' | translate}}</a> </li>
                        </ul>
                        </div>
                    </div>
                  
                </div>
          </div>
          <div class="col-12 col-lg-2">
              <div class="single-footer">
                  <a href=""  data-toggle="collapse" data-target="#collapseFooterTwo" aria-expanded="false" aria-controls="collapseFooterTwo">{{'Our Polices' | translate}}</a>
                <div class="collapse" id="collapseFooterTwo">
                    <div class="card card-body">
                <ul class="pl-0 mb-0">
                  <li> <a [routerLink]="'/faq'">{{'FAQs' | translate}}</a> </li>
                  <li> <a [routerLink]="'/terms'">{{'Terms of Service' | translate}}</a> </li>
                    <li> <a [routerLink]="'/privacy'">{{'Privacy Policy' | translate}}</a> </li>
                    <li> <a [routerLink]="'/refund'">{{'Refund Policy' | translate}}</a> </li>  
                    <li> <a [routerLink]="'/review-policy'">{{'Review Policy' | translate}}</a> </li>
                    <li> <a [routerLink]="'/cookie-policy'">{{'Cookie Policy' | translate}}</a> </li>
                    <li> <a [routerLink]="'/copyright-policy'">{{'Copyright Policy' | translate}}</a> </li>
                    <li> <a [routerLink]="'/nondiscrimination-policy'">{{'Non-discrimination Policy' | translate}}</a> </li>
                    <li> <a [routerLink]="'/content-policy'">{{'Content Policy' | translate}}</a> </li>    
                    <li> <a [routerLink]="'/shipping-policy'">{{'Shipping & Delivery Policy' | translate}}</a> </li>
                      
                </ul>
                </div>
                </div>
              </div>
            </div>
          <div class="col-12 col-lg-2">
            <div class="single-footer">
              <a href=""  data-toggle="collapse" data-target="#collapseFooterThree" aria-expanded="false" aria-controls="collapseFooterThree">{{'My Account' | translate}}</a>
              <div class="collapse" id="collapseFooterThree">
                  <div class="card card-body">
              <ul class="pl-0 mb-0">
                
                <li> <a [routerLink]="'/my-orders'">{{'Orders'}}</a> </li>
                <li> <a [routerLink]="'/cart'">{{'Shopping Cart' | translate}}</a> </li>   
                <li> <a [routerLink]="'/new-offering'">{{'Create Offer' | translate}}</a> </li>        
              </ul>
              </div>
              </div>
            </div>
          </div>
          
          <div class="col-12 col-lg-3">
            <div class="single-footer">
                <div class="pro-newsletter">
                    <h5>{{'Newsletter' | translate}}</h5>
                    
                    <form class="form-inline">
                        <div class="search"> 
                          <input #news_registration type="search" id="user_news_letter_registration" placeholder="Your Email ...">
                          
                            <button type="button" class="btn btn-secondary" (click)="addRegistration(news_registration.value)">
                                <i class="fas fa-location-arrow"></i>
                            </button>
                        </div>
                        
                      </form>
                      <p>{{'By entering your email, you agree to our Terms of Service and Privacy Policy.' | translate}}</p>
                </div>
                
                <div class="pro-socials">
                    <h5>{{'Follow Us' | translate}}</h5>
                    <ul>
                      <li><a href="https://www.facebook.com/zigwiktime" class="fab fb fa-facebook-square"></a></li>
                      <li><a href="https://twitter.com/zigwik" class="fab tw fa-twitter-square"></a></li>
                      <li><a href="https://www.linkedin.com/company/zigwik/" class="fab ln fa-linkedin"></a></li>
                      <li><a href="https://www.instagram.com/zigwikworld/" class="fab ig fa-instagram"></a></li>
                  </ul>
                </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid p-0">
          <div class="copyright-content">
              <div class="container">
                <div class="row align-items-center">
                    
                    <div class="col-12 col-sm-12">
                      <div class="footer-info">
                          &copy;&nbsp;2024 Zigwik® Pvt Ltd <a [routerLink]="'/privacy'">{{'Privacy' | translate}}</a>&nbsp;&bull;&nbsp;<a [routerLink]="'/terms'">{{'Terms' | translate}}</a>
                      </div>
                      
                    </div>
                </div>
              </div>
              
            </div>
      </div>

    </footer>

    <div class="mobile-overlay"></div>