import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
@Injectable({
  providedIn: "root",
})
export class GetProductDetailsQuery extends Query {
  document = gql`
    query getProductDetailsQuery( $product_id:Int, $product_name:String, $id_token: String,$lender_email:String) {
        getProductDetails(product_id:$product_id, product_name:$product_name, id_token: $id_token,lender_email:$lender_email) {
            id
            lender_id
            lender_first_name
            lender_last_name
            lender_email
            category_id
            category
            sub_category1
            sub_category2
            category_group
            title
            product_name
            product_description
            product_markdown_description
            age
            guidelines
            markdown_guidelines
            status
            address_line1
            address_line2
            landmark
            latitude
            longitude
            area_id
            area_name
            pin_code
            city
            district
            state
            country
            country_abbreviation
      }
    }
  `;
}