<div class="product-list-main">
  <div class="list-thumb">
    <img [src]="data?.product_image_link || data.products_image" alt="No Image" />
  </div>
  <div class="like" *ngIf="userLoginCheck" (click)="onAddLike(data.products_id)">
    <i class="far fa-heart" aria-hidden="true"></i>

  </div>
  <div class="list-details">
    <div class="list-titles">
      <!-- <p class="list-catagory">Web & App Design</p> -->
      <a
        [routerLink]="
          data.product_name == null || data.product_name == ''
            ? ['/offer/', data.products_id]
            : ['/offername/', data.product_name]
        "
        routerLinkActive="router-link-active"
        class="list-title"
      >
        {{ data?.title }}</a
      >
      <div class="rating pt-2" *ngIf="data.rating > 0">
        <ngb-rating
          [(rate)]="data.rating"
          [readonly]="true"
          [max]="1"
        ></ngb-rating>
        <span class="rating-title"> {{(data.rating)/2 +" "}} Rating</span>
      </div>
      <button class="calender-btn" (click)="openModel()"><i class="fas fa-calendar-alt"></i></button>
      <!-- <app-date-picker-calendar [productId]="data.products_id"></app-date-picker-calendar> -->
    </div>
    <div class="list-price">
      
      <p class="price">
        <ng-template [ngIf]="data.products_hour_price !== '' && data.products_hour_price !== null ">
          <ng-template [ngIf]="data.products_hour_discounted_price !== '' && data.products_hour_discounted_price !== null && data.products_hour_price !== 0">
            <div class="pro-price">
              Hour Price:&nbsp;<s>{{ data.products_hour_price | curency }}</s>&nbsp;<ins>{{ data.products_hour_discounted_price | curency }}
                <ng-template [ngIf]="data.hourly_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>  
          </ng-template>
          <ng-template [ngIf]="data.products_hour_discounted_price === '' || data.products_hour_discounted_price === null || data.products_hour_price === 0 ">
            <div class="pro-price">
              Hour Price:&nbsp;<ins>{{ data.products_hour_price | curency }}
                <ng-template [ngIf]="data.hourly_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>  
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="data.products_day_price !== '' && data.products_day_price !== null ">
          <ng-template [ngIf]="data.products_day_discounted_price !== '' && data.products_day_discounted_price !== null && data.products_day_price !== 0 ">
            <div class="pro-price">
              Day Price:&nbsp;<s>{{ data.products_day_price | curency }}</s>&nbsp;<ins>{{ data.products_day_discounted_price | curency }}
                <ng-template [ngIf]="data.daily_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
          <ng-template [ngIf]="data.products_day_discounted_price === '' || data.products_day_discounted_price === null || data.products_day_price === 0">
            <div class="pro-price">
              Day Price:&nbsp;<ins>{{ data.products_day_price | curency }}
                <ng-template [ngIf]="data.daily_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="data.products_week_price !== '' && data.products_week_price !== null ">
          <ng-template [ngIf]="data.products_week_discounted_price !== '' && data.products_week_discounted_price !== null && data.products_week_price !== 0 ">
            <div class="pro-price">
              Week Price:&nbsp;<s>{{ data.products_week_price | curency }}</s>&nbsp;<ins>{{ data.products_week_discounted_price | curency }}
                <ng-template [ngIf]="data.weekly_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
          <ng-template [ngIf]="data.products_week_discounted_price === '' || data.products_week_discounted_price === null || data.products_week_price === 0">
            <div class="pro-price">
              Week Price:&nbsp;<ins>{{ data.products_week_price | curency }}
                <ng-template [ngIf]="data.weekly_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="data.products_month_price !== '' && data.products_month_price !== null ">
          <ng-template [ngIf]="data.products_month_discounted_price !== '' && data.products_month_discounted_price !== null && data.products_month_price !== 0 ">
            <div class="pro-price">
              Month Price:&nbsp;<s>{{ data.products_month_price | curency }}</s>&nbsp;<ins>{{ data.products_month_discounted_price | curency }}
                <ng-template [ngIf]="data.monthly_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
          <ng-template [ngIf]="data.products_month_discounted_price === '' || data.products_month_discounted_price === null || data.products_month_price === 0">
            <div class="pro-price">
              Month Price:&nbsp;<ins>{{ data.products_month_price | curency }}
                <ng-template [ngIf]="data.monthly_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
        </ng-template>
        <ng-template [ngIf]="data.products_custom_price !== '' && data.products_custom_price !== null ">
          <ng-template [ngIf]="data.products_custom_discounted_price !== '' && data.products_custom_discounted_price !== null && data.products_custom_price !== 0 ">
            <div class="pro-price">
              Custom Price:&nbsp;<s>{{ data.products_custom_price | curency }}</s>&nbsp;<ins>{{ data.products_custom_discounted_price | curency }}
                <ng-template [ngIf]="data.custom_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
          <ng-template [ngIf]="data.products_custom_discounted_price === '' || data.products_custom_discounted_price === null || data.products_custom_price === 0">
            <div class="pro-price">
              Custom Price:&nbsp;<ins>{{ data.products_custom_price | curency }}
                <ng-template [ngIf]="data.custom_multiple_price_flag == true">
                  *
                </ng-template>
              </ins>
            </div>
          </ng-template>
        </ng-template>
      </p>
      <div class="user_name" [routerLink]="['/public-profile/user/', data?.hashedId]">
        <img [src]="data?.offeror_profile_image_name ? profileURL+ data?.offeror_profile_image_name : defaultProfile" alt="">
        <p>{{data?.offeror_first_name}} {{data.offeror_last_name}}</p>
      </div>
    </div>
  </div>

  <span>
    <div id="myModal" class="modal" [ngClass]="openModelBox ? 'show' : 'hide' " >

      <!-- Modal content -->
      <div class="modal-content">
        <button (click)="openModel()" class="close-btn">&times;</button>
        <div class="content">
          <app-date-picker-calendar [productId]="data.products_id"></app-date-picker-calendar>
        </div>
      </div>
    
    </div>
  </span>
</div>
