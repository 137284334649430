import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class GenerateUserAIDescriptionMutation extends Mutation {

  document = gql`
    mutation GenerateUserAIDescriptionMutation(
      $email: String!
      $id_token: String!
      $input_text: String
    ) {
      generateUserAIDescription(
        email: $email,
        id_token: $id_token,
        input_text: $input_text
      )
    }
  `;
}
