<ul class="list-group mb-4">
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="['/profile']">
            <i class="fas fa-user"></i>
            {{'Profile' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="['/public-profile/user/', inputUserId]">
            <i class="fas fa-user"></i>
            {{'Public Profile' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/cart'">
            <i class="fas fa-shopping-cart"></i>
            {{'My Cart' | translate}}       
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/my-orders'">
            <i class="fas fa-handshake"></i>
            {{'Orders' | translate}}                 
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/change-password'">
            <i class="fas fa-lock"></i>
            {{'Change Password' | translate}}                           
        </a>
    </li>
    
  </ul>