<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div *ngIf="isOfferImage === true" class="row">
          <div class="column">
            <img [src]="offerImage1" alt="Offer" width="100%" height="100%">
            <a *ngIf="offerImageId1 !== 0 && offerImageId1 !== null" (click)="deleteImage(offerImageId1)"><i class="fas fa-trash-alt text-secondary"></i></a>
          </div>
          <div class="column">
            <img [src]="offerImage2" alt="Offer" width="100%" height="100%">
            <a *ngIf="offerImageId2 !== 0 && offerImageId2 !== null" (click)="deleteImage(offerImageId2)"><i class="fas fa-trash-alt text-secondary"></i></a>
          </div>
          <div class="column">
            <img [src]="offerImage3" alt="Offer" width="100%" height="100%">
            <a *ngIf="offerImageId3 !== 0 && offerImageId3 !== null" (click)="deleteImage(offerImageId3)"><i class="fas fa-trash-alt text-secondary"></i></a>
          </div>
          <div class="column">
            <img [src]="offerImage4" alt="Offer" width="100%" height="100%">
            <a *ngIf="offerImageId4 !== 0 && offerImageId4 !== null" (click)="deleteImage(offerImageId4)"><i class="fas fa-trash-alt text-secondary"></i></a>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input id="imageFileUpload" class="form-control" type="file" (change)="onChange($event)">
            <div class="p-2">
              <img class="cropimage" id="imageCropping" #image [src]="imageSource" crossorigin />
          </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button id="modalImageUploaderButton" type="button" class="button is-outlined is-primary" (click)="saveImage(modal)">Save</button>
    </div>
  </ng-template>
  
  <a *ngIf="isProfileImage === true" (click)="open(content)"><i class="fas fa-edit text-secondary"></i></a>

  <button *ngIf="isOfferImage === true" [disabled]="productID === 0 || productID === null" class="button is-outlined is-primary" (click)="open(content)">Add Images</button>