<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Privacy Policy</h1>
            <p class="has-text-grey-light">
                Last Updated: Jan 06, 2023
            </p>
            <p class="box">
                Our Privacy Policy explains what personal information we collect, how we use personal information, how personal information is shared, and privacy rights.
                <br /><br />
                Zigwik exists to help build connections between people and make the world more open and inclusive. In short—to build a world where anyone can share any product or service with others. We are a community built on trust. A fundamental part of earning that trust means being clear about how we use your information and protecting your human right to privacy.
                <br /><br />
                This Privacy Policy describes how Zigwik Pvt. Ltd. and its affiliates ("we," "us," or "Zigwik"), process personal information in relation to your use of the Zigwik Platform. Depending on where you live and what you are doing on the Zigwik Platform, the supplemental privacy pages listed below may apply to you.
            </p>
            <br />
            <h3>1. Definitions</h3>
            <p class="box">
                Undefined terms in this Privacy Policy have the same definition as in our <a [routerLink]="'/terms'">Terms of Service</a>.
            </p>
            <br />
            <h3>2. Personal information we collect</h3>
            <span class="box"><p>
                <b>2.1 Information needed to use the Zigwik Platform</b></p>
                We collect personal information about you when you use the Zigwik Platform. Without it, we may not be able to provide all services requested. This information includes:
                <ul>
                    <li>
                        <b>Contact Information, Account, Profile Information.</b> Such as your first name, last name, phone number, postal address, email address, date of birth, and profile photo, some of which will depend on the features you use.
                    </li>
                    <li>
                        <b>Identity Verification and Payment Information.</b> Such as images of your government issued ID (as permitted by applicable laws), your ID number or other verification information, bank account or payment account information. If you are not an Zigwik user, we may receive payment information relating to you, such as when an Zigwik user provides your payment card to complete a booking. If a copy of your ID is provided to us, we may scan, use, and store information contained in your ID to verify your identity.
                    </li>
                </ul>
                <p>
                    <b>2.2 Information you choose to give us.</b>
                </p>
                <p>
                    You can choose to provide us with additional personal information. This information may include:
                </p>
                <ul>
                    <li>
                        <b>Additional Profile Information.</b> Such as gender, preferred language(s), and personal description. Some of this information as indicated in your account settings is part of your public profile page and will be publicly visible.
                    </li>
                    <li>
                        <b>Address Book Contact Information.</b> Address book contacts you import or enter manually.
                    </li>
                    <li>
                        <b>Other Information.</b> Such as when you fill in a form, add information to your account, respond to surveys, post to community forums, participate in promotions, communicate with our customer care team and other Members, or share your experience with us. This may include health information if you choose to share it with us.
                    </li>
                </ul>
                <p>
                    <b>2.3 Information Automatically Collected by Using the Zigwik Platform.</b>
                </p>
                <p>
                    When you use the Zigwik Platform, we automatically collect personal information. This information may include:
                </p>
                <ul>
                    <li>
                        <b>Geo-location Information.</b> Such as precise or approximate location determined from your IP address or mobile device's GPS depending on your device settings. We may also collect this information when you're not using the app if you enable this through your settings or device permissions.
                    </li>
                    <li>
                        <b>Usage Information.</b> Such as the pages or content you view, searches for Offers, bookings you have made, and other actions on the Zigwik Platform.
                    </li>
                    <li>
                        Log Data and Device Information. Such as details about how you've used the Zigwik Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you've viewed or engaged with before or after using the Zigwik Platform. We may collect this information even if you haven't created an Zigwik account or logged in.
                    </li>
                    <li>
                        <b>Cookies and Similar Technologies as described in our <a [routerLink]="'/cookie-policy'">Cookie Policy</a></b>.
                    </li>
                    <li>
                        <b>Payment Transaction Information.</b> Such as payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, PayPal email address, IBAN information, your address, and other related transaction details.
                    </li>
                </ul>
                <p>
                    <b>2.4 Personal Information We Collect from Third Parties.</b>
                </p>
                <p>
                    We collect personal information from other sources, such as:
                </p>
                <ul>
                    <li>
                        <b>Third-Party Services.</b> If you link, connect, or login to the Zigwik Platform with a third party service (e.g. Google, Facebook, WeChat), you direct the service to send us information such as your registration, friends list, and profile information as controlled by that service or as authorized by you via your privacy settings at that service.
                    </li>
                    <li>
                        <b>Other Sources.</b> To the extent permitted by applicable law, we may receive additional information about you, such as references, demographic data, or information to help detect fraud and safety issues from third party service providers and partners, and combine it with information we have about you. For example, we may receive background check results or fraud warnings from identity verification service providers for use in our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the Zigwik Platform, or about your experiences and interactions from our partners. We may receive health information, including but not limited to, health information related to contagious diseases.
                    </li>
                </ul>
                </span>
            <br />
            <h3>3. How we use information we collect</h3>
            <span class="box">
                <p>
                    <b>3.1 Provide, Improve, and Develop the Zigwik Platform.</b>
                </p>
                <p>
                    Such as to:
                </p>
                <ul>
                    <li>
                        enable you to access the Zigwik Platform and make and receive payments,
                    </li>
                    <li>
                        enable you to communicate with other Members,
                    </li>
                    <li>
                        process your request,
                    </li>
                    <li>
                        perform analytics, debug and conduct research,
                    </li>
                    <li>
                        provide customer service, training,
                    </li>
                    <li>
                        send you messages, updates, security alerts, and account notifications,
                    </li>
                    <li>
                        if you provide us with your contacts' information, such as your friends or co-travelers, we may process this information: (i) to facilitate your referral invitations, (ii) to share your trip details and facilitate trip planning, (iii) for fraud detection and prevention, and (iv) to facilitate your requests or for any other purpose you authorize,
                    </li>
                    <li>
                        personalize and customize your experience based on your interactions with the Zigwik Platform, your search and booking history, your profile information and preferences, and other content you submit, and
                    </li>
                </ul>
                <p>
                    <b>3.2 Create and Maintain a Trusted and Safer Environment.</b>
                </p>
                <p>
                    Including to:
                </p>
                <ul>
                    <li>
                        detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity,
                    </li>
                    <li>
                        study and combat discrimination consistent with our <a [routerLink]="'/nondiscrimination-policy'">Nondiscrimination Policy</a>,
                    </li>
                    <li>
                        conduct security investigations and risk assessments,
                    </li>
                    <li>
                        verify or authenticate information provided by you,
                    </li>
                    <li>
                        conduct checks against databases and other information sources, including background or police checks,
                    </li>
                    <li>
                        comply with our legal obligations, protect the health and well-being of our Offerees, Offerors, and members of the public,
                    </li>
                    <li>
                        resolve disputes with our Members,
                    </li>
                    <li>
                        enforce our agreements with third parties,
                    </li>
                    <li>
                        comply with law, respond to legal requests, prevent harm, and protect our rights
                    </li>
                    <li>
                        enforce our <a [routerLink]="'/terms'">Terms</a> and other policies (e.g. <a [routerLink]="'/nondiscrimination-policy'">Nondiscrimination Policy</a>), and
                    </li>
                    <li>
                        in connection with the activities above, we may conduct profiling based on your interactions with the Zigwik Platform, your profile information and other content you submit to Zigwik, and information obtained from third parties. In limited cases, automated processes, which analyze your account and activities on the Zigwik platform as well as information in relation to activities on and off the Zigwik platform that can be associated with you, could restrict or suspend access to the Zigwik Platform if such processes detect activity that may pose a safety or other risk to Zigwik, our community, or third parties. If you would like to challenge decisions based on automated processes, please contact us via the Contact Information section below.
                    </li>
                </ul>
                <p>
                    <b>3.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing.</b>
                </p>
                <p>
                    For example to:
                </p>
                <ul>
                    <li>
                        send you promotional messages, marketing, advertising, and other information based on your preferences and social media advertising through social media platforms,
                    </li>
                    <li>
                        personalize, measure, and improve our advertising,
                    </li>
                    <li>
                        administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Zigwik or its third-party partners,
                    </li>
                    <li>
                        analyze characteristics and preferences to send you promotional messages, marketing, advertising, and other information that we think might be of interest to you, and
                    </li>
                    <li>
                        invite you to events and relevant opportunities.
                    </li>
                </ul>
            </span>
            <br />
            <h3>4. Sharing and Disclosure</h3>
            <span class="box">
                <p>
                    <b>4.1 Sharing With Your Consent or at Your Direction.</b>
                </p>
                <p>
                    Where you provide consent, we share your information as described at the time of consent, such as when authorizing a third-party application or website to access your Zigwik account or participating in promotional activities by Zigwik partners or third parties.
                    <br /><br />
                    Where permissible under applicable law, we may use certain information about you such as your email address, de-identify it, and share it with social media platforms to generate leads, drive traffic to Zigwik, or otherwise promote our products and services.
                </p>
                <p>
                    <b>4.2 Sharing Between Members.</b>
                </p>
                <p>
                    To help facilitate bookings or other interactions between Members, we may need to share certain information such as:
                </p>
                <ul>
                    <li>
                        When a booking request is made, or a dispute is submitted, certain information may be shared between Offeror(s) and Offeree(s), including profile, name, cancellation history, review information, age of Offeree (unless prohibited by applicable law), dispute outcome (when applicable), and other information you choose to share and submit. When a booking is confirmed, additional information is shared to assist with the offer, like public profile. When you as an Offeror have a confirmed booking, certain information is shared with the Offeree to coordinate the booking, such as your profile, full name, and Offer details.
                    </li>
                </ul>
                <p>
                    <b>
                        4.3 Information You Publish in Profiles, Bookings, and other Public Information.
                    </b>
                </p>
                <p>
                    You can make certain information publicly visible to others, such as:
                </p>
                <ul>
                    <li>
                        Your public profile page, which includes your profile photo, first name (or initials where applicable), description, and Offers.
                    </li>
                    <li>
                        Offer pages that include information such as approximate or precise location description, calendar availability, profile photo, Offer photos/thumbnails, aggregated demand information (like page views over a period of time), rating and offer likes and additional information you choose to share.
                    </li>
                    <li>
                        Reviews, ratings, and other public feedback.
                    </li>
                    <li>
                        Content in a community or discussion forum, blog, or social media post.
                    </li>
                </ul>
                <p>
                    We may display parts of your public profile and other Content you make available to the public like booking details on third-party sites, platforms, and apps.
                    <br /><br />
                    Information you share publicly on the Zigwik Platform may be indexed through third-party search engines.
                </p>
                <p>
                    <b>4.4 Offeror Service Providers.</b>
                </p>
                <p>
                    Offerors may use third-party services to help manage or deliver their services, such as online video calling tools, or digital whiteboard. Offerors may use features on the Zigwik Platform to share information about the Offerees (like name, address) with such third-party service providers.
                </p>
                <p>
                    <b>4.5 Complying with Law, Responding to Legal Requests, Preventing Harm and Protecting our Rights.</b>
                </p>
                <p>
                    We may disclose your information to courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with our legal obligations, (ii) comply with a valid legal request (such as a subpoena or court order) or to respond to claims asserted against Zigwik, (iii) respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability, (iv) enforce and administer our agreements with Members, including our Terms and Policies, or (v) protect the rights, property or personal safety of Zigwik, its employees, its Members, or members of the public. For example, if permitted due to the foregoing circumstances, Host tax information may be shared with tax authorities or other governmental agencies.
                    <br /><br />
                    Where appropriate, we may notify Members about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon or harm to Zigwik, our Members, or expose Zigwik to a claim of obstruction of justice.
                    <br /><br />
                    Where legally required or permissible according to applicable law, we may disclose Offerors' and Offerees' information to tax authorities for the purpose of the tax authorities' determination of proper compliance with relevant tax obligations. Relevant tax obligations include Zigwik's tax obligations on its service fees, its facilitation of taxes on Offers and withholding taxes, and Offerors' individual tax obligations. Information that may be disclosed includes, but is not limited to, Offeror and Offeree names, Offer addresses, Offeror addresses, tax/business identification number(s), date of birth, and/or contact information, payout information, transaction dates and amounts, gross and net booking value and payout amounts, taxes collected by Zigwik on behalf of Offeror and Offeree, to the extent any of this information is known by Zigwik.
                    <br /><br />
                    In jurisdictions where Zigwik facilitates or requires a registration, notification, permit, or license application or number of an Offeror with a governmental authority, we may share information of participating Offeror with the relevant authority, both during the application process, when the Offer is published, and periodically thereafter, such as the Offeror's full name and contact details, address, tax identification number, registration, permit, or license number, Offer details, and number of slots booked subject to applicable laws.
                </p>
                <p>
                    <b>4.6 Service Providers.</b>
                </p>
                <p>
                    We share personal information with affiliated and unaffiliated service providers to help us run our business and for their compliance purposes, including service providers that help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the Zigwik Services through third-party platforms and software tools (e.g. through the integration with our APIs), (vi) provide customer service, advertising, or payments services, or (vii) process, handle or assess insurance claims or similar claims. These providers are contractually bound to protect your personal information and have access to your personal information to perform these tasks.
                </p>
                <p>
                    <b>4.7 Business Transfers.</b>
                </p>
                <p>
                    If Zigwik undertakes or is involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer, or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction (e.g., due diligence). In this event, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.
                </p>
            </span>
            <br />
            <h3>5. Other important information</h3>
            <span class="box">
                <p>
                    <b>5.1 Analyzing your Communications.</b>
                </p>
                <p>
                    We may review, scan, or analyze your communications on the Zigwik Platform for reasons outlined in the "How We Use Information We Collect" section of this policy, including fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, enforcing our <a [routerLink]="'/terms'">Terms of Service</a>, and customer support purposes. For example, as part of our fraud prevention efforts, we scan and analyze messages to mask contact information and references to other sites. In some cases, we may also scan, review, or analyze messages to debug, improve, and expand offerings. We use automated methods where reasonably possible. Occasionally we may need to manually review communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyze your messaging communications to send third-party marketing messages to you and we will not sell reviews or analyses of these communications.
                </p>
                <p>
                    <b>5.2 Third-Party Partners & Integrations.</b>
                </p>
                <p>
                    Parts of Zigwik may link to third-party services, not owned or controlled by Zigwik, such as Google Maps/Earth, or Google APIs. Use of these services is subject to the privacy policies of those providers. Zigwik does not own or control these third parties and when you interact with them you are providing your information to them.
                </p>
            </span>
            <br />
            <h3>6. Your rights</h3>
            <span class="box">
                <p>
                    You can exercise any of the rights described in this section consistent with applicable law. We may ask you to verify your identity and request before taking further action on your request.
                </p>
                <p>
                    <b>
                        6.1 Managing Your Information.
                    </b>
                </p>
                <p>
                    You can access and update some of your personal information through your Account settings. You are responsible for keeping your personal information up to date.
                </p>
                <p>
                    <b>6.2 Data Access and Portability.</b>
                </p>
                <p>
                    In some jurisdictions, applicable law may entitle you to request certain copies of your personal information or information about how we handle your personal information, request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format, and request that we transmit this information to another service provider (where technically feasible).
                </p>
                <p>
                    <b>6.3 Data Erasure.</b>
                </p>
                <p>
                    In some jurisdictions, you can request that your personal information be deleted. Please note that if you request deletion of your personal information, or if your account is suspended, terminated, or voluntarily closed:
                </p>
                <ul>
                    <li>
                        We may retain your personal information as necessary for our legitimate business interests, such as prevention of money laundering, fraud detection and prevention, and enhancing safety. For example, if we suspend a Zigwik Account for fraud or safety reasons, we may retain information from that Zigwik Account to prevent that Member from opening a new Zigwik Account in the future.
                    </li>
                    <li>
                        We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Zigwik may keep information for tax, legal reporting, and auditing obligations.
                    </li>
                    <li>
                        Information you have shared with others (e.g., Reviews, forum postings) will continue to be publicly visible on Zigwik, even after your Zigwik Account is canceled. Also, information related to the Offer will also be publicly visible on Zigwik. However, attribution of such information to you will be removed. Some copies of your information (e.g., log records) will remain in our database, but are disassociated from personal identifiers.
                    </li>
                    <li>
                        Because we take measures to protect data from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.
                    </li>
                </ul>
            </span>
            <br />
            <h3>7. Security</h3>
            <p class="box">
                While no organization can guarantee perfect security, we are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration.
            </p>
            <br />
            <h3>8. Changes to this privacy policy</h3>
            <p class="box">
                We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If we do so, we will post the revised Privacy Policy and update the "Last Updated" date at the top. In case of material changes, we will also provide you with notice of the modification by email at least thirty (30) days before the effective date. If you disagree with the revised Privacy Policy, you can cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the Airbnb Platform will be subject to the revised Privacy Policy.
            </p>
            <br />
            <h3>9. Contact information and responsible Zigwik entities</h3>
            <p class="box">
                For questions or complaints about this Privacy Policy or Zigwik's handling of personal information, please contact us at team@zigwik.com.
            </p>
        </div>
    </div>
</div>


<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
