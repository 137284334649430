// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA8kbXyd1FW30v9HUZSb_Sst7_6mMWLMdA",
    authDomain: "zigwikprod.firebaseapp.com",
    databaseURL: "https://zigwikprod.firebaseio.com",
    projectId: "zigwikprod",
    storageBucket: "zigwikprod.appspot.com",
    messagingSenderId: "923526178661",
    appId: "1:923526178661:web:cf21c2a81b63432ac7638c",
    measurementId: "G-39R8HR2PD1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
