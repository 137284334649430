<!-- <section class="categorie-list">
 
  
</section> -->

<section id="mega-menu" class="category_list_main">
  <button class="carogery" [ngClass]="isSticky ? 'black-text' : ''">
    category
  </button>

  <div class="sub_menu_list">
    <ul class="sub_catogeries">
      <div class="main-list" *ngFor="let item of allCategoryList">
        <li>
          <p class="list-litle">
            {{ item.name }} <i class="fa fa-angle-right" aria-hidden="true"></i>
          </p>

          <div class="catogerys">
            <div class="side-menu">
              <section class="Categories overview_list">
                <p class="sub_heading">Categories</p>
                <span
                  class="category_value"
                  *ngFor="let category of item?.category"
                >
                  <p
                    class="sub_value"
                    (click)="
                      redrictToSearch(
                        item,
                        item?.name + ' / ' + category.name,
                        category.name
                      )
                    "
                  >
                    {{ category.name }}
                    <i
                      [ngClass]="
                        category.sub_category1?.length ? 'show' : 'hide'
                      "
                      class="fa fa-angle-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                  <section *ngIf="category.sub_category1 != null && category.sub_category1.length > 0" class="overview_list sub_categories_1">
                    <p class="sub_heading">Sub Categories 1</p>

                    <span
                      class="category_value_1"
                      *ngFor="let sub_category1 of category.sub_category1"
                    >
                      <p
                        class="sub_value"
                        (click)="
                          redrictToSearch(
                            item,
                            item?.name +
                              ' / ' +
                              category.name +
                              ' / ' +
                              sub_category1?.name,
                              category.name,
                            sub_category1?.name
                          )
                        "
                      >
                        {{ sub_category1?.name }}
                        <i
                          [ngClass]="
                            sub_category1?.sub_category2?.length
                              ? 'show'
                              : 'hide'
                          "
                          class="fa fa-angle-right"
                          aria-hidden="true"
                        ></i>
                      </p>

                      <section *ngIf="sub_category1.sub_category2 != null && sub_category1.sub_category2.length > 0" class="overview_list sub_categories_2">
                        <p class="sub_heading">Sub Categories 2</p>
                        <p
                          class="sub_value"
                          *ngFor="
                            let sub_category2 of sub_category1?.sub_category2
                          "
                          (click)="
                            redrictToSearch(
                              item,
                              item?.name +
                                ' / ' +
                                category.name +
                                ' / ' +
                                sub_category1?.name +
                                ' / ' +
                                sub_category2,
                                category.name,
                            sub_category1?.name,
                              sub_category2
                            )
                          "
                        >
                          {{ sub_category2 }}
                        </p>
                      </section>
                    </span>
                  </section>
                </span>
              </section>
            </div>
          </div>
        </li>
      </div>
    </ul>
  </div>
</section>
