<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Offer Rating" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content order-content">
    <div class="container">
      <div class="row">
        <div class="pro-heading-title">
          <h1>
            {{ "Rating Page" | translate }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3 d-block d-xl-block">
            <app-offerings-sidebar></app-offerings-sidebar>
        </div>
        <div *ngIf="productLockDetails != null && user_id == productLockDetails['offeree_id'] && productLockDetails['lock_type'] == 'lock_fulfilled'" class="col-12 col-lg-9">
<table class="table border-0">
    <tbody>
      <tr class="border-0">
        <td>Rate the Offer - 
          <ngb-rating [max]="5" [readonly]="false" (rateChange)="onRateChange($event, productLockId)">
            <ng-template let-fill="fill" let-index="index">
              <span class="rating-star" [class.rating-filled]="fill === 100" [class.rating-bad]="index < 3">&#9733;</span>
            </ng-template>
          </ngb-rating>
        </td>
        </tr>
        <tr class="border-0">
          <td>
            <textarea [id]="'ratingtext' + productLockId" maxlength="300" matInput placeholder="Leave a comment..."></textarea>
          </td>
        </tr>
        <tr class="border-0">
          <td>
            <button id="ratingButton" class="button is-outline is-create is-light-gray" (click)="this.rateOffer(productLockId)">
              {{ "Rate the Offer" | translate }}
            </button>
          </td>
          </tr>
        </tbody>
        </table>
        </div>
        <div *ngIf="productLockDetails != null && user_id == productLockDetails['offeree_id'] && productLockDetails['lock_type'] != 'lock_fulfilled'" class="col-12 col-lg-9">
            Rating cannot be added as of now.
        </div>
        <div *ngIf="productLockDetails == null || user_id != productLockDetails['offeree_id']" class="col-12 col-lg-9">
            Hmm, looks like you are not allowed to rate this offer for now.
        </div>
    </div>
</div>
</section>
<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>
<app-back-to-top></app-back-to-top>