<div class="switcher">
    <button type="button" class="btn slide-toggle"><i class="fa fa-cogs" aria-hidden="true"></i></button>
    
    <a href="#" class="swticher-boxed ">
        <div class="text ">Use demo with Box</div>
        <div class="toggle">
          <span class="circle"></span>
        </div>
    </a>

    <div class="swicher-color">
        <div class="text "><b>Use demo with Colors</b></div>
        <ul id="switchColor" > 
          <li class="active">
            <a href="#" id="default"></a>
          </li>
          <li>
            <a href="#" id="yellow"></a>
          </li>
          <li>
            <a href="#" id="blue"></a>
          </li>
          <li>
            <a href="#" id="green"></a>
          </li>
          <li>
            <a href="#" id="navy-blue"></a>
          </li>
          <li>
            <a href="#" id="red"></a>
          </li>
          <li>
            <a href="#" id="pink"></a>
          </li>
        </ul>
        <div class="text mt-2 mb-0">Note: Template built with sass, you can use desire primary and secondary colors of your choice.</div>
     </div>
</div>
