import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class MarkOfferMessageMutation extends Mutation {

  document = gql`
    mutation MarkOfferMessageMutation(
      $lock_id: Int!
      $id_token: String!
      $user_email: String!
      $user_type: LockInUserType!
    ) {
      markReadMessages(
        lock_id: $lock_id
        id_token: $id_token
        user_email: $user_email
        user_type: $user_type
      )
    }
  `;
}
