import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./pages/home/home/home.component";

import { LogInComponent } from "./pages/session/log-in/log-in.component";
import { OfferingChatComponent } from "./pages/offerings/offering-chat/offering-chat.component";
import { OfferGrievanceComponent } from "./pages/offerings/offer-grievance/offer-grievance.component";
import { SignUpComponent } from "./pages/session/sign-up/sign-up.component";
import { ForgotPasswordComponent } from "./pages/session/forgot-password/forgot-password.component";

import { CartOneComponent } from "./pages/cart/cart-one/cart-one.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";

import { ProfileComponent } from "./pages/user-account/profile/profile.component";
import { PublicProfileComponent } from "./pages/user-account/public-profile/public-profile.component";
import { OrdersComponent } from "./pages/user-account/orders/orders.component";
import { OrderDetailComponent } from "./pages/user-account/order-detail/order-detail.component";
import { ShippingAddressComponent } from "./pages/user-account/shipping-address/shipping-address.component";
import { ResendEmailLinkComponent } from "./pages/user-account/resend-email/resend-email-link.component";

import { BlogOneComponent } from "./pages/blog/blog-one/blog-one.component";
import { ThankYouComponent } from "./pages/session/thank-you/thank-you.component";
import { NotfoundComponent } from "./pages/notfound/notfound.component";
import { OfferingReportComponent } from "./pages/offerings/offering-report/offering-report.component";
import { OfferRatingComponent } from "./pages/offerings/offer-rating/offer-rating.component";
import { OfferingCommunicationComponent } from "./pages/offerings/offering-communication/offering-communication.component";

import { AboutOneComponent } from "./pages/about/about-one/about-one.component";
import { AboutTwoComponent } from "./pages/about/about-two/about-two.component";
import { ContactOneComponent } from "./pages/about/contact-one/contact-one.component";
import { ContactTwoComponent } from "./pages/about/contact-two/contact-two.component";
import { ContentPolicyComponent } from "./pages/about/content-policy/content-policy.component";
import { ReviewPolicyComponent } from "./pages/about/review-policy/review-policy.component";
import { NondiscriminationPolicyComponent } from "./pages/about/nondiscrimination-policy/nondiscrimination-policy.component";
import { CookiePolicyComponent } from "./pages/about/cookie-policy/cookie-policy.component";
import { CopyrightPolicyComponent } from "./pages/about/copyright-policy/copyright-policy.component";
import { PrivacyPolicyComponent } from "./pages/about/privacy-policy/privacy-policy.component";
import { ShippingComponent } from "./pages/about/shipping/shipping.component";
import { TermAndConditionComponent } from "./pages/about/term-and-condition/term-and-condition.component";
import { RefundPolicyComponent } from "./pages/about/refund-policy/refund-policy.component";
import { NewOfferingsComponent } from "./pages/offerings/new-offerings/new-offerings.component";
import { MyOfferingsComponent } from "./pages/offerings/my-offerings/my-offerings.component";
import { ApproveOfferingComponent } from "./pages/offerings/approve-offering/approve-offering.component";
import { ApproveOfferingEmailComponent } from "./pages/offerings/approve-offering-email/approve-offering-email.component";
import { SearchPageComponent } from "./pages/search/search-page/search-page.component";
import { ChangePasswordComponent } from "./pages/user-account/change-password/change-password.component";
import { ProductComponent } from "./pages/search/product/product.component";
import { FaqComponent } from "./pages/faq/faq/faq.component";

import { OfferSEOResolver } from './helpers/offerseo.resolver';


const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "resend-email-verification-link/:redirect", component: ResendEmailLinkComponent },
  { path: "resend-email-verification-link", component: ResendEmailLinkComponent },
  { path: "login", component: LogInComponent },
  { path: "signup/:referral_code", component: SignUpComponent },
  { path: "signup", component: SignUpComponent },
  { path: "forgot", component: ForgotPasswordComponent },
  { path: "cart", component: CartOneComponent },
  { path: "checkout", component: CheckoutComponent },

  { path: "profile", component: ProfileComponent },
  { path: "public-profile/:user_id", component: PublicProfileComponent },
  { path: "public-profile/user/:user", component: PublicProfileComponent },
  { path: "change-password", component: ChangePasswordComponent },

  { path: "my-orders", component: OrdersComponent },
  { path: "offering-report", component: OfferingReportComponent },
  { path: "offering-communication", component: OfferingCommunicationComponent },
  { path: "offer-rating/:id", component: OfferRatingComponent },
  { path: "order-detail", component: OrderDetailComponent },
  { path: "shipping-addresses", component: ShippingAddressComponent },
  { path: "blog", component: BlogOneComponent },
  { path: "thank-you/:masked_hash", component: ThankYouComponent },

  { path: "about-us", component: AboutOneComponent },
  { path: "contact-us", component: ContactOneComponent },
  { path: "privacy", component: PrivacyPolicyComponent },
  { path: "cookie-policy", component: CookiePolicyComponent },
  { path: "nondiscrimination-policy", component: NondiscriminationPolicyComponent },
  { path: "copyright-policy", component: CopyrightPolicyComponent },
  { path: "review-policy", component: ReviewPolicyComponent },
  { path: "content-policy", component: ContentPolicyComponent },
  { path: "shipping-policy", component: ShippingComponent },
  { path: "terms", component: TermAndConditionComponent },
  { path: "refund", component: RefundPolicyComponent },
  { path: "new-offering", component: NewOfferingsComponent },
  { path: "my-offering", component: MyOfferingsComponent },
  { path: "update-offering/:id", component: NewOfferingsComponent },
  { path: "offer-grievance/:id/:order_id", component: OfferGrievanceComponent },
  { path: "approve-offering", component: ApproveOfferingComponent },
  { path: "approve-offering-email/:approval_token/:approval_status/:order_id", component: ApproveOfferingEmailComponent },
  { 
    path: "offer/:id", 
    resolve: { offerSEODetails: OfferSEOResolver },
    loadChildren: () => import('./pages/search/product/product.module').then(m => m.ProductModule)
  },
  { 
    path: "offername/:productname", 
    resolve: { offerSEODetails: OfferSEOResolver },
    loadChildren: () => import('./pages/search/product/product.module').then(m => m.ProductModule)
  },
  { path: "search", component: SearchPageComponent },
  { path: "offering-chat", component: OfferingChatComponent },
  { path: "offering-chat/:id", component: OfferingChatComponent },
  { path: "faq", component: FaqComponent },
  { path: "**", component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
