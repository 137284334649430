<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "About Us" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<!-- About-us Content -->
<section class="pro-content aboutus-content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="pro-heading-title">
        <h1>
          {{ "Welcome To Zigwik" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <img
          class="img-fluid"
          src="assets/images/about-us/about_us.png"
          alt="full banner"
        />
        <p class="pro-pera">
          Zigwik is a product that gives facilities required to create a better sharable economy. The sharing economy is a term that refers to the growing trend of individuals and organizations sharing access to physical or virtual goods and services rather than owning them outright. This can take many forms; however, we are currently focusing on providing a Marketplace for any online service from one customer to another. Zigwik is an aggregator of all online services and has functionalities to search and market online service offers. In Zigwik, sharing such an online service is called an offer.
        <br />Zigwik provides a convenient way to: create schedules for online services, create an offer page that looks just like a product page in a traditional online e-commerce website, help market such offers, and help organize the entire process of offer management. This provides excellent benefits for Offerors where they can have a new source of income and a great Marketplace for any online service for Offerees.</p>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid bg-media">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 media-col">
          <div class="media align-items-center">
            <img
              src="assets/images/about-us/profile.png"
              alt="profile"
              class="rounded-circle"
              style="width: 100px;"
            />
            <div class="media-body">
              <h4>Charlis <small>Chief Officer </small></h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="media align-items-center">
            <img
              src="assets/images/about-us/profile.png"
              alt="profile"
              class="rounded-circle"
              style="width: 100px;"
            />
            <div class="media-body">
              <h4>John Doe <small>Sales Executive</small></h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="conatiner-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
          <div class="pro-heading-title">
            <h2>{{ "Our Team" | translate }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <ngx-slick-carousel
            #slickModal="slick-carousel"
            [config]="slideTeamConfig"
            (init)="slickInit($event)"
            class="carousel row"
            style="margin-bottom: 10px;"
          >
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_01_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Charlis</h3>
                    <p>Chief Officer</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_02_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>John Doe</h3>
                    <p>Sales Executive</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_03_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Theresa May</h3>
                    <p>Markting Officer</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_04_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Xavior</h3>
                    <p>Manager</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_05_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Malissa</h3>
                    <p>Product Manager</p>
                  </div>
                </article>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div> -->
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
