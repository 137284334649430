import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class TrackOfferViewMutation extends Mutation {

  document = gql`
    mutation TrackOfferViewMutation(
      $masked_id: String
      $user_id: Int
      $offer_id: Int!
      $category_id: Int!
    ) {
      trackOfferView(
        masked_id: $masked_id
        user_id: $user_id
        offer_id: $offer_id
        category_id: $category_id
      )
    }
  `;
}
