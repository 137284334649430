import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import {EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class GetProductImagesQuery extends Query {

  private __imgProdDataLoaded = new BehaviorSubject<string>(
    EventConstants.CONST_IMGPROD_LOADED
  );
  _imgProdDataLoadedObservble = this.__imgProdDataLoaded.asObservable();

  getProductImagesData: any;
  prodImageLink =[];
  document = gql`
    query getProductImagesQuery($product_id:Int!) {
      getProductImages(product_id:$product_id) {
        id
        link
        type
        status
        image_width
        image_height
        image_type
      }
    }
  `;
prodImg:[];
prodImageLinkData:any;
populateProductImage(data){
 this.prodImg= data;
 let prodImageLinkArr = [];
 let getProductImagesData = data["getProductImages"];
  getProductImagesData.forEach((item) => {
    prodImageLinkArr.push(item["link"]);
   });
this.prodImageLink = prodImageLinkArr.filter(this.onlyUnique);
this.__imgProdDataLoaded.next(EventConstants.CONST_IMGPROD_LOADED); 

}
onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
}