import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class TrackLeadGenCouponDealMutation extends Mutation {
  document = gql`
    mutation TrackLeadGenCouponDealMutation(
        $masked_id: String!
        $discount_percentage: Float!
        $start_date: String!
        $end_date: String!
        $surprise_flag: Boolean!
        $category_id: Int
        $category_group_id: Int
        $offer_id: Int
    )
    {
      trackLeadGenCouponDeal(
        masked_id: $masked_id
        discount_percentage: $discount_percentage
        start_date: $start_date
        end_date: $end_date
        surprise_flag: $surprise_flag
        category_id: $category_id
        category_group_id: $category_group_id
        offer_id: $offer_id
      )
    }
    `;
}
