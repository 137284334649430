import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-policy',
  templateUrl: './content-policy.component.html',
  styleUrls: ['./content-policy.component.scss']
})
export class ContentPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
