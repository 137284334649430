import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetOfferMessageChecksQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getOfferMessageChecksQuery(
      $idToken: String!
      $userEmail: String!
      $userType: LockInUserType
      ) {
        getMessageChecks(
          id_token: $idToken,
          user_email: $userEmail,
          user_type: $userType
      ) {
        offeror_full_name
        offeree_full_name
        offeror_id
        offeree_id
        lock_id
        offeror_image_url
        offeree_image_url
        communication_window_check_id
        product_title
        offeror_total_unread_messages
        offeree_total_unread_messages
        schedule_id
        product_id
        schedule_frequency
        start_date
        end_date
        order_id
        invoice_id
        window_open_flag
        }
      }
  `;
}
