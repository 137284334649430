import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CreateUserMutation } from "../../../services/graphql/createuser.graphql";
import firebase from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';
import { GetUserQuery } from "../../../services/graphql/getuser.graphql";
import { LoginUserMutation } from "../../../services/graphql/login.graphql";
import { AttachMaskedUserDetailsMutation } from "../../../services/graphql/attachMaskedUserDetails.graphql";
import { SessionService } from "../../../services/session.service";
import { AuthService } from "../../../services/auth/auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { SessionConstants, EventConstants } from "src/app/constants/app.constants";
import { RandomUserService } from "../../../services/randomeUser.service";
import { ToastrService } from 'ngx-toastr';
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  referral_code_module = EventConstants.CONST_REFERRAL_CODE_MODULE;
  referral_code = new FormControl("");
  firstname = new FormControl("");
  lastname = new FormControl("");
  email = new FormControl("");
  mobile = new FormControl("");
  password = new FormControl("");
    passed_referral_code = "";

  isSubmitBtnDisabled = false;

  signUpForm: FormGroup;
  submitted = false;
  errorMessage = "";
    // profileHtmlDescription = "";
  // profileMarkdownDescription = "";
  birthdate = null;
  maxBirthDate: string;

  login$!: Observable<any>;
  user$: Observable<firebase.User | null>;

  // profileInputDescription = new BehaviorSubject<string>(
  //   ""
  // );

  constructor(
    private apollo: Apollo,
    private createUserMutation: CreateUserMutation,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private getUserQuery: GetUserQuery,
        private toastr: ToastrService,
    private readonly authService: AuthService,
    private loginUserMutation: LoginUserMutation,
    private attachMaskedUserDetailsMutation: AttachMaskedUserDetailsMutation,
    private randomUserService: RandomUserService
  ) {
    this.user$ = this.authService.user$;
  }

  get f() {
    return this.signUpForm.controls;
  }

  // addHtmlDescription(value: string) {
  //   this.profileHtmlDescription = value;
  // }

  // addMarkdownDescription(value: string) {
  //   this.profileMarkdownDescription = value;
  // }

  ngOnInit() {
    
    this.route.paramMap.subscribe(
      params => {
        this.passed_referral_code = params.get('referral_code');
      }
    );

    let auxDate = new Date();
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);

    let userBirthDate = this.sessionService.get("user_birth_date");
    let userMobileNumber = this.sessionService.get("user_mobile_number");
    var referral_code_val = this.passed_referral_code;
    if(referral_code_val == null || referral_code_val == "") {
      referral_code_val = this.sessionService.get("referral_code_signup_value");
    } else {
      this.sessionService.set("referral_code_signup_value", this.passed_referral_code);
    }

    this.sessionService.delete("user_birth_date");
    this.sessionService.delete("user_mobile_number");

    this.signUpForm = this.formBuilder.group({
      firstname: ["", [Validators.required, Validators.minLength(2)]],
      lastname: ["", [Validators.required, Validators.minLength(2)]],
      referral_code: [referral_code_val],
      birthdate: [userBirthDate, [Validators.required]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      mobile: [userMobileNumber, [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(8)]]
    });

    // if(this.referral_code_module == "True") {
    //   this.isSubmitBtnDisabled = true;
    // }

    this.user$.subscribe(data1 => {
      if(data1 != null) {
        if(data1.providerData[0]["email"] != null ) {

      this.apollo
      .mutate({
        mutation: this.loginUserMutation.document,
        variables: {
          email: data1.providerData[0]["email"],
          refresh_token: data1.refreshToken,
        },
      })
      .subscribe(
        ({ data }) => {
          data["loginUser"].valid_user = false;
          data["loginUser"]["login_time"] = (new Date()).getTime();
          if(data["loginUser"] !== null && data["loginUser"]["id_token"] !== null && 
          data["loginUser"]["id_token"] !== "" && 
          data["loginUser"]["user_status"] == "known" && 
          data["loginUser"]["email"] !== null && 
          data["loginUser"]["email"] !== "") {
            data["loginUser"].valid_user = true;
          }
          this.sessionService.setLoginContent(data["loginUser"]);
          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, data["loginUser"]["email"], null);
          }
          this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
          this.apollo
            .query({
              query: this.getUserQuery.document,
              variables: {
                email: data1.providerData[0]["email"],
                idToken: data["loginUser"]["id_token"],
              },
              fetchPolicy: "network-only"
            })
            .subscribe(
              ({ data }) => {
                this.sessionService.setLocal(
                  SessionConstants.CONST_SESSION_USER_KEYWORD,
                  data["getUserDetails"]
                );
                this.sessionService.deleteLocal("anonymousUser");
                this.sessionService.deleteLocal("validAnonymousUser");
                this.router.navigate(["/profile"]);
              },
              (error) => {
                this.toastr.error(error.message);
              }
            );
        },
        (error) => {
          if(data1.providerData[0]["email"] != null && data1.providerData[0]["email"] != "") {
            let first_name = data1.displayName.split(" ")[0];
            let last_name = data1.displayName.split(" ")[1];
            this.signUpForm.patchValue({
              firstname: first_name,
              lastname: last_name,
              email: data1.providerData[0]["email"]
            });
            this.createAccount(true, data1.uid, data1.refreshToken);
          }
        }
      );
        }
      }
    });
  }

  substractYearsToDate(auxDate: Date, years: number): Date {
    auxDate.setFullYear(auxDate.getFullYear() - years);
    return auxDate;
  }

  getDateFormateForSearch(date: Date): string {
    let year = date.toLocaleDateString('es', { year: 'numeric' });
    let month = date.toLocaleDateString('es', { month: '2-digit' });
    let day = date.toLocaleDateString('es', { day: '2-digit' });
    return `${year}-${month}-${day}`;
  }

  referralCodeOnKey(ref_val) {
    if(this.referral_code_module == "True") {
      if(this.signUpForm.controls.referral_code.value.length > 5) {
        this.sessionService.set("referral_code_signup_value", this.signUpForm.controls.referral_code.value);
        // this.isSubmitBtnDisabled = false;
      } //else {
      //   this.isSubmitBtnDisabled = true;
      // }
    }
  }

  checkPassword(inputtxt) 
  { 
    var decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if(inputtxt.match(decimal)) 
    { 
      return true;
    }
    else
    { 
      return false;
    }
  }

  createAccount(sso = false, firebase_user_id = "", refresh_token = "") {

    (<HTMLInputElement>document.getElementById("createAccountButton")).disabled = true;
    
    let auxDate = new Date();
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);

    this.submitted = true;
    if (this.signUpForm.invalid) {
      console.log("invalid form");
      (<HTMLInputElement>document.getElementById("createAccountButton")).disabled = false;
      return;
    }

    var dirtyFields = false;

    if((new Date(this.signUpForm.controls.birthdate.value)) > (new Date(this.maxBirthDate))) {
      this.signUpForm.get('birthdate').setErrors({'incorrect': true});
      this.toastr.error("Invalid value for birth date.");
      dirtyFields = true;
    }

    if(this.signUpForm.controls.mobile.value != null && this.signUpForm.controls.mobile.value != "") {
      if(String(this.signUpForm.controls.mobile.value).trim().length != 10) {
        this.signUpForm.get('mobile').setErrors({'incorrect': true});
        this.toastr.error("The Mobile number should be of 10 digits.");
        dirtyFields = true;
      }
    } else {
      this.toastr.error("The Mobile number is required.");
      this.signUpForm.get('mobile').setErrors({'incorrect': true});
      dirtyFields = true;
    }

    let sso_field = (sso == true) ? "True" : "False";

    if(sso_field == "False") {
      const emailRegex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");

      if(this.signUpForm.controls.email.value == null || this.signUpForm.controls.email.value == "" || emailRegex.test(this.signUpForm.controls.email.value) == false ) {
        this.signUpForm.get('email').setErrors({'incorrect': true});
        this.toastr.error("The Email ID is incorrect.");
        dirtyFields = true;
      }
  
      if(this.signUpForm.controls.firstname.value == null || this.signUpForm.controls.firstname.value == "" ) {
        this.signUpForm.get('firstname').setErrors({'incorrect': true});
        this.toastr.error("Your First Name is incorrect.");
        dirtyFields = true;
      }

      if(this.signUpForm.controls.lastname.value == null || this.signUpForm.controls.lastname.value == "" ) {
        this.signUpForm.get('lastname').setErrors({'incorrect': true});
        this.toastr.error("Your Last Name is incorrect.");
        dirtyFields = true;
    }

    if(this.signUpForm.controls.password.value != null && this.signUpForm.controls.password.value != "") {
      if(!this.checkPassword(this.signUpForm.controls.password.value)) {
        this.signUpForm.get('password').setErrors({'incorrect': true});
        this.toastr.error("Please add a strong password [8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character].");
        dirtyFields = true;
        }
      } else {
        this.signUpForm.get('password').setErrors({'incorrect': true});
        this.toastr.error("Password is required");
        dirtyFields = true;
      }
    }

    if(dirtyFields === true) {
      (<HTMLInputElement>document.getElementById("createAccountButton")).disabled = false;
      return;
      }
    
    var referral_code_val = "";

    if(this.referral_code_module == "True") {
      referral_code_val = this.signUpForm.controls.referral_code.value;
      if(referral_code_val == null || referral_code_val == "") {
        referral_code_val = this.sessionService.get("referral_code_signup_value");
      }
    }

    this.apollo
      .mutate({
        mutation: this.createUserMutation.document,
        variables: {
          first_name: this.signUpForm.controls.firstname.value,
          last_name: this.signUpForm.controls.lastname.value,
          adhaar_card: "@@@",
          pan_card: "@@@",
          birth_date: this.signUpForm.controls.birthdate.value,
          terms_accepted: true,
          referral_code: referral_code_val,
          sso: sso_field,
          verify_email: "True",
          firebase_user_id: firebase_user_id,
          email: this.signUpForm.controls.email.value,
          phone: String(this.signUpForm.controls.mobile.value).trim(),
          refresh_token: refresh_token,
          password: this.signUpForm.controls.password.value,
          account_number: "@@@",
          bank_code: "@@@",
          address_line1: "@@@",
          address_line2: "@@@",
          area_name: "@@@",
          landmark: "@@@",
          pin_code: "@@@",
          city: "@@@",
          district: "@@@",
          state: "@@@",
          description: "Thank for for visiting my profile. My name is " + this.signUpForm.controls.firstname.value + " " + this.signUpForm.controls.lastname.value + " and I am delighted to welcome you here.",
          markdown_description: "Thank for for visiting my profile. My name is " + this.signUpForm.controls.firstname.value + " " + this.signUpForm.controls.lastname.value + " and I am delighted to welcome you here."
        },
      })
      .subscribe(
        ({ data }) => {
          data["createUser"].valid_user = false;
          data["createUser"]["login_time"] = (new Date()).getTime();
          if(data["createUser"] !== null && data["createUser"]["id_token"] !== null && 
          data["createUser"]["id_token"] !== "" && 
          data["createUser"]["user_status"] == "known" && 
          data["createUser"]["email"] !== null && 
          data["createUser"]["email"] !== "") {
            data["createUser"].valid_user = true;
          }
          this.sessionService.set("token", data["createUser"]);
          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, data["createUser"]["email"], null);
          }
          this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
          this.apollo
            .query({
              query: this.getUserQuery.document,
              variables: {
                email: this.signUpForm.controls.email.value,
                idToken: data["createUser"]["id_token"],
              },
              fetchPolicy: "network-only"
            })
            .subscribe(
              ({ data }) => {
                this.sessionService.setLocal(
                  "user",
                  data["getUserDetails"]
                );
                this.sessionService.deleteLocal("anonymousUser");
                this.sessionService.deleteLocal("validAnonymousUser");
                if(sso == true) {
                  this.router.navigate(["/profile"]);
                } else {
                  this.router.navigate(["/"]);
                }
              },
              (error) => {
                (<HTMLInputElement>document.getElementById("createAccountButton")).disabled = false;
                this.toastr.error(error.message);
              }
            );
        },
        (error) => {
          (<HTMLInputElement>document.getElementById("createAccountButton")).disabled = false;
          if(error.message.includes("[createUser]")) {
            this.toastr.error("The email has already been taken.");
          } else if(String(error.message).includes("Internal server error")) {
            this.toastr.error(String(error.graphQLErrors[0]["debugMessage"]).replace(/_/g, " "));
          } else {
            this.toastr.error(error.message);
          }
        }
      );
  }

  googleLogin() {

    this.signUpForm.get('firstname').setErrors(null);
    this.signUpForm.get('lastname').setErrors(null);
    this.signUpForm.get('email').setErrors(null);
    this.signUpForm.get('password').setErrors(null);
    
    let auxDate = new Date();
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);

    if(this.signUpForm.controls.birthdate.value == null || this.signUpForm.controls.birthdate.value == "" || (new Date(this.signUpForm.controls.birthdate.value)) > (new Date(this.maxBirthDate))) {
      this.signUpForm.get('birthdate').setErrors({'incorrect': true});
      this.signUpForm.get('mobile').setErrors(null);
            this.submitted = true;
      return;
    }

    if(this.signUpForm.controls.mobile.value == null || this.signUpForm.controls.mobile.value == "" || this.signUpForm.controls.mobile.value.length !== 10) {
      this.signUpForm.get('mobile').setErrors({'incorrect': true});
      this.signUpForm.get('birthdate').setErrors(null);
      this.submitted = true;
      this.toastr.error("Invalid Mobile number. The Mobile number should be of 10 digits.");
      return;
    }

    this.authService.loginWithPopup().subscribe((dat) => {
      this.user$.subscribe((data1) => {
        if(data1.email !== null && data1.email != "") {
    this.apollo
      .mutate({
        mutation: this.loginUserMutation.document,
        variables: {
          email: data1.email,
          refresh_token: data1.refreshToken,
        },
      })
      .subscribe(
        ({ data }) => {
          data["loginUser"].valid_user = false;
          data["loginUser"]["login_time"] = (new Date()).getTime();
          if(data["loginUser"] !== null && data["loginUser"]["id_token"] !== null && 
          data["loginUser"]["id_token"] !== "" && 
          data["loginUser"]["user_status"] == "known" && 
          data["loginUser"]["email"] !== null && 
          data["loginUser"]["email"] !== "") {
            data["loginUser"].valid_user = true;
          }

          this.sessionService.setLoginContent(data["loginUser"]);
          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, data1.email, null);
          }
          this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
          this.apollo
            .query({
              query: this.getUserQuery.document,
              variables: {
                email: data1.email,
                idToken: data["loginUser"]["id_token"],
              },
            })
            .subscribe(
              ({ data }) => {
                this.sessionService.setLocal(
                  SessionConstants.CONST_SESSION_USER_KEYWORD,
                  data["getUserDetails"]
                );
                this.sessionService.deleteLocal("anonymousUser");
                this.sessionService.deleteLocal("validAnonymousUser");
                this.router.navigate(["/profile"]);
              },
              (error) => {
                this.toastr.error(error.message);
              }
            );
        },
        (error) => {
          if(data1.email != null && data1.email != "") {
            let first_name = data1.displayName.split(" ")[0];
            let last_name = data1.displayName.split(" ")[1];
            this.signUpForm.patchValue({
              firstname: first_name,
              lastname: last_name,
              email: data1.email
            });
            this.createAccount(true, data1.uid, data1.refreshToken);
          }
        }
      );
      }
      });
    });
  }

  twitterLogin() {
    let auxDate = new Date();
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);

    this.signUpForm.get('firstname').setErrors(null);
    this.signUpForm.get('lastname').setErrors(null);
    this.signUpForm.get('email').setErrors(null);
    this.signUpForm.get('password').setErrors(null);

    if(this.signUpForm.controls.birthdate.value == null || this.signUpForm.controls.birthdate.value == "" || (new Date(this.signUpForm.controls.birthdate.value)) > (new Date(this.maxBirthDate))) {
      this.signUpForm.get('birthdate').setErrors({'incorrect': true});
      this.signUpForm.get('mobile').setErrors(null);
      this.submitted = true;
      return;
    }

    if(this.signUpForm.controls.mobile.value == null || this.signUpForm.controls.mobile.value == "" || this.signUpForm.controls.mobile.value.length !== 10) {
      this.signUpForm.get('mobile').setErrors({'incorrect': true});
      this.signUpForm.get('birthdate').setErrors(null);
this.toastr.error("Invalid Mobile number. The Mobile number should be of 10 digits.");
      this.submitted = true;
      return;
    }

    this.sessionService.set("user_birth_date", this.signUpForm.controls.birthdate.value);
this.sessionService.set("user_mobile_number", this.signUpForm.controls.mobile.value);
    this.authService.loginWithRedirect("twitter").subscribe((dat) => {
    });
  }

  facebookLogin() {
    let auxDate = new Date();
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);

    this.signUpForm.get('firstname').setErrors(null);
    this.signUpForm.get('lastname').setErrors(null);
    this.signUpForm.get('email').setErrors(null);
    this.signUpForm.get('password').setErrors(null);

    if(this.signUpForm.controls.birthdate.value == null || this.signUpForm.controls.birthdate.value == "" || (new Date(this.signUpForm.controls.birthdate.value)) > (new Date(this.maxBirthDate))) {
      this.signUpForm.get('birthdate').setErrors({'incorrect': true});
      this.signUpForm.get('mobile').setErrors(null);
      this.submitted = true;
      return;
    }

    if(this.signUpForm.controls.mobile.value == null || this.signUpForm.controls.mobile.value == "" || this.signUpForm.controls.mobile.value.length !== 10) {
      this.signUpForm.get('mobile').setErrors({'incorrect': true});
      this.signUpForm.get('birthdate').setErrors(null);
this.toastr.error("Invalid Mobile number. The Mobile number should be of 10 digits.");
      this.submitted = true;
      return;
    }
    
    this.sessionService.set("user_birth_date", this.signUpForm.controls.birthdate.value);
this.sessionService.set("user_mobile_number", this.signUpForm.controls.mobile.value);
    this.authService.loginWithRedirect("facebook").subscribe((dat) => {
    });
  }

  alertCloseHandler() {
    console.log("function called");
    this.errorMessage = "";
  }

  }