import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class CreateDayTemplateMutation extends Mutation {

  document = gql`
    mutation CreateDayTemplateMutation(
      $template_name: String!
      $product_id: Int!
      $id_token: String!
      $lender_email: String!
      $slot_hours: Int!
      $slot_minutes: Int!
      $slots: [ProductDayTemplateSlotsInput!]!
    ) {
      addProductDayTemplate(
        template_name: $template_name,
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
        slot_hours: $slot_hours
        slot_minutes: $slot_minutes
        slots: $slots
      )
    }
  `;
}
