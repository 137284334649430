import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Apollo } from "apollo-angular";
import { Router, NavigationExtras } from '@angular/router';
import { SessionConstants } from 'src/app/constants/app.constants';
import { AuthService } from "src/app/services/auth/auth.service";
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { TrackSearchCriteriaMutation } from "src/app/services/graphql/trackSearchCriteria.graphql";
import { GetCategoryQuery } from "src/app/services/graphql/getcategory.graphql";
import { SessionService } from "src/app/services/session.service";
import { start } from "repl";


@Component({
  selector: "app-filter-serach",
  templateUrl: "./filter-serach.component.html",
  styleUrls: ["./filter-serach.component.scss"],
})
export class FilterSerachComponent implements OnInit {
  @Input() countValue = 12;
  productForm: FormGroup;
  title = new FormControl("");
  category = new FormControl("");
  scheduletype = new FormControl("");
  count = new FormControl("");
  start_date = new FormControl("");
  end_date = new FormControl("");
  categoryValue = "";
  scheduleTypeValue = "";
  categoryData: any[];
  currentDate = Date.now();
  currentDate1 = new Date((new Date()).getFullYear(), 11, 31);
  selectedStartDate: Date = new Date(this.currentDate);
  selectedEndDate: Date = new Date(this.currentDate1);
  model_start_date: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate()
  };
  model_end_date: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: 12,
    day: 31
  };
  search_start_date = this.selectedStartDate.getFullYear().toString() + "-" + (this.selectedStartDate.getMonth() + 1).toString() + "-" + this.selectedStartDate.getDate().toString();
  search_end_date = this.selectedEndDate.getFullYear().toString() + "-" + (this.selectedEndDate.getMonth() + 1).toString() + "-" + this.selectedEndDate.getDate().toString();
  
  @Input() offsetValue = 0;
  @Output() offsetSetting = new EventEmitter<number>();
  @Output() pageCountSetting = new EventEmitter<number>();

  @Output() spinnerShow = new EventEmitter();
  @Output() spinnerHide = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private getCategoryQuery: GetCategoryQuery,
    private sessionService: SessionService,
    private getProductListQuery: GetProductListQuery,
    private authService:AuthService,
    private trackSearchCriteriaMutation: TrackSearchCriteriaMutation,
    private routeWeb: Router
  ) {}

  ngOnInit(): void {
    this.productForm = this.formBuilder.group({
      title: [""],
      category: [''],
      scheduletype: [""],
      start_date: [""],
      end_date: [""],
      count: [this.countValue],
    });
    this.getCategoriesData();
    this.authService.filetrData.subscribe({
      next:(res)=>{
        this.productForm.patchValue({
          category:res?.filterList.categoryGroup,
        })
        this.categoryValue = res?.filterList.categoryGroup
        this.productList(true, res?.filterList)
      }
    });

    this.loadSavedSearchCriteria();
  }

  getCategoriesData() {
    this.apollo
      .query({
        query: this.getCategoryQuery.document,
        variables: {},
        fetchPolicy: "network-only",
      })
      .subscribe(
        ({ data }) => {
          this.getCategoryQuery.populateCategoryGroup(data);
          this.categoryData = this.getCategoryQuery.categoryGroupHeader;
          this.authService.catogeryList.next(this.categoryData)
          let allCategoryGroup = this.extractCategoryGroups(data["getProductCategories"])
          this.authService.allCatogeryList.next(allCategoryGroup);
        },
        (error) => {
          // console.log(error);
        }
      );
  }
  onDateSelection(evt: NgbDate, date_type) {
    if(date_type == "start_date") {
      this.search_start_date = evt.year + "-" + evt.month + "-" + evt.day;
    }

    if(date_type == "end_date") {
      this.search_end_date = evt.year + "-" + evt.month + "-" + evt.day;
    }
  }

  setOffsetValue(value) {
    this.offsetValue = value;
  }

  saveSearchCriteria(){

    this.sessionService.setSession("search_title", this.productForm.controls.title.value);
    this.sessionService.setSession("search_category_group", this.productForm.controls.category.value);
    this.sessionService.setSession("search_schedule_type", this.productForm.controls.scheduletype.value);
    this.sessionService.setSession("search_start_date", this.search_start_date);
    this.sessionService.setSession("search_end_date", this.search_end_date);

    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
      
      this.apollo
            .mutate({
              mutation: this.trackSearchCriteriaMutation.document,
              variables: {
                masked_id: maskedUserData,
                user_id: user_id,
                search_text: this.productForm.controls.title.value,
                start_date: this.search_start_date,
                end_date: this.search_end_date,
                frequency: this.productForm.controls.scheduletype.value,
                category_id: null,
                category_group: this.productForm.controls.category.value
              },
          }).subscribe(({ data }) => {});
    }
  }

  loadSavedSearchCriteria() {
    if(this.sessionService.checkSessionKey("search_title")) {
      this.productForm.patchValue({
        title: this.sessionService.getSession("search_title")
      });
    }

    if(this.sessionService.checkSessionKey("search_start_date")) {
      let tempDate = new Date(this.sessionService.getSession("search_start_date"));
      this.search_start_date = this.sessionService.getSession("search_start_date");
      this.model_start_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    }

    if(this.sessionService.checkSessionKey("search_end_date")) {
      let tempDate = new Date(this.sessionService.getSession("search_end_date"));
      this.search_end_date = this.sessionService.getSession("search_end_date");
      this.model_end_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    }
    
    if(this.sessionService.checkSessionKey("search_category_group")) {
      this.categoryValue = this.sessionService.getSession("search_category_group");
    }

    if(this.sessionService.checkSessionKey("search_schedule_type")) {
      this.scheduleTypeValue = this.sessionService.getSession("search_schedule_type");
    }
  }

  loadExternalSearchCriteria(
    title: string,
    categorygroup: string,
    scheduletype: string,
    start_date: string,
    end_date: string
  ) {
    if(title != null && title != "") {
      this.productForm.patchValue({
        title: title
      });
    }

    if(start_date != null && start_date != "") {
      let tempDate = new Date(start_date);
      this.search_start_date = start_date;
      this.model_start_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    }

    if(end_date != null && end_date != "") {
      let tempDate = new Date(end_date);
      this.search_end_date = end_date;
      this.model_end_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    }
    
    if(categorygroup != null && categorygroup != "") {
      this.categoryValue = categorygroup;
    }

    if(scheduletype != null && scheduletype != "") {
      this.scheduleTypeValue = scheduletype;
    }
  }

  productListLoad(
    title: string,
    categorygroup: string,
    scheduletype: string,
    start_date: string,
    end_date: string,
    category: string,
    sub_category1: string,
    sub_category2: string,
    offset: number,
    externalFlag = false  
  ) {

    this.spinnerShow.emit();

    this.apollo
    .query({
      query: this.getProductListQuery.document,
      variables: {
        title_keyword: title,
        category_group: categorygroup,
        schedule_frequency: scheduletype,
        start_date: start_date,
        end_date: end_date,
        offset: offset,
        count: this.countValue,
        category: category,
        sub_category1: sub_category1,
        sub_category2: sub_category2
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        // console.log("data received");

        if (data["getProductList"]) {
          this.getProductListQuery.populateProductList(data, true);
        }
        
        if(offset > 0) {
          this.offsetValue = offset;
        }

        this.offsetSetting.emit(this.offsetValue);
        this.pageCountSetting.emit(this.countValue);

        if(externalFlag == true) {
          this.loadExternalSearchCriteria(
            title,
            categorygroup,
            scheduletype,
            start_date,
            end_date
          );

          this.saveSearchCriteria();
        }

        this.spinnerHide.emit();
      },
      (error) => {
        // console.log(error.message);
        this.spinnerHide.emit();
        this.getProductListQuery.populateProductList(error.message, false);
      }
    );
  }

  productList(button_press = false, filetrData?) {
    let title = this.productForm.controls.title.value;
    let categorygroup = this.productForm.controls.category.value;
    let scheduletype = this.productForm.controls.scheduletype.value;

    this.saveSearchCriteria();

    this.loadSavedSearchCriteria();

    let start_date = this.search_start_date;
    let end_date = this.search_end_date;

    if(button_press == true) {
      this.offsetValue = 0;
    }

    this.productListLoad(
      title,
      categorygroup,
      scheduletype,
      start_date,
      end_date,
      filetrData?.category || null,
      filetrData?.subCategory1 || null,
      filetrData?.subCategory2 || null,
      this.offsetValue
    );

    let navigationExtras: NavigationExtras = {
      queryParams: { 
        'categorygroup': categorygroup,
        'category': filetrData?.category || null,
        'subcategory1': filetrData?.subCategory1 || null,
        'subcategory2': filetrData?.subCategory2 || null,
        'startdate': start_date,
        'enddate': end_date,
        'keyword': title,
        'frequency': scheduletype,
        'offset': this.offsetValue
       },
      fragment: 'anchor'
    };
    
    // Navigate to the login page with extras
    this.routeWeb.navigate(['/search'], navigationExtras);

  }

  extractCategoryGroups = (data: any[]): any[] => {
    let organizedData = [];

    data.forEach(item => {
        const { category_group, category, sub_category1, sub_category2 } = item;

        let groupIndex = organizedData.findIndex(group => group.name === category_group);

        if (groupIndex === -1) {
            organizedData.push({
                name: category_group,
                category: [{
                    name: category,
                    sub_category1: sub_category1 ? [{ name: sub_category1, sub_category2: sub_category2 ? [sub_category2] : [] }] : []
                }]
            });
        } else {
            let categoryIndex = organizedData[groupIndex].category.findIndex(cat => cat.name === category);

            if (categoryIndex === -1) {
                organizedData[groupIndex].category.push({
                    name: category,
                    sub_category1: sub_category1 ? [{ name: sub_category1, sub_category2: sub_category2 ? [sub_category2] : [] }] : []
                });
            } else {
                let subCategoryIndex = organizedData[groupIndex].category[categoryIndex].sub_category1.findIndex(subCat => subCat.name === sub_category1);

                if (subCategoryIndex === -1) {
                    if (sub_category1) {
                        organizedData[groupIndex].category[categoryIndex].sub_category1.push({
                            name: sub_category1,
                            sub_category2: sub_category2 ? [sub_category2] : []
                        });
                    }
                } else {
                    if (sub_category2) {
                        organizedData[groupIndex].category[categoryIndex].sub_category1[subCategoryIndex].sub_category2.push(sub_category2);
                    }
                }
            }
        }
    });

    return organizedData;
  };
}
