import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class BroadcastOfferMessageMutation extends Mutation {

  document = gql`
    mutation BroadcastOfferMessageMutation(
      $lock_ids: [Int]
      $id_token: String!
      $user_email: String!
      $offer_id: Int!
      $schedule_id: Int
      $schedule_frequency: ProductScheduleFrequency
      $message: String!
    ) {
      addBroadcastMessage(
        lock_ids: $lock_ids
        id_token: $id_token
        user_email: $user_email
        offer_id: $offer_id
        message: $message
        schedule_id: $schedule_id
        schedule_frequency: $schedule_frequency
      )
    }
  `;
}
