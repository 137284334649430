<div class="dropdown-account">
  <a href="javascript:void(0);" class="dropdown-toggle-header">
    {{ "My Account" | translate }}
  </a>
  <div class="dropdown-menu-list">
    <a class="dropdown-item-value" [routerLink]="'/profile'">{{
      "Profile" | translate
    }}</a>
    <a class="dropdown-item-value" [routerLink]="'/new-offering'">{{
      "Offerings" | translate
    }}</a>
    <a class="dropdown-item-value" [routerLink]="'/cart'"
      >{{ "Shopping Cart" | translate }}</a>
    <a class="dropdown-item-value" [routerLink]="'/my-orders'">{{
      "Orders" | translate
    }}</a>
    <a class="dropdown-item-value" (click)="logout()">{{ "Logout" | translate }}</a>
  </div>
</div>
