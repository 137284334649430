import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferorLockStatusChangeMutation } from 'src/app/services/graphql/offerorLockStatusChange.graphql';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-approve-offering-email',
  templateUrl: './approve-offering-email.component.html',
  styleUrls: ['./approve-offering-email.component.scss']
})
export class ApproveOfferingEmailComponent implements OnInit {

  approvalToken = "";
  orderId = "";
  approvalStatus = "";

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private offerorLockStatusChangeMutation: OfferorLockStatusChangeMutation,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.approvalToken = params.get('approval_token');
      }
    );

    this.route.paramMap.subscribe(
      params => {
        this.orderId = params.get('order_id');
      }
    );

    this.route.paramMap.subscribe(
      params => {
        this.approvalStatus = params.get('approval_status');
      }
    );

    if(this.approvalToken == null || this.approvalToken == "") {
      this.router.navigate(['/']);
    }

    if(this.orderId == null || this.orderId == "") {
      this.router.navigate(['/']);
    }

    if(this.approvalStatus !== "success" && this.approvalStatus !== "fail") {
      this.router.navigate(['/']);
    }

    this.offerorStatusChange();
  }

  offerorStatusChange() {
    this.apollo
    .mutate({
      mutation: this.offerorLockStatusChangeMutation.document,
      variables: {
        id_token: this.approvalToken,
        order_id: this.orderId,
        user_type: "offeror",
        lock_type: "hard_lock",
        status: this.approvalStatus
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        this.toastr.success("Action completed successfully.");
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 4000);
      },
      (error) => {
        this.toastr.error(error.message);
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 4000);
      }
    );
  }



}
