import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class AttachMaskedUserDetailsMutation extends Mutation {
  document = gql`
    mutation AttachMaskedUserDetailsMutation(
      $user_id:Int,
      $masked_id:String,
      $email: String,
      $phone: String
   ){
    attachMaskedUserDetails(
        user_id:$user_id,
        masked_id:$masked_id,
        email:$email,
        phone:$phone
        )
      }
    `;
}
