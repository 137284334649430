import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class DeactivateScheduleMutation extends Query {

  document = gql`
    query DeactivateScheduleMutation(
      $schedule_id: Int!
      $product_id: Int!
      $id_token: String!
      $lender_email: String!
    ) {
      forcedDeactivationProductSchedule(
        schedule_id: $schedule_id,
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
      )
    }
  `;
}
