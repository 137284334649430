import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class UpdateAnonymousUserMutation extends Mutation {
  document = gql`
    mutation UpdateAnonymousUserMutation(
      $email:String!,
      $id_token:String!,
      $first_name: String!,
      $last_name: String!,
      $phone: String!,
      $address_line1: String,
      $address_line2: String,
      $landmark: String,
      $latitude:String,
      $longitude:String,
      $area_name: String,
      $pin_code: String,
      $verify_email: String,
      $city: String,
      $district: String,
      $state: String
   ){
    updateAnonymousUser(
        email:$email,
        id_token:$id_token,
        first_name:$first_name,
        last_name:$last_name,
        phone:$phone,
        verify_email: $verify_email,
        address_line1:$address_line1,
        address_line2:$address_line2,
        landmark:$landmark,
        latitude:$latitude,
        longitude:$longitude,
        area_name:$area_name,
        pin_code:$pin_code,
        city:$city,
        district:$district,
        state:$state
        ) {
        creation_status
        token_type
        id_token
        refresh_token
        firebase_id
        expires_in
        id
        firebase_id
        user_status
        is_email_verified
      }
      }
    `;
}
