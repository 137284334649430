import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class ReplaceAITextMutation extends Mutation {

  document = gql`
    mutation ReplaceAITextMutation(
      $offeror_email: String!
      $id_token: String!
      $input_text: String
      $remove_phone: Boolean
      $remove_email: Boolean
    ) {
      replaceAIText(
        offeror_email: $offeror_email,
        id_token: $id_token,
        input_text: $input_text,
        remove_phone: $remove_phone,
        remove_email: $remove_email
      )
    }
  `;
}
