<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Shipping & Delivery Policy</h1>
            <p class="has-text-grey-light">
                Last Updated: Jun 29, 2023
            </p>
            <br />
            <p class="box">
                Shipping is not applicable for business.
            </p>
        </div>
    </div>
</div>


<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
