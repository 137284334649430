import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';
import { RemoveSoftLockMutation } from 'src/app/services/graphql/removeSoftLock.graphql';
import { KatoraService } from '../../services/katora.service';
import { EventConstants } from 'src/app/constants/app.constants';

import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from "rxjs";
import { SessionService } from 'src/app/services/session.service';
import { StringValueNode } from 'graphql';


interface ProductSoftLockSlot {
  actual_date: string;
  day_template_id: number;
  slot_hours: number;
  slot_minutes: number;
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  schedule_slot_id: number;
  day_template_slot_id: number;
}

interface ProductSoftLock {
  schedule_frequency: string;
  start_date: string;
  end_date: string;
  offeror_id: number;
  product_id: number;
  offeree_id: number;
  category_id: number;
  product_description: string;
  title: string;
  address_id: number;
  product_image_link: string;
  age: number;
  guidelines: string;
  product_status: number;
  price: number;
  actual_price: number;
  schedule_id: number;
  deposit: number;
  calculated_deposit: number;
  id: number;
  lock_type: string;
  slots: Array<ProductSoftLockSlot>;

}
@Component({
  selector: 'app-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CartDropdownComponent implements OnInit {
  public productTotal = new BehaviorSubject<number>(
    0
  );
  public cartCount;
  loading = true
  defaultProductImage = EventConstants.CONST_DEFAULT_OFFER_IMAGE;

  productAddedToCart = new BehaviorSubject<Array<ProductSoftLock>>(
    []
  );
  @Input() showWhiteCart:boolean = false;

  @Output() refreshParentProductLocks = new EventEmitter();
  
  constructor(
    private katoraService : KatoraService,
    private apollo: Apollo,
    private getProductLocksQuery: GetProductLocksQuery,
    private removeSoftLockMutation: RemoveSoftLockMutation,
    private sessionService: SessionService
    ) { 
    
  }

  ngOnInit() {
    this.getProductLocks();     
  }

  getProductLocks() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    if(token != null) {
    this.apollo
    .query({
      query: this.getProductLocksQuery.document,
      variables: {
        id_token: token,
        user_email: email,
        user_type: "offeree",
        lock_type: "soft_lock"
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["getProductLocks"] != null) {
          this.productAddedToCart.next(data["getProductLocks"]);
          let totalPrice = 0;
            data["getProductLocks"].forEach(productLock => {
            if(productLock.discounted_price != null && productLock.discounted_price != 0) {
                totalPrice += productLock.discounted_price;
              } else {
              totalPrice += productLock.price;
              }
          });

          this.productTotal.next(totalPrice);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  }

  ngAfterContentChecked(){
    this.cartCount = this.katoraService.getCartCount();
  }
  removeProductSoftLock(product_lock_id: number){
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .mutate({
      mutation: this.removeSoftLockMutation.document,
      variables: {
        id_token: token,
        offeree_email: email,
        lock_id: product_lock_id
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["removeProductSoftLock"] != null) {
          this.refreshParentProductLocks.next();
          // this.getProductLocks();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
