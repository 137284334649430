<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Frequently Asked Questions
        </li>
      </ol>
    </div>
  </nav>
</div>
<div class="container">

	<div class="page-header m-4">
		<h1>Zigwik FAQ</h1>
	</div>
	
	<div class="container">
		<br />
	
		<div class="alert alert-warning alert-dismissible" role="alert">
			<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
			This section contains most frequently asked questions about Zigwik. If you cannot find an answer to your question, 
			make sure to contact us. 
		</div>
	
		<br />
	
		<div class="panel-group" id="accordion">
			<div class="faqHeader">General questions</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">Do I need to pay anything to create an Offer in Zigwik?</a>
					</h4>
				</div>
				<div id="collapseOne" class="panel-collapse collapse p-2">
					<div class="panel-body">
						You do not need to pay anything. Just create an Offer and fix a price for it. We add commission on top of it which is dynamic in nature. We will pay you your price after deducting the tax from it. That is it.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTen">My Offer got deactivated automatically. In what scenarios can my Offer get deactivated?</a>
					</h4>
				</div>
				<div id="collapseTen" class="panel-collapse collapse p-2">
					<div class="panel-body">
						If there are no active schedules available for the Offer, the Offer will get deactivated automatically. Adding new schedule, activating the schedule and activating the Offer again will show your Offer in marketplace again. 
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEleven">I created an Offer and also completed some bookings successfully. When should I expect the payment for the bookings?</a>
					</h4>
				</div>
				<div id="collapseEleven" class="panel-collapse collapse p-2">
					<div class="panel-body">
						All completed bookings are aggregated and the final payment settlement for the bookings are done at the end of every month for each Offer. If you did not receive the amount by end of the month or first week of the month at the latest, please contact us with your Offer name.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwelve">I added some slots to cart. I don't see them now. What can be the reason?</a>
					</h4>
				</div>
				<div id="collapseTwelve" class="panel-collapse collapse p-2">
					<div class="panel-body">
						There can be limited slots available for certain Offers. If an Offer is fully booked for a specific slot, then no further booking is possible, in that case, if you have a slot in your cart, that will be removed. 
					</div>
				</div>
			</div>
	
			<div class="faqHeader">Schedules</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">I created a weekly schedule. While activating it, it is throwing an error that it is not possible to activate it. What is the reason? Same is the case with Monthly schedules.</a>
					</h4>
				</div>
				<div id="collapseTwo" class="panel-collapse collapse p-2">
					<div class="panel-body">
						The minimum days for serving an weekly Offer is 4 days and monthly Offer is 18 days. That means, the Offeree should have an option to book the Offer for atleast 4 days for weekly Offer and 18 days for monthly Offer. If the days are not planned appropriately in the schedule, the schedule won't get activated. This is the most common reason for such issue.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">Can I have multiple schedule types for one Offer? For example I want to have weekly and daily schedules for my Offer.</a>
					</h4>
				</div>
				<div id="collapseThree" class="panel-collapse collapse p-2">
					<div class="panel-body">
						Yes it is possible to do that.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive">How much do I get from each booking?</a>
					</h4>
				</div>
				<div id="collapseFive" class="panel-collapse collapse p-2">
					<div class="panel-body">
						The price what you have decided for the Offer at the time of creation of the schedule is what you get. However, we deduct GST Tax on your behalf. The current GST % for online services is 18%. For example if you have kept an amount of 200 Rs for your Offer, you will get 164 Rs from us for a successful booking completion. Zigwik generates invoices to Offerees using our own GST number registered in Gujarat state (24AACCZ1913G1ZL).
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix">Why create Offers here?</a>
					</h4>
				</div>
				<div id="collapseSix" class="panel-collapse collapse p-2">
					<div class="panel-body">
						Zigwik is targeting to become the biggest marketplace for online services Offered by any user. Having an Offer created in Zigwik gives you the levarage of huge customer base constantly searching for such Offers. Not even that, your Offer page is SEO enabled and also you could just use your Offer page to market it in your social media groups which will show your title and image professionally. Also, you get benefit of managing the slots and schedules for your Offer effectively bringing down the complexity to a great extend. So create an Offer and just relax.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseEight">Do we need to specify account details when creating an Offer?</a>
					</h4>
				</div>
				<div id="collapseEight" class="panel-collapse collapse p-2">
					<div class="panel-body">
						Bank account details are not required for creation of the Offer. However, it would be required when you try to activate an Offer. Without activation, it is not possible to publish the Offer in the marketplace.
					</div>
				</div>
			</div>


			<div class="faqHeader">Rebooking and Refund</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThirteen">I booked the Offer by mistake, can I cancel the booking?</a>
					</h4>
				</div>
				<div id="collapseThirteen" class="panel-collapse collapse p-2">
					<div class="panel-body">
						You can cancel your booking. Please check the cancellation policy mentioned in the Offer before booking it. The refund will be granted depending on the cancellation policy for the Offer. You can cancel the booking by going to the order page. There will be a cancel button for your booking inside the order details.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFourteen">I booked the Offer, but I am not happy with the way how it went. Can I get refund for my booking?</a>
					</h4>
				</div>
				<div id="collapseFourteen" class="panel-collapse collapse p-2">
					<div class="panel-body">
						We highly recommend to discuss this with the Offeror before taking any action. Majority of the times, they will be able to support you and resolve issues. However, if you are still not satisfied, please raise complaint to us. You can do that by clicking on the link mentioned in one of the notification emails sent to you. We would still wait for the Offeror to try to resolve your issue.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFifteen">I got an email that the Offer that I had booked was rejected by the Offeror. Will I get the full refund?</a>
					</h4>
				</div>
				<div id="collapseFifteen" class="panel-collapse collapse p-2">
					<div class="panel-body">
						Yes you will get the full refund if the Offeror cancels the booking.
					</div>
				</div>
			</div>
			<div class="panel panel-default">
				<div class="panel-heading">
					<h4 class="panel-title">
						<a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSixteen">Any other things that I need to know about the refund policy?</a>
					</h4>
				</div>
				<div id="collapseSixteen" class="panel-collapse collapse p-2">
					<div class="panel-body">
						There is only a certain time when you can file complaint for your booking. If you would like to raise the complaint after the deadline, you would need to contact us going to the contact us page. Our decisions under this Policy are binding, but do not affect other contractual or statutory rights that may be available. Any right that Offeree or Offeror may have to initiate legal action remains unaffected. This Policy is not insurance and no premium has been paid by any Offeree or Offeror. All rights and obligations under this Policy are personal to the booking Offeree and Offeror of the booking and may not be transferred or assigned. Any changes to this Policy will be made in accordance with our Terms of Service.
					</div>
				</div>
			</div>
	
		</div>
	</div>	
	</div>


<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-product-modal></app-product-modal>
<app-back-to-top></app-back-to-top>