import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from "src/app/pipes/pipes.module";

import { ComponentsModule } from '../../components/components.module';
import { HeaderModule } from '../../layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { ProfileComponent } from './profile/profile.component';
import { PublicProfileComponent } from './public-profile/public-profile.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgbRatingModule} from '@ng-bootstrap/ng-bootstrap';
import { ResendEmailLinkComponent } from './resend-email/resend-email-link.component';


@NgModule({
  declarations: [ProfileComponent, PublicProfileComponent, OrdersComponent, OrderDetailComponent, ShippingAddressComponent, ChangePasswordComponent, ResendEmailLinkComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PipesModule,
    HeaderModule,
    FooterModule,
    SidebarModule,
    NgbRatingModule
  ],
  exports: [ProfileComponent, PublicProfileComponent, OrdersComponent, OrderDetailComponent, ShippingAddressComponent , ChangePasswordComponent]
})
export class UserAccountModule { }
