import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { SessionService } from "../../../services/session.service";
import { SessionConstants } from "src/app/constants/app.constants";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { Apollo } from "apollo-angular";
import { ActivatedRoute, Router } from "@angular/router";
import { GetCategoryQuery } from "src/app/services/graphql/getcategory.graphql";
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { CartDropdownComponent } from "../../../components/cart-dropdown/cart-dropdown.component";
import {
  NgbDateStruct,
  NgbDate,
  NgbCalendar,
} from "@ng-bootstrap/ng-bootstrap";
import { DeleteUserCachedTokenMutation } from "src/app/services/graphql/deleteUserCachedToken.graphql";
import { LogoutUserMutation } from "src/app/services/graphql/logout.graphql";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-header-mobile",
  // templateUrl: './header-mobile.component.html',
  templateUrl: "./header-mobile.temp.component.html",
  styleUrls: ["./header-mobile.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderMobileComponent implements OnInit {
  model_start_date: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate()
  };
  model_end_date: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: 12,
    day: 31
  };

  private userData;
  productFormMobile: FormGroup;
  currentDate = Date.now();
  currentDate1 = new Date((new Date()).getFullYear(), 11, 31);
  selectedStartDate: Date = new Date(this.currentDate);
  selectedEndDate: Date = new Date(this.currentDate1);
  categoryData = [];
  productselectedId: number;

  search_start_date =
    this.selectedStartDate.getFullYear().toString() +
    "-" +
    (this.selectedStartDate.getMonth() + 1).toString() +
    "-" +
    this.selectedStartDate.getDate().toString();
  search_end_date =
    this.selectedEndDate.getFullYear().toString() +
    "-" +
    (this.selectedEndDate.getMonth() + 1).toString() +
    "-" +
    this.selectedEndDate.getDate().toString();

  categoryValue = "";
  scheduleTypeValue = "";

  @Input() offsetValue = 0;
  @Input() countValue = 12;

  @Input() loginCheck = false;
  @Input() withAnonymous = false;
  @Input() loginRedirectUrl = "/";
  @Input() emailVerificationCheck = false;
  @Input() reverseLoginCheck = false;

  @Output() offsetSetting = new EventEmitter<number>();
  @Output() pageCountSetting = new EventEmitter<number>();

  @ViewChild(CartDropdownComponent, { static: true })
  cartDropdownComponent: CartDropdownComponent;

  showHideSideNav: boolean = false;
  navLinksMobile: any[] = [
    { name: "Sign Up", link: "/signup", show:this.getUserName() == false  },
    { name: "Profile", link: "/profile" , show:this.getUserName() ? true : false },
    { name: "Offerings", link: "/new-offering" ,show:true },
    { name: "Shopping Cart", link: "/cart" , show:true },
    { name: "Orders", link: "/my-orders", show:this.getUserName() ? true : false  },
    { name: 'logout', link: "/", show:this.getUserName() ? true : false  },

  ];

  constructor(
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apollo: Apollo,
    private getCategoryQuery: GetCategoryQuery,
    private getProductListQuery: GetProductListQuery,
    private router: Router,
    private location: Location,
    private deleteUserCachedTokenMutation: DeleteUserCachedTokenMutation,
    private logoutService: LogoutUserMutation,
    private readonly authService: AuthService
  ) {}

  setOffsetValue(value) {
    this.offsetValue = value;
  }

  toggleSideMenu() {
    var menu = document.getElementById("left_menu_id");
    if(menu.classList.contains("showNav")) {
      menu.classList.remove("showNav");
    } else {
      menu.classList.add("showNav");
    }
  }

  ngOnInit() {
    // let user_id = this.sessionService.getKeyValues("token", "id");
    // if(user_id != null && user_id != 0) {
    //   this.apollo
    //   .mutate({
    //     mutation: this.deleteUserCachedTokenMutation.document,
    //     variables: {
    //       user_id: user_id
    //     },
    //     fetchPolicy: "network-only"
    //   })
    //   .subscribe(
    //     ({ data }) => {
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }
    var flag = this.redirectLogin();
    
    if (flag === true) {
      this.redirectEmailVerify();
      this.redirectInverseLogin();
    }

    this.route.paramMap.subscribe((params) => {
      this.productselectedId = +params.get("id");
    });

    this.getCategoriesData();
    this.getUserData();

    this.productFormMobile = this.formBuilder.group({
      title: [""],
      category: [""],
      scheduletype: [""],
      start_date: [""],
      end_date: [""],
      offset: [this.offsetValue],
      count: [this.countValue],
    });

    this.categoryValue = "";
    this.scheduleTypeValue = "";

    this.loadSavedSearchCriteria();

    let left_menu = document.getElementById("left_menu_id");
    let right_menu_button = document.getElementById("mobile-right-button-id");

    function assertIsNode(e: EventTarget | null): asserts e is Node {
      if (!e || !("nodeType" in e)) {
          throw new Error(`Node expected`);
      }
    }

    document.onclick = function (e) {
      assertIsNode(e.target);
      if (!left_menu.contains(e.target) && !right_menu_button.contains(e.target)) {
          left_menu.classList.remove("showNav");
      }
    }
  }

  callChildLoadSoftLocks() {
    this.cartDropdownComponent.getProductLocks();
  }
  
  redirectLogin() {
    var flag = true;

    if(this.loginCheck == true) {
      let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
      var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if((email === null || email === undefined || email === "") && this.withAnonymous == true) {
        email = anonymousEmail;
      }
  
      if(token == null || token == "" || email == null || email == "") {

        flag = false;
        if (this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate(["/login"]);
      }
    }
    return flag;
  }

  redirectInverseLogin() {
    if(this.reverseLoginCheck == true) {
      let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
      var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if((email === null || email === undefined || email === "") && this.withAnonymous == true) {
        email = anonymousEmail;
      }
  
      if(token != null && token != "" && email != null && email != "") {
        if(this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate([this.loginRedirectUrl]);
      }
    }
  }

  redirectEmailVerify() {
    if(this.emailVerificationCheck == true) {
      let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
      var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");
      let emailVerified = this.sessionService.get(SessionConstants.CONST_VERIFIED_EMAIL_KEYWORD);
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if((email === null || email === undefined || email === "") && this.withAnonymous == true) {
        email = anonymousEmail;
      }

      if(emailVerified === null || emailVerified === undefined || emailVerified === "") {
        emailVerified = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "is_email_verified");
      }
      
      if(token == null || token == "" || email == null || email == "") {
        if(this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate(["/login"]);
      }

      if(emailVerified != true && emailVerified != "true" && emailVerified != "True") {
        this.router.navigate(["/resend-email-verification-link/1"]);
      }
    }
  }

  getCategoriesData() {
    this.apollo
      .query({
        query: this.getCategoryQuery.document,
        variables: {},
        fetchPolicy: "network-only",
      })
      .subscribe(
        ({ data }) => {
          this.getCategoryQuery.populateCategoryGroup(data);
          this.categoryData = this.getCategoryQuery.categoryGroupHeader;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getUserData() {
    this.userData = this.sessionService.getLocal(SessionConstants.CONST_SESSION_USER_KEYWORD);
  }

  getUserName() {
    this.getUserData();
    let userData = this.userData;
    if (!userData) return false;
    else {
      this.userData = userData;
      let firstName: string = userData["first_name"];
      let lastName: string = userData["last_name"];
      return (
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1)
      );
    }
  }

  saveSearchCriteria(){
    this.sessionService.setSession("search_title", this.productFormMobile.controls.title.value);
    this.sessionService.setSession("search_category_group", this.productFormMobile.controls.category.value);
    this.sessionService.setSession("search_schedule_type", this.productFormMobile.controls.scheduletype.value);
    this.sessionService.setSession("search_start_date", this.search_start_date);
    this.sessionService.setSession("search_end_date", this.search_end_date);
  }

  loadSavedSearchCriteria() {
    if(this.sessionService.checkSessionKey("search_title")) {
      this.productFormMobile.patchValue({
        title: this.sessionService.getSession("search_title")
      });
    }

    if(this.sessionService.checkSessionKey("search_start_date")) {
      let tempDate = new Date(this.sessionService.getSession("search_start_date"));
      this.search_start_date = this.sessionService.getSession("search_start_date");
      this.model_start_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate(),
      };
    }

    if(this.sessionService.checkSessionKey("search_end_date")) {
      let tempDate = new Date(this.sessionService.getSession("search_end_date"));
      this.search_end_date = this.sessionService.getSession("search_end_date");
      this.model_end_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate(),
      };
    }
    
    if(this.sessionService.checkSessionKey("search_category_group")) {
      this.categoryValue = this.sessionService.getSession("search_category_group");
    }

    if(this.sessionService.checkSessionKey("search_schedule_type")) {
      this.scheduleTypeValue = this.sessionService.getSession("search_schedule_type");
    }
  }

  onDateSelection(evt: NgbDate, date_type) {
    if (date_type == "start_date") {
      this.search_start_date = evt.year + "-" + evt.month + "-" + evt.day;
    }

    if (date_type == "end_date") {
      this.search_end_date = evt.year + "-" + evt.month + "-" + evt.day;
    }
  }

  productList(button_press = false) {
    let title = this.productFormMobile.controls.title.value;
    let categorygroup = this.productFormMobile.controls.category.value;
    let scheduletype = this.productFormMobile.controls.scheduletype.value;

    this.saveSearchCriteria();

    this.loadSavedSearchCriteria();

    let start_date = this.search_start_date;
    let end_date = this.search_end_date;

    if (button_press == true) {
      this.offsetValue = 0;
    }

    this.apollo
      .query({
        query: this.getProductListQuery.document,
        variables: {
          title_keyword: title,
          category_group: categorygroup,
          schedule_frequency: scheduletype,
          start_date: start_date,
          end_date: end_date,
          offset: this.offsetValue,
          count: this.countValue,
        },
        fetchPolicy: "network-only",
      })
      .subscribe(
        ({ data }) => {
          console.log("data received");

          if (data["getProductList"]) {
            this.getProductListQuery.populateProductList(data, true);
          }
          this.offsetSetting.emit(this.offsetValue);
          this.pageCountSetting.emit(this.countValue);
        },
        (error) => {
          console.log(error.message);
          this.getProductListQuery.populateProductList(error.message, false);
        }
      );
  }

  extractDateFromDateTime(valDateTime: string) {
    if (valDateTime) {
      var d = new Date(valDateTime),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let dateVal = [year, month, day].join("-");
      return dateVal;
    } else return "";
  }

  logout() {
    let loginSessionData = this.sessionService.get("token");
    let loginUserData = this.sessionService.getLocal("user");
    let email = loginUserData["email"];
    let idToken = loginSessionData["id_token"];
    this.apollo
      .mutate({
        mutation: this.logoutService.document,
        variables: {
          email: email,
          idToken: idToken,
        },
      })
      .subscribe(
        ({ data }) => {
          this.authService.logout();
          this.sessionService.deleteLogin();
          this.router.navigate(["/"]);
          this.navLinksMobile = [
            { name: "Sign Up", link: "/signup", show:this.getUserName() == false  },
            { name: "Profile", link: "/profile" , show:this.getUserName() ? true : false },
            { name: "Offerings", link: "/new-offering", show:true },
            { name: "Shopping Cart", link: "/cart", show:true },
            { name: "Orders", link: "/my-orders", show:this.getUserName() ? true : false  },
            { name: 'logout', link: "/", show:this.getUserName() ? true : false  },
          ];
          this.showHideSideNav = false;
        },
        (error) => {
          //this.errorMessage = "Unable to get User details. Please try again later.";
        }
      );
  }
}
