import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CartDropdownComponent } from "../../../components/cart-dropdown/cart-dropdown.component";
import { SessionService } from 'src/app/services/session.service';
import { SessionConstants } from 'src/app/constants/app.constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header-sticky',
  templateUrl: './header-sticky.component.html',
  styleUrls: ['./header-sticky.component.scss']
})
export class HeaderStickyComponent implements OnInit {

  @ViewChild(CartDropdownComponent, { static: true })
  cartDropdownComponent: CartDropdownComponent;
  userData: any;
  @Input() loginRedirectUrl = "/";
  emailVerificationCheck: boolean;
  hideSearch:boolean = true;

  constructor(private sessionService: SessionService,  private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {
    let route = this.route.url.subscribe({
      next:(res)=>{
        // console.log(res[0].path , 'Res');
        if(res[0]?.path == 'search'){
          this.hideSearch = false;
        }else this.hideSearch = true;
        
      }
    })
    // console.log(route);
    this.getUserData();
  }

  callChildLoadSoftLocks() {
    this.cartDropdownComponent.getProductLocks();
  }

  getUserData() {
    this.userData = this.sessionService.getLocal(SessionConstants.CONST_SESSION_USER_KEYWORD);
  }
  getUserName() {
    this.getUserData();
    let userData = this.userData;
    
    if (!userData) return false;
    else {
      this.userData = userData;
      let firstName: string = userData["first_name"];
      let lastName: string = userData["last_name"];
      return (
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1)
      );
    }
  }
  redirectEmailVerify() {
    if(this.emailVerificationCheck == true) {
      let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getKeyValues("user", "email");
      let emailVerified = this.sessionService.get(SessionConstants.CONST_VERIFIED_EMAIL_KEYWORD);
      
      if(token == null || token == "" || email == null || email == "") {
        if(this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate(["/login"]);
      }

      if(emailVerified != true) {
        this.router.navigate(["/resend-email-verification-link/1"]);
      }
    }
  }

}
