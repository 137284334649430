<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- cart Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Cart Page" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section
  class="custom-content pro-content cart-content"
  *ngIf="(this.productAddedToCart | async)?.length > 0"
>
  <div class="container">
    <div *ngIf="this.anonymousUserDetails != null" class="row pb-5">
      <div class="col-12 pb-2">
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.first_name != null && this.anonymousUserDetails.first_name != '')" class="h5">
          <i class="fas fa-file-invoice"></i> {{ "Your details for Invoice" | translate }} &nbsp;&nbsp;<button class="button is-link is-outlined is-small" (click)="openModalWithValues()"><i class="fas fa-edit product-icon-passive"></i> &nbsp;Edit Information</button>
        </span>
        </div>
      <div class="col-12">
        <span *ngIf="((this.anonymousUserDetails != null && this.anonymousUserDetails.first_name != null && this.anonymousUserDetails.first_name != '') || (this.anonymousUserDetails != null && this.anonymousUserDetails.last_name != null && this.anonymousUserDetails.last_name != ''))" class="is-size-7">
          <i class="fas fa-file-signature"></i>
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.first_name != null && this.anonymousUserDetails.first_name != '')" class="is-size-7">
           {{ this.anonymousUserDetails.first_name | translate }}
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.last_name != null && this.anonymousUserDetails.last_name != '')" class="is-size-7">
           {{ this.anonymousUserDetails.last_name | translate }}
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.email != null && this.anonymousUserDetails.email != '')" class="is-size-7">
          <i class="fas fa-envelope"></i> {{ this.anonymousUserDetails.email | translate }}
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.phone != null && this.anonymousUserDetails.phone != '')" class="is-size-7">
          <i class="fas fa-phone"></i> {{ this.anonymousUserDetails.phone | translate }}
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.area_name != null && this.anonymousUserDetails.area_name != '')" class="is-size-7">
          <i class="fas fa-address-card"></i>
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.address_line1 != null && this.anonymousUserDetails.address_line1 != '')" class="is-size-7">
           {{ this.anonymousUserDetails.address_line1 | translate }},
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.address_line2 != null && this.anonymousUserDetails.address_line2 != '')" class="is-size-7">
           {{ this.anonymousUserDetails.address_line2 | translate }},
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.area_name != null && this.anonymousUserDetails.area_name != '')" class="is-size-7">
          {{ this.anonymousUserDetails.area_name | translate }},
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.pin_code != null && this.anonymousUserDetails.pin_code != '')" class="is-size-7">
           Pin: {{ this.anonymousUserDetails.pin_code }},
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.district != null && this.anonymousUserDetails.district != '')" class="is-size-7">
          {{ this.anonymousUserDetails.district | translate }},
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.state != null && this.anonymousUserDetails.state != '')" class="is-size-7">
           {{ this.anonymousUserDetails.state | translate }},
        </span>
        <span *ngIf="(this.anonymousUserDetails != null && this.anonymousUserDetails.country != null && this.anonymousUserDetails.country != '')" class="is-size-7">
          {{ this.anonymousUserDetails.country | translate }}
       </span>
      </div>
    </div>
    <div *ngIf="this.userDetails != null" class="row pb-5">
      <div class="col-12 pb-2">
        <span *ngIf="(this.userDetails != null && this.userDetails.first_name != null && this.userDetails.first_name != '')" class="h5">
          <i class="fas fa-file-invoice"></i> {{ "Your details for Invoice" | translate }}
        </span>
        </div>
      <div class="col-12">
        <span *ngIf="((this.userDetails != null && this.userDetails.first_name != null && this.userDetails.first_name != '') || (this.userDetails != null && this.userDetails.last_name != null && this.userDetails.last_name != ''))" class="is-size-7">
          <i class="fas fa-file-signature"></i>
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.first_name != null && this.userDetails.first_name != '')" class="is-size-7">
           {{ this.userDetails.first_name | translate }}
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.last_name != null && this.userDetails.last_name != '')" class="is-size-7">
           {{ this.userDetails.last_name | translate }}
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.userDetails != null && this.userDetails.email != null && this.userDetails.email != '')" class="is-size-7">
          <i class="fas fa-envelope"></i> {{ this.userDetails.email | translate }}
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.userDetails != null && this.userDetails.phone != null && this.userDetails.phone != '')" class="is-size-7">
          <i class="fas fa-phone"></i> {{ this.userDetails.phone | translate }}
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.userDetails != null && this.userDetails.area_name != null && this.userDetails.area_name != '')" class="is-size-7">
          <i class="fas fa-address-card"></i>
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.address_line1 != null && this.userDetails.address_line1 != '')" class="is-size-7">
           {{ this.userDetails.address_line1 | translate }},
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.address_line2 != null && this.userDetails.address_line2 != '')" class="is-size-7">
           {{ this.userDetails.address_line2 | translate }},
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.userDetails != null && this.userDetails.area_name != null && this.userDetails.area_name != '')" class="is-size-7">
          {{ this.userDetails.area_name | translate }},
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.pin_code != null && this.userDetails.pin_code != '')" class="is-size-7">
           Pin: {{ this.userDetails.pin_code | translate }},
        </span>
      </div>
      <div class="col-12">
        <span *ngIf="(this.userDetails != null && this.userDetails.district != null && this.userDetails.district != '')" class="is-size-7">
          {{ this.userDetails.district | translate }},
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.state != null && this.userDetails.state != '')" class="is-size-7">
           {{ this.userDetails.state | translate }},
        </span>
        <span *ngIf="(this.userDetails != null && this.userDetails.country != null && this.userDetails.country != '')" class="is-size-7">
          {{ this.userDetails.country | translate }}
       </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-9">




        <div class="cartoneload">
        <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
        <table class="table top-table">
          <tbody>
            <tr class="d-flex" *ngFor="let data of this.productAddedToCart | async">
              <td>
                <table class="table border-0">
                  <tbody>
                    <tr class="border-0">
                      <td class="col-12 col-md-2">
                        <img
                        *ngIf="data.product_image_link != null" 
                        class="img-fluid"
                        src="{{data.product_image_link}}"
                        alt="Product Image"
                      />
                      <img
                        *ngIf="data.product_image_link == null" 
                        class="img-fluid"
                        src="{{defaultProductImage}}"
                        alt="Product Image"
                      />
                      </td>
                      <td class="col-12 col-md-4">
                        <div class="item-detail">
                          <span class="pro-info">&nbsp;{{data.category_name}}&nbsp;&nbsp;&nbsp;</span>
                            <h2 class="pro-title">
                              <a [routerLink]="['/offer/', data.product_id]">
                                &nbsp;{{ data.title | translate }}
                              </a>&nbsp;&nbsp;&nbsp;
                            </h2>
                            <ng-template [ngIf]="data.coupon_code != null">
                              <div class="item-attributes">
                                  Coupon Code: <span class="has-text-primary">{{data.coupon_code}}</span>
                              </div>
                            </ng-template>
                          <div class="item-attributes">&nbsp;&nbsp;&nbsp;</div>
                          <div class="item-controls">
                            <a class="border-0" data-toggle="collapse" href="#offerorDetail_{{data.id}}" id="offerorDesc" role="button" aria-expanded="false">
                            <button type="button" class="btn">
                              <span class="fas fa-chevron-down"></span>
                            </button>
                          </a>
                            <button
                              type="button"
                              class="btn"
                              (click)="removeProductSoftLock(data.id)"
                            >
                              <span class="fas fa-times"></span>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td class="col-12 col-md-2">
                        <ng-template [ngIf]="data.discounted_price != null">
                          <span class="item-price">&nbsp;&nbsp;&nbsp;{{
                            data.discounted_price | curency
                          }}&nbsp;&nbsp;&nbsp;</span>
                        </ng-template>
                        <ng-template [ngIf]="data.discounted_price == null">
                        <span class="item-price">&nbsp;&nbsp;&nbsp;{{
                          data.price | curency
                        }}&nbsp;&nbsp;&nbsp;</span>
                        </ng-template>
                      </td>
                      <td class="col-12 col-md-2">
                        <div class="input-group item-quantity">
                          <input
                            type="text"
                            name="quantity"
                            class="form-control"
                            value="1"
                            readonly
                          />
        
                          <span class="input-group-btn">
                            <button
                              type="button"
                              (click)="addQuantity(data)"
                              class="quantity-plus btn"
                              data-type="minus"
                              data-field=""
                            >
                              <span class="fas fa-plus"></span>
                            </button>
        
                            <button
                              type="button"
                              [disabled]="1"
                              (click)="removeQuantity(data)"
                              class="quantity-minus btn"
                              data-type="plus"
                              data-field=""
                            >
                              <span class="fas fa-minus"></span>
                            </button>
                          </span>
                        </div>
                      </td>
                      <td class="col-12 col-md-2">
                        <ng-template [ngIf]="data.discounted_price != null">
                          <span class="item-price">&nbsp;&nbsp;&nbsp;{{
                            data.discounted_price * 1 | curency
                          }}&nbsp;&nbsp;&nbsp;</span>
                        </ng-template>
                        <ng-template [ngIf]="data.discounted_price == null">
                        <span class="item-price">&nbsp;&nbsp;&nbsp;{{
                          data.price * 1 | curency
                        }}&nbsp;&nbsp;&nbsp;</span>
                        </ng-template>
                      </td>
                    </tr></tbody></table>



                    <table class="table border-0">
                      <tbody>
                        <tr class="border-0">
                          <td>
                            <div class="col-md-12 icon-box border-0">
                            <div class="collapse border-0" id="offerorDetail_{{data.id}}">
                              <div class="card card-body border-0">
                                <table class="w-auto border-0">
                                  <tr class="border-0">
                                    <th width="30%">Start&nbsp;date:</th>
                                    <td width="70%" valign="bottom">{{ data.start_date }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">End&nbsp;date:</th>
                                    <td width="70%" valign="bottom">{{ data.end_date }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">Additional&nbsp;Guidelines:</th>
                                    <td width="70%" valign="bottom">{{ data.schedule_guidelines }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">Maximum&nbsp;offer&nbsp;count:</th>
                                    <td width="70%" valign="bottom">{{ data.product_count }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">Approval&nbsp;days&nbsp;for&nbsp;Offer:</th>
                                    <td width="70%" valign="bottom">{{ data.buffer_days }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">Full&nbsp;refund&nbsp;before:</th>
                                    <td width="70%" valign="bottom">{{ data.full_refund_date }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">Half&nbsp;refund&nbsp;before:</th>
                                    <td width="70%" valign="bottom">{{ data.half_refund_date }}</td>
                                  </tr>
                                  <tr class="border-0">
                                    <th width="30%">Slot(s):</th>
                                    <td width="70%" valign="bottom">
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="2">
                                      <table class="table table-bordered table-striped">
                                        <tr>
                                          <th>&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                          <th>Duration</th>
                                          <th>Start&nbsp;time</th>
                                          <th>End&nbsp;time</th>
                                        </tr>
                                      <tbody>
                                        <tr *ngFor="let slot of data.slots">
                                          <td>&nbsp;{{slot["actual_date"]}}</td>
                                          <td>
                                            {{slot["slot_hours"]}}&nbsp;hour&nbsp;{{slot["slot_minutes"]}}&nbsp;minute
                                          </td>
                                          <td>
                                            <ng-template [ngIf]="slot['start_hour'].toString().length < 2">0{{slot["start_hour"]}}</ng-template><ng-template [ngIf]="slot['start_hour'].toString().length >= 2">{{slot["start_hour"]}}</ng-template>:<ng-template [ngIf]="slot['start_minute'].toString().length < 2">0{{slot["start_minute"]}}</ng-template><ng-template [ngIf]="slot['start_minute'].toString().length >= 2">{{slot["start_minute"]}}</ng-template>
                                          </td>
                                          <td>
                                            <ng-template [ngIf]="slot['end_hour'].toString().length < 2">0{{slot["end_hour"]}}</ng-template><ng-template [ngIf]="slot['end_hour'].toString().length >= 2">{{slot["end_hour"]}}</ng-template>:<ng-template [ngIf]="slot['end_minute'].toString().length < 2">0{{slot["end_minute"]}}</ng-template><ng-template [ngIf]="slot['end_minute'].toString().length >= 2">{{slot["end_minute"]}}</ng-template>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                            </td>
                            </tr>
                            </tbody>
                            </table>









              </td>
            </tr>
          </tbody>
        </table>
        </div>
        </div>















        <div class="col-12 col-lg-12 mb-4">
          <div class="row justify-content-between">
            <div class="col-12 col-lg-4">
              <ng-template [ngIf]="(couponCodeLoadFlag | async) == true">
                <div class="row">
                  <div class="applied-coupon" id="appliedCoupon" style="display: block;">
                    <span id="couponCodeSpan">{{ couponCodeAsync | async }}</span>
                    <button id="cancelCouponButton" (click)="removeCouponCode()">Cancel</button>
                  </div>
                </div>
              </ng-template>
              <div class="row">
                <div class="input-group">
                  <input
                    id="couponCodeInput"
                    type="text"
                    class="form-control"
                    style="height: 43px;"
                    placeholder="Coupon Code"
                    aria-label="Coupon Code"
                    aria-describedby="coupon-code"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-secondary swipe-to-top"
                      type="button"
                      id="coupon-code"
                      (click)="applyCouponCode()"
                    >
                      {{ "Apply" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-7">
              <div class="row justify-content-end btn-res">
                <button
                  type="button"
                  class="btn btn-link"
                  [routerLink]="'/'"
                >
                  {{ "Continue Shopping" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <table class="table right-table">
          <thead>
            <tr>
              <th scope="col" colspan="2">{{ "Order Summary" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{{ "Subtotal" | translate }}</th>
              <td>{{productTotal | async | curency}}</td>
            </tr>
            <tr class="item-price">
              <th scope="row">{{ "Total" | translate }}</th>
              <td>{{productTotal | async | curency}}</td>
            </tr>
          </tbody>
        </table>
        





















        <ng-template #verifyEmail let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Verify Email</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="field mw-50 max-vw-100">
                Your Email Id is not yet verified. Please verify your email id to continue.
              </div>
              <div class="field mw-50 max-vw-100">
                <button class="button is-danger is-outlined" (click)="resendEmailLink()"><i class="fas fa-envelope"></i> &nbsp;Send Verification Link</button>    
              </div>
            </div>
          </div>
        </ng-template>




        <ng-template #fillRequiredDetails let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Please fill required details</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">













            <form [formGroup]="getUserForm">
              <div class="container">
                    <legend>User Details</legend>
                    <div class="field is-horizontal form-group">
                      <div class="field-body pt-2">
                        <div class="field mw-50 max-vw-100">
                          <label class="label">First Name</label>
                          <input formControlName="firstname" class="form-control" type="text" placeholder="First Name"
                            [ngClass]="{'is-invalid': submitted && f.firstname.errors }" required />
                          <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                            <div *ngIf="f.firstname.errors.required">First Name is required</div>
                          </div>
                        </div>
                        <div class="field mw-50 max-vw-100">
                          <label class="label">Last Name</label>
                          <input formControlName="lastname" class="form-control" type="text" placeholder="Last Name"
                            [ngClass]="{'is-invalid': submitted && f.lastname.errors }" required />
                          <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                            <div *ngIf="submitted && f.lastname.errors.required">
                              Last Name is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field is-horizontal form-group">
                      <div class="field-body pt-2">
                        <div class="field mw-50 max-vw-100">
                          <label class="label">Email</label>
                          <input formControlName="email" class="form-control" type="text" placeholder="Email" 
                          [ngClass]="{'is-invalid': submitted && f.email.errors }" required />
                          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                          </div>
                        </div>
                        <div class="field mw-50 max-vw-100">
                          <label class="label">Mobile Number</label>
                          <input formControlName="contact" class="form-control" type="text" placeholder="Mobile Number"
                          [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"/>
                          <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                            <div *ngIf="f.contact.errors.required">Mobile number is required</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container mt-4">
                    <legend>Location Details</legend>
                    <div class="field-body pt-2 form-group">
                      <div class="field w-1000">
                        <label class="label">Search Area</label>
                        <p class="control has-icons-right">
                        <input id="searchArea" class="input form-control" type="text" name="searchArea" placeholder="Search Area..." (keyup)="onKeyupAddress($event)" #searchArea>
                        <span class="icon is-small is-right">
                          <i class="fas fa-search"></i>
                        </span>
                      </p>
                      </div>
                    </div>
                    <div class="field-body pt-2 form-group">
                      <div class="field mw-50 max-vw-100">
                        <label class="label">Address One</label>
                        <p class="control is-expanded has-icons-right"> </p>
                        <input id="addressone" formControlName="addressone" class="form-control" type="text" placeholder="Address One"
                          [ngClass]="{ 'is-invalid': submitted && f.addressone.errors }">
                        <div *ngIf="submitted && f.addressone.errors" class="invalid-feedback">
                          <div *ngIf="submitted && f.addressone.errors.required">
                            Address One is required
                          </div>
                        </div>
                      </div>
                      <div class="field mw-50 max-vw-100">
                        <label class="label">Address Two</label>
                        <p class="control is-expanded has-icons-right"> </p>
                        <input id="addresstwo" formControlName="addresstwo" class="form-control" type="text" placeholder="Address Two">
                      </div>
                    </div>
                    <div class="field-body pt-2 form-group">
                      <div class="field mw-50 max-vw-100">
                        <label class="label">Landmark</label>
                        <p class="control is-expanded has-icons-right"> </p>
                        <input id="landmark" formControlName="landmark" class="form-control" type="text" placeholder="Landmark"
                          value="pincode" />
                      </div>
                      <div class="field mw-50 max-vw-100">
                        <label class="label">Pin Code</label>
                        <p class="control is-expanded has-icons-right"></p>
                        <input id="pincode" formControlName="pincode" class="form-control" type="text" placeholder="Pin Code"
                          [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" />
                        <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                          <div *ngIf="submitted && f.pincode.errors.required">
                            Pin Code is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field-body pt-2 form-group">
                      <div class="field mw-50 max-vw-100">
                        <label class="label">Area Name</label>
                          <input id="area" formControlName="area" class="form-control" type="text" placeholder="Area" 
                          [ngClass]="{'is-invalid': submitted && f.area.errors }" required />
                          <div *ngIf="submitted && f.area.errors" class="invalid-feedback">
                            <div *ngIf="f.area.errors.required">Area for Address is required</div>
                          </div>
                      </div>
                      <div class="field mw-50 max-vw-100">
                        <label class="label">City</label>
                        <input id="city" formControlName="city" class="form-control" type="text" placeholder="City" 
                        [ngClass]="{'is-invalid': submitted && f.city.errors }" required />
                          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                            <div *ngIf="f.city.errors.required">City for Address is required</div>
                          </div>
                      </div>
                    </div>
                    <div class="field-body pt-2 form-group">
                      <div class="field mw-50 max-vw-100">
                        <label class="label">District</label>
                        <input id="district" formControlName="district" class="form-control" type="text" placeholder="District" 
                        [ngClass]="{'is-invalid': submitted && f.district.errors }" required />
                          <div *ngIf="submitted && f.district.errors" class="invalid-feedback">
                            <div *ngIf="f.district.errors.required">District for Address is required</div>
                          </div>
                      </div>
                      <div class="field mw-50 max-vw-100">
                        <label class="label">State</label>
                          <input id="state" formControlName="state" class="form-control" type="text" placeholder="State"
                          [ngClass]="{'is-invalid': submitted && f.state.errors }" required />
                          <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                            <div *ngIf="f.state.errors.required">State for Address is required</div>
                          </div>
                      </div>
                    </div>
                    <footer class="my-footer">
                      <div class="from-group">
                        <div class="buttons pt-5">
                          <button id="anonymousUserUpdateButton" type="button" class="btn btn-secondary swipe-to-top" (click)="updateAnonymousUser()">
                            <span> Update</span>
                          </button>
                        </div>
                      </div>
                    </footer>
                  </div>
                </form>














          </div>
        </ng-template>



































        <button
          id="placeOrderButton" 
          class="btn btn-secondary btn-block swipe-to-top"
          (click)="redirectPaymentGateway()"
        >
          {{ "PlaceOrder" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
<section class="empty-content" *ngIf="(this.productAddedToCart | async)?.length == 0">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="pro-empty-page">
          <h2 style="font-size: 150px;">
            <i class="fas fa-shopping-cart"></i>
          </h2>
          <h1>{{ "Your Cart is empty" | translate }}</h1>
          <p>
            <button class="btn btn-secondary" [routerLink]="'/'">
              {{ "Continue Shopping" | translate }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
