import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';
import { GetProductRatingQuery } from 'src/app/services/graphql/getProductRating.graphql';
import { AddOfferLockRatingMutation } from 'src/app/services/graphql/addOfferLockRating.graphql';
import { Apollo } from 'apollo-angular';
import { SessionService } from 'src/app/services/session.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-offer-rating',
  templateUrl: './offer-rating.component.html',
  styleUrls: ['./offer-rating.component.scss']
})
export class OfferRatingComponent implements OnInit {

  productLockDetails = null;
  productLockId = null;
  offer_rating = [];
  user_id = null;

  constructor(
    public router: Router,
    private apollo: Apollo,
    private getProductLocksQuery: GetProductLocksQuery,
    private getProductRatingQuery: GetProductRatingQuery,
    private addOfferLockRatingMutation: AddOfferLockRatingMutation,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.productLockId = +params.get('id');
      }
    );
    this.user_id = this.sessionService.getLocalKeyValues("user", "user_id");
    this.getOfferLockDetails(this.productLockId);
  }

  getOfferLockDetails(lockId) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
              .query({
                query: this.getProductLocksQuery.document,
                variables: {
                  id_token: token,
                  user_email: email,
                  user_type: "offeree",
                  lock_id: lockId
                },
                fetchPolicy: "network-only"
              })
              .subscribe(
                ({ data }) => {
                  this.productLockDetails = data["getProductLocks"][0];
                },
                (error) => {
                }
              );
  }

  onRateChange($evt, lock_id) {
    this.offer_rating[lock_id] = $evt;
  }

  rateOffer(lock_id) {

    if(this.offer_rating[lock_id] == undefined || this.offer_rating[lock_id] <= 0) {
      alert("No rating set. Please specify a rating before continuing.");
    } else {
      let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getLocalKeyValues("user", "email");
  
      this.apollo
      .mutate({
        mutation: this.addOfferLockRatingMutation.document,
        variables: {
          user_email: email,
          id_token: token,
          lock_id: lock_id,
          comment: (document.getElementById("ratingtext" + lock_id) as HTMLInputElement).value,
          rating: this.offer_rating[lock_id] * 2
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Rating successfully added.");
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
    
  }

}
