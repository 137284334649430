<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Content Policy</h1>
            <p class="has-text-grey-light">
                Last Updated: Jan 06, 2023
            </p>
            <span class="box">
                <p>
                    Our Content Policy explains how the content should be used in Zigwik platform. By posting content on Zigwik, you agree to abide by this policy. Content includes any written, photographic, audio, video or other content, including:
                </p>
                <ul>
                    <li>
                        <b>Writing:</b> Offer titles and descriptions, profile pages, public and private reviews, feedback, Community Centre posts and messages to Zigwik, Offerors and Offerees
                    </li>
                    <li>
                        <b>Imagery:</b> Photos and videos, as well as imagery depicted within photos and videos (such as posters or art hanging on a wall)
                    </li>
                </ul>
                <p>
                    We reserve the right to remove any content, in whole or part, that violates this policy, our Terms of Service, our Community Standards, our Reviews Policy or for any other reason at our sole discretion. In the event of repeated or severe violations, we may also restrict, suspend or remove the associated Zigwik account.
                </p>
            </span>
            <br />
            <h3>1. Restricted Content</h3>
            <span class="box">
                <p>
                    The following content is not allowed on Zigwik:
                </p>
                <ul>
                    <li>
                        Content created solely for the purpose of advertising or other commercial content, including company logos, links or company names.
                    </li>
                    <li>
                        Spam, unwanted contact or content that is shared repeatedly in a disruptive manner.
                    </li>
                    <li>
                        Content that endorses or promotes illegal or harmful activity, or that is sexually explicit, violent, graphic, threatening or harassing.
                    </li>
                    <li>
                        Content that is discriminatory (review our Nondiscrimination Policy for more information).
                    </li>
                    <li>
                        Content that attempts to impersonate another person, account or entity, including a representative of Zigwik.
                    </li>
                    <li>
                        Content that is illegal or that violates another person's or entity's rights, including intellectual property rights and privacy rights
                    </li>
                    <li>
                        Content that includes another person's private or confidential information, including content that is sufficient to identify location.
                    </li>
                </ul>
                <p>
                    Additional policy violations for specific types of content:
                </p>
                <ul>
                    <li>
                        <b>Offer titles.</b> Offer titles that include information irrelevant to the Offer Category, style or experience. Offer titles that include symbols or emojis
                    </li>
                    <li>
                        <b>Offer or profile pages.</b> Offers and profiles that provide fraudulent, false, misleading or deceptive information.
                    </li>
                    <li>
                        <b>Community Centre.</b> Content that is off-topic, doesn't ask a question or doesn't offer knowledge in response to a question as part of a larger discussion.
                        Harassing, trolling or repeatedly targeting community members
                    </li>
                    <li>
                        <b>Reviews.</b> Reviews that violate our <a [routerLink]="'/review-policy'">Reviews Policy</a>
                    </li>
                </ul>
            </span>
            <br />
            <h3>2. User Content License</h3>
            <p class="box">
                You hereby grant to Zigwik and its related companies, agents, licensees, sublicensees, contractors, successors, legal representatives, assigns, and third-party service providers, and their respective retail partners, marketing or public relations agencies, and other affiliates (the "Licensed Parties") a worldwide, perpetual, irrevocable, royalty-free, fully-paid, non-exclusive, transferable, sublicensable right to use your User Content in any manner to be determined in the Licensed Parties' sole discretion, including but not limited to on webpages and social media pages operated by the Licensed Parties, in promotional e-mails and advertisements, and in any and all other marketing, promotional and advertising initiatives, and in any media now or hereafter known. The Licensed Parties may use, display, reproduce, distribute, transmit, create derivative works from, combine with other materials, alter or edit your User Content in any manner in their sole discretion, with no obligation to you whatsoever.
                <br /><br />
                You grant the Licensed Parties the right to use your username, real name, image, likeness, descriptions of you, location or other identifying information, including but not limited to your voice, in connection with any use of your User Content.
                <br /><br />
                You hereby agree and represent and warrant that (i) you are solely responsible for your User Content, (ii) you own all rights in and to your User Content or have obtained appropriate rights and permissions from any and all other persons or entities who own, manage or otherwise claim any rights with respect to such User Content, (iii) you are not a minor, (iv) the Licensed Parties' use of your User Content as described herein will not violate the rights, including but not limited to copyright, trademark, patent, trade secret, privacy, publicity, moral, proprietary or other rights, of any third party, or any law, rule or regulation, and <b>(v) the User Content is not libelous, defamatory, obscene, pornographic, abusive, indecent, threatening, harassing, hateful, or offensive or otherwise unlawful.</b>
                <br /><br />
                You hereby release, discharge and agree to hold the Licensed Parties, and any person acting on their behalf, harmless from any liability related in any way to the Licensed Parties' use of your User Content.
                <br /><br />
                The User Content that you submit is deemed non-confidential and the Licensed Parties have no obligation to maintain the confidentiality of any information, in whatever form, contained in any submission, except pursuant to the Licensed Parties' respective privacy policies. By using this website or the Services, you are consenting to the Licensed Parties' collection of any personal information you provide for the Licensed Parties' use and disclosure in connection with the use of your User Content as described herein. If you do not agree to the collection, use and disclosure of your personal information in this way, please do not use this website or the Services or otherwise provide the Licensed Parties with personal information. Your personal information may be transferred to servers located outside the country in which you live or to third parties in other countries so that they may process personal information on the Licensed Parties behalf. By using the website or the Services or otherwise providing the Licensed Parties with personal information, you agree to the foregoing collection, use, disclosure, transfer and processing of your information in accordance with the terms of these Terms, the Privacy Policy and applicable data protection laws and regulations.
                <br /><br />
                The Licensed Parties reserve the right to remove any User Content from the website and the Zigwik Properties. If you believe any content, including User Content, residing on the website or on the Zigwik Properties or displayed or used in connection with the Zigwik Services infringes any person's or entity's copyright rights, please refer to the Zigwik <a [routerLink]="'/copyright-policy'">Copyright Policy</a>.
            </p>
            <br />
            <h3>3. Additional Terms</h3>
            <p class="box">
                These Terms of Use apply to the entire website, the Zigwik Services and to your User Content unless otherwise provided. In addition, to the extent your User Content is displayed on the website or on any of the Zigwik Offers or in connection with the Zigwik Services, you also will be subject to additional terms of use, agreements, guidelines or rules provided by Zigwik applicable to such Zigwik Services and User Content, including but not limited to those set forth on the Zigwik website at <a [routerLink]="'/terms'">Terms of Service</a>, and you hereby agree to be bound by such additional terms of use or service, agreements, guidelines, instructions or rules provided or posted by Zigwik (the "Zigwik Terms").
                <br /><br />
                You certify that you are at least 18 years of age.
            </p>
            <br />
            <h3>4. Intellectual Property Rights</h3>
            <p class="box">
                The website, Zigwik Services may be protected by copyright, trademark and other intellectual property laws. You acknowledge and agree that you do not acquire any ownership or other rights in proprietary information and materials of Zigwik by authorizing use of your User Content or otherwise using or accessing the website, the Zigwik Services.
            </p>
            <br />
            <h3>5. Miscellaneous</h3>
            <p class="box">
                These Terms of Use are personal to you and may not be assigned or transferred by you for any reason whatsoever without Zigwik's prior written consent; and any action or conduct in violation of the foregoing shall be void and without effect.
                <br /><br />
                You agree that if Zigwik does not exercise or enforce any legal right or remedy which is contained in these Terms of Use (or which Zigwik has the benefit of under any applicable law), this will not be taken to be a formal waiver of Zigwik's rights and that those rights or remedies will still be available to Zigwik.
                <br /><br />
                If any court of law, having the jurisdiction to decide on this matter, rules that any provision of these Terms of Use are invalid, then that provision will be removed without affecting the rest of the Terms of Use. The remaining provisions of these Terms of Use will continue to be valid and enforceable.
            </p>
        </div>
    </div>
</div>



<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
