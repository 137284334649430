import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class TrackSearchCriteriaMutation extends Mutation {

  document = gql`
    mutation TrackSearchCriteriaMutation(
      $masked_id: String
      $user_id: Int
      $search_text: String
      $start_date: String
      $end_date: String
      $frequency: String
      $category_id: Int
      $category_group: String
    ) {
      trackSearchCriteria(
        masked_id: $masked_id
        user_id: $user_id
        search_text: $search_text
        start_date: $start_date
        end_date: $end_date
        frequency: $frequency
        category_id: $category_id
        category_group: $category_group
      )
    }
  `;
}
