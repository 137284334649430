import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetOfferSEODetailsQuery extends Query {
  document = gql`
    query getOfferSEODetailsQuery(
      $offer_id: Int
      $offer_name: String
    ) {
      getOfferSEODetails(
        offer_name: $offer_name
        offer_id: $offer_id
      ) {
        offer_image
        offer_image_height
        offer_image_width
        offer_image_type
        offer_description
        offer_title
        keywords
      }
    }
  `;
}
