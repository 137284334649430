import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review-policy',
  templateUrl: './review-policy.component.html',
  styleUrls: ['./review-policy.component.scss']
})
export class ReviewPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
