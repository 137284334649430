<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Refund Policy</h1>
            <p class="has-text-grey-light">
                Last Updated: Jan 06, 2023
            </p>
            <br />
            <p class="box">
                This Rebooking and Refund Policy explains how we will assist with rebooking an Offer and how we handle refunds when an Offeror cancels a booking or if Offeree cancels a booking.
            </p>
            <br />
            <h3>1. What happens if an Offeror cancels the booking</h3>
            <p class="box">
                Offeror have an option to configure approval days for booking of an Offer. Offeror have right to cancel the Offer before such approval days have been breached. Offeror cannot cancel the booking after such approval days have passed. If an Offeror cancels the booking, the Offeree will get a full refund. If the Offeror does not showup during the time of the booking, Offeree can raise complaint using the link provided in one of the notification emails sent to them.
            </p>
            <br />
            <h3>2. What happens if an Offeree cancels the booking</h3>
            <p class="box">
                Offeree can cancel the booking at any point of time before the actual date and time of the booking. The refund of such cancellation will depend on the cancellation policy set by the Offeror for the particular Offer.
            </p>
            <br />
            <h3>3. What happens if any of the contractual condition is breached</h3>
            <p class="box">
                Offeror can set the guidelines for the Offer and also for a particular booking. If such guidelines exists, the guidelines should be considered as contractual binding to the Offer and any breach of such guidelines will make the booking invalid for the Offeree. If the Offeree breaks such guidelines, they will not be entitled for any refund for the booking.
            </p>
        </div>
    </div>
</div>


<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
