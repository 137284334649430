import { Component, OnInit  } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { SendContactUsMessageMutation } from 'src/app/services/graphql/sendContactUsMessage.graphql';
import { Apollo } from "apollo-angular";
import { SessionService } from "src/app/services/session.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-one',
  templateUrl: './contact-one.component.html',
  styleUrls: ['./contact-one.component.scss']
})
export class ContactOneComponent implements OnInit {

  contactusmessage = "";
  contactussubject = "";
  
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private sendContactUsMessageMutation: SendContactUsMessageMutation,
    private apollo: Apollo,
    private sessionService: SessionService,
    public router: Router
  ) { }

  ngOnInit() {}
  
  frmContactUs = this.fb.group({
    subject:  ["", Validators.compose([Validators.required])],
    message:  ["", Validators.compose([Validators.required])]
  });

  sendOppMessage() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo.mutate({
      mutation: this.sendContactUsMessageMutation.document,
      variables: {
        id_token: token,
        user_email: email,
        subject: this.contactussubject,
        message: this.contactusmessage
      }
    }).subscribe(
      ({ data }) => {
        this.toastr.success("Message sent successfully.");
        this.contactusmessage = "";
        this.contactussubject = "";
      },
      (error) => {
        this.toastr.error(error.message);
        this.contactusmessage = "";
        this.contactussubject = "";
    });
  }
}
