
  <section class="our-features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-title">
            <h2 class="title mb-0">Still wondering about Zigwik benefits?</h2>
            <p class="text">We got your covered.</p>
          </div>
        </div>
      </div>
      <div class="row iconbox-card">
        <div class="col-sm-6 col-lg-3" *ngFor="let item of featuresCards">
          <div class="iconbox-style">
            <div class="icon before-none"><i [class]="item.icon" aria-hidden="true"></i></div>
            <div class="details">
              <h5 class="title mt10 mb-3"> {{item.title}} </h5>
              <p class="text is-size-7"> {{item.sub_title}} </p>
            </div>
          </div>
      </div>
    </div>
    </div>
    <br />
    <section class="our-partners">
      <div class="container">
        <div class="row wow fadeInUp">
          <div class="col-lg-12">
            <div class="main-title">
              <h3 class="title mb-0">We have used the best of tech in this Platform.</h3><br /><br /><br />
            </div>
          </div>
        </div>
        <div class="row wow fadeInUp">
          <div class="col-6 col-md-4 col-xl-2">
            <div class="partner_item text-center mb30-lg p-3"><img class="wa m-auto" style="width: 35%; height: 35%" src="/assets/images/tech_partners/1.png" title="WhatsApp"></div>
          </div>
          <div class="col-6 col-md-4 col-xl-2">
            <div class="partner_item text-center mb30-lg p-3"><img class="wa m-auto" style="width: 50%; height: 50%" src="/assets/images/tech_partners/2.png" title="Google Cloud"></div>
          </div>
          <div class="col-6 col-md-4 col-xl-2">
            <div class="partner_item text-center mb30-lg p-3"><img class="wa m-auto" style="width: 35%; height: 35%" src="/assets/images/tech_partners/3.png" title="Open AI ChatGPT"></div>
          </div>
          <div class="col-6 col-md-4 col-xl-2">
            <div class="partner_item text-center mb30-lg p-3"><img class="wa m-auto" src="/assets/images/tech_partners/4.png" title="CCAVENUE Payment Gateway"></div>
          </div>
          <div class="col-6 col-md-4 col-xl-2">
            <div class="partner_item text-center mb30-lg p-3"><img class="wa m-auto" src="/assets/images/tech_partners/5.png" title="AI Image Processing"></div>
          </div>
          <div class="col-6 col-md-4 col-xl-2">
            <div class="partner_item text-center mb30-lg p-3"><img class="wa m-auto" src="/assets/images/tech_partners/6.png" title="AI Text Analysis"></div>
          </div>
        </div>
      </div>
    </section>
  </section>