import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class UploadProfileImageMutation extends Mutation {

  document = gql`
    mutation UploadProfileImageMutation(
      $file: Upload!
      $user_email: String!
      $id_token: String!
    ) {
      uploadProfileImage(
        file: $file,
        user_email: $user_email,
        id_token: $id_token
      )
    }
  `;
}
