<ul class="list-group mb-4">
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/new-offering'">
            <i class="fa fa-gift has-text-black"></i> &nbsp;
            {{'New Offering' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/my-offering'">
            <i class="fas fa-gifts has-text-black"></i>
            {{'My Offerings' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/approve-offering'">
            <i class="fas fa-check-circle has-text-black"></i>
            {{'Approve Offering' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/offering-report'">
            <i class="fa fa-file has-text-black"></i> &nbsp;
            {{'Offering Report' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/offering-communication'">
            <i class="fas fa-comment has-text-black"></i>
            {{'Active Communication' | translate}}
        </a>
    </li>
    <li class="list-group-item">
        <a class="nav-link" [routerLink]="'/offering-chat'">
            <i class="fas fa-comment has-text-black"></i>
            {{'Offering Messenger' | translate}}
        </a>
    </li>
</ul>
<br />
<ng-template [ngIf]="currRoute != null && currRoute != '' && currRoute.includes('new-offering')">
    <div class="w-75 align-middle pr-2">
        <iframe src="https://www.youtube.com/embed/2nTu03yNkhU?si=nYHPf8p450zmVRDT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
</ng-template>
<br />