<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [withAnonymous]=true></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [withAnonymous]=true></app-header-mobile>

<section class="empty-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div id="pageloader" class="loader-wrapper is-active">
          <div class="loader is-loading"></div>
        </div>
        <ng-template [ngIf]="(this.successful_status | async) == 'success_untouched'">
          <div class="pro-empty-page">
            <h2 style="font-size: 150px;"><i class="far fa-check-circle"></i></h2>
            <h1>{{ "Thank You" | translate }}</h1>
            <p>
              You have successfully place your order. Your order id is {{ this.shared_order_id }}.
              Go to the
              <a class="btn-link" [routerLink]="'/my-orders'"><b>Order page</b></a
              >.
            </p>
          </div>
        </ng-template>
        <ng-template [ngIf]="(this.successful_status | async) == 'failed_untouched' ||  (this.successful_status | async) == 'failed_touched'">
          <div class="pro-empty-page">
            <h2 style="font-size: 150px;"><i class="fas fa-ban"></i></h2>
            <h1>{{ "Oops!... something went wrong." | translate }}</h1>
            <p>
              Please try again later.
            </p>
          </div>
        </ng-template>
        <ng-template [ngIf]="(this.successful_status | async) == 'success_touched'">
          <div class="pro-empty-page">
            <h2 style="font-size: 150px;"><i class="far fa-check-circle"></i></h2>
            <h1>{{ "Order already placed successfully." | translate }}</h1>
            <p>
              You have already placed this order. Your order id is {{ this.shared_order_id }}.
              Go to the
              <a class="btn-link" [routerLink]="'/my-orders'"><b>Order page</b></a
              >.
            </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>