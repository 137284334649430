import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class LogoutUserMutation extends Mutation {
  document = gql`
    mutation logoutUserMutation($email: String!, $idToken: String!) {
      logoutUser(user_email: $email, id_token: $idToken) {
        logout_status
        user_email
      }
    }
  `;
}
