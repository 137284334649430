import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GetOfferSEODetailsQuery } from 'src/app/services/graphql/getOfferSEODetails.graphql';


interface OfferSEO {
    offer_image: string;
    offer_image_height: number;
    offer_image_width: number;
    offer_image_type: string;
    offer_description: string;
    offer_title: string;
    keywords: string;
}

@Injectable({ 
    providedIn: 'root' 
  })
  export class OfferSEOResolver implements Resolve<OfferSEO> {

    constructor(
        private apollo: Apollo,
        private getOfferSEODetailsQuery: GetOfferSEODetailsQuery
        ) {}
  
    resolve(route: ActivatedRouteSnapshot): Promise<OfferSEO> {
      const id = +route.paramMap.get('id');
      const productName = route.paramMap.get('productname');
  
      return this.getOfferSEODetails(productName, id);
    }

    async getOfferSEODetails(offerName: string, offerId: number) {

        var result = await this.apollo
        .query({
          query: this.getOfferSEODetailsQuery.document,
          variables: {
            offer_name: offerName,
            offer_id: offerId
            },
            fetchPolicy: "network-only"
        }).toPromise();

        if(result != null && "data" in result) {
            return result["data"]["getOfferSEODetails"];
        }

        return null;
      }
  }