import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class OffereeRevertRefundRequestMutation extends Mutation {

  document = gql`
    mutation OffereeRevertRefundRequestMutation(
      $lock_id: Int!
      $shared_order_id: String!
      $id_token: String!
      $offeree_email: String!
    ) {
      offereeRevertRefundRequest(
        lock_id: $lock_id,
        shared_order_id: $shared_order_id
        id_token: $id_token
        offeree_email: $offeree_email
      )
    }
  `;
}
