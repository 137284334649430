import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class CreateScheduleMutation extends Mutation {

  document = gql`
    mutation CreateScheduleMutation(
      $schedule_name: String!
      $product_id: Int!
      $id_token: String!
      $lender_email: String!
      $schedule_frequency: ProductScheduleFrequency!
      $price: Float!
      $start_date: Date!
      $end_date: Date!
      $deposit: Float
      $buffer_days: Int
      $product_count: Int
      $guidelines: String
      $slots: [ProductScheduleSlotsInput!]!
      $cancellation_policies: [ProductCancellationPolicyInput!]
    ) {
      addProductSchedule(
        schedule_name: $schedule_name,
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
        schedule_frequency: $schedule_frequency
        price: $price
        start_date: $start_date
        end_date: $end_date
        deposit: $deposit
        buffer_days: $buffer_days
        product_count: $product_count
        guidelines: $guidelines
        slots: $slots
        cancellation_policies: $cancellation_policies
      )
    }
  `;
}
