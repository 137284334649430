import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class RemoveSoftLockMutation extends Mutation {

  document = gql`
    mutation RemoveSoftLockMutation(
      $lock_id: Int!
      $id_token: String!
      $offeree_email: String
    ) {
      removeProductSoftLock(
        lock_id: $lock_id,
        id_token: $id_token
        offeree_email: $offeree_email
      )
    }
  `;
}
