<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- empty Content -->
<section class="empty-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="pro-empty-page">
          <h2>{{ "Error 404..." | translate }}</h2>
          <h1>{{ "Page Not Found" | translate }}</h1>
          <p>
            We looked everywhere for this page. Are you sure the website URL is
            correct? Go to the
            <strong><a href="index.html" class="btn-link">main page</a></strong>
            or select suitable category.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
<app-switcher></app-switcher>
