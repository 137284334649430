import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Apollo } from "apollo-angular";

import { GetLenderProductListQuery } from "src/app/services/graphql/getlenderproductlist.graphql";
import { GetProductOfferorAggReportQuery } from "src/app/services/graphql/getProductOfferorAggReport.graphql";
import { SessionService } from "src/app/services/session.service";
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';
import { ChartConfiguration, Chart } from 'chart.js';
import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-offering-report',
  templateUrl: './offering-report.component.html',
  styleUrls: ['./offering-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferingReportComponent implements OnInit {

  productList = [];
  productLocks = [];
  productScheduleReportData = new Map<number, any>();
  model_start: NgbDateStruct;
  model_report_start: NgbDateStruct;
  model_report_end: NgbDateStruct;
  closeLockSlotsResult = "";
  hideReport = true;

  graph_options = [];
  selected_graph_option = null;

  totalAnnualEarning = 0;
  totalMonthlyUnpaid = 0;

  public graph_start_date = null;
  public graph_end_date = null;

  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];

  da = new Date();

  currentMonthDates = "";
  currentYear = 2022;
  reportButtonDisable = true;

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    
  };
  public barChartLabels = ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], 
      label: 'Earning graph', 
      backgroundColor: 'rgba(226, 132, 24, 0.4)',
      hoverBackgroundColor: 'rgba(226, 132, 24, 0.9)'
      }
  ];

  constructor(
    private modalService: NgbModal,
    private getLenderProductListQuery: GetLenderProductListQuery,
    private getProductOfferorAggReportQuery: GetProductOfferorAggReportQuery,
    private getProductLocksQuery: GetProductLocksQuery,
    private apollo: Apollo,
    private sessionService: SessionService,
    private toastr: ToastrService
  ) {}

  fetchReport() {

    var offerselect = (document.getElementById("offerList") as HTMLSelectElement).options[(document.getElementById("offerList") as HTMLSelectElement).options.selectedIndex].value;
    var scheduletypeselect = (document.getElementById("scheduleTypeList") as HTMLSelectElement).options[(document.getElementById("scheduleTypeList") as HTMLSelectElement).options.selectedIndex].value;

    this.callGetLenderAggReport(offerselect, scheduletypeselect);

    this.graphInitSetup(offerselect, scheduletypeselect);

    this.getProductLocks(offerselect, scheduletypeselect);
  }

  graphInitSetup(offer_id, schedule_type, no_option_change = false) {
    if((no_option_change == false && (schedule_type == "hourly" || schedule_type == "daily" || schedule_type == "custom")) || (no_option_change == true && this.selected_graph_option == "Day")) {
      if(no_option_change == false) {
        this.graph_options = ["Day", "Week", "Month"];
        this.selected_graph_option = "Day";
      }

      var temp_date = new Date();
      temp_date.setDate(temp_date.getDate() - 9);
      
      this.changeDates(temp_date, "Day");
      this.model_start = { day: this.graph_start_date.getDate(), month: this.graph_start_date.getMonth() + 1, year: this.graph_start_date.getFullYear()};
      this.changeBarChartLabels("Day");

    } else if((no_option_change == false && schedule_type == "weekly") || (no_option_change == true && this.selected_graph_option == "Week")) {
      if(no_option_change == false) {
        this.graph_options = ["Week", "Month"];
        this.selected_graph_option = "Week";
      }
      var temp_date = new Date();
      let monday = temp_date.getDate() - temp_date.getDay() + 1;
      var temp_date2 = new Date(temp_date.getFullYear(), temp_date.getMonth(), monday);
      temp_date2.setDate(temp_date2.getDate() - 70);
      
      this.changeDates(temp_date2, "Week");
      this.model_start = { day: this.graph_start_date.getDate(), month: this.graph_start_date.getMonth() + 1, year: this.graph_start_date.getFullYear()};
      this.changeBarChartLabels("Week");

    } else if((no_option_change == false && schedule_type == "monthly") || (no_option_change == true && this.selected_graph_option == "Month")) {
      if(no_option_change == false) {
        this.graph_options = ["Month"];
        this.selected_graph_option = "Month"
      }

      var temp_date = new Date();
      var temp_date2 = new Date(temp_date.getFullYear() + "-" + (temp_date.getMonth() + 1) + "-01 00:00:00");
      temp_date2.setDate(temp_date2.getDate() - 300);

      var temp_date3 = new Date(temp_date2.getFullYear() + "-" + (temp_date2.getMonth() + 1) + "-01 00:00:00");
      
      this.changeDates(temp_date3, "Month");
      this.model_start = { day: this.graph_start_date.getDate(), month: this.graph_start_date.getMonth() + 1, year: this.graph_start_date.getFullYear()};
      this.changeBarChartLabels("Month");
    }
  }

  reportInitSetup() {
    this.model_report_start = { day: this.graph_start_date.getDate(), month: this.graph_start_date.getMonth() + 1, year: this.graph_start_date.getFullYear()};
    this.model_report_end = { day: this.graph_end_date.getDate(), month: this.graph_end_date.getMonth() + 1, year: this.graph_end_date.getFullYear()};
  }

  getNewReportData() {
    var startDateVal = (document.getElementById("reportStartDate") as HTMLInputElement).value;
    var endDateVal = (document.getElementById("reportEndDate") as HTMLInputElement).value;

    this.reportDataSetup(new Date(startDateVal), new Date(endDateVal));
  }

  getNewGraphData() {
    var dateVal = (document.getElementById("graphStartDate") as HTMLInputElement).value;
    if(dateVal != "") {

      this.changeDates(new Date(dateVal), this.selected_graph_option);
      this.changeBarChartLabels(this.selected_graph_option);

      this.graphDataSetup();
    }
  }

  changeDates(dateVal, graphOption) {
    if(graphOption == "Day") {
      var end_date_temp = new Date(dateVal);
      this.graph_start_date = new Date(dateVal);
      end_date_temp.setDate(end_date_temp.getDate() + 9);
      this.graph_end_date = end_date_temp;
    } else if(graphOption == "Week") {
      let monday = dateVal.getDate() - dateVal.getDay() + 1;
      this.graph_start_date = new Date(dateVal.getFullYear(), dateVal.getMonth(), monday);
      var end_date_temp = new Date(this.graph_start_date);
      end_date_temp.setDate(end_date_temp.getDate() + 69);
      this.graph_end_date = end_date_temp;
    } else if(graphOption == "Month") {
      this.graph_start_date = new Date(
        dateVal.getFullYear() + "-" + (dateVal.getMonth() + 1) + "-01 00:00:00"
      );
      var end_date_temp = new Date(this.graph_start_date);
      end_date_temp.setDate(end_date_temp.getDate() + 300);
      this.graph_end_date = new Date(end_date_temp.getFullYear(), end_date_temp.getMonth() + 1, 0);
    }
  }

  changeBarChartLabels(graphOption) {
    this.barChartLabels = [];
    let start_date = new Date(this.graph_start_date);
    let end_date = new Date(this.graph_end_date);

    if(graphOption == "Day") {
      for (var d = start_date; d <= end_date; d.setDate(d.getDate() + 1)) {
        this.barChartLabels.push(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate());
      }
      this.barChartData[0].label = "Total Daily Earnings";
    } else if(graphOption == "Week") {
      var temp_start_date = null;
      for (var d = start_date; d <= end_date; d.setDate(d.getDate() + 1)) {
        if(temp_start_date == null) {
          temp_start_date = new Date(d);
        }

        if(d.getDay() == 0) {
          this.barChartLabels.push(temp_start_date.getFullYear() + "-" + (temp_start_date.getMonth() + 1) + "-" + temp_start_date.getDate()
                          + " till " + d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate());
          temp_start_date = null;
        }
      }
      this.barChartData[0].label = "Total Weekly Earnings";
    } else if(graphOption == "Month") {
      var temp_month = null;
      for (var d = start_date; d <= end_date; d.setDate(d.getDate() + 1)) {
        if(temp_month == null) {
          temp_month = this.monthNames[d.getMonth()]
          this.barChartLabels.push(this.monthNames[d.getMonth()] + " " + d.getFullYear());
        }

        if(temp_month != this.monthNames[d.getMonth()]) {
          temp_month = null;
        }
      }
      this.barChartData[0].label = "Total Monthly Earnings";
    }
  }

  changeGraphType(graph_option) {
    this.selected_graph_option = graph_option;
    var offer_id = (document.getElementById("offerList") as HTMLSelectElement).options[(document.getElementById("offerList") as HTMLSelectElement).options.selectedIndex].value;
    var schedule_type = (document.getElementById("scheduleTypeList") as HTMLSelectElement).options[(document.getElementById("scheduleTypeList") as HTMLSelectElement).options.selectedIndex].value;
    this.graphInitSetup(offer_id, schedule_type, true);
    this.graphDataSetup();
  }

  graphDataSetup() {
    var offer_id = (document.getElementById("offerList") as HTMLSelectElement).options[(document.getElementById("offerList") as HTMLSelectElement).options.selectedIndex].value;
    var schedule_type = (document.getElementById("scheduleTypeList") as HTMLSelectElement).options[(document.getElementById("scheduleTypeList") as HTMLSelectElement).options.selectedIndex].value;
    if(this.selected_graph_option == "Day") {
      this.barChartData[0].data = [];
      let start_date = new Date(this.graph_start_date);
      let end_date = new Date(this.graph_end_date);
      for (var d = start_date; d <= end_date; d.setDate(d.getDate() + 1)) {
        var totalPrice = 0;
        this.productLocks.forEach(function(productLock) {
          if((new Date(productLock.start_date + " 00:00:00")).getTime() - (new Date(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " 00:00:00")).getTime() == 0 && 
          (new Date(productLock.end_date + " 00:00:00")).getTime() - (new Date(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " 00:00:00")).getTime() == 0) {
            totalPrice += (productLock.price_after_tax - productLock.commission_after_tax);
          }
        })
        this.barChartData[0].data.push(totalPrice);
      }
    } else if(this.selected_graph_option == "Week") {
      this.barChartData[0].data = [];
      let start_date = new Date(this.graph_start_date);
      let end_date = new Date(this.graph_end_date);
      var totalPrice = 0;
      var monday = null;
      var monday_date = null;
      var sunday = null;
      var sunday_date = null;
      for (var d = start_date; d <= end_date; d.setDate(d.getDate() + 1)) {
        if(monday == null) {
          monday = d.getDate() - d.getDay() + 1;
          monday_date = new Date(d.getFullYear(), d.getMonth(), monday)
        }
        if(sunday == null) {
          sunday = d.getDate() - d.getDay() + 7;
          sunday_date = new Date(d.getFullYear(), d.getMonth(), sunday)
        }

        if(d > sunday_date || d.getTime() == end_date.getTime()) {
          this.productLocks.forEach(function(productLock) {
            if((new Date(productLock.start_date + " 00:00:00")) >= (new Date(monday_date)) && 
            (new Date(productLock.end_date + " 00:00:00")) <= (new Date(sunday_date))) {
              totalPrice += (productLock.price_after_tax - productLock.commission_after_tax);
            }
          })
          this.barChartData[0].data.push(totalPrice);
          totalPrice = 0;
          monday = d.getDate() - d.getDay() + 1;
          monday_date = new Date(d.getFullYear(), d.getMonth(), monday)
          sunday = d.getDate() - d.getDay() + 7;
          sunday_date = new Date(d.getFullYear(), d.getMonth(), sunday)
        }
      }
    } else if(this.selected_graph_option == "Month") {
      this.barChartData[0].data = [];
      let start_date = new Date(this.graph_start_date);
      let end_date = new Date(this.graph_end_date);
      var totalPrice = 0;
      var first_date = null;
      var last_date = null;
      for (var d = start_date; d <= end_date; d.setDate(d.getDate() + 1)) {
        if(first_date == null) {
          first_date = new Date(d.getFullYear(), d.getMonth(), 1);
        }
        if(last_date == null) {
          last_date = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        }

        if(d > last_date || d.getTime() == end_date.getTime()) {
          this.productLocks.forEach(function(productLock) {
            if((new Date(productLock.start_date + " 00:00:00")) >= (new Date(first_date)) && 
            (new Date(productLock.end_date + " 00:00:00")) <= (new Date(last_date))) {
              totalPrice += (productLock.price_after_tax - productLock.commission_after_tax);
            }
          })
          this.barChartData[0].data.push(totalPrice);
          totalPrice = 0;
          first_date = new Date(d.getFullYear(), d.getMonth(), 1);
          last_date = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        }
      }
    }
  }

  reportDataSetup(start_date_param = null, end_date_param = null) {
    var scheduleMap = new Map<number, any>();
    this.productLocks.forEach((productLock) => {
      var start_date = new Date(this.graph_start_date);
      var end_date = new Date(this.graph_end_date);
      if(start_date_param != null) {
        start_date = new Date(start_date_param);
      }
      if(end_date_param != null) {
        end_date = new Date(end_date_param);
      }
      if((new Date(productLock["start_date"])).getTime() >= start_date.getTime() && 
      (new Date(productLock["end_date"])).getTime() <= end_date.getTime()) {
        var dataObj = null;
        var productLockArr = [];
        if(scheduleMap.has(productLock["schedule_id"])) {
          dataObj = scheduleMap.get(productLock["schedule_id"]);
          dataObj = this.getProductLockObject(productLock, dataObj);
        } else {
          dataObj = this.getProductLockObject(productLock);
        }
  
        scheduleMap.set(productLock["schedule_id"], dataObj); 
      }
    });

    this.productScheduleReportData = scheduleMap;
  }

  getProductLockObject(productLock, dataObj = null) {
    var tempObj = dataObj;
    var productLockArr = [];
    var totalLocks = [productLock];

    if(dataObj != null) {
      totalLocks = dataObj["product_locks"];
      totalLocks.push(productLock);
    }

    if(Array("hard_lock", "confirmed_lock", "lock_fulfilled").includes(productLock["lock_type"])) {
      if(dataObj != null && dataObj["confirmed_product_locks"] != null && dataObj["confirmed_product_locks"].length > 0) {
        productLockArr = dataObj["confirmed_product_locks"];
        productLockArr.push(productLock);
      } else {
        productLockArr = Array(productLock);
      }

      if(dataObj != null) {
        let totalCommission = dataObj.total_commission + productLock["commission_before_tax"];
        let totalPriceAfterTax = dataObj.total_price_after_tax + productLock["price_after_tax"] - productLock["commission_after_tax"];
        let totalPrice = dataObj.total_price + productLock["actual_price"] - productLock["refund_amount"];
        var lockCount = 0;
        if(dataObj.rejected_product_locks != null) {
          lockCount = dataObj.rejected_product_locks.length
        }
        tempObj = {
          "total_price": totalPrice,
          "total_price_after_tax": totalPriceAfterTax,
          "total_commission": totalCommission,
          "schedule_id": dataObj.schedule_id,
          "schedule_name": dataObj.schedule_name,
          "schedule_start_date": dataObj.schedule_start_date,
          "schedule_end_date": dataObj.schedule_end_date,
          "product_locks": totalLocks,
          "confirmed_product_locks": productLockArr,
          "confirmed_product_locks_total": productLockArr.length,
          "rejected_product_locks": dataObj.rejected_product_locks,
          "rejected_product_locks_total": lockCount
        };
      } else {
        tempObj = {
          "total_price": productLock["actual_price"] - productLock["refund_amount"],
          "total_price_after_tax": productLock["price_after_tax"] - productLock["commission_after_tax"],
          "total_commission": productLock["commission_before_tax"],
          "schedule_id": productLock.schedule_id,
          "schedule_name": productLock.schedule_name,
          "schedule_start_date": productLock.schedule_start_date,
          "schedule_end_date": productLock.schedule_end_date,
          "product_locks": totalLocks,
          "confirmed_product_locks": productLockArr,
          "confirmed_product_locks_total": productLockArr.length,
          "rejected_product_locks": null,
          "rejected_product_locks_total": 0
        };
      }

    } else if(Array(
      "auto_rejected_hard_lock", 
      "offeree_rejected_hard_lock", 
      "offeror_rejected_hard_lock",
      "offeree_rejected_paid_lock_half_refund",
      "offeree_rejected_paid_lock_full_refund",
      "offeree_rejected_paid_lock_no_refund",
      "offeree_grievance_refund_lock").includes(productLock["lock_type"])) {
      if(dataObj != null && dataObj["rejected_product_locks"] != null && dataObj["rejected_product_locks"].length > 0) {
        productLockArr = dataObj["rejected_product_locks"];
        productLockArr.push(productLock);
      } else {
        productLockArr = Array(productLock);
      }

      if(dataObj != null) {
        let totalCommission = dataObj.total_commission + productLock["commission_before_tax"];
        let totalPriceAfterTax = dataObj.total_price_after_tax + productLock["price_after_tax"] - productLock["commission_after_tax"];
        let totalPrice = dataObj.total_price + productLock["actual_price"] - productLock["refund_amount"];
        var lockCount = 0;
        if(dataObj.confirmed_product_locks != null) {
          lockCount = dataObj.confirmed_product_locks.length
        }
        tempObj = {
          "total_price": totalPrice,
          "total_price_after_tax": totalPriceAfterTax,
          "total_commission": totalCommission,
          "schedule_id": dataObj.schedule_id,
          "schedule_name": dataObj.schedule_name,
          "schedule_start_date": dataObj.schedule_start_date,
          "schedule_end_date": dataObj.schedule_end_date,
          "product_locks": totalLocks,
          "confirmed_product_locks": dataObj.confirmed_product_locks,
          "confirmed_product_locks_total": lockCount,
          "rejected_product_locks": productLockArr,
          "rejected_product_locks_total": productLockArr.length
        };
      } else {
        tempObj = {
          "total_price": productLock["actual_price"] - productLock["refund_amount"],
          "total_price_after_tax": productLock["price_after_tax"] - productLock["commission_after_tax"],
          "total_commission": productLock["commission_before_tax"],
          "schedule_id": productLock.schedule_id,
          "schedule_name": productLock.schedule_name,
          "schedule_start_date": productLock.schedule_start_date,
          "schedule_end_date": productLock.schedule_end_date,
          "product_locks": totalLocks,
          "confirmed_product_locks": null,
          "confirmed_product_locks_total": 0,
          "rejected_product_locks": productLockArr,
          "rejected_product_locks_total": productLockArr.length
        };
      }
    }
    return tempObj;
  }

  getProductLocks(offer_id, schedule_type) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .query({
      query: this.getProductLocksQuery.document,
      variables: {
        id_token: token,
        user_email: email,
        product_id: offer_id,
        schedule_frequency: schedule_type,
        user_type: "offeror",
        lock_type_arr: ["offeree_rejected_paid_lock_half_refund", 
                        "offeree_rejected_paid_lock_full_refund", 
                        "offeree_rejected_paid_lock_no_refund",
                        "confirmed_lock",
                        "lock_fulfilled",
                        "offeree_grievance_refund_lock"]
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["getProductLocks"] != null && data["getProductLocks"].length > 0) {
          this.toastr.success("Found locks for the query.");
          this.productLocks = data["getProductLocks"];
          this.graphDataSetup();
          this.reportInitSetup();
          this.reportDataSetup();
          this.hideReport = false;
        } else {
          this.toastr.error("No locks found.");
          this.hideReport = true;
        }
      },
      (error) => {
        this.toastr.error(error.message);
        this.hideReport = true;
      }
    );
  }

  callGetLenderProducts() {
    let id_token = this.sessionService.getKeyValues("token", "id_token");
    let emailId = this.sessionService.getLocalKeyValues("user", "email");
    this.apollo
      .query({
        query: this.getLenderProductListQuery.document,
        fetchPolicy: "network-only",
        variables: {
          email: emailId,
          id_token: id_token,
        },
      })
      .subscribe(
        ({ data }) => {
          this.productList = data["getLenderProductList"];
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  callGetLenderAggReport(offer_id, schedule_frequency) {
    let id_token = this.sessionService.getKeyValues("token", "id_token");
    let emailId = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .query({
        query: this.getProductOfferorAggReportQuery.document,
        fetchPolicy: "network-only",
        variables: {
          userEmail: emailId,
          idToken: id_token,
          offerId: offer_id,
          scheduleFrequency: schedule_frequency
        },
      })
      .subscribe(
        ({ data }) => {
          this.totalAnnualEarning = data["getOfferorAggregateReport"]["total_annual_earning"];
          this.totalMonthlyUnpaid = data["getOfferorAggregateReport"]["total_monthly_unpaid_amount"];
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  checkButtonActivation() {
    var offerselect = (document.getElementById("offerList") as HTMLSelectElement).options[(document.getElementById("offerList") as HTMLSelectElement).options.selectedIndex].value;
    var scheduletypeselect = (document.getElementById("scheduleTypeList") as HTMLSelectElement).options[(document.getElementById("scheduleTypeList") as HTMLSelectElement).options.selectedIndex].value;

    if(offerselect != "" && scheduletypeselect != "") {
      this.reportButtonDisable = false;
    } else {
      this.reportButtonDisable = true;
    }
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeLockSlotsResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeLockSlotsResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(){
    this.callGetLenderProducts();

    this.currentMonthDates = this.monthNames[this.da.getMonth()] + " 1 - " + this.da.getDate() + ", " + this.da.getFullYear();
    this.currentYear = this.da.getFullYear();
  }
}
