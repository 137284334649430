import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GetOrderDetailsQuery } from 'src/app/services/graphql/getOrderDetails.graphql';
import { OfferorLockStatusChangeMutation } from 'src/app/services/graphql/offerorLockStatusChange.graphql';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from "rxjs";
import { SessionService } from 'src/app/services/session.service';
import { ToastrService } from 'ngx-toastr';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-approve-offering',
  templateUrl: './approve-offering.component.html',
  styleUrls: ['./approve-offering.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApproveOfferingComponent implements OnInit {

  public productLocks = new BehaviorSubject<any>(
    []
  );

  closepopupResult = "";
  user_id = null;

  constructor(
    private getOrderDetailsQuery: GetOrderDetailsQuery,
    private apollo: Apollo,
    private sessionService: SessionService,
    private toastr: ToastrService,
    private offerorLockStatusChangeMutation: OfferorLockStatusChangeMutation,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.user_id = this.sessionService.getKeyValues("token", "id");
    this.getProductLock();
  }

  getProductLock() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");
  
    this.apollo
    .query({
      query: this.getOrderDetailsQuery.document,
      variables: {
        id_token: token,
        user_email: email,
        user_type: "offeror",
        lock_type: "hard_lock"
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if (data["getProductOrders"]) {
          var temp_array = [];
          data["getProductOrders"].forEach((order, index) => {
            order["locks"].forEach((lock) => {
              temp_array.push({"lock": lock, "order_id": order["shared_order_id"]});
            });
          });
          this.productLocks.next(temp_array);
        }
      },
      (error) => {
        console.log("error");
      }
    );
  }

  changeOfferorStatus(order_id, lock_id, approve_status, comment) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .mutate({
      mutation: this.offerorLockStatusChangeMutation.document,
      variables: {
        id_token: token,
        user_email: email,
        order_id: order_id,
        user_type: "offeror",
        lock_type: "hard_lock",
        status: approve_status,
        lock_id: lock_id,
        comment: comment
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        this.getProductLock();
        this.toastr.success("Action completed successfully.");
        this.modalService.dismissAll("testing");
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closepopupResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closepopupResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
