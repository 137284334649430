import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetProductRatingQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getProductRatingQuery($productId: Int!) {
      getProductRating(
        product_id: $productId
      ) {
          rating
          likes
          }
        }
  `;
}
