import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class TrackHomeViewMutation extends Mutation {
  document = gql`
    mutation TrackHomeViewMutation(
      $masked_id: String
      $user_id: Int
    )
    {
    trackHomeView(
      masked_id: $masked_id
      user_id: $user_id
    )
    }
    `;
}
