<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!--Shipping Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Shipping Address" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content shipping-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Shipping Address" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-user-sidebar></app-user-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <table class="table">
          <thead>
            <tr class="d-flex">
              <th class="col-12 col-md-8">
                {{ "DEFAULT ADDRESS" | translate }}
              </th>
              <th class="col-12 col-md-4">{{ "ACTION" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="d-flex">
              <td class="col-12 col-md-8">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="option1"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </label>
                </div>
              </td>

              <td class="col-12 col-md-4">
                <ul class="controls">
                  <li>
                    <a [routerLink]="'/shipping-addresses'">
                      <i class="fas fa-pen"></i> {{ "Edit" | translate }}</a
                    >
                  </li>
                  <li>
                    <a [routerLink]="'/shipping-addresses'">
                      <i class="fas fa-trash-alt"></i>
                      {{ "Remove" | translate }}</a
                    >
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="add-address">
          <form action="/" name="general-form">
            <h4>{{ "Personal Information" | translate }}</h4>

            <div class="form-row">
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    name="firstname"
                    class="form-control"
                    id="inlineFormInputGroup0"
                    placeholder="{{ 'First Name' | translate }}"
                  />
                </div>
              </div>
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    name="lastname"
                    class="form-control"
                    id="inlineFormInputGroup1"
                    placeholder="{{ 'Last Name' | translate }}"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    id="inlineFormInputGroup2"
                    placeholder="{{ 'Company Name' | translate }}"
                  />
                </div>
              </div>
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    name="address"
                    class="form-control"
                    id="inlineFormInputGroup3"
                    placeholder="{{ 'Address' | translate }}"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="from-group col-md-6 mb-4">
                <div class="input-group select-control">
                  <select
                    class="form-control"
                    name="SelectName"
                    id="inlineFormInputGroup4"
                  >
                    <option selected>{{ "Select Country" | translate }}</option>
                    <option value="1">Canada</option>
                    <option value="2">United Kingdom</option>
                    <option value="3">United States</option>
                  </select>
                </div>
              </div>
              <div class="from-group col-md-6 mb-4">
                <div class="input-group select-control">
                  <select
                    class="form-control"
                    name="SelectStateName"
                    id="inlineFormInputGroup5"
                  >
                    <option selected>{{ "Select State" | translate }}</option>
                    <option value="1">Alaska</option>
                    <option value="2">New York</option>
                    <option value="3">Taxes</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    name="postcode"
                    class="form-control"
                    id="inlineFormInputGroup7"
                    placeholder="{{ 'City' | translate }}"
                  />
                </div>
              </div>
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    name="postcode"
                    class="form-control"
                    id="inlineFormInputGroup7"
                    placeholder="{{ 'Postal Code' | translate }}"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="from-group col-md-6 mb-4">
                <div class="input-group">
                  <input
                    type="text"
                    name="phone"
                    class="form-control"
                    id="inlineFormInputGroup8"
                    placeholder="{{ 'Phone' | translate }}"
                  />
                </div>
              </div>
            </div>

            <button type="submit" class="btn btn-secondary swipe-to-top">
              {{ "Add Address" | translate }}
            </button>
          </form>
        </div>
        <!-- ............the end..... -->
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
<app-switcher></app-switcher>
