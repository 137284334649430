import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class GetPaymentInvoiceDataQuery extends Query {
  document = gql`
  query getPaymentInvoiceDataQuery (
    $id_token: String!,
    $user_email: String!,
    $order_id: Int!,
    $lock_id: Int!,
    $user_type: LockInUserType
    ) {
      getInvoiceData (
      id_token: $id_token,
      user_email: $user_email,
      order_id: $order_id,
      lock_id: $lock_id,
      user_type: $user_type
      ) {
    shared_order_id
    order_id
    offeree_id
    offeror_id
    offeror_fullname
    offeree_fullname
    offeror_address_id
    offeree_address_id
    lock_id
    price_before_tax
    price_after_tax
    shared_invoice_id
    commission_before_tax
    commission_after_tax
    created_at
    updated_at
    tax_data {
      tax_type
      invoice_id
      tax_percentage
      price
      offeror_price
      offeror_price_tax
      commission
      commission_tax
      price_tax
      product_category_tax_id
      flat_tax_id
      created_at
      updated_at
    }
  }
  }
`;
}
