import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class OfferorLockStatusChangeMutation extends Mutation {

  document = gql`
    mutation OfferorLockStatusChangeMutation(
      $id_token: String!
      $order_id: String!
      $status: OfferorLockStatus!
      $lock_id: Int
      $user_email: String
      $comment: String
    ) {
      offerorProductLockStatusChange(
        id_token: $id_token
        order_id: $order_id
        user_email: $user_email
        lock_id: $lock_id
        status: $status
        comment: $comment
      )
    }
  `;
}
