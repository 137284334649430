import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})

export class GetProductListQuery extends Query {
  productListData = [];
  responseErrorMessage = "";
  private _prodListDataLoaded = new BehaviorSubject<string>(
    ""
  );
  __prodListDataLoadedObservble = this._prodListDataLoaded.asObservable();

  document = gql`
    query getProductListQuery(
      $offer_id: Int
      $offeror_id: Int
      $title_keyword: String
      $category: String
      $start_date: Date
      $randomize_search_flag: Boolean
      $schedule_frequency: ProductScheduleFrequency
      $category_group: String
      $end_date: Date
      $offset: Int
      $count: Int
      $sub_category1: String
      $sub_category2: String
    ) {
      getProductList(
        offer_id: $offer_id
        offeror_id: $offeror_id
        title_keyword: $title_keyword
        category: $category
        category_group: $category_group
        start_date: $start_date
        randomize_search_flag: $randomize_search_flag
        schedule_frequency: $schedule_frequency
        end_date: $end_date
        offset: $offset
        count: $count
        sub_category1: $sub_category1
        sub_category2: $sub_category2
      ) {
        id
        title
        product_name
        lender_id
        offeror_first_name
        offeror_last_name
        offeror_profile_image_name
        product_image_link
        schedule_weekly_price
        schedule_hourly_price
        schedule_monthly_price
        schedule_daily_price
        schedule_custom_price
        schedule_weekly_discounted_price
        schedule_hourly_discounted_price
        schedule_monthly_discounted_price
        schedule_daily_discounted_price
        schedule_custom_discounted_price
        offer_discount_id
        offer_discount_percentage
        offer_discount_end_time
        area
        pin_code
        city
        district
        country_name
        rating
        likes
        hourly_multiple_price_flag
        daily_multiple_price_flag
        weekly_multiple_price_flag
        monthly_multiple_price_flag
        custom_multiple_price_flag
      }
    }
  `;
  populateProductList(data, errorFlag: boolean = true, homeFlag: boolean = false) {
    this.responseErrorMessage = "";
    this.productListData = [];
    if (!errorFlag) {
      this.responseErrorMessage = data;
    } else {
      var productAssignment = [];
      var cnt = 0;
      for(let product of data["getProductList"]) {
        var product_image = product.product_image_link;
        if(product.product_image_link === "" || product.product_image_link === null) {
          product_image = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
        }
        let assignProd = {
          id: product.id,
          title: product.title,
          product_name: product.product_name,
          lender_id: product.lender_id,
          offeror_first_name: product.offeror_first_name,
          offeror_last_name: product.offeror_last_name,
          offeror_profile_image_name: product.offeror_profile_image_name,
          likes: product.likes,
          offeror_likes: product.offeror_likes,
          schedule_hourly_price: product.schedule_hourly_price,
          schedule_hourly_discounted_price: product.schedule_hourly_discounted_price,
          hourly_multiple_price_flag: product.hourly_multiple_price_flag,
          hourly_first_schedule_id: product.hourly_first_schedule_id,
          schedule_daily_price: product.schedule_daily_price,
          schedule_daily_discounted_price: product.schedule_daily_discounted_price,
          daily_multiple_price_flag: product.daily_multiple_price_flag,
          daily_first_schedule_id: product.daily_first_schedule_id,
          schedule_weekly_price: product.schedule_weekly_price,
          schedule_weekly_discounted_price: product.schedule_weekly_discounted_price,
          weekly_multiple_price_flag: product.weekly_multiple_price_flag,
          weekly_first_schedule_id: product.weekly_first_schedule_id,
          schedule_monthly_price: product.schedule_monthly_price,
          schedule_monthly_discounted_price: product.schedule_monthly_discounted_price,
          monthly_multiple_price_flag: product.monthly_multiple_price_flag,
          monthly_first_schedule_id: product.monthly_first_schedule_id,
          schedule_custom_price: product.schedule_custom_price,
          schedule_custom_discounted_price: product.schedule_custom_discounted_price,
          custom_multiple_price_flag: product.custom_multiple_price_flag,
          custom_first_schedule_id: product.custom_first_schedule_id,
          product_image_link: product.product_image_link,
          offer_discount_id: product.offer_discount_id,
          offer_discount_percentage: product.offer_discount_percentage,
          offer_discount_end_time: product.offer_discount_end_time,
          pin_code: product.pin_code,
          area: product.area,
          city: product.city,
          district: product.district,
          state: product.state,
          country_name: product.country_name,
          country_abbreviation: product.country_abbreviation,
          products_id: product.id,
          products_quantity: 1,
          products_image: product_image,
          products_day_price: product.schedule_daily_price,
          products_hour_price: product.schedule_hourly_price,
          products_week_price: product.schedule_weekly_price,
          products_month_price: product.schedule_monthly_price,
          products_custom_price: product.schedule_custom_price,
          products_day_discounted_price: product.schedule_daily_discounted_price,
          products_hour_discounted_price: product.schedule_hourly_discounted_price,
          products_week_discounted_price: product.schedule_weekly_discounted_price,
          products_month_discounted_price: product.schedule_monthly_discounted_price,
          products_custom_discounted_price: product.schedule_custom_discounted_price,
          products_status: 1,
          is_current: 1,
          products_liked: product.likes,
          products_name: product.title,
          currency: "INR",
          rating: product.rating
        };
        productAssignment[cnt] = assignProd;
        cnt++;
      }
      this.productListData = productAssignment;
    }
    if(homeFlag) {
      this._prodListDataLoaded.next(EventConstants.CONST_PRODLIST_HOME_LOADED);
    } else {
      this._prodListDataLoaded.next(EventConstants.CONST_PRODLIST_LOADED);
    }
  }
}
