import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetAddressQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getAddressQuery(
      $id_token: String!
      $user_email: String!
      $address_id: Int!
      ) {
        getAddress(
          id_token: $id_token,
          user_email: $user_email,
          address_id: $address_id
      ) {
        area_name,
        pin_code,
        district,
        city,
        address_line1,
        address_line2,
        landmark,
        area_id,
        latitude,
        longitude,
      	state,
        country,
        tax_code,
        tax_number
        }
      }
  `;
}
