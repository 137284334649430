import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class LoginUserMutation extends Mutation {
  document = gql`
    mutation loginUserMutation($email: String="", $password: String="", $refresh_token: String="",$id_token: String="") {
      loginUser(email: $email, password: $password, refresh_token: $refresh_token, id_token:$id_token) {
        creation_status
        token_type
        id_token
        refresh_token
        firebase_id
        expires_in
        id
        firebase_id
        user_status
        is_email_verified
      }
    }
  `;
}
