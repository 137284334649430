import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class GenerateCouponCodeMutation extends Mutation {

  document = gql`
    mutation GenerateCouponCodeMutation(
      $masked_user_id: String!
      $offer_id: Int
    ) {
      generateCouponCode(
        masked_user_id: $masked_user_id
        offer_id: $offer_id
      ) {
          coupon_code
          start_date
          end_date
          discount_percentage
          offer_id
          category_id
          category_group_id
          offer_title
          category
          sub_category1
          sub_category2
          category_group
          for_all_offers
          existing
      }
    }
  `;
}
