import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from "apollo-angular";
import { SessionService } from "src/app/services/session.service";
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';
import { OffereeRefundRequestMutation } from 'src/app/services/graphql/offereeRefundRequest.graphql';
import { OffereeRevertRefundRequestMutation } from 'src/app/services/graphql/offereeRevertRefundRequest.graphql';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-offer-grievance',
  templateUrl: './offer-grievance.component.html',
  styleUrls: ['./offer-grievance.component.scss']
})
export class OfferGrievanceComponent implements OnInit {

  productLockId = null;
  productLockDetails = null;
  sharedOrderId = null;
  complaintComment = "";
  grievanceRaised = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private apollo: Apollo,
    private getProductLocksQuery: GetProductLocksQuery,
    private offereeRefundRequestMutation:OffereeRefundRequestMutation,
    private offereeRevertRefundRequestMutation: OffereeRevertRefundRequestMutation,
    private toastr: ToastrService
  ) { }

  raiseRevertRefundRequest() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    var ele = document.getElementById("raiseRevertComplaintButton");

    if(!ele.classList.contains("is-loading")) {
      ele.classList.add("is-loading");
    }

    this.apollo
        .mutate({
          mutation: this.offereeRevertRefundRequestMutation.document,
          variables: {
            lock_id: this.productLockId,
            shared_order_id: this.sharedOrderId,
            id_token: token,
            offeree_email: email
          },
        })
        .subscribe(
          ({ data }) => {
            this.toastr.success("Thank you for taking your complaint back.");
            if(ele.classList.contains("is-loading")) {
              ele.classList.remove("is-loading");
            }
            this.router.navigate(["/"]);
          },
          (error) => {
            this.toastr.error(error.message);
            if(ele.classList.contains("is-loading")) {
              ele.classList.remove("is-loading");
            }
          }
        );
  }

  raiseRefundRequest() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    var ele = document.getElementById("raiseComplaintButton");

    if(!ele.classList.contains("is-loading")) {
      ele.classList.add("is-loading");
    }

    this.apollo
        .mutate({
          mutation: this.offereeRefundRequestMutation.document,
          variables: {
            lock_id: this.productLockId,
            shared_order_id: this.sharedOrderId,
            id_token: token,
            offeree_email: email,
            comment: this.complaintComment
          },
        })
        .subscribe(
          ({ data }) => {
            this.toastr.success("Thanks, your complaint is registered.");
            this.grievanceRaised = true;
            if(ele.classList.contains("is-loading")) {
              ele.classList.remove("is-loading");
            }
            this.router.navigate(["/"]);
          },
          (error) => {
            this.toastr.error(error.message);
            if(ele.classList.contains("is-loading")) {
              ele.classList.remove("is-loading");
            }
          }
        );
  }

  getOfferLockDetails(lockId) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
              .query({
                query: this.getProductLocksQuery.document,
                variables: {
                  id_token: token,
                  user_email: email,
                  user_type: "offeree",
                  lock_id: lockId
                }
              })
              .subscribe(
                ({ data }) => {
                  this.productLockDetails = data["getProductLocks"][0];
                },
                (error) => {
                }
              );
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.productLockId = +params.get('id');
        this.sharedOrderId = params.get('order_id');
      }
    );

    this.getOfferLockDetails(this.productLockId);
  }

}
