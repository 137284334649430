<div class="accordion shop-bar-categories" id="accordionExample1">
    <div class="card">
      <div class="card-header" id="CardOne">
        <a href="" class="mb-0"  data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          {{'Collections' | translate}}
        </a>
      </div>
  
      <div id="collapseOne" class="collapse show" aria-labelledby="CardOne" data-parent="#accordionExample1">
        <div class="card-body">
            <ul  class="brands">
                <li><a class=" brands-btn" href="#" role="button"><i class="fas fa-angle-right"></i>Rings</a></li>
                <li><a class="  brands-btn" href="#" role="button"><i class="fas fa-angle-right"></i>Earrings</a></li>
                <li><a class=" brands-btn" href="#" role="button"><i class="fas fa-angle-right"></i>Crowns</a></li>
                <li><a class=" brands-btn" href="#" role="button"><i class="fas fa-angle-right"></i>Necklace</a></li>
                <li><a class=" brands-btn" href="#" role="button"><i class="fas fa-angle-right"></i>Bracelet </a></li>
              </ul>
        </div>
      </div>
    </div>
</div>
<div class="accordion shop-bar-categories" id="accordionExample2">
    <div class="card">
        <div class="card-header" id="CardTwo">
          <a href="" class="mb-0"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            {{'Price' | translate}}
          </a>
        </div>
    
        <div id="collapseTwo" class="collapse show" aria-labelledby="CardTwo" data-parent="#accordionExample2">
          <div class="card-body">
              <ul  class="brands">
                  <li><a class=" brands-btn" href="#" role="button">$10-$100
                      </a></li>
                  <li><a class="  brands-btn" href="#" role="button">$100-$200
                      </a></li>
                  <li><a class=" brands-btn" href="#" role="button">$200-$300
                      </a></li>
                  <li><a class=" brands-btn" href="#" role="button">$300-$400
                      </a></li>
                  <li><a class=" brands-btn" href="#" role="button">$400-$500</a></li>
                </ul>
          </div>
        </div>
    </div>
</div>
<div class="accordion shop-bar-categories" id="accordionExample3">
    <div class="card">
        <div class="card-header" id="CardThree">
          <a href="" class="mb-0"  data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">
            {{'Color Selection' | translate}}
          </a>
        </div>
    
        <div id="collapseThree" class="collapse show" aria-labelledby="CardThree" data-parent="#accordionExample3">
          <div class="card-body">
              <div class="pro-options">
                  <div class="color-selection">
                    
                    <ul>
                      <li class="active"><a class="bg-primary " href="javascript:void(0);"></a></li>
                      <li ><a class="bg-secondary " href="javascript:void(0);"></a></li>
                      <li ><a class="bg-success " href="javascript:void(0);"></a></li>
                      <li ><a class="bg-info " href="javascript:void(0);"></a></li>
                      <li ><a class="bg-warning " href="javascript:void(0);"></a></li>
                      <li ><a class="bg-danger " href="javascript:void(0);"></a></li>

                      <li ><a class="bg-light " href="javascript:void(0);"></a></li>
                      <li ><a class="bg-dark " href="javascript:void(0);"></a></li>
                    </ul>
                    </div>
                    
              </div>
          </div>
        </div>
    </div> 
</div>
<div class="accordion shop-bar-categories" id="accordionExample4">
    <div class="card">
        <div class="card-header" id="CardFour">
          <a href="" class="mb-0"  data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseOne">
            {{'Size Selection' | translate}}
          </a>
        </div>
    
        <div id="collapseFour" class="collapse show" aria-labelledby="CardFour" data-parent="#accordionExample4">
          <div class="card-body">
              <div class="pro-options">
              <div class="size-selection">
                  
                    <ul>
                      <li class="active"><a href="javascript:void(0);" tabindex="0">28</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">32</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">34</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">36</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">38</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">40</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">42</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">44</a></li>
                      <li><a href="javascript:void(0);" tabindex="0">46</a></li>
                    </ul>
                    </div>
                  </div>
          </div>
        </div>
    </div>
</div>

<div class="img-main">
    <a href="shop-page1.html" ><img class="img-fluid" src="assets/images/shop/side-image.jpg" alt="shop Image"></a>
    
</div>