import { Component, Input, OnInit } from "@angular/core";
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { TrackHomeViewMutation } from "src/app/services/graphql/trackHomeView.graphql";
import { Subscription } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
import { Apollo } from "apollo-angular";
import { SessionService } from "src/app/services/session.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SessionConstants } from "src/app/constants/app.constants";
import AOS from 'aos';

@Component({
  selector: "app-home",
  // templateUrl: "./home.component.html",
  templateUrl: "./home.temp.component.html",
  styleUrls: ["./home.component.scss"],
  styles: [`
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      border-radius: 0.25rem;
      display: inline-block;
      width: 2rem;
    }
    .custom-day:hover, .custom-day.focused {
      background-color: #e6e6e6;
    }
    .weekend {
      background-color: #f0ad4e;
      border-radius: 1rem;
      color: white;
    }
    .hidden {
      display: none;
    }
  `]
})
export class HomeComponent implements OnInit {
  subscription: Subscription;
  homeProducts: any[];
  noProduct: Boolean = false;
  responseErrorMessage = "";
  catogeryList = [];
  selectedFilter:string = null
  isLoading:boolean = false;
  allCatogeryList:any = [];
  currentDate = Date.now();
  currentDate1 = new Date((new Date()).getFullYear(), 11, 31);
  selectedStartDate: Date = new Date(this.currentDate);
  selectedEndDate: Date = new Date(this.currentDate1);
  search_start_date = this.selectedStartDate.getFullYear().toString() + "-" + (this.selectedStartDate.getMonth() + 1).toString() + "-" + this.selectedStartDate.getDate().toString();
  search_end_date = this.selectedEndDate.getFullYear().toString() + "-" + (this.selectedEndDate.getMonth() + 1).toString() + "-" + this.selectedEndDate.getDate().toString();


  constructor(
    private apollo: Apollo,
    private getProductListQuery: GetProductListQuery,
    private authService:AuthService,
    private breakpointObserver: BreakpointObserver,
    private trackHomeViewMutation: TrackHomeViewMutation,
    private sessionService: SessionService
    ) {

    }

  ngOnInit() {
    AOS.init({disable: 'mobile'});
    this.isLoading = true;
    this.getPopularProductList();
    
    this.breakpointObserver.observe([
      "(max-width: 785px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        document.getElementById("backgroundVideo").remove();        
      }
    });

    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");

      this.apollo
            .mutate({
              mutation: this.trackHomeViewMutation.document,
              variables: {
                masked_id: maskedUserData,
                user_id: user_id
              },
          }).subscribe(({ data }) => {});
    }

    this.subscription = this.getProductListQuery.__prodListDataLoadedObservble.subscribe(
      (data) => {
        if (data === EventConstants.CONST_PRODLIST_HOME_LOADED) {
          this.getProductListQuery.responseErrorMessage;
          this.responseErrorMessage = "";
          this.homeProducts = [];
          if (this.getProductListQuery.responseErrorMessage) {
            this.responseErrorMessage = this.getProductListQuery.responseErrorMessage;
            console.log(this.responseErrorMessage);
          } else {
            this.homeProducts = this.getProductListQuery.productListData;
          }
          if(this.homeProducts.length <= 0) {
            this.noProduct = true;
          } else {
            this.noProduct = false;
          }
        }
        this.isLoading = false;
      }
    );

    // TODO: Remove it later
  //   this.homeProducts = [
  //     {
  //         "id": "33",
  //         "title": "Master the Art of Singing with Interactive Online Music Classes",
  //         "product_name": "singingonlineclass12938",
  //         "lender_id": 85,
  //         "product_image_link": "https://zigwik.com/offer_images/j6mBRsYDethFFHbwGvZXSHYgVkz2_product_33_1703915974.003",
  //         "schedule_weekly_price": null,
  //         "schedule_hourly_price": null,
  //         "schedule_monthly_price": null,
  //         "schedule_daily_price": null,
  //         "schedule_custom_price": 6900,
  //         "area": null,
  //         "pin_code": null,
  //         "city": null,
  //         "district": null,
  //         "country_name": null,
  //         "rating": 4,
  //         "likes": 0,
  //         "hourly_multiple_price_flag": false,
  //         "daily_multiple_price_flag": false,
  //         "weekly_multiple_price_flag": false,
  //         "monthly_multiple_price_flag": false,
  //         "custom_multiple_price_flag": true,
  //         "__typename": "ProductList"
  //     },
  //     {
  //         "id": "11",
  //         "title": "Consult for any Oil Painting related challenges",
  //         "product_name": "oilpaintingconsultationonline",
  //         "lender_id": 59,
  //         "product_image_link": "https://zigwik.com/offer_images/uF2sz8aPz7YUSAzlLsgxb3o7U523_product_11_1689247894.0669",
  //         "schedule_weekly_price": null,
  //         "schedule_hourly_price": 920,
  //         "schedule_monthly_price": null,
  //         "schedule_daily_price": null,
  //         "schedule_custom_price": null,
  //         "area": null,
  //         "pin_code": null,
  //         "city": null,
  //         "district": null,
  //         "country_name": null,
  //         "rating": 3,
  //         "likes": 0,
  //         "hourly_multiple_price_flag": false,
  //         "daily_multiple_price_flag": false,
  //         "weekly_multiple_price_flag": false,
  //         "monthly_multiple_price_flag": false,
  //         "custom_multiple_price_flag": false,
  //         "__typename": "ProductList"
  //     },
  //     {
  //         "id": "29",
  //         "title": "में यहाँ आपको सुनने के लिए हूँ - आराम से अपनी मन की बात व्यक्त करें",
  //         "product_name": "badedukhkeliye1",
  //         "lender_id": 77,
  //         "product_image_link": "https://zigwik.com/offer_images/2SqyfgsSE1NY2r68FIOyIRQjJhO2_product_29_1702307029.5709",
  //         "schedule_weekly_price": null,
  //         "schedule_hourly_price": 1265,
  //         "schedule_monthly_price": null,
  //         "schedule_daily_price": null,
  //         "schedule_custom_price": null,
  //         "area": null,
  //         "pin_code": null,
  //         "city": null,
  //         "district": null,
  //         "country_name": null,
  //         "rating": 2,
  //         "likes": 0,
  //         "hourly_multiple_price_flag": false,
  //         "daily_multiple_price_flag": false,
  //         "weekly_multiple_price_flag": false,
  //         "monthly_multiple_price_flag": false,
  //         "custom_multiple_price_flag": false,
  //         "__typename": "ProductList"
  //     },
  //     {
  //         "id": "45",
  //         "title": "Create Offer in Zigwik and start earning. Offer Creation Tutorial.",
  //         "product_name": "offercreationtutorial",
  //         "lender_id": 52,
  //         "product_image_link": "https://zigwik.com/offer_images/BX727xHSM6RjyLUm6IKbxxKzueD2_product_45_1705291403.216",
  //         "schedule_weekly_price": null,
  //         "schedule_hourly_price": 10,
  //         "schedule_monthly_price": null,
  //         "schedule_daily_price": null,
  //         "schedule_custom_price": null,
  //         "area": null,
  //         "pin_code": null,
  //         "city": null,
  //         "district": null,
  //         "country_name": null,
  //         "rating": 10,
  //         "likes": 0,
  //         "hourly_multiple_price_flag": false,
  //         "daily_multiple_price_flag": false,
  //         "weekly_multiple_price_flag": false,
  //         "monthly_multiple_price_flag": false,
  //         "custom_multiple_price_flag": false,
  //         "__typename": "ProductList"
  //     },
  //     {
  //         "id": "32",
  //         "title": "Weekly once Online meditation session",
  //         "product_name": "weekonceonmesess",
  //         "lender_id": 81,
  //         "product_image_link": "https://zigwik.com/offer_images/DnAkTdfbyoSvQoWdMnMxna80Ma53_product_32_1703153987.7056",
  //         "schedule_weekly_price": null,
  //         "schedule_hourly_price": 230,
  //         "schedule_monthly_price": null,
  //         "schedule_daily_price": null,
  //         "schedule_custom_price": null,
  //         "area": null,
  //         "pin_code": null,
  //         "city": null,
  //         "district": null,
  //         "country_name": null,
  //         "rating": 1,
  //         "likes": 0,
  //         "hourly_multiple_price_flag": false,
  //         "daily_multiple_price_flag": false,
  //         "weekly_multiple_price_flag": false,
  //         "monthly_multiple_price_flag": false,
  //         "custom_multiple_price_flag": false,
  //         "__typename": "ProductList"
  //     },
  //     {
  //         "id": "16",
  //         "title": "Online Consultation for SAP Project System",
  //         "product_name": "sapprojectsystemconsult",
  //         "lender_id": 64,
  //         "product_image_link": "https://zigwik.com/category_default_images/Advance_Tech_Consult.jpg",
  //         "schedule_weekly_price": null,
  //         "schedule_hourly_price": 2300,
  //         "schedule_monthly_price": null,
  //         "schedule_daily_price": null,
  //         "schedule_custom_price": null,
  //         "area": null,
  //         "pin_code": null,
  //         "city": null,
  //         "district": null,
  //         "country_name": null,
  //         "rating": 0,
  //         "likes": 0,
  //         "hourly_multiple_price_flag": false,
  //         "daily_multiple_price_flag": false,
  //         "weekly_multiple_price_flag": false,
  //         "monthly_multiple_price_flag": false,
  //         "custom_multiple_price_flag": false,
  //         "__typename": "ProductList"
  //     }
  // ]

  this.authService.catogeryList.subscribe(res => this.catogeryList = res);
  
  }

  getPopularProductList():void{
    this.apollo
      .query({
        query: this.getProductListQuery.document,
        variables: {
          title: "",
          category_group: this.selectedFilter || "",
          start_date: "",
          end_date: "",
          offset: 0,
          count: 6,
          randomize_search_flag: true
        },
      })
      .subscribe(
        ({ data }) => {
          console.log("data received");

          if (data["getProductList"]) {
            this.getProductListQuery.populateProductList(data, true, true);
          }
        },
        (error) => {
          console.log(error.message);
          this.getProductListQuery.populateProductList(error.message, false, true);
        }
      );
  }

  slideConfigCarousel = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,

    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,

    pauseOnHover: false,

    pauseOnDotsHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slideOffersConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: false,

    arrows: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slideCategoryConfig = {
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true,
    infinite: false,

    arrows: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
