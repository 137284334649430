<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- About-us Content -->
<section class="pro-content aboutus-content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="pro-heading-title">
        <h1>
          {{ "Welcome To Katora" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-7">
        <p class="peragraph">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
          skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et. Nihil anim
          keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
          sapiente ea proident. Ad vegan excepteur butcher vice lomo.
        </p>
        <p class="peragraph">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
          skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
          Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
          single-origin coffee nulla assumenda shoreditch et.
        </p>
      </div>
      <div class="col-12 col-md-5">
        <video width="320" height="240" controls>
          <source src="*.mp4" type="video/mp4" />
          <source src="*.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>

  <div class="container-fluid accordion-container">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="pro-heading-title">
            <h1>
              {{ "Our Vision" | translate }}
            </h1>
          </div>
          <section class="accordions">
            <!--Accordion wrapper-->
            <div
              class="accordion md-accordion"
              id="accordionEx"
              role="tablist"
              aria-multiselectable="true"
            >
              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="headingOne1">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseOne1"
                    aria-expanded="true"
                    aria-controls="collapseOne1"
                    class=""
                  >
                    <h5 class="mb-0">
                      {{ "Who We Are" | translate }}
                    </h5>
                  </a>
                </div>

                <!-- Card body -->
                <div
                  id="collapseOne1"
                  class="collapse show"
                  role="tabpanel"
                  aria-labelledby="headingOne1"
                  data-parent="#accordionEx"
                  style=""
                >
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                  </div>
                </div>
              </div>
              <!-- Accordion card -->

              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="headingTwo2">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseTwo2"
                    aria-expanded="false"
                    aria-controls="collapseTwo2"
                  >
                    <h5 class="mb-0">
                      {{ "What We Want" | translate }}
                    </h5>
                  </a>
                </div>

                <!-- Card body -->
                <div
                  id="collapseTwo2"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo2"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                  </div>
                </div>
              </div>
              <!-- Accordion card -->

              <!-- Accordion card -->
              <div class="card">
                <!-- Card header -->
                <div class="card-header" role="tab" id="headingThree3">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx"
                    href="#collapseThree3"
                    aria-expanded="false"
                    aria-controls="collapseThree3"
                  >
                    <h5 class="mb-0">
                      {{ "What We Can Give" | translate }}
                    </h5>
                  </a>
                </div>

                <!-- Card body -->
                <div
                  id="collapseThree3"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree3"
                  data-parent="#accordionEx"
                >
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                    accusamus terry richardson ad squid. 3 wolf moon officia
                    aute, non cupidatat skateboard dolor brunch. Food truck
                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                    sunt aliqua put a bird on it squid single-origin coffee
                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                    helvetica, craft beer labore wes anderson cred nesciunt
                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                  </div>
                </div>
              </div>
              <!-- Accordion card -->
            </div>
            <!-- Accordion wrapper -->
          </section>
        </div>
        <div class="col-12 col-md-6">
          <div class="pro-heading-title">
            <h1>
              {{ "Our Offer" | translate }}
            </h1>
          </div>
          <p class="peragraph peragraph2">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
            single-origin coffee nulla assumenda shoreditch et. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
            sapiente ea proident. Ad vegan excepteur butcher vice lomo.
          </p>
          <p class="peragraph">
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. 3 wolf moon officia aute, non cupidatat
            skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
            Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid
            single-origin coffee nulla assumenda shoreditch et. Nihil anim
            keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
            sapiente ea proident. Ad vegan excepteur butcher vice lomo.Nihil
            anim keffiyeh helvetica, craft beer labore wes anderson cred
            nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="conatiner-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
          <div class="pro-heading-title">
            <h1>{{ "Our Team" | translate }}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <ngx-slick-carousel
            #slickModal="slick-carousel"
            [config]="slideTeamConfig"
            (init)="slickInit($event)"
            class="carousel row"
            style="margin-bottom: 10px;"
          >
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_01_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Charlis</h3>
                    <p>Chief Officer</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_02_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>John Doe</h3>
                    <p>Sales Executive</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_03_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Theresa May</h3>
                    <p>Markting Officer</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_04_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Xavior</h3>
                    <p>Manager</p>
                  </div>
                </article>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="team-member">
                <article>
                  <div class="team-thumb">
                    <img
                      class="img-fluid"
                      src="assets/images/about-us/14_05_about.jpg"
                      alt="Member Image"
                    />
                  </div>
                  <div class="team-info">
                    <h3>Malissa</h3>
                    <p>Product Manager</p>
                  </div>
                </article>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
<app-switcher></app-switcher>
