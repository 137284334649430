<div class="field is-horizontal">
    <div class="field-body">
        <div class="field w-100">
            <label class="label">Time Scheduler</label>
        </div>
        
    </div>
</div>
<div class="field is-horizontal mt-2 day-scheduler-web">
    <div class="field-body">
        <div class="field w-25">
            <div class="slot-info w-100 h-100 mt-1">
                <div class="slot-info-hr h-100"><span>Hr</span></div>
                <div class="slot-info-min h-100"><span>&nbsp;0</span></div>
                <div class="slot-info-min h-100"><span>15</span></div>
                <div class="slot-info-min h-100"><span>30</span></div>
                <div class="slot-info-min h-100"><span>45</span></div>
            </div>
        </div>
        <div class="field w-25">
            <div class="slot-info w-100 h-100 mt-1">
                <div class="slot-info-hr h-100"><span>Hr</span></div>
                <div class="slot-info-min h-100"><span>&nbsp;0</span></div>
                <div class="slot-info-min h-100"><span>15</span></div>
                <div class="slot-info-min h-100"><span>30</span></div>
                <div class="slot-info-min h-100"><span>45</span></div>
            </div>
        </div>
        <div class="field w-25">
            <div class="slot-info w-100 h-100 mt-1">
                <div class="slot-info-hr h-100"><span>Hr</span></div>
                <div class="slot-info-min h-100"><span>&nbsp;0</span></div>
                <div class="slot-info-min h-100"><span>15</span></div>
                <div class="slot-info-min h-100"><span>30</span></div>
                <div class="slot-info-min h-100"><span>45</span></div>
            </div>
        </div>
        <div class="field w-25">
            <div class="slot-info w-100 h-100 mt-1">
                <div class="slot-info-hr h-100"><span>Hr</span></div>
                <div class="slot-info-min h-100"><span>&nbsp;0</span></div>
                <div class="slot-info-min h-100"><span>15</span></div>
                <div class="slot-info-min h-100"><span>30</span></div>
                <div class="slot-info-min h-100"><span>45</span></div>
            </div>
        </div>
    </div>
</div>
<div class="field is-horizontal mt-2 day-scheduler-mobile">
    <div class="field-body">
        <div class="field w-100">
            <div class="slot-info w-100 h-100 mt-1">
                <div class="slot-info-hr h-100"><span>Hr</span></div>
                <div class="slot-info-min h-100"><span>&nbsp;0</span></div>
                <div class="slot-info-min h-100"><span>15</span></div>
                <div class="slot-info-min h-100"><span>30</span></div>
                <div class="slot-info-min h-100"><span>45</span></div>
            </div>
        </div>
    </div>
</div>
<div id="webSelectSlots" class="field is-horizontal mt-2 day-scheduler-web" *ngFor="let slot of timeSlotIndexes">
    <div class="field-body">
        <div class="field w-25">
            <div id="{{slot}}" class="slot-scheduler w-100 h-100">
                <div id="{{slot}}_0" (click)="clearCalendar()" class="slot-inner-select h-100">
                    <span *ngIf="slot.toString().length == 1">0{{slot}}</span>
                    <span *ngIf="slot.toString().length > 1">{{slot}}</span>
                </div>
                <div id="{{slot}}_1" class="slot-inner h-100" (click)="selectTimeSlot(slot + '_' + 1)">&nbsp;</div>
                <div id="{{slot}}_2" class="slot-inner h-100" (click)="selectTimeSlot(slot + '_' + 2)">&nbsp;</div>
                <div id="{{slot}}_3" class="slot-inner h-100" (click)="selectTimeSlot(slot + '_' + 3)">&nbsp;</div>
                <div id="{{slot}}_4" class="slot-inner h-100" (click)="selectTimeSlot(slot + '_' + 4)">&nbsp;</div>
            </div>
        </div>
        <div class="field w-25">
            <div id="{{slot + 6}}" class="slot-scheduler w-100 h-100">
                <div id="{{slot + 6}}_0" class="slot-inner-select h-100">
                    <span *ngIf="(slot + 6).toString().length == 1">0{{slot + 6}}</span>
                    <span *ngIf="(slot + 6).toString().length > 1">{{slot + 6}}</span>
                </div>
                <div id="{{slot + 6}}_1" class="slot-inner h-100" (click)="selectTimeSlot((slot + 6) + '_' + 1)">&nbsp;</div>
                <div id="{{slot + 6}}_2" class="slot-inner h-100" (click)="selectTimeSlot((slot + 6) + '_' + 2)">&nbsp;</div>
                <div id="{{slot + 6}}_3" class="slot-inner h-100" (click)="selectTimeSlot((slot + 6) + '_' + 3)">&nbsp;</div>
                <div id="{{slot + 6}}_4" class="slot-inner h-100" (click)="selectTimeSlot((slot + 6) + '_' + 4)">&nbsp;</div>
            </div>
        </div>
        <div class="field w-25">
            <div id="{{slot + 12}}" class="slot-scheduler w-100 h-100">
                <div id="{{slot + 12}}_0" class="slot-inner-select h-100">
                    <span *ngIf="(slot + 12).toString().length == 1">0{{slot + 12}}</span>
                    <span *ngIf="(slot + 12).toString().length > 1">{{slot + 12}}</span>
                </div>
                <div id="{{slot + 12}}_1" class="slot-inner h-100" (click)="selectTimeSlot((slot + 12) + '_' + 1)">&nbsp;</div>
                <div id="{{slot + 12}}_2" class="slot-inner h-100" (click)="selectTimeSlot((slot + 12) + '_' + 2)">&nbsp;</div>
                <div id="{{slot + 12}}_3" class="slot-inner h-100" (click)="selectTimeSlot((slot + 12) + '_' + 3)">&nbsp;</div>
                <div id="{{slot + 12}}_4" class="slot-inner h-100" (click)="selectTimeSlot((slot + 12) + '_' + 4)">&nbsp;</div>
            </div>
        </div>
        <div class="field w-25">
            <div id="{{slot + 18}}" class="slot-scheduler w-100 h-100">
                <div id="{{slot + 18}}_0" class="slot-inner-select h-100">
                    <span *ngIf="(slot + 18).toString().length == 1">0{{slot + 18}}</span>
                    <span *ngIf="(slot + 18).toString().length > 1">{{slot + 18}}</span>
                </div>
                <div id="{{slot + 18}}_1" class="slot-inner h-100" (click)="selectTimeSlot((slot + 18) + '_' + 1)">&nbsp;</div>
                <div id="{{slot + 18}}_2" class="slot-inner h-100" (click)="selectTimeSlot((slot + 18) + '_' + 2)">&nbsp;</div>
                <div id="{{slot + 18}}_3" class="slot-inner h-100" (click)="selectTimeSlot((slot + 18) + '_' + 3)">&nbsp;</div>
                <div id="{{slot + 18}}_4" class="slot-inner h-100" (click)="selectTimeSlot((slot + 18) + '_' + 4)">&nbsp;</div>
            </div>
        </div>
    </div>
</div>

<div id="mobileSelectSlots" class="field is-horizontal mt-2 day-scheduler-mobile" *ngFor="let slot of timeSlotIndexes">
    <div class="field-body">
        <div class="w-100">
            <div id="mob_{{(slot * 4)}}" class="slot-scheduler">
                <div id="mob_{{(slot * 4)}}_0" (click)="clearCalendar()" class="slot-inner-select">
                    <span *ngIf="(slot * 4).toString().length == 1">0{{slot * 4}}</span>
                    <span *ngIf="(slot * 4).toString().length > 1">{{slot * 4}}</span>
                </div>
                <div id="mob_{{(slot * 4)}}_1" class="slot-inner" (click)="selectTimeSlot(('mob_' + (slot * 4) + '_1'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4)}}_2" class="slot-inner" (click)="selectTimeSlot(('mob_' + (slot * 4) + '_2'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4)}}_3" class="slot-inner" (click)="selectTimeSlot(('mob_' + (slot * 4) + '_3'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4)}}_4" class="slot-inner" (click)="selectTimeSlot(('mob_' + (slot * 4) + '_4'), true)">&nbsp;</div>
            </div>
        </div>
        <div class="w-100">
            <div id="mob_{{(slot * 4) + 1}}" class="slot-scheduler">
                <div id="mob_{{(slot * 4) + 1}}_0" class="slot-inner-select h-100">
                    <span *ngIf="((slot * 4) + 1).toString().length == 1">0{{(slot * 4) + 1}}</span>
                    <span *ngIf="((slot * 4) + 1).toString().length > 1">{{(slot * 4) + 1}}</span>
                </div>
                <div id="mob_{{(slot * 4) + 1}}_1" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 1) + '_1'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 1}}_2" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 1) + '_2'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 1}}_3" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 1) + '_3'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 1}}_4" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 1) + '_4'), true)">&nbsp;</div>
            </div>
        </div>
        <div class="w-100">
            <div id="mob_{{(slot * 4) + 2}}" class="slot-scheduler">
                <div id="mob_{{(slot * 4) + 2}}_0" class="slot-inner-select h-100">
                    <span *ngIf="((slot * 4) + 2).toString().length == 1">0{{(slot * 4) + 2}}</span>
                    <span *ngIf="((slot * 4) + 2).toString().length > 1">{{(slot * 4) + 2}}</span>
                </div>
                <div id="mob_{{(slot * 4) + 2}}_1" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 2) + '_1'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 2}}_2" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 2) + '_2'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 2}}_3" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 2) + '_3'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 2}}_4" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 2) + '_4'), true)">&nbsp;</div>
            </div>
        </div>
        <div class="w-100">
            <div id="mob_{{(slot * 4) + 3}}" class="slot-scheduler">
                <div id="mob_{{(slot * 4) + 3}}_0" class="slot-inner-select h-100">
                    <span *ngIf="((slot * 4) + 3).toString().length == 1">0{{(slot * 4) + 3}}</span>
                    <span *ngIf="((slot * 4) + 3).toString().length > 1">{{(slot * 4) + 3}}</span>
                </div>
                <div id="mob_{{(slot * 4) + 3}}_1" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 3) + '_1'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 3}}_2" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 3) + '_2'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 3}}_3" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 3) + '_3'), true)">&nbsp;</div>
                <div id="mob_{{(slot * 4) + 3}}_4" class="slot-inner h-100" (click)="selectTimeSlot(('mob_' + ((slot * 4) + 3) + '_4'), true)">&nbsp;</div>
            </div>
        </div>
    </div>
</div>
