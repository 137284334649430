import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class DeleteUserCachedTokenMutation extends Mutation {

  document = gql`
    mutation DeleteUserCachedTokenMutation(
      $user_id: Int!
    ) {
      deleteUserToken(
        user_id: $user_id
      )
    }
  `;
}
