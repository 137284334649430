<!-- Product Modal -->
<div class="modal fade" id="quickViewModal" tabindex="-1" role="dialog" aria-hidden="true"
    *ngFor="let product of products | slice:0:1">

    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-6">
                            <div class="row ">
                                <div id="quickViewCarousel" class="carousel slide" data-ride="carousel">
                                    <!-- The slideshow -->
                                    <div class="carousel-inner">
                                        <div class="carousel-item" *ngFor="let image_gallerys of product.images; let i=index" [class.active]="i==0">

                                            <img class="img-fluid" src="{{image_gallerys.image}}" alt="image">
                                        </div>

                                    </div>
                                    <!-- Left and right controls -->
                                    <a class="carousel-control-prev" href="#quickViewCarousel" data-slide="prev">
                                        <span class="fas fa-angle-left "></span>
                                    </a>
                                    <a class="carousel-control-next" href="#quickViewCarousel" data-slide="next">
                                        <span class="fas fa-angle-right "></span>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="pro-description">
                                <h2 class="pro-title">{{product.products_name | translate}}</h2>

                                <div class="pro-price">
                                    <ins>{{product.products_price | currency}}</ins>
                                </div>

                                <div class="pro-infos">
                                    <div class="pro-single-info"><b>Product ID :</b>{{product.products_id}}</div>
                                    <div class="pro-single-info"><b>Categroy :</b><a href="#">{{product.categories.categories_name | translate}}</a>
                                    </div>
                                    
                                    <div class="pro-single-info"><b>Available :</b><span
                                            class="text-secondary">InStock</span></div>
                                </div>

                                <p>
                                    {{product.products_description}}
                                </p>
                                <div class="pro-counter">
                                    <div class="input-group item-quantity">

                                        <input type="text" name="quantity" class="form-control"
                                        value="{{product.products_quantity}}" readonly>

                                        <span class="input-group-btn">
                                            <button type="button" (click)="addQuantity(product)" class="quantity-plus btn"
                                                data-type="plus" data-field="">
                                                <i class="fas fa-plus"></i>
                                            </button>

                                            <button type="button" [disabled]="product.products_quantity==1" (click)="removeQuantity(product)" class="quantity-minus btn"
                                                data-type="minus" data-field="">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </span>
                                    </div>
                                    <button type="button" class="btn btn-secondary btn-lg swipe-to-top" (click)="onAddCart(product)">{{'Add to Cart' | translate}}</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>
