import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class ProductLikeMutation extends Mutation {
  document = gql`
    mutation productLikeMutation($email: String, $idToken: String!, $productId: Int!) {
      productLike(
        user_email: $email, 
        id_token: $idToken, 
        product_id: $productId
      )
    }
  `;
}
