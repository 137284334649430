import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { Apollo } from "apollo-angular";
import {Location} from '@angular/common';
import { GetCategoryQuery } from "src/app/services/graphql/getcategory.graphql";
import { SessionService } from "../../../services/session.service";
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { SessionConstants } from "src/app/constants/app.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { CartDropdownComponent } from "../../../components/cart-dropdown/cart-dropdown.component";
import { runInThisContext } from "vm";
import { NgbDateStruct, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DeleteUserCachedTokenMutation } from 'src/app/services/graphql/deleteUserCachedToken.graphql';
import { AuthService } from "src/app/services/auth/auth.service";

interface ICatogeryGroupList{
  category_group:string,
  sub_catogery:[{
    sub_category1:string,
    sub_category2:string,
  }]
}

@Component({
  selector: "app-header",
  // templateUrl: "./header.component.html",
  templateUrl: "./header.temp.component.html",
  // styleUrls: ["./header.component.scss"],
  styleUrls: ["./header.temp.component.scss"],
})
export class HeaderComponent implements OnInit {
  title = new FormControl("");
  category = new FormControl("");
  scheduletype = new FormControl("");
  count = new FormControl("");
  start_date = new FormControl("");
  end_date = new FormControl("");
  private userData;
  categoryData = [];
  productData = [];
  productForm: FormGroup;
  errorMessage: String;
  productselectedId: number;

	model_start_date: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: (new Date()).getMonth() + 1,
    day: (new Date()).getDate()
  };
  model_end_date: NgbDateStruct = {
    year: (new Date()).getFullYear(),
    month: 12,
    day: 31
  };

  categoryValue = "";
  scheduleTypeValue = "";

  @Output() offsetSetting = new EventEmitter<number>();
  @Output() pageCountSetting = new EventEmitter<number>();

  @ViewChild(CartDropdownComponent, { static: true })
  cartDropdownComponent: CartDropdownComponent;

  @Input() offsetValue = 0;
  @Input() countValue = 12;

  @Input() loginCheck = false;
  @Input() withAnonymous = false;
  @Input() loginRedirectUrl = "/";
  @Input() emailVerificationCheck = false;
  @Input() reverseLoginCheck = false;
  @Input() showWhiteLogo:boolean = false;

  constructor(
    private apollo: Apollo,
    private sessionService: SessionService,
    private getCategoryQuery: GetCategoryQuery,
    private getProductListQuery: GetProductListQuery,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private deleteUserCachedTokenMutation: DeleteUserCachedTokenMutation,
    private authService:AuthService
  ) {}

  currentDate = Date.now();
  currentDate1 = new Date((new Date()).getFullYear(), 11, 31);
  selectedStartDate: Date = new Date(this.currentDate);
  selectedEndDate: Date = new Date(this.currentDate1);

  search_start_date = this.selectedStartDate.getFullYear().toString() + "-" + (this.selectedStartDate.getMonth() + 1).toString() + "-" + this.selectedStartDate.getDate().toString();
  search_end_date = this.selectedEndDate.getFullYear().toString() + "-" + (this.selectedEndDate.getMonth() + 1).toString() + "-" + this.selectedEndDate.getDate().toString();
  allCategoryList:ICatogeryGroupList[] = [];

  ngOnInit() {

    var flag = this.redirectLogin();

    if(flag === true) {
      this.redirectEmailVerify();

      this.redirectInverseLogin();
    }

    this.route.paramMap.subscribe(
      params => {
        this.productselectedId = +params.get('id');
      }
    );
    this.getCategoriesData();
    this.getUserData();
    this.productForm = this.formBuilder.group({
      title: [""],
      category: [""],
      scheduletype: [""],
      start_date: [""],
      end_date: [""],
      count: [this.countValue],
    });

    this.categoryValue = "";
    this.scheduleTypeValue = "";

    this.loadSavedSearchCriteria();
    
  //  this.allCategoryList =  this.authService.allCatogeryList.getValue();

  }
  get f() {
    return this.productForm.controls;
  }

  setOffsetValue(value) {
    this.offsetValue = value;
  }

  getUserData() {
    this.userData = this.sessionService.getLocal(SessionConstants.CONST_SESSION_USER_KEYWORD);
  }
  getCategoriesData() {
    this.apollo
      .query({
        query: this.getCategoryQuery.document,
        variables: {
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.getCategoryQuery.populateCategoryGroup(data);
          this.categoryData = this.getCategoryQuery.categoryGroupHeader;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getUserName() {
    this.getUserData();
    let userData = this.userData;
    if (!userData) return false;
    else {
      this.userData = userData;
      let firstName: string = userData["first_name"];
      let lastName: string = userData["last_name"];
      return (
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) +
        " " +
        lastName.charAt(0).toUpperCase() +
        lastName.slice(1)
      );
    }
  }

  redirectLogin() {
    var flag = true;
    if(this.loginCheck == true) {
      let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
      var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if((email === null || email === undefined || email === "") && this.withAnonymous == true) {
        email = anonymousEmail;
      }
  
      if(token == null || token == "" || email == null || email == "") {
        flag = false;
        if(this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate(["/login"]);
      }
    }
    return flag;
  }

  redirectInverseLogin() {
    if(this.reverseLoginCheck == true) {
      let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
      var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if((email === null || email === undefined || email === "") && this.withAnonymous == true) {
        email = anonymousEmail;
      }
  
      if(token != null && token != "" && email != null && email != "") {
        if(this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate([this.loginRedirectUrl]);
      }
    }
  }

  redirectEmailVerify() {
    if(this.emailVerificationCheck == true) {
      let token = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "id_token");
      var email = this.sessionService.getLocalKeyValues(SessionConstants.CONST_SESSION_USER_KEYWORD, "email");
      var emailVerified = this.sessionService.get(SessionConstants.CONST_VERIFIED_EMAIL_KEYWORD);
      var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

      if((email === null || email === undefined || email === "") && this.withAnonymous == true) {
        email = anonymousEmail;
      }

      if(emailVerified === null || emailVerified === undefined || emailVerified === "") {
        emailVerified = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "is_email_verified");;
      }
      
      if(token == null || token == "" || email == null || email == "") {
        if(this.loginRedirectUrl !== "/") {
          this.sessionService.set("redirect_url", this.loginRedirectUrl);
        }
        this.router.navigate(["/login"]);
      }

      if(emailVerified != true && emailVerified != "true" && emailVerified != "True") {
        this.router.navigate(["/resend-email-verification-link/1"]);
      }
    }
  }

  callChildLoadSoftLocks() {
    this.cartDropdownComponent.getProductLocks();
  }

  getCategoryGroup() {
    return this.getCategoryQuery.categoryGroupHeader;
  }


  saveSearchCriteria(){

    this.sessionService.setSession("search_title", this.productForm.controls.title.value);
    this.sessionService.setSession("search_category_group", this.productForm.controls.category.value);
    this.sessionService.setSession("search_schedule_type", this.productForm.controls.scheduletype.value);
    this.sessionService.setSession("search_start_date", this.search_start_date);
    this.sessionService.setSession("search_end_date", this.search_end_date);
  }

  loadSavedSearchCriteria() {
    if(this.sessionService.checkSessionKey("search_title")) {
      this.productForm.patchValue({
        title: this.sessionService.getSession("search_title")
      });
    }

    if(this.sessionService.checkSessionKey("search_start_date")) {
      let tempDate = new Date(this.sessionService.getSession("search_start_date"));
      this.search_start_date = this.sessionService.getSession("search_start_date");
      this.model_start_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    }

    if(this.sessionService.checkSessionKey("search_end_date")) {
      let tempDate = new Date(this.sessionService.getSession("search_end_date"));
      this.search_end_date = this.sessionService.getSession("search_end_date");
      this.model_end_date = {
        year: tempDate.getFullYear(),
        month: tempDate.getMonth() + 1,
        day: tempDate.getDate()
      };
    }
    
    if(this.sessionService.checkSessionKey("search_category_group")) {
      this.categoryValue = this.sessionService.getSession("search_category_group");
    }

    if(this.sessionService.checkSessionKey("search_schedule_type")) {
      this.scheduleTypeValue = this.sessionService.getSession("search_schedule_type");
    }
  }

  onDateSelection(evt: NgbDate, date_type) {
    if(date_type == "start_date") {
      this.search_start_date = evt.year + "-" + evt.month + "-" + evt.day;
    }

    if(date_type == "end_date") {
      this.search_end_date = evt.year + "-" + evt.month + "-" + evt.day;
    }
  }

  productList(button_press = false) {
    let title = this.productForm.controls.title.value;
    let categorygroup = this.productForm.controls.category.value;
    let scheduletype = this.productForm.controls.scheduletype.value;

    this.saveSearchCriteria();

    this.loadSavedSearchCriteria();

    let start_date = this.search_start_date;
    let end_date = this.search_end_date;

    if(button_press == true) {
      this.offsetValue = 0;
    }

    this.apollo
      .query({
        query: this.getProductListQuery.document,
        variables: {
          title_keyword: title,
          category_group: categorygroup,
          schedule_frequency: scheduletype,
          start_date: start_date,
          end_date: end_date,
          offset: this.offsetValue,
          count: this.countValue,
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          console.log("data received");

          if (data["getProductList"]) {
            this.getProductListQuery.populateProductList(data, true);
          }
          this.offsetSetting.emit(this.offsetValue);
          this.pageCountSetting.emit(this.countValue);
        },
        (error) => {
          console.log(error.message);
          this.getProductListQuery.populateProductList(error.message, false);
        }
      );
  }

  extractDateFromDateTime(valDateTime: string, seperator = "-", dayFirst = false) {
    if (valDateTime) {
      var d = new Date(valDateTime),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      if(dayFirst == false) {
        let dateVal = [year, month, day].join(seperator);
        return dateVal;
      } else {
        let dateVal = [day, month, year].join(seperator);
        return dateVal;
      }
    
    } else return "";
  }
}
