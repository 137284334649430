import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class UpsertUserCachedTokenMutation extends Mutation {

  document = gql`
    mutation UpsertUserCachedTokenMutation(
      $user_id: Int!
      $id_token: String!
      $user_email: String!
    ) {
      upsertUserToken(
        user_id: $user_id,
        id_token: $id_token
        user_email: $user_email
      )
    }
  `;
}
