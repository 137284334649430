import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/pipes/pipes.module";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ComponentsModule } from "../../components/components.module";
import { HeaderModule } from "../../layouts/header/header.module";
import { FooterModule } from "src/app/layouts/footer/footer.module";
import { ChartsModule } from 'ng2-charts';

import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NewOfferingsComponent } from "./new-offerings/new-offerings.component";
import { MyOfferingsComponent } from "./my-offerings/my-offerings.component";
import { SidebarModule } from "src/app/layouts/sidebar/sidebar.module";
import { StepperComponent } from "./stepper/stepper.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApproveOfferingComponent } from './approve-offering/approve-offering.component';
import { OfferingReportComponent } from './offering-report/offering-report.component';
import { OfferingCommunicationComponent } from './offering-communication/offering-communication.component';
import { ApproveOfferingEmailComponent } from './approve-offering-email/approve-offering-email.component';
import { OfferingChatComponent } from './offering-chat/offering-chat.component';
import { OfferRatingComponent } from './offer-rating/offer-rating.component';
import { OfferGrievanceComponent } from './offer-grievance/offer-grievance.component';

@NgModule({
  declarations: [NewOfferingsComponent, StepperComponent, MyOfferingsComponent, ApproveOfferingComponent, OfferingReportComponent, OfferingCommunicationComponent, ApproveOfferingEmailComponent, OfferingChatComponent, OfferRatingComponent, OfferGrievanceComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    NgbModule,
    ChartsModule,
    PipesModule,
    SidebarModule,
    CdkStepperModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [NewOfferingsComponent, MyOfferingsComponent, StepperComponent],
})
export class OfferingsModule {}
