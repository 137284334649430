import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss']
})
export class HomeInfoComponent implements OnInit {

  featuresCards:any[] = [
    {
      icon:'fas fa-lock',
      title:'Find and book an Expert/Professional',
      sub_title:'Search for the best advice and guidance from the best Professionals in the Industry and Academics.'
    },
    {
      icon:'fa-sharp fas fa-users',
      title:'Are you an Expert/Professional? Create your Offer for free.',
      sub_title:'You can create your own Offer for free where other users can find you and book your services.'
    },
    {
      icon:'fas fa-piggy-bank',
      title:'Pay safely',
      sub_title:'We don’t send your money to the Offeror till you have utilized the service.'
    },
    {
      icon:'fas fa-list-check',
      title:'Manage your booking and Offers easily',
      sub_title:'Zigwik is like an Operating System that helps you manage your bookings and Offers easily. Get notifications, generate customized Offers, manage all your Offers with Smart Scheduling and much more.'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
