import { Component, OnInit, Input, OnChanges, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { GetProductSchedulesQuery } from "../../services/graphql/getProductSchedules.graphql";
import { Apollo } from "apollo-angular";
import { BehaviorSubject } from "rxjs";
import { SessionService } from "src/app/services/session.service";
import { DeleteScheduleMutation } from "../../services/graphql/deleteSchedule.graphql";
import { ActivateScheduleMutation } from "../../services/graphql/activateSchedule.graphql";
import { ActivateProductMutation } from "../../services/graphql/activateProduct.graphql";
import { DeactivateProductMutation } from "../../services/graphql/deactivateProduct.graphql";
import { DeactivateScheduleMutation } from "../../services/graphql/deactivateSchedule.graphql";
import { ToastrService } from 'ngx-toastr';
import { GetProductDetailsQuery } from 'src/app/services/graphql/getProductDetails.graphql';
import { NewOfferingsComponentInterface } from "../../pages/offerings/new-offerings/new-offerings.component";

import {
  faUser,
  faAddressBook,
  faCreditCard,
  faChevronLeft,
  faChevronRight,
  faSave,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

interface ScheduleInt {
  schedule_name: string;
  schedule_frequency: string;
  start_date: string;
  end_date: string;
  status: number;
  schedule_id: number;

}

@Component({
  selector: 'app-activate-product',
  templateUrl: './activate-product.component.html',
  styleUrls: ['./activate-product.component.scss']
})


export class ActivateProductComponent implements OnInit {

  @Input() 
  productChanged = new BehaviorSubject<boolean> (
    false
  );

  @Input()
  isEditable = true;

  @Input()
  productID = null;

  @Input()
  newProductID = new BehaviorSubject<number> (
    0
  );

  @Input() 
  newOfferingInterface: NewOfferingsComponentInterface;

  schedules = new BehaviorSubject<Array<ScheduleInt>>(
    []
  );

  public scheduleAvailable = new BehaviorSubject<boolean>(
    false
  );

  productTitle = "";
  productStatus = null;

  faPerson = faUser;
  faAddressBook = faAddressBook;
  faCreditCard = faCreditCard;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSave = faSave;

  faBan = faBan;

  frmReview = this.fb.group({});

  constructor(private fb: FormBuilder,
    private getProductSchedulesQuery: GetProductSchedulesQuery,
    private apollo: Apollo,
    private sessionService: SessionService,
    private deleteScheduleMutation: DeleteScheduleMutation,
    private toastr: ToastrService,
    private activateScheduleMutation: ActivateScheduleMutation,
    private deactivateScheduleMutation: DeactivateScheduleMutation,
    private getProductDetailsQuery: GetProductDetailsQuery,
    private activateProductMutation: ActivateProductMutation,
    private deactivateProductMutation: DeactivateProductMutation,
    private changeDetection: ChangeDetectorRef
    ) { }

updateSchedule(scheduleId: number) {
  this.newOfferingInterface.updateSchedule(scheduleId);
}

querySchedules() {
  let token = this.sessionService.getKeyValues("token", "id_token");
  let email = this.sessionService.getLocalKeyValues("user", "email");
  if(this.productID != null && this.productID != 0) {
    this.apollo
    .query({
      query: this.getProductSchedulesQuery.document,
      fetchPolicy: "network-only",
      variables: {
        productId: <number>this.productID,
        idToken: token,
getDaySlots: false,
        lenderEmail: email
      }
    }).subscribe(
      ({ data }) => {
        this.schedules.next(data["getProductSchedules"]);
        if(data["getProductSchedules"].length > 0) {
          this.scheduleAvailable.next(true);
        } else {
          this.scheduleAvailable.next(false);
        }
      });
  }
}

  ngOnInit(): void {
    this.querySchedules();
    this.productDetail();

    this.productChanged.subscribe(
      (data) => {
        if(data === true) {
          this.newProductID.subscribe(
            (data) => {
              if(data != null && data != 0) {
                this.productID = data;
                this.productDetail();
              }
            }
          )
        }
      }
    );
  }

  toggleScheduleStatus(scheduleId: number, scheduleStatus: number) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let activateSchedule = "false";

    if(scheduleStatus == 1) {
      activateSchedule = "false";
    } else {
      activateSchedule = "true";
    }


    if(activateSchedule == "true") {
      this.apollo.query({
        query: this.activateScheduleMutation.document,
        variables: {
          product_id: <number>this.productID,
          id_token: token,
          lender_email: email,
          schedule_id: scheduleId
        }
      }).subscribe(
        ({ data }) => {
          if(data["activateProductSchedule"] == "true") {
            this.querySchedules();
            this.toastr.success("Request completed.");
            this.changeDetection.detectChanges();
          } else {
            this.toastr.error("Request was not successful. The schedule was not activated because the schedule have slots that are not valid.");
          }
          
        },
        (error) => {
          this.toastr.error(error.message);
        });
    } else if(activateSchedule == "false") {
      this.apollo
      .query({
        query: this.deactivateScheduleMutation.document,
        variables: {
          product_id: <number>this.productID,
          id_token: token,
          lender_email: email,
          schedule_id: scheduleId
        }
      }).subscribe(
        ({ data }) => {
          if(data["forcedDeactivationProductSchedule"] == "true") {
            this.querySchedules();
            this.toastr.success("Request completed.");
            this.changeDetection.detectChanges();
          } else {
            this.toastr.error("Schedule was not deactivated because of some unknown issue.");
          }
          
        },
        (error) => {
          this.toastr.error(error.message);
        });
    }
  }

  toggleProductStatus() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    let activateProduct = "false";

    if(this.productStatus == 1) {
      activateProduct = "false";
    } else {
      activateProduct = "true";
    }


    if(activateProduct == "true") {
      this.apollo.mutate({
        mutation: this.activateProductMutation.document,
        variables: {
          product_id: <number>this.productID,
          id_token: token,
          lender_email: email
        }
      }).subscribe(
        ({ data }) => {
          if(data["activateProduct"] == "True") {
            this.productStatus = 1;
            this.toastr.success("Request completed.");
          } else {
            this.toastr.error("Request was not completed successfully.");
          }
        },
        (error) => {
          this.toastr.error(error.message);
        });
    } else if(activateProduct == "false") {
      this.apollo
      .mutate({
        mutation: this.deactivateProductMutation.document,
        variables: {
          product_id: <number>this.productID,
          id_token: token,
          lender_email: email
        }
      }).subscribe(
        ({ data }) => {
          if(data["forcedDeactivateProduct"] == "true") {
            this.productStatus = 0;
            this.toastr.success("Request completed."); 
          } else {
            this.toastr.error("Request was not completed successfully.");
          }
        },
        (error) => {
          this.toastr.error(error.message);
        });
    }
  }

  deleteSchedule(scheduleId: number) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .mutate({
      mutation: this.deleteScheduleMutation.document,
      variables: {
        product_id: <number>this.productID,
        id_token: token,
        lender_email: email,
        schedule_id: scheduleId
      }
    }).subscribe(
      ({ data }) => {
        this.querySchedules();
        this.toastr.success("The schedule is now successfully marked for deletion.");
        this.changeDetection.detectChanges();
      },
      (error) => {
        this.toastr.error(error.message);
      });
  }

  productDetail() {
    let productSelectedId = this.productID;
    let idtoken = this.sessionService.getKeyValues("token", "id_token");
    let useremail = this.sessionService.getLocalKeyValues("user", "email");

    if(productSelectedId != null && productSelectedId != 0) {
      this.apollo
      .query({
        query: this.getProductDetailsQuery.document,
        variables: {
          product_id: productSelectedId,
          id_token: idtoken,
          lender_email: useremail
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["getProductDetails"]) {
            this.productStatus = data["getProductDetails"]["status"];
            this.productTitle = data["getProductDetails"]["title"];
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
  }

}
