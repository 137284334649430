<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!-- login Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Forgot Password" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>

<section class="pro-content login-content center-content-page">
  <div class="container">
    <div class="row justify-content-center">
      <div class="pro-heading-title">
        <h1>
          {{ "Forgot Password" | translate }}
        </h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-12 col-md-8 col-lg-5 mb-5 px-0">
        <div class="tab-content" id="registerTabContent">
          <div class="tab-pane active">
            <div class="registration-process" *ngIf="!isPasswordResetSuccess">
              <form [formGroup]="changePasswordForm">
                <div class="from-group mb-4" *ngIf="isPasswordResetFail">
                  <div class="input-group col-12">
                    <div class="alert alert-danger">
                      <div>
                        Unable to change password. Please try again later.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="from-group mb-4">
                  <div class="input-group col-12">
                    <input
                      type="text"
                      name="email"
                      class="form-control"
                      formControlName="email"
                      placeholder="email"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    />
                    <div
                      *ngIf="submitted && f.email.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="submitted && f.email.errors.required">
                        Email is required
                      </div>
                      <div *ngIf="f.email.errors.email">
                        Email must be a valid email address
                      </div>
                    </div>
                  </div>
                </div>
                <div class="from-group mb-4">
                  <div class="input-group col-12">
                    <input
                      type="text"
                      name="confirmemail"
                      class="form-control"
                      formControlName="confirmemail"
                      placeholder="confirm email"
                      [ngClass]="{
                        'is-invalid': submitted && f.confirmemail.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.confirmemail.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="submitted && f.confirmemail.errors.required">
                        Email is required
                      </div>
                      <div *ngIf="f.confirmemail.errors.email">
                        Email must be a valid email address
                      </div>
                      <div *ngIf="f.confirmemail.errors.mustMatch">
                        Email must match
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <button
                    class="btn btn-secondary swipe-to-top"
                    (click)="changePassword()"
                  >
                    {{ "Change" | translate }}
                  </button>
                </div>
              </form>
            </div>
            <div class="registration-process" *ngIf="isPasswordResetSuccess">
              <div class="from-group mb-4">
                <div class="col-12 col-sm-12">
                  <div class="alert alert-success">
                    <div>An email sent to your email id.</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <button
                    class="btn btn-secondary swipe-to-top"
                    (click)="gotoLogin()"
                  >
                    {{ "Go To Login" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-back-to-top></app-back-to-top>
