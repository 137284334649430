
<ng-template #customDay let-date let-currentMonth="currentMonth" let-disabled="disabled">
	<span class="custom-day" [class.week-first-day]="isFirstDay(date)" [class.weekend]="isWeekend(date)"
     [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled">
        <div class="custom-table">
        <ng-template [ngIf]="isFirstDay(date)">
        <div>
          <div class="week-box orange"><p>{{ week_price | curency }}</p></div>
        </div>
        </ng-template>
        <div class="custom-td">
          <div class="day-box">
          <div>
            <div>
            <div>
              {{ date.day }}
            </div>
          </div>
        <div>
          <ng-template [ngIf]="priceExists(date)">
          <div class="day-price-table">
        {{ day_price | curency }}
          </div>
          <div class="hour-price-table">
            {{ hour_price | curency }}
              </div>
        </ng-template>
        </div>
      </div>
      </div>
    </div>
  </div>
  </span>
</ng-template>


<div  id="viewonly-calendar-{{ productId }}" class="productdatepicker" #productdatepicker>
    <ngb-datepicker [dayTemplate]=customDay [outsideDays]=outsideDays [minDate]="minDate" #dt (navigate)="navi($event.next)">
    </ngb-datepicker>
</div>
    <!-- <button
    (click)="toggle(productId)"
    class="icon active swipe-to-top"
    >
    <i class="fas fa-calendar-alt"></i>
    </button> -->