import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { KatoraService } from 'src/app/services/katora.service';
import { BehaviorSubject } from "rxjs";
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';
import { RemoveSoftLockMutation } from 'src/app/services/graphql/removeSoftLock.graphql';
import { LoginUserMutation } from 'src/app/services/graphql/login.graphql';
import { AttachClpCouponOfferMutation } from 'src/app/services/graphql/attachClpCouponOffer.graphql';
import { UpdateAnonymousUserMutation } from 'src/app/services/graphql/updateAnonymousUser.graphql';
import { DetachClpCouponOfferMutation } from 'src/app/services/graphql/detachClpCouponOffer.graphql';
import { ZeroPaymentRedirectionMutation } from 'src/app/services/graphql/zeroPaymentRedirection.graphql';
import { CreateProductTransactionTokenMutation } from 'src/app/services/graphql/createProductTransactionToken.graphql';
import { SearchAreaQuery } from "../../../services/graphql/searchArea.graphql";
import { GetAreaDetailsQuery } from "../../../services/graphql/getAreaDetails.graphql";
import { SessionService } from 'src/app/services/session.service';
import { ResendVerificationLinkMutation } from 'src/app/services/graphql/resendVerificationLink.graphql';
import { EventConstants, ApplicationConstants, SessionConstants } from 'src/app/constants/app.constants';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

interface ProductSoftLockSlot {
  actual_date: string;
  day_template_id: number;
  slot_hours: number;
  slot_minutes: number;
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  schedule_slot_id: number;
  day_template_slot_id: number;
}

interface ProductSoftLock {
  schedule_frequency: string;
  start_date: string;
  end_date: string;
  offeror_id: number;
  product_id: number;
  offeree_id: number;
  category_id: number;
  product_description: string;
  title: string;
  address_id: number;
  product_image_link: string;
  age: number;
  guidelines: string;
  product_status: number;
  price: number;
  actual_price: number;
  schedule_id: number;
  deposit: number;
  calculated_deposit: number;
  id: number;
  lock_type: string;
  slots: Array<ProductSoftLockSlot>;

}

@Component({
  selector: 'app-cart-one',
  templateUrl: './cart-one.component.html',
  styleUrls: ['./cart-one.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartOneComponent implements OnInit {
  
  public productTotal = new BehaviorSubject<number>(
    0
  );
  public cartCount;
  anonymousUserDetails = null;
  user_id = null;
  loading = true;
  submitted: boolean = false;
  @ViewChild('fillRequiredDetails') fillRequiredDetails: any;
  @ViewChild('verifyEmail') verifyEmail: any;
  userDetails = null;
  closeDetailsModal = "";
  modalReference:any = null;
  areaSessionToken: null;
  areas: [];
  couponCodeLoadFlag = new BehaviorSubject<boolean>(false);
  couponCodeAsync = new BehaviorSubject<string>("");
  lockIds = [];
  defaultProductImage = EventConstants.CONST_DEFAULT_OFFER_IMAGE;

  productAddedToCart = new BehaviorSubject<Array<ProductSoftLock>>(
    []
  );
  getUserForm: FormGroup;

  get f() {
    return this.getUserForm.controls; }

  constructor(
    private katoraService : KatoraService,
    private apollo: Apollo,
    private getProductLocksQuery: GetProductLocksQuery,
    private removeSoftLockMutation: RemoveSoftLockMutation,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private createProductTransactionTokenMutation: CreateProductTransactionTokenMutation,
    private attachClpCouponOfferMutation: AttachClpCouponOfferMutation,
    private detachClpCouponOfferMutation: DetachClpCouponOfferMutation,
    private updateAnonymousUserMutation: UpdateAnonymousUserMutation,
    private zeroPaymentRedirectionMutation: ZeroPaymentRedirectionMutation,
    private resendVerificationLinkMutation: ResendVerificationLinkMutation,
    private loginUserMutation: LoginUserMutation,
    private searchAreaQuery: SearchAreaQuery,
    private getAreaDetailsQuery: GetAreaDetailsQuery,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder
  ){
    this.getUserForm = this.formBuilder.group({
      firstname: [""],
      lastname: [""],
      email: ["", [Validators.required]],
      contact: ["", [Validators.required]],
      addressone: ["", [Validators.required]],
      addresstwo: [""],
      landmark: [""],
      pincode: ["", [Validators.required]],
      area: ["", [Validators.required]],
      city: ["", [Validators.required]],
      district: ["", [Validators.required]],
      state: ["", [Validators.required]]
       });
  }
 
  
  ngOnInit() {
    this.user_id = this.sessionService.getKeyValues("token", "id");
    this.getProductLocks();     

    this.userDetails = this.sessionService.getLocal(SessionConstants.CONST_SESSION_USER_KEYWORD);
    this.anonymousUserDetails = this.sessionService.getLocal("anonymousUser");
    this.patchFormValues();
  }

  onKeyupAddress($evt) {
    this.areas = [];
    var text = $evt.target.value;
    if(text.length > 2) {
      this.apollo
      .query({
        query: this.searchAreaQuery.document,
        variables: {
          sessionToken: this.areaSessionToken,
          titleKeyword: text
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          if(data["searchAreas"] != null && data["searchAreas"].length > 0) {
            this.areaSessionToken = data["searchAreas"][0]["session_token"];
            this.areas = data["searchAreas"];

            this.autocomplete(document.getElementById("searchArea"), this.areas);
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    }
  }

  autocomplete(inp, arr) {

    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = (x.length - 1);
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt = null) {
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    var a, b, i, val = inp.value;
    /*close any already open lists of autocompleted values*/
    closeAllLists();
    if (!val) { return false;}
    currentFocus = -1;
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");
    /*append the DIV element as a child of the autocomplete container:*/
    inp.parentNode.appendChild(a);
    /*for each item in the array...*/
    for (i = 0; i < arr.length; i++) {
      /*check if the item starts with the same letters as the text field value:*/
      // if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
        /*create a DIV element for each matching element:*/
        b = document.createElement("DIV");
        /*make the matching letters bold:*/
        b.innerHTML = arr[i]["area_information"];
        /*insert a input field that will hold the current array item's value:*/
        b.innerHTML += "<input type='hidden' value='" + arr[i]["area_information"] + "'>";
        /*execute a function when someone clicks on the item value (DIV element):*/

        // areaClickEventHandler(event, information, areaId, placeId) {

        b.addEventListener(
          "click", 
          this.areaClickEventHandler.bind(
            null, 
            arr[i]["area_information"], 
            arr[i]["area_id"], 
            arr[i]["place_id"], 
            this.getAreaDetails, 
            this.apollo, 
            this.getAreaDetailsQuery, 
            this.areaSessionToken, 
            this.closeAllListsDef,
            this.getUserForm), false);
        a.appendChild(b);
      // }
    }
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function(e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        var y = null;
        if (x) {
          y = x.getElementsByTagName("div");
          x = y;
        }
        if (e.keyCode == 40) {
          /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/
          currentFocus++;
          /*and and make the current item more visible:*/
          addActive(x);
        } else if (e.keyCode == 38) { //up
          /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
          currentFocus--;
          /*and and make the current item more visible:*/
          addActive(x);
        } else if (e.keyCode == 13) {
          /*If the ENTER key is pressed, prevent the form from being submitted,*/
          e.preventDefault();
          if (currentFocus > -1) {
            /*and simulate a click on the "active" item:*/
            if (x) x[currentFocus].click();
          }
        }
    });
    
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
  }

  closeAllListsDef(elmnt = null) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != document.getElementById("searchArea")) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }

  getAreaDetails(areaId: number, placeId: number, apollo, getAreaDetailsQuery, sessionToken, getUserForm) {
    apollo
      .query({
        query: getAreaDetailsQuery.document,
        variables: {
          sessionToken: sessionToken,
          areaId: areaId,
          placeId: placeId
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {

          getUserForm.patchValue({
            area: data["getAreaDetails"]["area_name"],
            pincode: data["getAreaDetails"]["pin_code"],
            city: data["getAreaDetails"]["city"],
            district: data["getAreaDetails"]["district"],
            state: data["getAreaDetails"]["state"],
            landmark: "",
            addressone: "",
            addresstwo: ""
          });
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  patchFormValues() {
    if(this.anonymousUserDetails != null) {
      this.getUserForm.patchValue({
        firstname: this.anonymousUserDetails["first_name"],
        lastname: this.anonymousUserDetails["last_name"],
        email: this.anonymousUserDetails["email"],
        contact: this.anonymousUserDetails["phone"],
        addressone: this.anonymousUserDetails["address_line1"],
        addresstwo: this.anonymousUserDetails["address_line2"],
        area: this.anonymousUserDetails["area_name"],
        landmark: this.anonymousUserDetails["landmark"],
        pincode: this.anonymousUserDetails["pin_code"],
        city: this.anonymousUserDetails["city"],
        district: this.anonymousUserDetails["district"],
        state: this.anonymousUserDetails["state"]
      });
    }
  }

  loadUserDetailsToLocalStorage() {
    var data = {
      "first_name": this.getUserForm.controls.firstname.value,
      "last_name": this.getUserForm.controls.lastname.value,
      "email": this.getUserForm.controls.email.value,
      "phone": String(this.getUserForm.controls.contact.value).trim(),
      "address_line1": this.getUserForm.controls.addressone.value,
      "address_line2": this.getUserForm.controls.addresstwo.value,
      "area_name": this.getUserForm.controls.area.value,
      "landmark": this.getUserForm.controls.landmark.value,
      "pin_code": this.getUserForm.controls.pincode.value,
      "city": this.getUserForm.controls.city.value,
      "district": this.getUserForm.controls.district.value,
      "state": this.getUserForm.controls.state.value
    };
    this.sessionService.setLocal("anonymousUser", data);
    this.anonymousUserDetails = data;
  }

  updateAnonymousUser() {
    document.getElementById("anonymousUserUpdateButton").classList.add("is-loading");
    (<HTMLInputElement>document.getElementById("anonymousUserUpdateButton")).disabled = true;
    let token = this.sessionService.getKeyValues("token", "id_token");

    this.submitted = true;
    if (this.getUserForm.invalid) {
      console.log("invalid form");
      return;
    }

    var dirtyFields = false;

    if(this.getUserForm.controls.contact.value != null && this.getUserForm.controls.contact.value != "") {
      if(String(this.getUserForm.controls.contact.value).trim().length != 10) {
        this.getUserForm.get('contact').setErrors({'incorrect': true});
        this.toastr.error("The Mobile number should be of 10 digits.");
        dirtyFields = true;
      }
    } else {
      this.toastr.error("The Mobile number is required.");
      this.getUserForm.get('contact').setErrors({'incorrect': true});
      dirtyFields = true;
    }

    const emailRegex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");

      if(this.getUserForm.controls.email.value == null || this.getUserForm.controls.email.value == "" || emailRegex.test(this.getUserForm.controls.email.value) == false ) {
        this.getUserForm.get('email').setErrors({'incorrect': true});
        this.toastr.error("The Email ID is incorrect.");
        dirtyFields = true;
      }
  
      if(this.getUserForm.controls.firstname.value == null || this.getUserForm.controls.firstname.value == "" ) {
        this.getUserForm.get('firstname').setErrors({'incorrect': true});
        this.toastr.error("Your First Name is incorrect.");
        dirtyFields = true;
      }
  
      if(this.getUserForm.controls.lastname.value == null || this.getUserForm.controls.lastname.value == "" ) {
        this.getUserForm.get('lastname').setErrors({'incorrect': true});
        this.toastr.error("Your Last Name is incorrect.");
        dirtyFields = true;
      }

      if(dirtyFields == true) {
        document.getElementById("anonymousUserUpdateButton").classList.remove("is-loading");
        (<HTMLInputElement>document.getElementById("anonymousUserUpdateButton")).disabled = false;
        return;
      }

      this.apollo
      .mutate({
        mutation: this.updateAnonymousUserMutation.document,
        variables: {
          id_token: token,
          first_name: this.getUserForm.controls.firstname.value,
          last_name: this.getUserForm.controls.lastname.value,
          verify_email: "True",
          email: this.getUserForm.controls.email.value,
          phone: String(this.getUserForm.controls.contact.value).trim(),
          address_line1: this.getUserForm.controls.addressone.value,
          address_line2: this.getUserForm.controls.addresstwo.value,
          area_name: this.getUserForm.controls.area.value,
          landmark: this.getUserForm.controls.landmark.value,
          pin_code: this.getUserForm.controls.pincode.value,
          city: this.getUserForm.controls.city.value,
          district: this.getUserForm.controls.district.value,
          state: this.getUserForm.controls.state.value
        },
      })
      .subscribe(
        ({ data }) => {
          if(data != null) {
            if(data["updateAnonymousUser"]["is_email_verified"] != true || data["updateAnonymousUser"]["is_email_verified"] != "true") {
              this.toastr.error("Please verify your account before continuing");  
            } else {
              this.toastr.success("We got your details. You can now proceed to place your order.");
            }

            this.loadUserDetailsToLocalStorage();
            
            this.sessionService.setLocal("validAnonymousUser", "true");
            let loginTime = (new Date()).getTime();
            data["updateAnonymousUser"]["login_time"] = loginTime;
            data["updateAnonymousUser"]["valid_user"] = true;
            this.sessionService.setLoginContent(data["updateAnonymousUser"]);
          }

          document.getElementById("anonymousUserUpdateButton").classList.remove("is-loading");
          (<HTMLInputElement>document.getElementById("anonymousUserUpdateButton")).disabled = false;

        },
        (error) => {
          document.getElementById("anonymousUserUpdateButton").classList.remove("is-loading");
          (<HTMLInputElement>document.getElementById("anonymousUserUpdateButton")).disabled = false;
          if(String(error.message).includes("Internal server error")) {
            this.toastr.error(String(error.graphQLErrors[0]["debugMessage"]).replace(/_/g, " "));
          } else {
            this.toastr.error(error.message);
          }
        }
      );
  }

  areaClickEventHandler(
    information, 
    areaId, 
    placeId, 
    getAreaDetails, 
    apollo, 
    getAreaDetailsQuery, 
    sessionToken, 
    closeAllListsDef,
    getUserForm, 
    event) {
    /*insert the value for the autocomplete text field:*/
    (<HTMLInputElement>document.getElementById("searchArea")).value = information;
    getAreaDetails(areaId, placeId, apollo, getAreaDetailsQuery, sessionToken, getUserForm);
    /*close the list of autocompleted values,
    (or any other open lists of autocompleted values:*/
      closeAllListsDef();
  }

  applyCouponCode() {
    document.getElementById("coupon-code").classList.add("is-loading");
    
    var couponCode = (<HTMLInputElement>document.getElementById("couponCodeInput")).value;
    if(couponCode == null || couponCode == "") {
      document.getElementById("coupon-code").classList.remove("is-loading");
      this.toastr.error("Please enter a valid coupon code.");
      return;
    }

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .mutate({
      mutation: this.attachClpCouponOfferMutation.document,
      variables: {
        id_token: token,
        user_email: email,
        lock_ids: this.lockIds,
        coupon_code: couponCode
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["attachClpCouponOffer"] != null) {
          this.getProductLocks();
          this.couponCodeLoadFlag.next(true);
          this.couponCodeAsync.next(couponCode);

          (<HTMLInputElement>document.getElementById("couponCodeInput")).value = "";
          document.getElementById("coupon-code").classList.remove("is-loading");
          this.toastr.success("Coupoon code applied successfully.");
        } else {
          this.toastr.error("Invalid coupon code or coupon code already used.");
          document.getElementById("coupon-code").classList.remove("is-loading");
        }
      },
      (error) => {
        this.toastr.error("Invalid coupon code or coupon code already used.");
        document.getElementById("coupon-code").classList.remove("is-loading");
      }
    );
  }

  removeCouponCode() {
    document.getElementById("coupon-code").classList.add("is-loading");
    
    var couponCode = (<HTMLInputElement>document.getElementById("couponCodeSpan")).innerText;
    if(couponCode == null || couponCode == "") {
      document.getElementById("coupon-code").classList.remove("is-loading");
      this.toastr.error("Please enter a valid coupon code.");
      return;
    }

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .mutate({
      mutation: this.detachClpCouponOfferMutation.document,
      variables: {
        id_token: token,
        user_email: email,
        lock_ids: this.lockIds
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["detachClpCouponOffer"] != null) {
          this.getProductLocks();
          this.couponCodeLoadFlag.next(false);
          this.couponCodeAsync.next("");

          (<HTMLInputElement>document.getElementById("couponCodeInput")).value = "";
          document.getElementById("coupon-code").classList.remove("is-loading");
        } else {
          this.toastr.error("Invalid coupon code.");
          document.getElementById("coupon-code").classList.remove("is-loading");
        }
      },
      (error) => {
        document.getElementById("coupon-code").classList.remove("is-loading");
        console.log(error);
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

    openModal(content) {
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    this.modalReference.result.then((result) => {
      this.closeDetailsModal = `Closed with: ${result}`;
    }, (reason) => {
      this.closeDetailsModal = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getProductLocks() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    if(token != null) {
    this.apollo
    .query({
      query: this.getProductLocksQuery.document,
      variables: {
        id_token: token,
        user_email: email,
        user_type: "offeree",
        lock_type: "soft_lock"
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["getProductLocks"] != null) {
          this.productAddedToCart.next(data["getProductLocks"]);
          let totalPrice = 0;
          this.lockIds = [];
            data["getProductLocks"].forEach(productLock => {
            this.lockIds.push(productLock.id);
              if(productLock.discounted_price != null && productLock.discounted_price > 0) {
                totalPrice += productLock.discounted_price;
                this.couponCodeLoadFlag.next(true);
                this.couponCodeAsync.next(productLock.coupon_code);
              } else {
              totalPrice += productLock.price;
              }
          });

          this.productTotal.next(totalPrice);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  }

  removeProductSoftLock(product_lock_id: number){
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
    .mutate({
      mutation: this.removeSoftLockMutation.document,
      variables: {
        id_token: token,
        offeree_email: email,
        lock_id: product_lock_id
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["removeProductSoftLock"] != null) {
          this.getProductLocks();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openModalWithValues() {
    this.patchFormValues();
    this.openModal(this.fillRequiredDetails);
  }

  resendEmailLink() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");
    var email = this.sessionService.getLocalKeyValues("user", "email");

    if(email == null || email == "") {
      email = anonymousEmail;
    }

    this.apollo
      .mutate({
        mutation: this.resendVerificationLinkMutation.document,
        variables: {
          email: email,
          id_token: token
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Verification link is successfully sent to your email id.");
          this.modalReference.close();
        },
        (error) => {
          if(String(error.message).includes("Internal server error")) {
            this.toastr.error(String(error.graphQLErrors[0]["debugMessage"]).replace(/_/g, " "));
          } else {
            this.toastr.error(error.message);
          }
        }
      );
  }

  async redirectPaymentGateway() {

    let token = this.sessionService.getKeyValues("token", "id_token");
    var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");
    var email = this.sessionService.getLocalKeyValues("user", "email");
    let user_address_id = this.sessionService.getLocalKeyValues("user", "address_id");
    let validAnonymousUser = this.sessionService.getLocal("validAnonymousUser");
    let refresh_token = this.sessionService.get("refresh_token");

    var loginInfo = null;

    if((email == null || email == "") && (validAnonymousUser != "true")) {
      this.openModalWithValues();
      return;
    }

    if(refresh_token != null && refresh_token != "") {
      loginInfo = await this.apollo
          .mutate({
            mutation: this.loginUserMutation.document,
            variables: {
              email: email,
              refresh_token: refresh_token
            },
            fetchPolicy: "network-only"
          }).toPromise();

      if(loginInfo != null && 
        loginInfo["data"] != null && 
        loginInfo["data"]["loginUser"] != null && 
        loginInfo["data"]["loginUser"]["is_email_verified"] != true && 
        loginInfo["data"]["loginUser"]["is_email_verified"] != "true" && 
        loginInfo["data"]["loginUser"]["is_email_verified"] != "True") {
        this.openModal(this.verifyEmail);
        return;
      }
    } else {
      this.toastr.error("Please login to continue.");
      return;
    }

    (<HTMLInputElement>document.getElementById("placeOrderButton")).disabled = true;    

    var lock_ids = [];

    this.productAddedToCart.getValue().forEach(productLock => {
      lock_ids.push({"id": productLock.id});
    });

    if(ApplicationConstants.CONST_ADDRESS_CHECK_FOR_OFFER_BOOKING === "true" && (user_address_id == null || user_address_id == 0) && validAnonymousUser != "true") {
      alert("Please update your address before continuing. You can do that by going to your profile and updating your address.");
      (<HTMLInputElement>document.getElementById("placeOrderButton")).disabled = false;
      return;
    }

    if(email == null || email == "") {
      email = anonymousEmail;
    }

    this.apollo
    .mutate({
      mutation: this.createProductTransactionTokenMutation.document,
      variables: {
        id_token: token,
        offeree_email: email,
        lock_ids: lock_ids,
        payment_gateway: EventConstants.CONST_PAYMENT_GATEWAY,
        callback_url: EventConstants.CONST_PAYMENT_REDIRECTION_URL
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["createProductTransactionToken"] != null) {
          let inputData = data["createProductTransactionToken"];

          if(inputData["price"] == 0) {
            this.apollo
            .mutate({
              mutation: this.zeroPaymentRedirectionMutation.document,
              variables: {
                id_token: token,
                email: email,
                order_number: inputData["shared_order_id"]
              },
              fetchPolicy: "network-only"
            })
            .subscribe(
              ({ data }) => {
                if(data["handleZeroTransaction"] != null) {
                  (<HTMLInputElement>document.getElementById("placeOrderButton")).disabled = false;
                  this.router.navigate(["/thank-you/" + data["handleZeroTransaction"]]);
                }
              },
              (error) => {
                this.toastr.error(error.message);
                (<HTMLInputElement>document.getElementById("placeOrderButton")).disabled = false;    
              }
            );

          } else {
          var myform = document.createElement('form');
          myform.method = 'POST';
          myform.action = EventConstants.CONST_PAYMENT_GATEWAY_URL;
          myform.style.display = 'none';
          
          let encRequest: HTMLInputElement=<HTMLInputElement>document.createElement('INPUT');
          encRequest.type='HIDDEN';
          encRequest.name='encRequest';
          encRequest.value=inputData["checksum"];
          myform.appendChild(encRequest);

          let access_code: HTMLInputElement=<HTMLInputElement>document.createElement('INPUT');
          access_code.type='HIDDEN';
          access_code.name='access_code';
          access_code.value=EventConstants.CONST_PAYMENT_GATEWAY_ACCESS_CODE;
          myform.appendChild(access_code);

          document.body.appendChild(myform);
          myform.submit();
        }
        }
      },
      (error) => {
        this.toastr.error(error.message);
        (<HTMLInputElement>document.getElementById("placeOrderButton")).disabled = false;    
      }
    );
  }

  ngAfterContentChecked(){
    console.log("No Entry");
  }
  
  addQuantity(product: any){
    this.katoraService.onAddQuantity(product);

  }
  removeQuantity(product: any){
    
    this.katoraService.onRemoveQuantity(product);
     
  }

  

  removeProduct(product: any){
    this.katoraService.removeProductFromCart(product);
  }
}
