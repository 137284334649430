import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ApplicationConstants } from 'src/app/constants/app.constants';
import { MustMatch } from 'src/app/helpers/validators/mustmatch.validator';
import { ForgetPasswordMutation } from 'src/app/services/graphql/forgetpassword.graphql';
import { SessionService } from 'src/app/services/session.service';
import { SessionConstants } from 'src/app/constants/app.constants';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm1: FormGroup;
  submitted = false;
  messageString = "";
  isLoggedIn = false;
  profileImageLink: string = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;

  first_name = "";
  last_name = "";
  email = "";

  hashed_user_id = btoa(this.sessionService.getKeyValues("token", "id"));

 constructor(private forgetPasswordMutation: ForgetPasswordMutation,
    private router: Router,
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
    private apollo: Apollo  
    ) {
    var usersdata = this.sessionService.getLocal("user");
    if(usersdata == null) {
      usersdata = this.sessionService.getLocal("anonymousUser");
    }

    this.first_name = usersdata["first_name"];
    this.last_name = usersdata["last_name"];
    this.email = usersdata["email"];

    if(usersdata["profile_image_name"] !== null && usersdata["profile_image_name"] !== "" && usersdata["profile_image_name"] !== undefined) {
      this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_MASTER_URL + usersdata["profile_image_name"];
    } else {
      this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
    }
  }

  get f() {
    return this.changePasswordForm1.controls;
  }
  ngOnInit() {
    this.changePasswordForm1 = this.formBuilder.group(
      {
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
        confirmemail: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
      },
      {
        validators: MustMatch("email", "confirmemail"),
      }
    );
  }
changePassword() {
    this.submitted = true;
    if (this.changePasswordForm1.invalid) {
      return;
    }
    
    var useremail = this.sessionService.getLocalKeyValues("user", "email");
    var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

    if(useremail === null || useremail === undefined || useremail === "") {
      useremail = anonymousEmail;
    }


    this.apollo
      .mutate({
        mutation: this.forgetPasswordMutation.document,
        variables: {
          email: this.changePasswordForm1.controls.email.value,
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["resetUserPassword"] === "True") {
            if (this.changePasswordForm1.controls.email.value == useremail) {
              this.isLoggedIn = true;
              this.messageString = ApplicationConstants.CONST_MESSAGE_PASSWORD_CHANGE_SUCCESS;
            }
            else {
              this.messageString = ApplicationConstants.CONST_MESSAGE_PASSWORD_CHANGE;
              // this.isLoggedIn = false;
              this.resetForm();
            }
          }
        },
        (error) => {
          this.isLoggedIn = false;
          this.messageString = ApplicationConstants.CONST_MESSAGE_EMAIL_NOT_FOUND;
        }
      );
  }
  private resetForm() {
    this.changePasswordForm1.reset();
  }
  gotoLogin() {
    this.router.navigate(["/login"]);
  }
}
