import { Component, OnInit } from "@angular/core";
import { KatoraService } from "src/app/services/katora.service";
import { Apollo } from "apollo-angular";
import { SessionService } from "../../services/session.service";
import { LogoutUserMutation } from "../../services/graphql/logout.graphql";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: "app-account-dropdown",
  templateUrl: "./account-dropdown.component.html",
  styleUrls: ["./account-dropdown.component.scss"],
})
export class AccountDropdownComponent implements OnInit {
  public wishlistCount;

  constructor(
    private apollo: Apollo,
    private katoraService: KatoraService,
    private sessionService: SessionService,
    private logoutService: LogoutUserMutation,
    private router: Router,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {}
  ngAfterContentChecked() {
    this.wishlistCount = this.katoraService.getWishlistCount();
  }

  logout() {
    let loginSessionData = this.sessionService.get("token");
    let loginUserData = this.sessionService.getLocal("user");
    let email = loginUserData["email"];
    let idToken = loginSessionData["id_token"];
    this.apollo
      .mutate({
        mutation: this.logoutService.document,
        variables: {
          email: email,
          idToken: idToken,
        },
      })
      .subscribe(
        ({ data }) => {
          this.sessionService.deleteLogin();
          this.authService.logout();
          this.router.navigate(["/"]);
        },
        (error) => {
          //this.errorMessage = "Unable to get User details. Please try again later.";
        }
      );
  }
}
