import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { SessionService } from 'src/app/services/session.service';
import { GetAreaQuery } from 'src/app/services/graphql/getarea.graphql';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateUserMutation } from 'src/app/services/graphql/updateuser.graphql';
import { GetUserQuery } from "../../../services/graphql/getuser.graphql";
import { SearchAreaQuery } from "../../../services/graphql/searchArea.graphql";
import { EventConstants, SessionConstants } from 'src/app/constants/app.constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetAreaDetailsQuery } from "../../../services/graphql/getAreaDetails.graphql";
import { GenerateUserAIDescriptionMutation } from "src/app/services/graphql/generateUserAIDescription.graphql";
import { ToastrService } from 'ngx-toastr';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from "rxjs";
import { ValidateAITextMutation } from "src/app/services/graphql/validateAIText.graphql";
import { ReplaceAITextMutation } from "src/app/services/graphql/replaceAIText.graphql";

import {
  faUser,
  faAddressBook,
  faCreditCard,
  faChevronLeft,
  faChevronRight,
  faSave,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  firstname = new FormControl("");
  lastname = new FormControl("");
  email = new FormControl("");
  contact = new FormControl("");
  offerAiPrompt = new FormControl("");
  adhaarcard = new FormControl("");
  pancard = new FormControl("");
  // birthdate = new FormControl();
  birthDatereadonlytext = new FormControl("");
  profileimagename = new FormControl("");
  accountnumber = new FormControl("");
  bankcode = new FormControl("");
  addressone = new FormControl("");
  addresstwo = new FormControl("");
  area = new FormControl("");
  landmark = new FormControl("");
  pincode = new FormControl("");
  city = new FormControl("");
  district = new FormControl("");
  state = new FormControl("");
  user_id = "";
  hashed_user_id = "";
  loc = "";

  birthDateReadOnly = new BehaviorSubject<boolean>(
    false
  );

  getUserForm: FormGroup;
  usersdata: String;
  submitted: boolean = false;
  areanameData=[];
  cityData=[];
  districtData=[];
  stateData=[];
  referral_code_module = EventConstants.CONST_REFERRAL_CODE_MODULE;
  areaSubscription: any;
  errorMessage: string;
  updateUserData: any;
  first_name: string;
  last_name: string;
  user_email: string;
  referral_code: string;
  areas: [];
  areaSessionToken: null;
  profileImageLink: string = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
  profileHtmlDescription = "";
  profileMarkdownDescription = "";

  profileInputDescription = new BehaviorSubject<string>(
    ""
  );

  closeResult = '';

  faPerson = faUser;
  faAddressBook = faAddressBook;
  faCreditCard = faCreditCard;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSave = faSave;

  faBan = faBan;

constructor(
    private apollo: Apollo,
    private modalService: NgbModal,
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
    private updateUsermutation: UpdateUserMutation,
    private getAreaQuery: GetAreaQuery,
    private searchAreaQuery: SearchAreaQuery,
    private getUserQuery: GetUserQuery,
    private toastr: ToastrService,
    private getAreaDetailsQuery: GetAreaDetailsQuery,
    private domSanitizer: DomSanitizer,
    private validateAITextMutation: ValidateAITextMutation,
    private replaceAITextMutation: ReplaceAITextMutation,
    private generateUserAIDescriptionMutation: GenerateUserAIDescriptionMutation
  ) {
    let usersdata = this.sessionService.getLocal("user");

    if(usersdata != null || usersdata != undefined) {
    this.user_id = usersdata["user_id"];
    this.hashed_user_id = btoa(usersdata["user_id"]);
    this.first_name = usersdata["first_name"];
    this.last_name = usersdata["last_name"];
    this.user_email = usersdata["email"];
      this.birthDatereadonlytext = usersdata["birth_date"];
    this.referral_code = usersdata["referral_code"];
    
    this.profileInputDescription.next(usersdata["markdown_description"]);
    if(usersdata["profile_image_name"] !== null && usersdata["profile_image_name"] !== "") {
      this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_MASTER_URL + usersdata["profile_image_name"];
    } else {
      this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
    }
    } else {
      this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
    }
   
    this.getUserForm = this.formBuilder.group({
      firstname: [""],
      lastname: [""],
      birthdate: [],
      birthdatereadonlytext: [this.birthDatereadonlytext],
      email: ["", [Validators.required]],
      contact: [""],
      offerAiPrompt: [""],
      adhaarcard: ["", [Validators.maxLength(12)]],
      pancard: ["", [Validators.minLength(8)]],
      accountnumber: [""],
      bankcode: [""],
      addressone: [""],
      addresstwo: [""],
      landmark: [""],
      pincode: [""],
      area: [""],
      city: [""],
      district: [""],
      state: [""]
       });
  }
  get f() {
    return this.getUserForm.controls; }

  addHtmlDescription(value: string) {
    this.profileHtmlDescription = value;
  }

  addMarkdownDescription(value: string) {
    this.profileMarkdownDescription = value;
  }

  ngOnInit() {
    this.loc = window.location.host;

    let loginSessionData = this.sessionService.get("token");
    let loginUserData = this.sessionService.getLocal("user");
    let email = loginUserData["email"];
    let idToken = loginSessionData["id_token"];

    this.apollo
    .query({
      query: this.getUserQuery.document,
      variables: {
        email: email,
        idToken: idToken,
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        this.sessionService.setLocal(
          "user",
          data["getUserDetails"]
        );
       
        let usersdata = data["getUserDetails"];

        if(usersdata["birth_date"] != null && usersdata["birth_date"] != "") {
          this.birthDateReadOnly.next(true);
        }

        this.getUserForm.patchValue({
          firstname: usersdata["first_name"],
          lastname: usersdata["last_name"],
          birthDatereadonlytext: usersdata["birth_date"],
          email: usersdata["email"],
          contact: usersdata["phone"],
          adhaarcard: usersdata["adhaar_card"],
          pancard: usersdata["pan_card"],
          accountnumber: usersdata["account_number"],
          bankcode: usersdata["bank_code"],
          addressone: usersdata["address_line1"],
          addresstwo: usersdata["address_line2"],
          landmark: usersdata["landmark"],
          area: usersdata["area_name"],
          pincode: usersdata["pin_code"],
          city: usersdata["city"],
          district: usersdata["district"],
          state: usersdata["state"]
        });
    
        this.profileInputDescription.next(usersdata["markdown_description"]);
    
        if(usersdata["profile_image_name"] !== null && usersdata["profile_image_name"] !== "") {
          this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_MASTER_URL + usersdata["profile_image_name"];
        } else {
          this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
        }

      },
      (error) => {
        this.toastr.error(error.message);
      }
    );

    this.areaSubscription = this.getAreaQuery._areaDataLoadedObservble.subscribe(
      (data) => {
        if (data === EventConstants.CONST_AREA_LOADED) {
          this.areanameData = this.getAreaQuery.areaName;
          this.cityData = this.getAreaQuery.city;
          this.districtData = this.getAreaQuery.district;
        }
      }
    );
 }
 async updateProfile() {
    this.submitted = true;

    (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = true;

    let loginSessionData = this.sessionService.get("token");
    let loginUserData = this.sessionService.getLocal("user");
    let email = loginUserData["email"];
    let idToken = loginSessionData["id_token"];

    let firstname = this.getUserForm.controls.firstname.value;

    let lastname = this.getUserForm.controls.lastname.value;

    if(this.getUserForm.controls.adhaarcard.value != null && this.getUserForm.controls.adhaarcard.value != "") {
      if(this.getUserForm.controls.adhaarcard.value.length < 12) {
        this.toastr.error("Minimum length required for Adhaar card is 12");
        (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
      }
    }

    if(this.getUserForm.controls.contact.value != null && this.getUserForm.controls.contact.value != "") {
      if(this.getUserForm.controls.contact.value.length < 10) {
        this.toastr.error("Minimum length required for Contact number is 10");
        (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
      }
    }

    let adhaarcard = (this.getUserForm.controls.adhaarcard.value == loginUserData["adhaar_card"])
    ? null : this.getUserForm.controls.adhaarcard.value;

    if(this.getUserForm.controls.firstname.value == null || this.getUserForm.controls.firstname.value == "") {
      this.toastr.error("Please add First Name before updating");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if(this.getUserForm.controls.lastname.value == null || this.getUserForm.controls.lastname.value == "") {
      this.toastr.error("Please add Last Name before updating");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if(this.getUserForm.controls.contact.value == null || this.getUserForm.controls.contact.value == "") {
      this.toastr.error("Please add contact number before updating");
        (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if(this.getUserForm.controls.contact.value == null || this.getUserForm.controls.contact.value == "") {
      this.toastr.error("Please add contact number before updating");
        (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.adhaarcard.value == null || this.getUserForm.controls.adhaarcard.value == "") && this.getUserForm.controls.adhaarcard.value != loginUserData["adhaar_card"]) {
      this.toastr.error("Aadhaar card cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.pancard.value == null || this.getUserForm.controls.pancard.value == "") && this.getUserForm.controls.pancard.value != loginUserData["pan_card"]) {
      this.toastr.error("Pan card cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.accountnumber.value == null || this.getUserForm.controls.accountnumber.value == "") && this.getUserForm.controls.accountnumber.value != loginUserData["account_number"]) {
      this.toastr.error("Account number cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.bankcode.value == null || this.getUserForm.controls.bankcode.value == "") && this.getUserForm.controls.bankcode.value != loginUserData["bank_code"]) {
      this.toastr.error("Bank IFSC code cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.addressone.value == null || this.getUserForm.controls.addressone.value == "") && this.getUserForm.controls.addressone.value != loginUserData["address_line1"]) {
      this.toastr.error("Address One cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.addresstwo.value == null || this.getUserForm.controls.addresstwo.value == "") && this.getUserForm.controls.addresstwo.value != loginUserData["address_line2"]) {
      this.toastr.error("Address Two cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.area.value == null || this.getUserForm.controls.area.value == "") && this.getUserForm.controls.area.value != loginUserData["area_name"]) {
      this.toastr.error("Area cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.pincode.value == null || this.getUserForm.controls.pincode.value == "") && this.getUserForm.controls.pincode.value != loginUserData["pin_code"]) {
      this.toastr.error("Pin code cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.city.value == null || this.getUserForm.controls.city.value == "") && this.getUserForm.controls.city.value != loginUserData["city"]) {
      this.toastr.error("City cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.district.value == null || this.getUserForm.controls.district.value == "") && this.getUserForm.controls.district.value != loginUserData["district"]) {
      this.toastr.error("District cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    if((this.getUserForm.controls.state.value == null || this.getUserForm.controls.state.value == "") && this.getUserForm.controls.state.value != loginUserData["state"]) {
      this.toastr.error("State cannot be kept blank.");
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        return;
    }

    // if(this.getUserForm.controls.adhaarcard.value == null || this.getUserForm.controls.adhaarcard.value == "") {
    //   this.toastr.error("Please add Aadhaar card number before updating");
    //   (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
    //     return;
    // }

    // if(this.getUserForm.controls.pancard.value == null || this.getUserForm.controls.pancard.value == "") {
    //   this.toastr.error("Please add Pan card number before updating");
    //   (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
    //     return;
    // }

    let pancard = (this.getUserForm.controls.pancard.value == loginUserData["pan_card"])
    ? null : this.getUserForm.controls.pancard.value;

    let phone = (this.getUserForm.controls.contact.value == loginUserData["phone"])
    ? null : this.getUserForm.controls.contact.value;
    

    var result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: idToken,
        input_text: this.profileMarkdownDescription,
        check_phone_and_email: true
      },
    }).toPromise();

    if(result["data"]["validateAIText"].toLowerCase().includes("yes")) {
      this.toastr.error("The description that you have provided contains either Phone Number or an Email ID. As per our policies, we request you to not include them in your description. We are removing them for now.");
      this.apollo
      .mutate({
        mutation: this.replaceAITextMutation.document,
        variables: {
          offeror_email: email,
          id_token: idToken,
          input_text: this.profileMarkdownDescription,
          remove_phone: true,
          remove_email: true
        },
      })
      .subscribe(
        ({ data }) => {
          this.profileInputDescription.next(data["replaceAIText"]);
          (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        },
        (error) => {
          this.toastr.error(error.message);
          (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
        }
      );
      return;
    }

    result = await this.apollo
    .mutate({
      mutation: this.validateAITextMutation.document,
      variables: {
        offeror_email: email,
        id_token: idToken,
        input_text: this.profileMarkdownDescription
      },
    }).toPromise();

    if(!result["data"]['validateAIText'].toLowerCase().includes("####")) {
      this.toastr.error("The description is not good. " + result["data"]['validateAIText']);
      (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
      return;
    }

    let description = this.profileHtmlDescription;
    let markdown_description = this.profileMarkdownDescription;
    let accountnumber = this.getUserForm.controls.accountnumber.value;
    let bankcode = this.getUserForm.controls.bankcode.value;
    let addressone = this.getUserForm.controls.addressone.value;
    let addresstwo = this.getUserForm.controls.addresstwo.value;
    let area = this.getUserForm.controls.area.value;
    let landmark = this.getUserForm.controls.landmark.value;
    let pincode = this.getUserForm.controls.pincode.value;
    let city = this.getUserForm.controls.city.value;
    let district = this.getUserForm.controls.district.value;
    let state = this.getUserForm.controls.state.value;
    var birthdate = this.getUserForm.controls.birthdate.value;

    if(birthdate == null || birthdate == "") {
      birthdate = this.getUserForm.controls.birthdatereadonlytext.value;
    } else {
      if((new Date(birthdate)) > (new Date())) {
        this.toastr.error("Invalid value for birth date.");
        return;
      }
    }

    this.apollo
      .mutate({
        mutation: this.updateUsermutation.document,
        variables: {
          user_email: email,
          id_token: idToken,
          first_name: firstname,
          last_name: lastname,
          adhaar_card: adhaarcard,
          phone: phone,
          pan_card: pancard,
          birth_date: birthdate,
          description: description,
          markdown_description: markdown_description,
          account_number: accountnumber,
          bank_code: bankcode,
          address_line1: addressone,
          address_line2: addresstwo,
          area_name: area,
          landmark: landmark,
          pin_code: pincode,
          city: city,
          district: district,
          state: state
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Successfully Updated");
          this.updateUsermutation.populateupdateUser(data);
          this.updateUserData =this.updateUsermutation.getupdateUserData;

          this.apollo
          .query({
            query: this.getUserQuery.document,
            variables: {
              email: email,
              idToken: idToken,
            },
          })
          .subscribe(
            ({ data }) => {
              this.sessionService.setLocal(
                "user",
                data["getUserDetails"]
              );
              (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
            },
            (error) => {
              (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
              this.toastr.error(error.message);
            }
          );
        },
        (error) => {
          (<HTMLInputElement>document.getElementById("profileUpdateButton")).disabled = false;
          this.toastr.error(error.message);
        }
      );
  }

     onKeyupAddress($evt) {
      this.areas = [];
      var text = $evt.target.value;
      if(text.length > 2) {
        this.apollo
        .query({
          query: this.searchAreaQuery.document,
          variables: {
            sessionToken: this.areaSessionToken,
            titleKeyword: text
          },
          fetchPolicy: "network-only"
        })
        .subscribe(
          ({ data }) => {
            if(data["searchAreas"] != null && data["searchAreas"].length > 0) {
              this.areaSessionToken = data["searchAreas"][0]["session_token"];
              this.areas = data["searchAreas"];
  
              this.autocomplete(document.getElementById("searchArea"), this.areas);
            }
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
      }
    }

    generateAIDescription(){

      let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getLocalKeyValues("user", "email");
  
      document.getElementById("aiGenerateDescriptionButton").classList.add("is-loading");
  
      var inputText = "";
  
      if(this.getUserForm.controls.offerAiPrompt.value != null && this.getUserForm.controls.offerAiPrompt.value != "") {
        inputText = this.getUserForm.controls.offerAiPrompt.value;

        this.apollo
        .mutate({
          mutation: this.generateUserAIDescriptionMutation.document,
          variables: {
            email: email,
            id_token: token,
            input_text: inputText,
          },
        })
        .subscribe(
          ({ data }) => {
            this.profileInputDescription.next(data['generateUserAIDescription']);
            document.getElementById("aiGenerateDescriptionButton").classList.remove("is-loading");
          },
          (error) => {
            this.toastr.error(error.message);
            document.getElementById("aiGenerateDescriptionButton").classList.remove("is-loading");
          }
        );
      }
    }

    getAreaDetails(areaId: number, placeId: number, apollo, getAreaDetailsQuery, sessionToken, getUserForm) {
      apollo
        .query({
          query: getAreaDetailsQuery.document,
          variables: {
            sessionToken: sessionToken,
            areaId: areaId,
            placeId: placeId
          },
          fetchPolicy: "network-only"
        })
        .subscribe(
          ({ data }) => {

            getUserForm.patchValue({
              area: data["getAreaDetails"]["area_name"],
              pincode: data["getAreaDetails"]["pin_code"],
              city: data["getAreaDetails"]["city"],
              district: data["getAreaDetails"]["district"],
              state: data["getAreaDetails"]["state"],
              landmark: "",
              addressone: "",
              addresstwo: ""
            });
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    }

    areaClickEventHandler(
      information, 
      areaId, 
      placeId, 
      getAreaDetails, 
      apollo, 
      getAreaDetailsQuery, 
      sessionToken, 
      closeAllListsDef,
      getUserForm, 
      event) {
      /*insert the value for the autocomplete text field:*/
      (<HTMLInputElement>document.getElementById("searchArea")).value = information;
      getAreaDetails(areaId, placeId, apollo, getAreaDetailsQuery, sessionToken, getUserForm);
      /*close the list of autocompleted values,
      (or any other open lists of autocompleted values:*/
        closeAllListsDef();
  }
  
  closeAllListsDef(elmnt = null) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != document.getElementById("searchArea")) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }

    autocomplete(inp, arr) {

      function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
      }
      function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("autocomplete-active");
        }
      }
      function closeAllLists(elmnt = null) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
          if (elmnt != x[i] && elmnt != inp) {
            x[i].parentNode.removeChild(x[i]);
          }
        }
      }
  
      /*the autocomplete function takes two arguments,
      the text field element and an array of possible autocompleted values:*/
      var currentFocus;
      /*execute a function when someone writes in the text field:*/
      var a, b, i, val = inp.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", inp.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      inp.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        // if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = arr[i]["area_information"];
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i]["area_information"] + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
  
          // areaClickEventHandler(event, information, areaId, placeId) {
  
          b.addEventListener(
            "click", 
            this.areaClickEventHandler.bind(
              null, 
              arr[i]["area_information"], 
              arr[i]["area_id"], 
              arr[i]["place_id"], 
              this.getAreaDetails, 
              this.apollo, 
              this.getAreaDetailsQuery, 
              this.areaSessionToken, 
              this.closeAllListsDef,
              this.getUserForm), false);
          a.appendChild(b);
        // }
      }
      /*execute a function presses a key on the keyboard:*/
      inp.addEventListener("keydown", function(e) {
          var x = document.getElementById(this.id + "autocomplete-list");
          var y = null;
          if (x) {
            y = x.getElementsByTagName("div");
            x = y;
          }
          if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
          } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
          } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
              /*and simulate a click on the "active" item:*/
              if (x) x[currentFocus].click();
            }
          }
      });
      
      /*execute a function when someone clicks in the document:*/
      document.addEventListener("click", function (e) {
          closeAllLists(e.target);
      });
    }

    open(content) {
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
}
       
    



