import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Apollo } from "apollo-angular";
import { SessionService } from "src/app/services/session.service";
import { GetOfferMessageChecksQuery } from "src/app/services/graphql/getOfferMessageChecks.graphql";
import { BroadcastOfferMessageMutation } from "../../../services/graphql/broadcastOfferMessage.graphql";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GetProductLocksQuery } from 'src/app/services/graphql/getProductLocks.graphql';

@Component({
  selector: 'app-offering-communication',
  templateUrl: './offering-communication.component.html',
  styleUrls: ['./offering-communication.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferingCommunicationComponent implements OnInit {

  eventGuid = 0;
  productList = [];
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  preparedDataVal = null;
  closeOfferBroadcastResult = "";
  broadcastMessage = "";
  NoDataFlag = false;
  productLockDetails = null;

  constructor(
    private getOfferMessageChecksQuery: GetOfferMessageChecksQuery,
    private broadcastOfferMessageMutation: BroadcastOfferMessageMutation,
    private apollo: Apollo,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private getProductLocksQuery: GetProductLocksQuery
  ) {}

  callGetLenderProducts() {
    let id_token = this.sessionService.getKeyValues("token", "id_token");
    let emailId = this.sessionService.getLocalKeyValues("user", "email");
    this.apollo
    .query({
      query: this.getOfferMessageChecksQuery.document,
      variables: {
        userEmail: emailId,
        idToken: id_token,
        userType: "offeror"
      },
      fetchPolicy: "network-only"
    })
    .subscribe(
      ({ data }) => {
        if(data["getMessageChecks"].length > 0) {
          this.preparedDataVal = this.prepareData(data["getMessageChecks"]);
        } else {
          this.NoDataFlag = true; 
        }

      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  openModalFirst(content, modal_name, lock_id = null) {
    if(modal_name == "loadLockSlots") {
      this.getOfferLockDetails(lock_id);
    }
    this.openModal(content);
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeOfferBroadcastResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeOfferBroadcastResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getOfferLockDetails(lockId) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
              .query({
                query: this.getProductLocksQuery.document,
                variables: {
                  id_token: token,
                  user_email: email,
                  user_type: "offeror",
                  lock_id: lockId
                }
              })
              .subscribe(
                ({ data }) => {
                  this.productLockDetails = data["getProductLocks"][0];
                },
                (error) => {
                }
              );
  }

  broadcastMessages(offerCheckData, broadcastMessage, modal) {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    var lock_ids = [];
    offerCheckData["lock_ids"].forEach(id => {
      lock_ids.push(id["lock_id"]);
    });

    this.apollo.mutate({
      mutation: this.broadcastOfferMessageMutation.document,
      variables: {
        id_token: token,
        user_email: email,
        lock_ids: lock_ids,
        message: broadcastMessage,
        offer_id: offerCheckData["offer_id"]
      }
    }).subscribe(
      ({ data }) => {
        this.toastr.success("Broadcast message sent");
        modal.close();
        this.broadcastMessage = "";
      },
      (error) => {
        this.toastr.error(error.message);
        modal.close();
        this.broadcastMessage = "";
    });
  }

  prepareData(data) {
    var result = new Map();
    data.forEach(messageCheck => {
      if(!result.has(messageCheck['product_id'])) {
        result.set(messageCheck['product_id'], [{
          'offer_title': messageCheck["product_title"],
          'start_date': messageCheck["start_date"],
          'end_date': messageCheck["end_date"],
          'unread_messages': messageCheck["offeror_total_unread_messages"],
          'schedule_frequency': messageCheck["schedule_frequency"],
          'lock_ids': [{
            "offeree_name": messageCheck['offeree_full_name'],
            "lock_id": messageCheck['lock_id'],
            "unread_messages": messageCheck["offeror_total_unread_messages"]
          }],
          'offer_id': messageCheck['product_id']
        }]);
      } else {
        var obj_arr = result.get(messageCheck['product_id']);
        var obj_arr_new = result.get(messageCheck['product_id']);
        obj_arr.forEach(function (value, index) {
          var lock_ids = value["lock_ids"];
          var offer_title = value["offer_title"];
          var start_date = value["start_date"];
          var end_date = value["end_date"];
          var schedule_frequency = value["schedule_frequency"];
          var splice_array_flag = false;
          var unread_messages = value["unread_messages"];
          var offer_id = value["offer_id"];
          if((value["schedule_frequency"] == "hourly" || value["schedule_frequency"] == "daily") && (value["schedule_frequency"] == messageCheck["schedule_frequency"])) {
            if(+(new Date(value["start_date"])) == +(new Date(messageCheck["start_date"]))) {
              lock_ids.push({
                "offeree_name": messageCheck['offeree_full_name'],
                "lock_id": messageCheck['lock_id'],
                "unread_messages": messageCheck["offeror_total_unread_messages"]
              });
              unread_messages += messageCheck["offeror_total_unread_messages"];
              splice_array_flag = true;
            }
          } else if(value["schedule_frequency"] == "weekly" && value["schedule_frequency"] == messageCheck["schedule_frequency"]) {
            let setToMonday = function(date) {
              var day = date.getDay() || 7;  
              if( day !== 1 ) 
                  date.setHours(-24 * (day - 1)); 
              return date;
            };

            let setToSunday = function(date) {
              var day = date.getDay();
              if( day !== 0 ) 
                  date.setHours(24 * (7 - day)); 
              return date;
            }

            let monday = setToMonday(new Date(value["start_date"]));
            let sunday = setToSunday(new Date(value["end_date"]));
            var monday_year = monday.getFullYear();
            var monday_month = monday.getMonth() + 1;
            var monday_date = monday.getDate();

            if(monday_month < 10) monday_month = '0' + monday_month;
            if(monday_date < 10) monday_date = '0' + monday_date;

            start_date = monday_year + '-' + monday_month + '-' + monday_date;

            var sunday_year = sunday.getFullYear();
            var sunday_month = sunday.getMonth() + 1;
            var sunday_date = sunday.getDate();

            if(sunday_month < 10) sunday_month = '0' + sunday_month;
            if(sunday_date < 10) sunday_date = '0' + sunday_date;

            end_date = sunday_year + '-' + sunday_month + '-' + sunday_date;

            let start_date_obj = new Date(messageCheck["start_date"]);
            let end_date_obj = new Date(messageCheck["end_date"]);

            if((monday < start_date_obj || +monday === +start_date_obj) && (sunday > end_date_obj || +sunday === +end_date_obj)) {
              lock_ids.push({
                "offeree_name": messageCheck['offeree_full_name'],
                "lock_id": messageCheck['lock_id'],
                "unread_messages": messageCheck["offeror_total_unread_messages"]
              });
              unread_messages += messageCheck["offeror_total_unread_messages"];
              splice_array_flag = true;
            }
          } else if(value["schedule_frequency"] == "monthly" && value["schedule_frequency"] == messageCheck["schedule_frequency"]) {
            var dat = new Date(value["start_date"]);
            var first_day = new Date(dat.getFullYear(), dat.getMonth(), 1);
            var dat = new Date(value["end_date"]);
            var last_day = new Date(dat.getFullYear(), dat.getMonth() + 1, 0);

            var first_year = first_day.getFullYear();
            var first_month_num = first_day.getMonth() + 1;
            var first_month = "" + first_month_num;
            var first_date_num = first_day.getDate();
            var first_date = "" + first_date_num;

            if(first_month_num < 10) first_month = '0' + first_month_num;
            if(first_date_num < 10) first_date = '0' + first_date_num;

            start_date = first_year + '-' + first_month + '-' + first_date;

            var last_year = last_day.getFullYear();
            var last_month_num = last_day.getMonth() + 1;
            var last_month = "" + last_month_num;
            var last_date_num = last_day.getDate();
            var last_date = "" + last_date_num;

            if(last_month_num < 10) last_month = '0' + last_month_num;
            if(last_date_num < 10) last_date = '0' + last_date_num;

            end_date = last_year + '-' + last_month + '-' + last_date;

            let start_date_obj = new Date(messageCheck["start_date"]);
            let end_date_obj = new Date(messageCheck["end_date"]);
            if((first_day < start_date_obj || +first_day === +start_date_obj) && (last_day > end_date_obj || +last_day === +end_date_obj)) {
              lock_ids.push({
                "offeree_name": messageCheck['offeree_full_name'],
                "lock_id": messageCheck['lock_id'],
                "unread_messages": messageCheck["offeror_total_unread_messages"]
              });
              unread_messages += messageCheck["offeror_total_unread_messages"];
              splice_array_flag = true;
            }
          }  else if(value["schedule_frequency"] == "custom" && value["schedule_frequency"] == messageCheck["schedule_frequency"]) {
            var first_day = new Date(value["start_date"]);;
            var last_day = new Date(value["end_date"]);

            var first_year = first_day.getFullYear();
            var first_month_num = first_day.getMonth() + 1;
            var first_month = "" + first_month_num;
            var first_date_num = first_day.getDate();
            var first_date = "" + first_date_num;

            if(first_month_num < 10) first_month = '0' + first_month_num;
            if(first_date_num < 10) first_date = '0' + first_date_num;

            start_date = first_year + '-' + first_month + '-' + first_date;

            var last_year = last_day.getFullYear();
            var last_month_num = last_day.getMonth() + 1;
            var last_month = "" + last_month_num;
            var last_date_num = last_day.getDate();
            var last_date = "" + last_date_num;

            if(last_month_num < 10) last_month = '0' + last_month_num;
            if(last_date_num < 10) last_date = '0' + last_date_num;

            end_date = last_year + '-' + last_month + '-' + last_date;

            let start_date_obj = new Date(messageCheck["start_date"]);
            let end_date_obj = new Date(messageCheck["end_date"]);
            if((first_day < start_date_obj || +first_day === +start_date_obj) && (last_day > end_date_obj || +last_day === +end_date_obj)) {
              lock_ids.push({
                "offeree_name": messageCheck['offeree_full_name'],
                "lock_id": messageCheck['lock_id'],
                "unread_messages": messageCheck["offeror_total_unread_messages"]
              });
              unread_messages += messageCheck["offeror_total_unread_messages"];
              splice_array_flag = true;
            }
          }

          if(splice_array_flag === true) {
            obj_arr_new.splice(index, 1, {
              'start_date': start_date,
              'end_date': end_date,
              'offer_title': offer_title,
              'schedule_frequency': schedule_frequency,
              'lock_ids': lock_ids,
              'unread_messages': unread_messages,
              'offer_id': offer_id
            })
          } else {
            var existing = false;
            obj_arr_new.forEach(dat => {
              if(+(new Date(dat["start_date"])) == +(new Date(messageCheck["start_date"]))) {
                existing = true;
              }
            });
            if(existing === false) {
              obj_arr_new.push({
                'start_date': messageCheck["start_date"],
                'end_date': messageCheck["end_date"],
                'offer_title': messageCheck["product_title"],
                'schedule_frequency': messageCheck["schedule_frequency"],
                'lock_ids': [{
                  "offeree_name": messageCheck['offeree_full_name'],
                  "lock_id": messageCheck['lock_id'],
                  "unread_messages": messageCheck["offeror_total_unread_messages"]
                }],
                'unread_messages': messageCheck["offeror_total_unread_messages"],
                'offer_id': messageCheck["product_id"]
              });
            }
          }
        });
        result.set(messageCheck['product_id'], obj_arr_new);
      }
    });

    var output = null;

    var final_arr = [];

    for (let [key, value] of result) {
      value.forEach(value2 => {
        final_arr.push({
          offer_title: value2["offer_title"],
          start_date: value2["start_date"],
          end_date: value2["end_date"],
          lock_ids: value2["lock_ids"],
          schedule_frequency: value2["schedule_frequency"],
          unread_messages: value2["unread_messages"],
          offer_id: value2["offer_id"]
        });
      });
      }

    return final_arr;
  }

  ngOnInit(){
    this.callGetLenderProducts();
  }

}
