import { Component, OnInit } from "@angular/core";
import {Location} from '@angular/common';
import { Router } from "@angular/router";
import { SessionConstants } from "src/app/constants/app.constants";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../../../services/auth/auth.service";
import { RandomUserService } from "../../../services/randomeUser.service";
import { BehaviorSubject, Observable } from "rxjs";
import { ResendVerificationLinkMutation } from "../../../services/graphql/resendVerificationLink.graphql";
import { AttachMaskedUserDetailsMutation } from "../../../services/graphql/attachMaskedUserDetails.graphql";
import { NgxSpinnerService } from "ngx-spinner";
import { CreateUserMutation } from "../../../services/graphql/createuser.graphql";

import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Apollo } from "apollo-angular";
import { LoginUserMutation } from "../../../services/graphql/login.graphql";
import { GetUserQuery } from "../../../services/graphql/getuser.graphql";
import { SessionService } from "../../../services/session.service";
import firebase from 'firebase/app';

@Component({
  selector: "app-log-in",
  templateUrl: "./log-in.component.html",
  styleUrls: ["./log-in.component.scss"],
})
export class LogInComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  errorMessage = "";

  redirectUrl = "/";

  login$!: Observable<any>;
  user$: Observable<firebase.User | null>;

  constructor(
    private apollo: Apollo,
    private loginUserMutation: LoginUserMutation,
    private formBuilder: FormBuilder,
    private router: Router,
    private sessionService: SessionService,
    private getUserQuery: GetUserQuery,
    private toastr: ToastrService,
    private readonly authService: AuthService,
    private resendVerificationLinkMutation: ResendVerificationLinkMutation,
    private attachMaskedUserDetailsMutation: AttachMaskedUserDetailsMutation,
    private location: Location,
    private randomUserService: RandomUserService,
    private spinner: NgxSpinnerService,
    private createUserMutation: CreateUserMutation
  ) {
    this.user$ = this.authService.user$;
  }

  ngOnInit() {
    this.redirectUrl = this.sessionService.get("redirect_url");

    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });


    this.user$.subscribe(data1 => {
      if(data1 != null) {
        if(data1.providerData[0]["email"] != null ) {
          this.ssoLoginUser(data1);
        }
      }
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  ssoLoginUser(data1, noCreateFlag = true) {
    let email = data1.email;
    let refresh_token = data1.refreshToken;

    this.apollo
    .mutate({
      mutation: this.loginUserMutation.document,
      variables: {
        email: email,
        refresh_token: refresh_token,
      },
    })
    .subscribe(
      ({ data }) => {
        var isEmailVerified = data["loginUser"].is_email_verified;
        data["loginUser"].email = email;
        data["loginUser"].valid_user = false;
        data["loginUser"]["login_time"] = (new Date()).getTime();
        if(data["loginUser"] !== null && data["loginUser"]["id_token"] !== null && 
        data["loginUser"]["id_token"] !== "" && 
        data["loginUser"]["user_status"] == "known" && 
        data["loginUser"]["email"] !== null && 
        data["loginUser"]["email"] !== "") {
          data["loginUser"].valid_user = true;
        }
        this.sessionService.setLoginContent(data["loginUser"]);
        if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
          var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
          var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
          this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, email, null);
        }
        this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
        this.apollo
          .query({
            query: this.getUserQuery.document,
            variables: {
              email: email,
              idToken: data["loginUser"]["id_token"],
            },
          })
          .subscribe(
            ({ data }) => {
              this.sessionService.setLocal(
                SessionConstants.CONST_SESSION_USER_KEYWORD,
                data["getUserDetails"]
              );
              this.sessionService.deleteLocal("anonymousUser");
              this.sessionService.deleteLocal("validAnonymousUser");
              this.sessionService.set(
                SessionConstants.CONST_VERIFIED_EMAIL_KEYWORD,
                isEmailVerified
              );
              this.sessionService.delete("redirect_url");
              if(this.redirectUrl !== null) {
                this.router.navigate([this.redirectUrl]);
              } else {
                this.router.navigate(["/"]);
              }
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
              this.toastr.error(error.message);
            }
          );
      },
      (error) => {
        if(noCreateFlag === false) {
          this.createAccount(data1);
        }
      }
    );
  }

  createAccount(data1) {

    let first_name = data1.displayName.split(" ")[0];
    let last_name = data1.displayName.split(" ")[1];
    var dataParam = {
      first_name: first_name,
      last_name: last_name,
      email: data1.email
    };
    var referral_code_val = "";

    let firebase_user_id = data1.uid;
    let refresh_token = data1.refreshToken;

    this.apollo
      .mutate({
        mutation: this.createUserMutation.document,
        variables: {
          first_name: (dataParam != null && dataParam["first_name"] != null) ? dataParam["first_name"] : "",
          last_name: (dataParam != null && dataParam["last_name"] != null) ? dataParam["last_name"] : "",
          adhaar_card: "@@@",
          pan_card: "@@@",
          birth_date: null,
          terms_accepted: true,
          referral_code: referral_code_val,
          sso: "True",
          verify_email: "True",
          firebase_user_id: firebase_user_id,
          email: dataParam.email,
          phone: "@@@",
          refresh_token: refresh_token,
          password: "",
          account_number: "@@@",
          bank_code: "@@@",
          address_line1: "@@@",
          address_line2: "@@@",
          area_name: "@@@",
          landmark: "@@@",
          pin_code: "@@@",
          city: "@@@",
          district: "@@@",
          state: "@@@",
          description: "Thank for for visiting my profile.",
          markdown_description: "Thank for for visiting my profile."
        },
      })
      .subscribe(
        ({ data }) => {
          data["createUser"].valid_user = false;
          data["createUser"]["login_time"] = (new Date()).getTime();
          if(data["createUser"] !== null && data["createUser"]["id_token"] !== null && 
          data["createUser"]["id_token"] !== "" && 
          data["createUser"]["user_status"] == "known" && 
          data["createUser"]["email"] !== null && 
          data["createUser"]["email"] !== "") {
            data["createUser"].valid_user = true;
          }

          this.sessionService.setLoginContent(data["createUser"]);
          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, data["createUser"]["email"], null);
          }
          this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
          this.apollo
            .query({
              query: this.getUserQuery.document,
              variables: {
                email: dataParam.email,
                idToken: data["createUser"]["id_token"],
              },
              fetchPolicy: "network-only"
            })
            .subscribe(
              ({ data }) => {
                this.spinner.hide();
                this.sessionService.setLocal(
                  "user",
                  data["getUserDetails"]
                );
                this.sessionService.deleteLocal("anonymousUser");
                this.sessionService.deleteLocal("validAnonymousUser");
                this.router.navigate(["/profile"]);
              },
              (error) => {
                this.spinner.hide();
                this.toastr.error(error.message);
              }
            );
        },
        (error) => {
          this.spinner.hide();
          this.authService.logout();
          if(error.message.includes("[createUser]")) {
            this.toastr.error("The email has already been taken.");
          } else if(String(error.message).includes("Internal server error")) {
            this.toastr.error(String(error.graphQLErrors[0]["debugMessage"]).replace(/_/g, " "));
          } else {
            this.toastr.error(error.message);
          }
      }
    );
  }

  googleLogin() {
    this.spinner.show();
    this.authService.loginWithPopup().subscribe((dat) => {
      this.user$.subscribe((data1) => {
        if(data1.email !== null && data1.email != "") {
          this.ssoLoginUser(data1, false);
      }
      });
    });
  }

  twitterLogin() {
    this.spinner.show();
    this.authService.loginWithRedirect("twitter").subscribe((dat) => {
    });
  }

  facebookLogin() {
    this.spinner.show();
    this.authService.loginWithRedirect("facebook").subscribe((dat) => {
    });
  }

  loginUser() {
    document.getElementById("loginButton").classList.add("is-loading");
    this.submitted = true;
    if (this.loginForm.invalid) {
      document.getElementById("loginButton").classList.remove("is-loading");
      return;
    }
    var email = this.loginForm.controls.email.value;
    let password = this.loginForm.controls.password.value;
    this.apollo
      .mutate({
        mutation: this.loginUserMutation.document,
        variables: {
          email: email,
          password: password,
        },
      })
      .subscribe(
        ({ data }) => {
          var isEmailVerified = data["loginUser"].is_email_verified;
          data["loginUser"].email = email;
          data["loginUser"].valid_user = false;
          data["loginUser"]["login_time"] = (new Date()).getTime();
          if(data["loginUser"] !== null && data["loginUser"]["id_token"] !== null && 
          data["loginUser"]["id_token"] !== "" && 
          data["loginUser"]["user_status"] == "known" && 
          data["loginUser"]["email"] !== null && 
          data["loginUser"]["email"] !== "") {
            data["loginUser"].valid_user = true;
          }

          this.sessionService.setLoginContent(data["loginUser"]);
          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, data["loginUser"]["email"], null);
          }
          this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
          this.apollo
            .query({
              query: this.getUserQuery.document,
              variables: {
                email: email,
                idToken: data["loginUser"]["id_token"],
              },
            })
            .subscribe(
              ({ data }) => {
                this.sessionService.setLocal(
                  SessionConstants.CONST_SESSION_USER_KEYWORD,
                  data["getUserDetails"]
                );
                this.sessionService.deleteLocal("anonymousUser");
                this.sessionService.deleteLocal("validAnonymousUser");
                this.sessionService.set(
                  SessionConstants.CONST_VERIFIED_EMAIL_KEYWORD,
                  isEmailVerified
                );
                document.getElementById("loginButton").classList.remove("is-loading");
                this.sessionService.delete("redirect_url");
                if(this.redirectUrl !== null) {
                  this.router.navigate([this.redirectUrl]);
                } else {
                  this.router.navigate(["/"]);
                }
                
              },
              (error) => {
                this.toastr.error(error.message);
                document.getElementById("loginButton").classList.remove("is-loading");
              }
            );
        },
        (error) => {
          if(String(error.message).includes("Internal server error")) {
            this.toastr.error(String(error.graphQLErrors[0]["debugMessage"]).replace(/_/g, " "));
          } else {
            this.toastr.error(String(error.message).replace(/_/g, " "));
          }
          
          document.getElementById("loginButton").classList.remove("is-loading");
        }
      );
  }

  alertCloseHandler() {
    this.errorMessage = "";
  }
}
