import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class DetachClpCouponOfferMutation extends Mutation {

  document = gql`
    mutation DetachClpCouponOfferMutation(
      $id_token: String!
      $user_email: String
      $lock_ids: [Int!]!
    ) {
      detachClpCouponOffer(
        user_email: $user_email
        id_token: $id_token
        lock_ids: $lock_ids
      )
    }
  `;
}
