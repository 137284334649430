import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  Renderer2,
} from "@angular/core";
import { element } from "protractor";
import { NewOfferingsComponentInterface } from "../../pages/offerings/new-offerings/new-offerings.component";

@Component({
  selector: "app-day-scheduler",
  templateUrl: "./day-scheduler.component.html",
  styleUrls: ["./day-scheduler.component.scss"],
})
export class DaySchedulerComponent implements OnInit {
  // used to populate scheduler
  timeSlotIndexes = [];

  // used to select schelude
  hours = 0;
  mins = 0;
  checkedSlots = [];
  updateFlag = false;

  @Input() newOfferingInterface: NewOfferingsComponentInterface;

  constructor(private renderer: Renderer2) {}
  ngOnInit() {
    this.populateTimeSlots();
  }

  populateTimeSlots() {
    this.timeSlotIndexes = Array(6)
      .fill(0)
      .map((x, i) => i);
    // this.timeSlotIndexes = Array(12)
    //   .fill(0)
    //   .map((x, i) => i);
  }

  fillupdateSlots() {
    this.updateFlag = true;

    this.checkedSlots.forEach(slot => {
      let hour = slot["start_hour"];
      let minute = (Number(slot["start_minute"]) / 15) + 1;

      if(window.getComputedStyle(document.getElementById("mobileSelectSlots"), null).display == "none") {
        this.selectTimeSlot(hour + "_" + minute);
      } else {
        this.selectTimeSlot("mob_" + hour + "_" + minute, true);
      }
    })
  }

  checkselection(mobView = false): boolean {
    let num = 0;
    let elementId = "";
    let htmlElement: HTMLElement;
    let flag = false;

    this.checkedSlots = [];

    while(num < 96) {
      let calMins = (num % 4) + 1;
      let calHrs = Math.floor(num / 4);
      elementId = calHrs + "_" + calMins;
      
      if(mobView == true) {
        elementId = "mob_" + calHrs + "_" + calMins;
      }

      htmlElement = document.getElementById(elementId);

      if(htmlElement.classList.contains("slot-inner-select-mid") || 
      htmlElement.classList.contains("slot-inner-select-first") || 
      htmlElement.classList.contains("slot-inner-select-single") || 
      htmlElement.classList.contains("slot-inner-select-last")) {
        flag = true;
        if(htmlElement.classList.contains("slot-inner-select-first") || 
        htmlElement.classList.contains("slot-inner-select-single")) {
          this.checkedSlots.push({start_hour: calHrs, start_minute: (calMins - 1) * 15})
        }
      }
      num++;
    }

    return flag;
  }

  selectTimeSlot(target_id: string, mobView=false) {
    let targetId = target_id;

    let hoursSlot = Number(targetId.split("_")[0]);
    let minsSlot = Number(targetId.split("_")[1]);

    if(mobView == true) {
      hoursSlot = Number(targetId.split("_")[1]);
      minsSlot = Number(targetId.split("_")[2]);
    }

    let slots = this.hours * 4 + this.mins / 15;
    let loopIndex = 1;
    let elementId = "";
    let htmlElement: HTMLElement;

    elementId = hoursSlot + "_" + minsSlot;

    if(mobView == true) {
      elementId = "mob_" + hoursSlot + "_" + minsSlot;
    }
    htmlElement = document.getElementById(elementId);
    if (
      htmlElement.classList.contains("slot-inner-select-first") ||
      htmlElement.classList.contains("slot-inner-select-single") ||
      htmlElement.classList.contains("slot-inner-select-last") ||
      htmlElement.classList.contains("slot-inner-select-mid")
    ) {
      let calPos = this.getValidSlotPosition(targetId, mobView);
      if(calPos != null) {
        hoursSlot = Number(calPos.split("_")[0]);
        minsSlot = Number(calPos.split("_")[1]);
      }

    } else if (!this.validateScheduler(targetId, mobView)) {
      alert("Please select valid time slots.");
      return false;
    }

    while (loopIndex <= slots) {
      elementId = hoursSlot + "_" + minsSlot;

      if(mobView == true) {
        elementId = "mob_" + hoursSlot + "_" + minsSlot;
      }

      htmlElement = document.getElementById(elementId);
      if(htmlElement.classList.contains("slot-inner")) {
        htmlElement.classList.remove("slot-inner");
        if (loopIndex == 1 && slots > 1 && 
          !htmlElement.classList.contains("slot-inner-select-first")) {
            htmlElement.classList.add("slot-inner-select-first");
        }
        else if (loopIndex == 1 && slots == 1 && 
          !htmlElement.classList.contains("slot-inner-select-single")) {
            htmlElement.classList.add("slot-inner-select-single");
        }
        else if (loopIndex == slots && 
          !htmlElement.classList.contains("slot-inner-select-last")) {
            htmlElement.classList.add("slot-inner-select-last");
        }
        else if(!htmlElement.classList.contains("slot-inner-select-mid")) {
          htmlElement.classList.add("slot-inner-select-mid");
        }
      } else {
        htmlElement.classList.add("slot-inner");
        if(htmlElement.classList.contains("slot-inner-select-first")) {
          htmlElement.classList.remove("slot-inner-select-first");
        }
        if(htmlElement.classList.contains("slot-inner-select-single")) {
          htmlElement.classList.remove("slot-inner-select-single");
        }
        if(htmlElement.classList.contains("slot-inner-select-last")) {
          htmlElement.classList.remove("slot-inner-select-last");
        }
        if(htmlElement.classList.contains("slot-inner-select-mid")) {
          htmlElement.classList.remove("slot-inner-select-mid");
        }
      }
      
      if (minsSlot == 4) {
        hoursSlot++;
        minsSlot = 0;
      }
      minsSlot++;
      loopIndex++;
    }
    if(this.checkselection(mobView)) {
      this.newOfferingInterface.addDayScheduleSlot("true", this.checkedSlots);
    } else {
      this.newOfferingInterface.addDayScheduleSlot("false", this.checkedSlots);
    }
  }

  checkAvailableSlots(targetId, mobView = false) {
    let hoursSlot = Number(targetId.split("_")[0]);
    let minsSlot = Number(targetId.split("_")[1]);

    if(mobView == true) {
      hoursSlot = Number(targetId.split("_")[1]);
      minsSlot = Number(targetId.split("_")[2]);
    }

    let slots = this.hours * 4 + this.mins / 15;
    let loopIndex = 1;
    let cnt = 0;
    let elementId = "";
    let htmlElement: HTMLElement;
    elementId = hoursSlot + "_" + minsSlot;

    if(mobView == true) {
      elementId = "mob_" + hoursSlot + "_" + minsSlot;
    }

    while (loopIndex <= slots) {
      elementId = hoursSlot + "_" + minsSlot;

      if(mobView == true) {
        elementId = "mob_" + hoursSlot + "_" + minsSlot;
      }

      htmlElement = document.getElementById(elementId);
      if (
        htmlElement != null && 
        htmlElement.classList.contains("slot-inner")
      ) cnt++;
      if (minsSlot == 4) {
        hoursSlot++;
        minsSlot = 0;
      }
      minsSlot++;
      loopIndex++;
    }

    if(cnt >= slots) return true;

    return false;
  }

  getValidSlotPosition(targetId, mobView = false): String {
    let hoursSlot = Number(targetId.split("_")[0]);
    let minsSlot = Number(targetId.split("_")[1]);

    if(mobView == true) {
      hoursSlot = Number(targetId.split("_")[1]);
      minsSlot = Number(targetId.split("_")[2]);
    }

    let currSlot = hoursSlot * 4 + minsSlot;
    let slots = this.hours * 4 + this.mins / 15;
    let loopIndex = 1;
    let cnt = 0;
    let elementId = "";
    let htmlElement: HTMLElement;
    elementId = hoursSlot + "_" + minsSlot;

    if(mobView == true) {
      elementId = "mob_" + hoursSlot + "_" + minsSlot;
    }

    while (loopIndex <= slots) {
      elementId = hoursSlot + "_" + minsSlot;

      if(mobView == true) {
        elementId = "mob_" + hoursSlot + "_" + minsSlot;
      }

      htmlElement = document.getElementById(elementId);

      if(htmlElement.classList.contains("slot-inner")) {
        break;
      }

      if (
        htmlElement != null && (
        htmlElement.classList.contains("slot-inner-select-first") ||
        htmlElement.classList.contains("slot-inner-select-single") ||
        htmlElement.classList.contains("slot-inner-select-last") ||
        htmlElement.classList.contains("slot-inner-select-mid")
        )
      ) cnt++;

      if(htmlElement.classList.contains("slot-inner-select-last")) {
        break;
      }

      if (minsSlot == 4) {
        hoursSlot++;
        minsSlot = 0;
      }
      minsSlot++;
      loopIndex++;
    }

    if(cnt < slots) {
      let sub = slots - cnt;
      let num = currSlot - sub;
      if(num >= 0) {
        let calMins = num % 4;
        let calHrs = Math.floor(num / 4);
        if(calMins == 0) {
          calHrs--;
          calMins = 4;
        }
        return calHrs + "_" + calMins;
      }
    }

    return null;
  }

  validateScheduler(targetId, mobView = false) {
    let hoursSlot = targetId.split("_")[0];
    let minsSlot = targetId.split("_")[1];

    if(mobView == true) {
      hoursSlot = targetId.split("_")[1];
      minsSlot = targetId.split("_")[2];
    }

    let slots = this.hours * 4 + this.mins / 15;
    let loopIndex = 1;
    let elementId = "";
    let htmlElement: HTMLElement;
    elementId = hoursSlot + "_" + minsSlot;

    if(mobView == true) {
      elementId = "mob_" + hoursSlot + "_" + minsSlot;
    }

    if(this.checkAvailableSlots(targetId, mobView) == false) {
      return false;
    }
    while (loopIndex <= slots) {
      elementId = hoursSlot + "_" + minsSlot;

      if(mobView == true) {
        elementId = "mob_" + hoursSlot + "_" + minsSlot;
      }

      htmlElement = document.getElementById(elementId);
      if (
        htmlElement != null && ( 
        htmlElement.classList.contains("slot-inner-select-first") ||
        htmlElement.classList.contains("slot-inner-select-single") ||
        htmlElement.classList.contains("slot-inner-select-last") ||
        htmlElement.classList.contains("slot-inner-select-mid")
        )
      )
        return false;
      if (minsSlot == 4) {
        hoursSlot++;
        minsSlot = 0;
      }
      minsSlot++;
      loopIndex++;
    }
    return true;
  }

  clearCalendar() {
    let elementId = "";
    let htmlElement: HTMLElement;
    for (let i = 0; i <= 23; i++) {
      for (let j = 1; j <= 4; j++) {
        elementId = i + "_" + j;
        htmlElement = document.getElementById(elementId);
        htmlElement.classList.remove(
          "slot-inner-select-first",
          "slot-inner-select-last",
          "slot-inner-select-mid",
          "slot-inner-select-single"
        );
        htmlElement.classList.add("slot-inner");
        elementId = "mob_" + i + "_" + j;
        htmlElement = document.getElementById(elementId);
        htmlElement.classList.remove(
          "slot-inner-select-first",
          "slot-inner-select-last",
          "slot-inner-select-mid",
          "slot-inner-select-single"
        );
        htmlElement.classList.add("slot-inner");
      }
    }
  }
}
