import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class CreateWeekTemplateMutation extends Mutation {

  document = gql`
    mutation CreateWeekTemplateMutation(
      $template_name: String!
      $product_id: Int!
      $id_token: String!
      $lender_email: String!
      $mon_day_template_id: Int
      $tue_day_template_id: Int 
      $wed_day_template_id: Int
      $thur_day_template_id: Int
      $fri_day_template_id: Int
      $sat_day_template_id: Int
      $sun_day_template_id: Int
    ) {
      addProductWeekTemplate(
        template_name: $template_name,
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
        mon_day_template_id: $mon_day_template_id
        tue_day_template_id: $tue_day_template_id
        wed_day_template_id: $wed_day_template_id
        thur_day_template_id: $thur_day_template_id
        fri_day_template_id: $fri_day_template_id
        sat_day_template_id: $sat_day_template_id
        sun_day_template_id: $sun_day_template_id
      )
    }
  `;
}
