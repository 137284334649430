import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class DeleteDayTemplateMutation extends Mutation {

  document = gql`
    mutation DeleteDayTemplateMutation(
      $template_id: Int
      $product_id: Int!
      $id_token: String!
      $lender_email: String!
    ) {
      deleteProductDayTemplate(
        template_id: $template_id
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
      )
    }
  `;
}
