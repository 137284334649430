import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})

export class GetProductAttributesQuery extends Query {
  
  document = gql`
    query getProductAttributesQuery(
      $product_id: Int!
    ) {
      getProductAttributes(
        product_id: $product_id
      ) {
        product_id
        attribute_key
        attribute_value
      }
    }
  `;
}
