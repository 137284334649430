<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Contact Us" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content contact-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Contact" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-9">
        <p class="mb-2">
          Having any kind of trouble using Zigwik? Please let us know. We are here for you.
        </p>
        <form name="frmContactUs" id="frmContactUs" [formGroup]="frmContactUs">
          <div class="form-group row">
            <div class="col-sm-12">
              <input
                [(ngModel)]="contactussubject"
                formControlName="subject"
                id="subject"
                type="text"
                name="subject"
                class="form-control"
                placeholder="Subject"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <textarea
                [(ngModel)]="contactusmessage"
                formControlName="message"
                id="message"
                class="form-control"
                name="message"
                placeholder="Message"
                rows="5"
                cols="56"
              ></textarea>
            </div>
          </div>

          <button [disabled]="frmContactUs.invalid" type="submit" class="btn btn-secondary swipe-to-top" (click)="sendOppMessage()">
            Send
          </button>

          <div
            id="alert-box"
            class="alert alert-success alert-dismissible"
            role="alert"
          >
            <div id="alert-msg"></div>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </form>
      </div>

      <div class="col-12 col-lg-3">
        <ul class="contact-info pl-0 mb-0">
          <li>
            <i class="fas fa-envelope"></i
            ><span>team@zigwik.com</span>
          </li>
          <li>
            <i class="fas fa-address-card"></i>
            <span class="m-2">401, Snehi Sadan, Opp G.I.D.C., Antalia, Navsari, 396325</span>
          </li>
          <li>
            <i class="fas fa-mobile-alt"></i
            ><span
              >+918490097007</span
            >
          </li>
        </ul>
        <div class="pro-socials">
          <h4>
            {{ "Follow Us" | translate }}
          </h4>
          <ul>
            <li><a href="https://www.facebook.com/zigwiktime" class="fab fb fa-facebook-square"></a></li>
            <li><a href="https://twitter.com/zigwik" class="fab tw fa-twitter-square"></a></li>
            <li><a href="https://www.linkedin.com/company/zigwik" class="fab ln fa-linkedin"></a></li>
            <li><a href="https://www.instagram.com/zigwikworld/" class="fab ig fa-instagram"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
