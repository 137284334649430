import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { Apollo } from 'apollo-angular';
import { SessionConstants } from 'src/app/constants/app.constants';
import { ResendVerificationLinkMutation } from 'src/app/services/graphql/resendVerificationLink.graphql';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resend-email-link',
  templateUrl: './resend-email-link.component.html',
  styleUrls: ['./resend-email-link.component.scss']
})
export class ResendEmailLinkComponent implements OnInit {

  hideMessage = 0;

  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private apollo: Apollo,
    private resendVerificationLinkMutation: ResendVerificationLinkMutation,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.hideMessage = +params.get('redirect');
      }
    );
    let emailVerified = this.sessionService.getKeyValues(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, "is_email_verified");

    if(emailVerified == true || emailVerified == "true" || emailVerified == 'True') {
      this.toastr.error("Your email is already verified.");
      this.router.navigate(["/"]);
      return;
    }
  }

  resendEmailLink() {
    let token = this.sessionService.getKeyValues("token", "id_token");
    let emailVerified = this.sessionService.getKeyValues("token", "is_email_verified");
    let email = this.sessionService.getLocalKeyValues("user", "email");
    var anonymousEmail = this.sessionService.getLocalKeyValues("anonymousUser", "email");

    if(email === null || email === undefined || email === "") {
      email = anonymousEmail;
    }

    if(emailVerified == true || emailVerified == "true" || emailVerified == 'True') {
      this.toastr.error("Your email is already verified.");
      this.router.navigate(["/"]);
      return;
    }

    this.apollo
      .mutate({
        mutation: this.resendVerificationLinkMutation.document,
        variables: {
          email: email,
          id_token: token
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Verification link is successfully sent to your email id.");
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

}
