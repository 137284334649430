import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetOfferMessagesQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query GetOfferMessagesQuery(
      $idToken: String!
      $userEmail: String!
      $lockId: Int!
      $offset: Int
      $count: Int
      $sortOrder: String
      ) {
        getMessages(
          id_token: $idToken,
          user_email: $userEmail,
          lock_id: $lockId,
          offset: $offset,
          count: $count,
          sort_order: $sortOrder
      ) {
        lock_id
        message
        created_at
        broadcast_flag
        offeror_message_read_flag
        offeree_message_read_flag
        user_type
        }
      }
  `;
}
