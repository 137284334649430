import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class DeactivateProductMutation extends Mutation {

  document = gql`
    mutation DeactivateProductMutation(
      $product_id: Int!
      $id_token: String
      $lender_email: String
    ) {
      forcedDeactivateProduct(
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
      )
    }
  `;
}
