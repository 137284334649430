import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class ZeroPaymentRedirectionMutation extends Mutation {
  document = gql`
    mutation zeroPaymentRedirectionMutation(
      $email: String!, 
      $id_token: String!, 
      $order_number: String!
      ) {
        handleZeroTransaction(
          email: $email, 
        id_token: $id_token, 
        order_number: $order_number
      )
    }
  `;
}
