import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class UpdateUserMutation extends Mutation {
  private _getupdateUserDataLoaded = new BehaviorSubject<string>(
    EventConstants.CONST_USER_LOADED
  );
  getupdateUserData: any;
  _getupdateUserDataLoadedObservble = this. _getupdateUserDataLoaded.asObservable();
  document = gql`
    mutation updateUserMutation(
      $user_email:String!,
      $id_token:String!,
      $first_name: String,
      $last_name: String,
      $phone: String,
      $birth_date: Date,
      $terms_accepted: Boolean,
      $adhaar_card: String,
      $pan_card: String,
      $description: String,
      $markdown_description: String,
      $address_line1: String,
      $address_line2: String,
      $landmark: String,
      $latitude:String,
      $longitude:String,
      $account_number:String,
      $bank_code: String,
      $area_name: String,
      $pin_code: String,
      $city: String,
      $district: String,
      $state: String
   ){
      updateUser(
        user_email:$user_email,
        id_token:$id_token,
        first_name:$first_name,
        last_name:$last_name,
        phone:$phone,
        birth_date:$birth_date,
        terms_accepted:$terms_accepted,
        adhaar_card:$adhaar_card,
        pan_card:$pan_card,
        description:$description,
        markdown_description: $markdown_description,
        address_line1:$address_line1,
        address_line2:$address_line2,
        landmark:$landmark,
        latitude:$latitude,
        longitude:$longitude,
        account_number:$account_number,
        bank_code:$bank_code,
        area_name:$area_name,
        pin_code:$pin_code,
        city:$city,
        district:$district,
        state:$state
        )
      }
  ` 
  ;
    populateupdateUser(data) {
        this.getupdateUserData = data["updateUser"];
        this._getupdateUserDataLoaded.next(EventConstants.CONST_UPDATE_LOADED);
      }
  }
