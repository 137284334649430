import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetAreaDetailsQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getAreaDetailsQuery(
      $sessionToken: String
      $areaId: Int
      $placeId: String
      ) {
        getAreaDetails(
          session_token: $sessionToken,
          area_id: $areaId,
          place_id: $placeId
      ) {
        area_id
        pin_code
        area_name
        city
        district
        state
        country
        }
      }
  `;
}
