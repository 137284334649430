import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { GetUserQuery } from "../../../services/graphql/getuser.graphql";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from 'src/app/services/session.service';
import { ToastrService } from 'ngx-toastr';
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { EventConstants, SessionConstants } from "src/app/constants/app.constants";

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit {

  userId = "";
  userData = [];
  userHashId = "";
  sanitizedDescription = null;
  homeProducts = [];
  profileImageLink = "";
  noProduct: Boolean = true;

  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private getUserQuery: GetUserQuery,
    private sessionService: SessionService,
    private toastr: ToastrService,
    private getProductListQuery: GetProductListQuery,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.userId = params.get('user_id');
        this.userHashId = params.get('user');
      }
    );
    
    let token = this.sessionService.getKeyValues("token", "id_token");

    if(this.userId === null || this.userId === "") {
      this.userId = atob(this.userHashId);
      this.userHashId = null;
    }

    this.apollo
          .query({
            query: this.getUserQuery.document,
            variables: {
              userId: this.userId,
              idToken: null,
            },
            fetchPolicy: "network-only"
          })
          .subscribe(
            ({ data }) => {
              this.userData = data["getUserDetails"];
              this.sanitizedDescription = this.userData["description"];
              if(this.userData["profile_image_name"] !== null && this.userData["profile_image_name"] !== "") {
                this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_MASTER_URL + this.userData["profile_image_name"];
              } else {
                this.profileImageLink = SessionConstants.CONST_PROFILE_IMAGE_DEFAULT;
              }
            },
            (error) => {
              this.toastr.error(error.message);
              this.router.navigate(["/"]);
            }
          );

          this.apollo
          .query({
            query: this.getProductListQuery.document,
            variables: {
              offeror_id: this.userId,
              category: "",
              start_date: "",
              end_date: "",
              offset: 0,
              count: 9,
            },
            fetchPolicy: "network-only"
          })
          .subscribe(
            ({ data }) => {
              if (data["getProductList"]) {
                this.getProductListQuery.populateProductList(data);
              }
            },
            (error) => {
              this.toastr.error(error.message);
              this.getProductListQuery.populateProductList(error.message);
            }
          );

          this.getProductListQuery.__prodListDataLoadedObservble.subscribe(
            (data) => {
              if (data === EventConstants.CONST_PRODLIST_LOADED) {
                this.homeProducts = [];
                if (this.getProductListQuery.responseErrorMessage) {
                  this.toastr.error(this.getProductListQuery.responseErrorMessage);
                } else {
                  this.homeProducts = this.getProductListQuery.productListData;
                }

                if(this.homeProducts.length <= 0) {
                  this.noProduct = true;
                } else {
                  this.noProduct = false;
                }
              }
            }
          );

  }
}
       
    



