import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class CreateSoftLockMutation extends Mutation {

  document = gql`
    mutation CreateSoftLockMutation(
      $schedule_id: Int!
      $product_id: Int!
      $id_token: String!
      $offeree_email: String
      $schedule_frequency: ProductScheduleFrequency!
      $price: Float!
      $start_date: Date!
      $end_date: Date!
      $slots: [ProductScheduleSlotInput!]!
    ) {
      createProductSoftLock(
        schedule_id: $schedule_id,
        product_id: $product_id
        id_token: $id_token
        offeree_email: $offeree_email
        schedule_frequency: $schedule_frequency
        price: $price
        start_date: $start_date
        end_date: $end_date
        slots: $slots
      )
    }
  `;
}
