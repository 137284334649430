import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class CreateProductTransactionTokenMutation extends Mutation {

  document = gql`
    mutation CreateProductTransactionTokenMutation(
      $payment_gateway: PaymentGateway!
      $lock_ids: [LockId]!
      $id_token: String!
      $offeree_email: String!
      $callback_url: String!
    ) {
      createProductTransactionToken(
        payment_gateway: $payment_gateway,
        lock_ids: $lock_ids,
        id_token: $id_token,
        offeree_email: $offeree_email,
        callback_url: $callback_url
      ) {
    shared_order_id,
    order_id,
    price,
    merchant_key,
    merchant_id,
    channel_id,
    website,
    customer_id,
    checksum
  }
    }
  `;
}
