import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "../../constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class GetOrderDetailsQuery extends Query {
  document = gql`
  query getOrderDetailsQuery (
    $user_type: LockInUserType!, 
    $id_token: String!,
    $user_email: String!,
    $order_id: Int,
    $lock_id: Int,
    $year: Int
    $lock_type: LockTypes
    ) {
    getProductOrders (
      user_type: $user_type, 
      id_token: $id_token,
      user_email: $user_email,
      order_id: $order_id,
      lock_id: $lock_id,
      lock_type: $lock_type,
      year: $year
      ) {
    shared_order_id
    order_id
    shared_customer_id
    offeree_id
    currency
    price_paid
    order_date
    commission
    offeror_payment_amount
    status
    locks {
        schedule_frequency
    start_date
    end_date
    product_id
    offeror_id
    offeree_id
    price
    actual_price
    refund_amount
    category_id
    category_name
    product_description
    title
    full_refund_days
    half_refund_days
    commission
    full_refund_date
    half_refund_date
    offeree_first_name
    offeree_last_name
    address_id
    product_image_link
    age
    product_guidelines
    schedule_guidelines
    product_count
    rating
    rating_comment
    buffer_days
    product_status
    schedule_id
    deposit
    calculated_deposit
    id
    lock_type
    slots {
        actual_date
    day_template_id
    slot_hours
    slot_minutes
    start_hour
    start_minute
    end_hour
    end_minute
    schedule_slot_id
    day_template_slot_id
    }
    }
  }
  }
`;
}
