import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class AddUserNewsLetterRegistrationMutation extends Mutation {

  document = gql`
    mutation AddUserNewsLetterRegistrationMutation(
      $email: String!
      $registration_active_flag: Boolean
    ) {
      changeUserNewsLetterRegistration(
        email: $email,
        registration_active_flag: $registration_active_flag
      )
    }
  `;
}
