import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class SearchAreaQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query searchAreaQuery(
      $sessionToken: String
      $titleKeyword: String
      ) {
        searchAreas(
          session_token: $sessionToken,
          title_keyword: $titleKeyword
      ) {
        session_token
        area_id
        area_information
        place_id
          }
        }
  `;
}
