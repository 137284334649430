<!-- //Sticky Header -->
<header id="stickyHeader" class="header-area header-sticky d-none">
  <div class="header-maxi bg-sticky-bar">
    <div class="container">
      <div class="d-flex align-items-center justify-content-sm-between">
        <div class="row">
          <div class="logo">
            <app-logo [isSticky]="true"></app-logo>
          </div>
          <app-header-categories [isSticky]="true"></app-header-categories>
        </div>

        <div class="">
          <ul class="pro-header-right-options">
            <li>
              <a class="pro-avatar" routerLink="/new-offering">
                {{ "Offer creation" | translate }}
              </a>
            </li>
            <li *ngIf="hideSearch">
              <a
                [routerLink]="['/search']"
                routerLinkActive="router-link-active"
              >
                <i class="fa fa-search" aria-hidden="true"></i>
              </a>
            </li>
            <li *ngIf="getUserName() === false">
              <a class="pro-avatar" routerLink="/login"
                >{{ "Sign In" | translate }}
              </a>
            </li>
            <li *ngIf="getUserName() === false">
              <a routerLink="/signup">{{ "Sign Up" | translate }} </a>
            </li>
            <li *ngIf="getUserName() !== false">
              <a>
                <app-account-dropdown></app-account-dropdown>
              </a>
            </li>
            <li *ngIf="getUserName() !== false">
              <a class="pro-avatar" routerLink="/profile">{{
                getUserName()
              }}</a>
            </li>
            <li class="dropdown">
              <app-cart-dropdown (refreshParentProductLocks)="callChildLoadSoftLocks()"></app-cart-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
