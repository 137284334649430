
<div class="container"><div class="row"><div class="col-6 col-sm-3"><label class="label">Manage Schedules</label></div><div class="col-2 col-sm-1">&nbsp;<a title="For your offer to be visible in Zigwik marketplace, you will need to activate at least one schedule. Also please activate your offer once you have activated one of your schedules."><i class="fas fa-circle-info"></i></a></div></div></div>
<div class="offering-activate-schedule">
<div class="table-wrapper-scroll-y my-custom-scrollbar-table">
  <table class="table table-bordered table-striped mb-0">
    <thead>
      <tr>
        <th scope="col">Schedule Name</th>
        <th scope="col">Schedule Type</th>
        <th scope="col">Start Date</th>
        <th scope="col">End Date</th>
        <th scope="col">#</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let schedule of schedules | async">
        <td>{{schedule["schedule_name"]}}</td>
        <td>{{schedule["schedule_frequency"]}}</td>
        <td>{{schedule["start_date"]}}</td>
        <td>{{schedule["end_date"]}}</td>
        <td><a (click)="updateSchedule(schedule['schedule_id'])" title="Edit Schedule"><i class="fas fa-lg fa-edit product-icon-passive"></i></a>&nbsp;<a *ngIf="schedule['status'] == 1" (click)="toggleScheduleStatus(schedule['schedule_id'], schedule['status'])" title="Deactivate Schedule"><i class="fas fa-lg fa-toggle-on activate-product-icon-active"></i></a><span *ngIf="schedule['status'] == 1">&nbsp;</span><a *ngIf="schedule['status'] == 0" (click)="toggleScheduleStatus(schedule['schedule_id'], schedule['status'])" title="Activate Schedule"><i class="fas fa-lg fa-toggle-off activate-product-icon-passive"></i></a><span *ngIf="schedule['status'] == 0">&nbsp;</span><a (click)="deleteSchedule(schedule['schedule_id'])" title="Delete Schedule"><i class="fas fa-lg fa-trash-alt activate-product-icon-passive"></i></a></td>
      </tr>
    </tbody>
  </table>
</div>
</div>

<form [formGroup]="frmReview">
    <div class="container">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field pb-4">
            <label class="label">Activate Offer: </label><a [routerLink]="['/offer/', productID]">{{ productTitle }}</a>
            <p class="control is-expanded has-icons-right">
                <a *ngIf="productStatus == 1" 
                (click)="toggleProductStatus()">
                  <i class="fas fa-toggle-on fa-2x activate-product-icon-active"></i>
                </a>
                <!-- <span *ngIf="productStatus == 1">&nbsp;</span> -->
                <a *ngIf="productStatus == 0" (click)="toggleProductStatus()">
                  <i class="fas fa-toggle-off fa-2x activate-product-icon-passive"></i>
                </a>
                <!-- <span *ngIf="schedule['status'] == 0">&nbsp;</span> -->
                <!-- <a (click)="deleteSchedule(schedule['schedule_id'])">
                  <i class="fas fa-trash-alt activate-product-icon-passive"></i>
                </a> -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>