<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>

<!--My Order Content -->
<section class="pro-content order-detail-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Order Information" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <app-user-sidebar></app-user-sidebar>
      </div>
      <div class="col-12 col-lg-9">
        <div class="row">
          <div class="col-12 col-md-6">
            <h4>{{ "Order ID" | translate }} 35468430</h4>

            <table class="table order-id">
              <tbody>
                <tr class="d-flex">
                  <td class="col-6 col-md-6 pb-0">
                    <strong>{{ "Order Status" | translate }}</strong>
                  </td>
                  <td class="pending col-6 col-md-6 pb-0">
                    <p>{{ "Pending" | translate }}</p>
                  </td>
                </tr>
                <tr class="d-flex">
                  <td class="col-6 col-md-6">
                    <strong>{{ "Order Date" | translate }}</strong>
                  </td>
                  <td class="col-6 col-md-6">28/04/2019</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-md-6">
            <h4>
              {{ "Payment Methods" | translate }}
            </h4>

            <table class="table order-id">
              <tbody>
                <tr class="d-flex">
                  <td class="col-6 pb-0">
                    <strong>{{ "Shipping Method" | translate }}</strong>
                  </td>
                  <td class="col-6 pb-0">{{ "Flat Rate" | translate }}</td>
                </tr>
                <tr class="d-flex">
                  <td class="col-6">
                    <strong>{{ "Payment Method" | translate }}</strong>
                  </td>
                  <td class="underline col-6">
                    {{ "Cash on Delivery" | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <h4>{{ "Shipping Details" | translate }}</h4>

            <table class="table order-id">
              <tbody>
                <tr class="d-flex">
                  <td class="address col-12 col-md-6 pb-0">
                    <strong>{{ "Shipping Address" | translate }}</strong>
                  </td>
                </tr>
                <tr class="d-flex">
                  <td class="address col-12 col-md-12">
                    Address Details, Near, City Name, Country Name
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-md-6">
            <h4>
              {{ "Billing Details" | translate }}
            </h4>

            <table class="table order-id">
              <tbody>
                <tr class="d-flex">
                  <td class="address col-12 pb-0">
                    <strong>{{ "Shipping Address" | translate }}</strong>
                  </td>
                </tr>
                <tr class="d-flex">
                  <td class="address col-12">
                    Address Details, Near, City Name, Country Name
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <table class="table top-table">
          <thead>
            <tr class="d-flex">
              <th class="col-12 col-md-2">{{ "ITEM(S)" | translate }}</th>
              <th class="col-12 col-md-4"></th>
              <th class="col-12 col-md-2">{{ "PRICE" | translate }}</th>
              <th class="col-12 col-md-2">{{ "QTY" | translate }}</th>
              <th class="col-12 col-md-2">{{ "SUBTOTAL" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="d-flex">
              <td class="col-12 col-md-2">
                <img
                  class="img-fluid"
                  src="assets/images/product_images/product_image_02.jpg"
                  alt="Product Image"
                />
              </td>
              <td class="col-12 col-md-4">
                <div class="item-detail">
                  <span class="pro-info">{{ "Earrings" | translate }}</span>
                  <h2 class="pro-title">
                    <a href="#">
                      {{ "Crytal Wedding Engagement Rings" | translate }}
                    </a>
                  </h2>

                  <div class="item-attributes"></div>
                </div>
              </td>
              <td class="col-12 col-md-2">
                <span class="item-price">$285</span>
              </td>
              <td class="col-12 col-md-2">
                <div class="input-group">2</div>
              </td>
              <td class="col-12 col-md-2">
                <span class="item-price">$540</span>
              </td>
            </tr>
            <tr class="d-flex">
              <td class="col-12 col-md-2">
                <img
                  class="img-fluid"
                  src="assets/images/product_images/product_image_02.jpg"
                  alt="Product Image"
                />
              </td>
              <td class="col-12 col-md-4">
                <div class="item-detail">
                  <span class="pro-info">{{ "Earrings" | translate }}</span>
                  <h2 class="pro-title">
                    <a href="#">
                      {{ "Crytal Wedding Engagement Rings" | translate }}
                    </a>
                  </h2>
                  <div class="item-attributes"></div>
                </div>
              </td>
              <td class="col-12 col-md-2">
                <span class="item-price">$85</span>
              </td>
              <td class="col-12 col-md-2">
                <div class="input-group">4</div>
              </td>
              <td class="col-12 col-md-2">
                <span class="item-price">$370</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="comments-area">
              <h4>
                {{ "Order Comments" | translate }}
              </h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                sagittis justo lacus, eu venenatis urna varius vitae. Vestibulum
                felis risus, tempus vitae consequat vel, porta at lacus.
                Curabitur sit amet scelerisque libero, sit amet feugiat ligula.
                Proin quis ex consectetur odio blandit vestibulum. Nullam
                fringilla pulvinar porttitor. Proin aliquet sapien fermentum
                justo placerat, et lacinia est egestas. Suspendisse ultricies
                finibus ex. Fusce sit amet est vel nisi ultricies cursus. Etiam
                ultricies malesuada dignissim.
              </p>
            </div>
          </div>
        </div>

        <!-- ............the end..... -->
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
<app-switcher></app-switcher>
