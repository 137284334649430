import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
@Injectable({
  providedIn: "root",
})
export class GetProductLocksQuery extends Query {
  document = gql`
    query getProductLocksQuery(
       $lock_id: Int, 
       $user_type: LockInUserType!,
       $id_token: String!,
       $user_email: String,
       $product_id: Int,
       $schedule_id: Int,
       $lock_type: LockTypes,
       $lock_type_arr: [LockTypes],
       $start_date: Date,
       $end_date: Date,
       $schedule_frequency: ProductScheduleFrequency
       ) {
        getProductLocks(
          lock_id:$lock_id,
          user_type:$user_type, 
          id_token:$id_token,
          user_email:$user_email,
          product_id:$product_id,
          schedule_id:$schedule_id,
          lock_type:$lock_type,
          lock_type_arr: $lock_type_arr,
          start_date:$start_date,
          end_date:$end_date,
          schedule_frequency:$schedule_frequency
          ) {
            schedule_frequency
            start_date
            end_date
            product_id
            offeror_id
            offeree_id
            offeree_first_name
            offeree_last_name
            price
            actual_price
            refund_amount
            coupon_code_id
            coupon_code
            discounted_price
            coupon_discount_percentage
            commission
            category_id
            category_name
            product_description
            title
            address_id
            product_image_link
            age
            product_guidelines
            schedule_guidelines
            full_refund_days
            full_refund_date
            half_refund_days
            half_refund_date
            rating
            rating_comment
            product_count
            buffer_days
            product_status
            grievance_window_open_flag
            schedule_id
            schedule_name
            schedule_start_date
            schedule_end_date
            deposit
            calculated_deposit
            id
            lock_type
            shared_invoice_id
            tax_offeror_id
            tax_offeree_id
            tax_offeror_fullname
            tax_offeree_fullname
            tax_offeror_address_id
            tax_offeree_address_id
            tax_order_id
            tax_shared_order_id
            tax_lock_id
            price_before_tax
            price_after_tax
            commission_before_tax
            commission_after_tax
            slots { 
              actual_date
              day_template_id
              slot_hours
              slot_minutes
              start_hour
              start_minute
              end_hour
              end_minute
              schedule_slot_id
              day_template_slot_id
            }
      }
    }
  `;
}