import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetMaskedUserDetailsQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getMaskedUserDetailsQuery(
      $user_id: Int
      $masked_id: String
      $email: String
      $phone: String
      ) {
        getMaskedUserDetails(
          user_id: $user_id,
          masked_id: $masked_id,
          email: $email,
          phone: $phone
      ) {
        id
        masked_id
        email
        phone
        }
      }
  `;
}
