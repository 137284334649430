import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class ResendVerificationLinkMutation extends Mutation {
  document = gql`
    mutation ResendVerificationLinkMutation(
      $email: String!, 
      $id_token: String!
      ){
        resendVerificationEmail(
          email: $email 
          id_token: $id_token
        )
    }
  `;
}
