<app-page-loader></app-page-loader>
<app-header [reverseLoginCheck]=true></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [reverseLoginCheck]=true></app-header-mobile>

<!-- login Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Login" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>

<section class="pro-content login-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Login" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <div class="registration-process">
          <h2>{{ "Already Registered?" | translate }}</h2>
          <form [formGroup]="loginForm">
            <div
              *ngIf="errorMessage"
              class="input-group col-12 alert alert-danger alert-dismissible"
            >
              <a
                href="#"
                class="close"
                data-dismiss="alert"
                aria-label="close"
                (click)="alertCloseHandler()"
                >&times;</a
              >
              {{ errorMessage }}
            </div>

            <div class="from-group row mb-4">
              <div class="input-group col-12">
                <input
                  type="text"
                  name="email"
                  class="form-control"
                  formControlName="email"
                  placeholder="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <div
                  *ngIf="submitted && f.email.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="submitted && f.email.errors.required">
                    Email is required
                  </div>
                  <div *ngIf="f.email.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
            </div>
            <div class="from-group row mb-4">
              <div class="input-group col-12">
                <input
                  type="password"
                  name="password"
                  class="form-control"
                  formControlName="password"
                  placeholder="password"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                />
                <div
                  *ngIf="submitted && f.password.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.password.errors.required">
                    Password is required
                  </div>
                  <div *ngIf="f.password.errors.minlength">
                    Password must be at least 6 characters
                  </div>
                </div>
              </div>
            </div>
            <div class="from-group">
              <button id="loginButton"
                class="button is-outline is-create is-orange m-2"
                (click)="loginUser()"
              >
                {{ "Login" | translate }}
              </button>
              <a [routerLink]="'/forgot'" class="btn btn-link">{{
                "Forgot Password" | translate
              }}</a>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <h2>{{ "New Customer" | translate }}</h2>
        <div class="registration-process">
          <p>
            Get access to your Offers and recommendations. Register your own offer to share with others.
          </p>
          <br/>
          <form>
            <div class="from-group">
              <a
                [routerLink]="'/signup'"
                class="button is-outline is-create is-black swipe-to-top"
                >{{ "Create an Account" | translate }}</a
              >
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 col-sm-12">
        <div class="registration-socials">
          <p class="mb-3 text-center">
            {{ "Access Your Account Through Your Social Networks" | translate }}
          </p>
          <div class="btn-group">
            <button type="button" class="btn btn-google mr-2 swipe-to-top" (click)="googleLogin()">
              <i class="fab fa-google-plus-g"></i>&nbsp;Google
            </button>
            <!-- <button type="button" class="btn btn-facebook mr-2 swipe-to-top" (click)="facebookLogin()">
              <i class="fab fa-facebook-f"></i>&nbsp;Facebook
            </button> -->
            <!-- <button type="button" class="btn btn-twitter swipe-to-top" (click)="twitterLogin()">
              <i class="fab fa-twitter"></i>&nbsp;Twitter
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
<!-- <app-switcher></app-switcher> -->
