import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class CreateAnonymousUserMutation extends Mutation {
  document = gql`
    mutation CreateAnonymousUserMutation {
      createAnonymousUser {
        creation_status
        token_type
        id_token
        refresh_token
        expires_in
        id
        user_status
        is_email_verified
      }
    }
  `;
}
