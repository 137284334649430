import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class RejectOffereeLockMutation extends Mutation {

  document = gql`
    mutation rejectOffereeLockMutation(
      $lock_id: Int!
      $id_token: String!
      $offeree_email: String!
      $order_id: Int!
      $refund_check: Boolean!
    ) {
      offereeLockReject(
        lock_id: $lock_id
        id_token: $id_token
        offeree_email: $offeree_email
        order_id: $order_id
        refund_check: $refund_check
      )
    }
  `;
}
