import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-offerings-sidebar",
  templateUrl: "./offerings-sidebar.component.html",
  styleUrls: ["./offerings-sidebar.component.scss"],
})
export class OfferingsSidebarComponent implements OnInit {
  currRoute = null;
  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.currRoute = this.router.url;
  }
}
