<header id="headerMobile" class="header-area header-mobile pl-2">
    <app-logo></app-logo>
    <div class="header-mobile_right">
        <app-cart-dropdown (refreshParentProductLocks)="callChildLoadSoftLocks()"></app-cart-dropdown>
        <button *ngIf="getUserName() == false" routerLink="/login" class="mobile_right-btn mr-5 mt-2">Sign In</button>
        <button id="mobile-right-button-id" class="mobile_right-btn mt-2 mr-4" (click)="toggleSideMenu()"> <img src="../../../../assets/images/logo/mobile-dark-nav-icon.svg" alt="" srcset=""></button>
    </div>

    <div id="left_menu_id" class="left_menu-bar">
        <header class="pl-2">
            <app-logo></app-logo>
        </header>
        <hr>
        <ul class="mobile-menu-list">
            <li class="mobile-menu-value">
                <a (click)="toggleSideMenu()" ><i class="fa fa-window-close is-size-3" aria-hidden="true"></i></a>
            </li>
            <ng-container *ngFor="let item of navLinksMobile">
                <li class="mobile-menu-value" *ngIf="item?.show" (click)="item.name == 'logout' ?  logout() : '' " >
                    <a [routerLink]="item.name == 'logout' ? '' : item?.link" > {{item.name | uppercase}} </a>
                </li>
            </ng-container>
            
        </ul>
    </div>
</header>