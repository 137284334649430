import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetProductOfferorAggReportQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getProductOfferorAggReportQuery(
      $offerId: Int!
      $scheduleFrequency: String!
      $userEmail: String!
      $idToken: String!
      ) {
          getOfferorAggregateReport(
            offer_id: $offerId
            id_token: $idToken
            user_email: $userEmail
            schedule_frequency: $scheduleFrequency
          ) 
          {
            total_annual_earning
            total_monthly_unpaid_amount
          }
        }
  `;
}
