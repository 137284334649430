<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header-mobile>

<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Offering Messenger" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<br /><br /><br />
<div class="container">
    <div class="row clearfix">
        <div class="col-lg-3">
            <app-offerings-sidebar></app-offerings-sidebar>
        </div>
        <div class="col-lg-9">
            <div class="dropdown mb-2">
                <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Select User Type
                </button><br /><span class="p-2" *ngIf="userType == 'offeree'">Offeree Selected</span><span class="p-2" *ngIf="userType == 'offeror'">Offeror Selected</span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" id="offeror" (click)="fetchOfferMessageChecks('offeror')">Offeror</a>
                  <a class="dropdown-item" id="offeree" (click)="fetchOfferMessageChecks('offeree')">Offeree</a>
                </div>
              </div>
            <div id="chat-check-div" class="card chat-app hidden-chat">
                <div id="plist" class="people-list" style="overflow-y: scroll; height:270px;">
                    <ul id="message_check_display" class="list-unstyled chat-list mt-2 mb-0">
                        <li id="checkelement_{{messageCheck['lock_id']}}" class="clearfix" (click)="changeUserChatWindow(messageCheck)"
                        [class.active]="((productLockId == null || productLockId == 0) && indexOfelement == 0) || (productLockId != null && productLockId != 0 && messageCheck['lock_id'] == productLockId)" *ngFor="let messageCheck of messageChecks;let indexOfelement=index;">
                            <ng-template [ngIf]="userType == 'offeree'">
                                <img src="{{messageCheck['offeror_image_url']}}" alt="avatar">
                                <div class="about">
                                    <div class="name">{{messageCheck["offeror_full_name"]}}
                                      
                                        &nbsp;<i *ngIf="(((productLockId == null || productLockId == 0) && indexOfelement != 0) || (productLockId != null && productLockId != 0 && messageCheck['lock_id'] != productLockId)) && (((showUnreadMessageFlags | async).has(messageCheck['lock_id']) && (showUnreadMessageFlags | async).get(messageCheck['lock_id']) == true) || !(showUnreadMessageFlags | async).has(messageCheck['lock_id'])) && userType == 'offeree' && messageCheck['offeree_total_unread_messages'] != null && messageCheck['offeree_total_unread_messages'] != '' && messageCheck['offeree_total_unread_messages'] > 0" class="fa fa-envelope"></i>
                                      
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="userType == 'offeror'">
                                <img src="{{messageCheck['offeree_image_url']}}" alt="avatar">
                                <div class="about">
                                    <div class="name">{{messageCheck["offeree_full_name"]}}</div>
                                    &nbsp;<i *ngIf="(((productLockId == null || productLockId == 0) && indexOfelement != 0) || (productLockId != null && productLockId != 0 && messageCheck['lock_id'] != productLockId)) && (((showUnreadMessageFlags | async).has(messageCheck['lock_id']) && (showUnreadMessageFlags | async).get(messageCheck['lock_id']) == true) || !(showUnreadMessageFlags | async).has(messageCheck['lock_id'])) && userType == 'offeror' && messageCheck['offeror_total_unread_messages'] != null && messageCheck['offeror_total_unread_messages'] != '' && messageCheck['offeror_total_unread_messages'] > 0" class="fa fa-envelope"></i>
                                </div>
                            </ng-template>
                            <div class="status">Offer: {{messageCheck["product_title"]}}</div>
                        </li>
                    </ul>
                </div>
                <div id="offer-messages" class="chat" *ngIf="userType != null">
                    <div class="chat-header clearfix">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                    <img src="{{selectedUser['other_user_profile_link']}}" alt="avatar">
                                </a>
                                <div class="chat-about">
                                    <h6 class="m-b-0"><a [routerLink]="['/public-profile/', selectedUser['other_user_id']]">{{selectedUser['other_user_full_name']}}</a></h6>
                                    <small>Offer: <a [routerLink]="['/offer/', selectedUser['offer_id']]">{{selectedUser['offer_title']}}</a></small>
                                    <ng-template #offerLockContent let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Additional Details</h4>
                                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          








                                            <table class="table border-0">
                                                <tbody>
                                                  <tr class="border-0">
                                                    <td>
                                                      <div class="col-md-12 icon-box border-0" data-aos="fade-up" data-aos-delay="500">
                                                      <div class="border-0" id="offerorDetail_{{productLockDetails['id']}}">
                                                        <div class="card card-body border-0">
                                                          <table class="w-auto border-0">
                                                            <tr class="border-0">
                                                              <th width="30%">Start&nbsp;date:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['start_date'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%">End&nbsp;date:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['end_date'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%">Additional&nbsp;Guidelines:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['schedule_guidelines'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%">Maximum&nbsp;offer&nbsp;count:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['product_count'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%">Approval&nbsp;days&nbsp;for&nbsp;Offer:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['buffer_days'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%">Full&nbsp;refund&nbsp;before:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['full_refund_date'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%">Half&nbsp;refund&nbsp;before:</th>
                                                              <td width="70%" valign="bottom">{{ productLockDetails['half_refund_date'] }}</td>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <th width="30%" colspan="2">Slot(s):</th>
                                                            </tr>
                                                            <tr class="border-0">
                                                              <td width="70%" valign="bottom" colspan="2">
                                                                <div class="table-wrapper-scroll-y chat-slot-scrollbar-table">
                                                                <table class="table table-bordered table-striped">
                                                                    <tr>
                                                                      <th>&nbsp;Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                      <th>Duration</th>
                                                                      <th>Start&nbsp;time</th>
                                                                      <th>End&nbsp;time</th>
                                                                    </tr>
                                                                  <tbody>
                                                                    <tr *ngFor="let slot of productLockDetails['slots']">
                                                                      <td>&nbsp;{{slot["actual_date"]}}</td>
                                                                      <td>
                                                                        {{slot["slot_hours"]}}&nbsp;hour&nbsp;{{slot["slot_minutes"]}}&nbsp;minute
                                                                      </td>
                                                                      <td>
                                                                        <ng-template [ngIf]="slot['start_hour'].toString().length < 2">0{{slot["start_hour"]}}</ng-template><ng-template [ngIf]="slot['start_hour'].toString().length >= 2">{{slot["start_hour"]}}</ng-template>:<ng-template [ngIf]="slot['start_minute'].toString().length < 2">0{{slot["start_minute"]}}</ng-template><ng-template [ngIf]="slot['start_minute'].toString().length >= 2">{{slot["start_minute"]}}</ng-template>
                                                                      </td>
                                                                      <td>
                                                                        <ng-template [ngIf]="slot['end_hour'].toString().length < 2">0{{slot["end_hour"]}}</ng-template><ng-template [ngIf]="slot['end_hour'].toString().length >= 2">{{slot["end_hour"]}}</ng-template>:<ng-template [ngIf]="slot['end_minute'].toString().length < 2">0{{slot["end_minute"]}}</ng-template><ng-template [ngIf]="slot['end_minute'].toString().length >= 2">{{slot["end_minute"]}}</ng-template>
                                                                      </td>
                                                                    </tr>
                                                                  </tbody>
                                                                </table>
                                                            </div>
                                                              </td>
                                                            </tr>



                                                            
                                                          </table>
                                                        </div>
                                                      </div>
                                                    </div>
                                                      </td>
                                                      </tr>
                                                      </tbody>
                                                      </table>
                                                    










                                        </div>
                                      </ng-template>
                                    &nbsp;&nbsp;<a class="ps-2" (click)="openModal(offerLockContent)"><i class="fas fa-info-circle text-secondary"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-history" style="overflow-y: scroll; height:400px;">
                        <div class="col text-center" *ngIf="showLoadMessageButton == true">
                            <button class="button is-primary is-small is-outlined mb-3" (click)="loadMoreMessages()">load chat</button>
                        </div>
                        <ul id="chat-ul" class="m-b-0">
                            <li class="clearfix" *ngFor="let message of messages">
                                <div class="message-data" 
                                [class.text-right]="userType == message['user_type']">
                                    <span class="message-data-time">{{message["created_at"]}}</span>
                                    <ng-template [ngIf]="userType == message['user_type']">
                                        <img src="{{selectedUser['user_profile_link']}}" alt="avatar">
                                    </ng-template>
                                    <ng-template [ngIf]="userType != message['user_type']">
                                        <img src="{{selectedUser['other_user_profile_link']}}" alt="avatar">
                                    </ng-template>
                                </div>
                                <div class="message"
                                [class.other-message]="userType == message['user_type']"
                                [class.float-right]="userType == message['user_type']"
                                [class.my-message]="userType != message['user_type']">
                                    {{message["message"]}}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="chat-message clearfix">
                        <div class="input-group mb-0">
                            <div class="input-group-prepend is-clickable" (click)="sendMessage()">
                                <span class="input-group-text"><i class="fa fa-paper-plane"></i></span>
                            </div>
                            <input [(ngModel)]="textMessage" id="text-message" type="text" class="form-control" placeholder="Enter text here...">                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>


    <app-footer-mobile></app-footer-mobile>
    <app-footer></app-footer>
    <app-accept-cookie></app-accept-cookie>
    <app-back-to-top></app-back-to-top>
    