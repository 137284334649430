import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetDayTemplatesQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getDayTemplatesQuery($productId: Int!) {
      getProductDayTemplates(
        product_id: $productId
      ) {
        day_template_id
        template_name
        slots{
          slot_id
          slot_hours
          slot_minutes
          start_hour
          start_minute
          end_hour
          end_minute
          }
        }
      }
  `;
}
