import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class GenerateAIDescriptionMutation extends Mutation {

  document = gql`
    mutation GenerateAIDescriptionMutation(
      $offeror_email: String!
      $id_token: String!
      $input_text: String
      $guidelines_flag: Boolean
    ) {
      generateAIDescription(
        offeror_email: $offeror_email,
        id_token: $id_token,
        input_text: $input_text,
        guidelines_flag: $guidelines_flag
      )
    }
  `;
}
