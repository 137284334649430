<div class="block">
    <div class="pro-heading-subtitle">
        <h4>
          Recent Posts
        </h4>
      
    </div>
      <div class="media">
          <img src="assets/images/miscellaneous/avatar.jpg" alt="Avatar" style="width:68px;height:68px;" >
          <div class="media-body">
              <h6 ><a [routerLink]="'/blog-single-post'">Gold bangle is worn on top of the engagement band</a></h6>
            <p>Lorem ipsum dolor sit amet, 
              consectetur adipiscing elit, 
              sed do eiusmod.</p>
          </div>
        </div>
        <div class="media">
            <img src="assets/images/miscellaneous/avatar.jpg" alt="Avatar" style="width:68px;height:68px;">
            <div class="media-body">
                <h6 ><a [routerLink]="'/blog-single-post'">Woman wearing all kind of silver colore jewelery</a></h6>
              <p>Lorem ipsum 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>

          <div class="media">
              <img src="assets/images/miscellaneous/avatar.jpg" alt="Avatar" style="width:68px;height:68px;">
              <div class="media-body">
                  <h6 ><a [routerLink]="'/blog-single-post'">White gold engagement rings for couples</a></h6>
                <p>Lorem ipsum dolor sit amet, 
                  ut labore et dolore magna aliqua.</p>
              </div>
            </div>
          
        
</div>
<div class="block">
    <div class="pro-heading-subtitle">
        <h4>
          Latest Comments
        </h4>
      
    </div>
      <div class="media">
          <i class="fas fa-comments"></i>
          <div class="media-body">
            <p><a [routerLink]="'/blog-single-post'">
                Lorem ipsum dolor sit amet, 
                consectetur aliqua.
            </a></p>
              <span><i class="far fa-clock"></i>Dec 25,2019</span>
          </div>
        </div>
        <div class="media">
            <i class="fas fa-comments"></i>
            <div class="media-body">
              <p><a [routerLink]="'/blog-single-post'">Lorem ipsum dolor sit amet, 
                  ut labore et dolore magna aliqua.</a></p>
                <span><i class="far fa-clock"></i>Dec 25,2019</span>
            </div>
          </div>
          <div class="media">
              <i class="fas fa-comments"></i>
              <div class="media-body">
                <p><a [routerLink]="'/blog-single-post'">Adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</a></p>
                  <span><i class="far fa-clock"></i>Dec 25,2019</span>
              </div>
            </div>

</div>
<div class="block">
  <div class="pro-socials">
      <h4>
          Stay Connected
      </h4>
      <ul>
          <li><a href="#" class="fab fb fa-facebook-square"></a></li>
          <li><a href="#" class="fab tw fa-twitter-square"></a></li>
          <li><a href="#" class="fab sk fa-skype"></a></li>
          <li><a href="#" class="fab ln fa-linkedin"></a></li>
          <li><a href="#" class="fab ig fa-instagram"></a></li>
      </ul>
  </div>
</div>