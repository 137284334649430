import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetProductSchedulesQuery extends Query {
  responseErrorMessage = "";
  document = gql`
    query getProductSchedulesQuery(
      $productId: Int!,
      $idToken: String,
      $lenderEmail: String,
      $scheduleId: Int,
      $scheduleName: String,
      $getDaySlots: Boolean,
      $scheduleFrequency: ProductScheduleFrequency,
      $includeDeleted: Boolean,
      $startDate: Date,
      $endDate: Date
      ) {
      getProductSchedules(
        product_id: $productId
        id_token: $idToken
        lender_email: $lenderEmail
        schedule_id: $scheduleId
        get_day_slots: $getDaySlots
        schedule_name: $scheduleName
        schedule_frequency: $scheduleFrequency
        include_deleted: $includeDeleted
        start_date: $startDate
        end_date: $endDate
      ) {
        product_id
        schedule_id
        schedule_name
        schedule_frequency
        price
        calculated_price
        discounted_price
        offer_discount_id
        offer_discount_percentage
        offer_discount_end_time
        start_date
        end_date
        deposit
        buffer_days
        product_count
        status
        is_deleted
        deletion_time
        guidelines
        slots{
          slot_id,
          date,
          template_type,
          template_id,
          day_template_id,
          day_slots
        }
        cancellation_policies{
          type,
          days
        }
      }
    }
  `;

}
