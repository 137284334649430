<!-- <app-page-loader></app-page-loader> -->
<app-header
  [offsetValue]="offset"
  [countValue]="page_count"
  (offsetSetting)="setOffset($event)"
  (pageCountSetting)="setPageCount($event)"
></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile
  [offsetValue]="offset"
  [countValue]="page_count"
  (offsetSetting)="setOffset($event)"
  (pageCountSetting)="setPageCount($event)"
></app-header-mobile>
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Search" | translate }}
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ url || "Search Page results" }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<ngx-spinner bdColor="rgba(90, 90, 90, 0.8)" style="color: #f4696b" z-index="1099" fullScreen="false" type="square-jelly-box" size="medium">
  </ngx-spinner>
<section class="container search-filter">
  <app-filter-serach 
  (offsetSetting)="setOffset($event)" 
  (pageCountSetting)="setPageCount($event)"
  (spinnerShow)="spinnerShow()"
  (spinnerHide)="spinnerHide()"></app-filter-serach>
</section>
<section class="pro-content shop-content shop-special">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Search Page results" | translate }}
        </h1>
      </div>
    </div>
    <div class="products-area">
      <div class="row">
        <div id="swap" class="col-12 col-sm-12">
          <div class="row">
            <div
              *ngIf="this.responseErrorMessage; else templateBlock"
              class="input-group col-12 alert alert-danger alert-dismissible"
            >
              {{ responseErrorMessage }}
            </div>
          </div>
          <ng-template #templateBlock>
          <div
              class="col-12 col-md-6 col-lg-4 col-xl-3 cards-list"
            *ngFor="let plist of this.plists"
          >
            <app-product-card [data]="plist"></app-product-card>
          </div>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3086321925552621"
     crossorigin="anonymous"></script>
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="+1i+qj+1c+f+3q"
     data-ad-client="ca-pub-3086321925552621"
     data-ad-slot="7757970785"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
        </div>
            <div class="pagination justify-content-between">
              <label class="col-form-label">Toggle between pages</label>
              <div class="col-12 col-sm-6">
                <button
                  class="button m-2"
                  (click)="previousPage()"
                  *ngIf="this.offset > 0"
                >
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
                <button 
                  *ngIf="this.plists != null && this.plists.length == this.page_count"
                  class="button m-2" 
                  (click)="nextPage()">
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
          <app-footer-mobile></app-footer-mobile>
          <app-footer></app-footer>
          <app-accept-cookie></app-accept-cookie>
          <app-product-modal></app-product-modal>
          <app-back-to-top></app-back-to-top>
          <!-- <app-switcher></app-switcher> -->
          