import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UploadResult, MdEditorOption } from "ngx-markdown-editor";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-markdown-text-editor',
  templateUrl: './markdown-text-editor.component.html',
  styleUrls: ['./markdown-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarkdownTextEditorComponent implements OnInit {

  @Output() newItemHtmlValue = new EventEmitter<string>();
  @Output() newItemMarkdownValue = new EventEmitter<string>();

  @Input() inputValue = new BehaviorSubject<string>(
    ""
  );

  public options: MdEditorOption = {
    showPreviewPanel: false,
    enablePreviewContentClick: false,
    resizable: true,
    customRender: {
      image: function(href: string, title: string, text: string) {
        let out = `<img style="max-width: 100%; border: 20px solid red;" src="${href}" alt="${text}"`;
        if (title) {
          out += ` title="${title}"`;
        }
        out += (<any>this.options).xhtml ? "/>" : ">";
        return out;
      }
    }
  };

  public content: string;
  public mode: string = "editor";

  constructor() {
    this.preRender = this.preRender.bind(this);
    this.postRender = this.postRender.bind(this);
  }

  ngOnInit() {
    let contentArr = ["[comment]: <> (This field gives you a way to make your text standout. You can customise your the text using Markdown text. To learn what is Markdown and how it works, check out: https://www.markdownguide.org/.)"];
    contentArr.push("[comment]: <> (This editor will help you to put Markdown text in place.)");
    contentArr.push("**Welcome**");
    this.content = contentArr.join("\r\n");

    this.inputValue.subscribe(
      (data) => {
        if(data != null && data != "") {
          this.content = data;
        }
      }
    );
  }

  togglePreviewPanel() {
    this.options.showPreviewPanel = !this.options.showPreviewPanel;
    this.options = Object.assign({}, this.options);
  }

  changeMode() {
    if (this.mode === "editor") {
      this.mode = "preview";
    } else {
      this.mode = "editor";
    }
  }

  togglePreviewClick() {
    this.options.enablePreviewContentClick = !this.options
      .enablePreviewContentClick;
    this.options = Object.assign({}, this.options);
  }

  toggleResizeAble() {
    this.options.resizable = !this.options.resizable;
    this.options = Object.assign({}, this.options);
  }

  onEditorLoaded(editor) {
    console.log(`ACE Editor Ins: `, editor);
  }

  preRender(mdContent) {
    console.log(`preRender fired`);
    return mdContent;
  }

  postRender(html) {
    console.log(`postRender fired`);
    return html;
  }

  onPreviewDomChanged(dom: HTMLElement) {
    this.newItemHtmlValue.emit(dom.innerHTML);
    this.newItemMarkdownValue.emit(this.content);
  }

}
