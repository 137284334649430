import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class DeleteProductImageMutation extends Mutation {

  document = gql`
    mutation DeleteProductImageMutation(
      $product_image_id: String!
      $user_email: String!
      $id_token: String!
      $product_id: String!
    ) {
      deleteProductImage(
        product_image_id: $product_image_id,
        product_id: $product_id
        id_token: $id_token
        user_email: $user_email
      )
    }
  `;
}
