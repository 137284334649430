<header id="headerOne" class="header-desktop">
  <div class="header-main bg-header-bar">
    <div class="container pt-2">
      <div class="row">
        <div class="logo" >
          <app-logo [showWhiteLogo]="showWhiteLogo"></app-logo>
        </div>
        <app-header-categories *ngIf="showWhiteLogo"></app-header-categories>
      </div>
      
      
      <div class="headre-right">
        <ul class="row pro-header-options" [ngClass]="!showWhiteLogo ? 'back-text' : 'white-text'">
          <li class="dropdown">
              <app-cart-dropdown [showWhiteCart]="showWhiteLogo" (refreshParentProductLocks)="callChildLoadSoftLocks()"></app-cart-dropdown>
          </li>
          <li  *ngIf="getUserName() !== false">
            <a
              class="pro-avatar"
              routerLink="/profile"
              >{{ getUserName() }}</a
            >
          </li>
          <li>
            <a 
            class="pro-avatar"
            routerLink="/new-offering">
              {{"Offer creation" | translate}}
            </a>
          </li>
          <li *ngIf="getUserName() === false">
            <a
            
            class="pro-avatar"
            routerLink="/login"
            >{{ "Sign In" | translate }}
          </a>
          </li>
          <!-- <li *ngIf="getUserName() === false">
            <div id="g_id_onload"
    data-client_id="923526178661-tni3087foi6ep45fqnl78nd1scpli2od.apps.googleusercontent.com"
    data-context="signup"
    data-ux_mode="popup"
    data-callback="googlelogin"
    data-auto_select="true"
    data-itp_support="true">
</div>

<div class="g_id_signin"
    data-type="icon"
    data-shape="square"
    data-theme="outline"
    data-text="signin_with"
    data-size="large">
</div>
          </li> -->
          <li *ngIf="getUserName() === false">
            <a
            
            class="signin-btn"
            [ngClass]="!showWhiteLogo ? 'green-btn-color'  : '' "
            routerLink="/signup"
            >{{ "Sign Up" | translate }}
          </a>
          </li>
          <li *ngIf="getUserName() !== false">
              <app-account-dropdown  ></app-account-dropdown>
          </li>
        </ul>
      </div>


    </div>
  </div>
</header>
