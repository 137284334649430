<section class="container">
  <!-- The navbar for the wizard -->
  <header class="header">
    <ol>
      <ng-container *ngFor="let step of steps; let i = index;">
        <li [ngClass]="(selectedIndex === i) ? activeClass : ''">
          <a (click)="clickActive(i)">
            <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
            </ng-container>
            <ng-template #showLabelText>
              {{ step.label }}
            </ng-template>
          </a>
        </li>
      </ng-container>
    </ol>
  </header>

  <div class="body">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>
</section>
