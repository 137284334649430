import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class FinalPaymentRedirection extends Mutation {
  document = gql`
    mutation finalPaymentRedirection(
      $offeree_email: String!, 
      $id_token: String!, 
      $masked_hash: String!
      ) {
      handleFinalPaymentRedirection(
        offeree_email: $offeree_email, 
        id_token: $id_token, 
        masked_hash: $masked_hash
      )
    }
  `;
}
