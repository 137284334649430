import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
@Injectable({
  providedIn: "root",
})
export class GetProductAttributesQuery extends Query {
    document = gql`
    query getProductAttributesQuery ($product_id: Int!, $attribute_key: String ) {
      getProductAttributes (attribute_key: $attribute_key, product_id: $product_id) {
        product_id
        attribute_key
        attribute_value
      }
    }
  `;
}
