import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class GetWeekTemplatesQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query getWeekTemplatesQuery($productId: Int!) {
      getProductWeekTemplates(
        product_id: $productId
      ) {
          week_template_id
          product_id
          template_name
          mon_day_template_id
          tue_day_template_id
          wed_day_template_id
          thur_day_template_id
          fri_day_template_id
          sat_day_template_id
          sun_day_template_id
          }
        }
  `;
}
