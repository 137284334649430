import { Component, OnInit } from '@angular/core';
import { AddUserNewsLetterRegistrationMutation } from 'src/app/services/graphql/addUserNewsLetterRegistration.graphql';
import { Apollo } from "apollo-angular";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {

  constructor(
    private apollo: Apollo,
    private addUserNewsLetterRegistrationMutation: AddUserNewsLetterRegistrationMutation,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  addRegistration(email) {
    this.apollo
      .mutate({
        mutation: this.addUserNewsLetterRegistrationMutation.document,
        variables: {
          email: email,
          registration_active_flag: true,
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Thank you for your registration.");
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

}
