import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "../../constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class GetAreaQuery extends Query {
  private _areaDataLoaded = new BehaviorSubject<string>(
    EventConstants.CONST_AREA_LOADED
  );
  _areaDataLoadedObservble = this._areaDataLoaded.asObservable();

  // variable to manage areas
  areaName = [];
  city = [];
  district = [];
  country = [];

  document = gql`
    query getAreaQuery(
      $pin_code: String
      $area: String
      $city: String
      $district: String
      $state: String
      $country: String
      $countryAbbreviation: String
    ) {
      getArea(
        pin_code: $pin_code
        area: $area
        city: $city
        district: $district
        state: $state
        country: $country
        country_abbreviation: $countryAbbreviation
      ) {
        area_id
        pin_code
        area_name
        city
        district
        country
      }
    }
  `;

  populateAreaData(data) {
    let area = [];
    let city = [];
    let district = [];
    let country = [];
    let areaData = data["getArea"];
    areaData.forEach((item) => {
      area.push(item["area_name"]);
      city.push(item["city"]);
      district.push(item["district"]);
      country.push(item["country"]);
    });
    this.areaName = area.filter(this.onlyUnique); // segregated product category from data
    this.city = city.filter(this.onlyUnique); // segregated category group from data
    this.district = district.filter(this.onlyUnique); // segregated category group from data
    this.country = country.filter(this.onlyUnique); // segregated category group from data
    this._areaDataLoaded.next(EventConstants.CONST_AREA_LOADED);
  }

  /**
   * @description filtering unique values from array
   * @param value
   * @param index
   * @param self
   */
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
