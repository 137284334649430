<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true [withAnonymous]=true ></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true [withAnonymous]=true ></app-header-mobile>

<!-- login Content -->
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Change Password" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>
<section class="pro-content profile-content">
  <div class="container">
    <div class="row">
      <div class="pro-heading-title">
        <h1>
          {{ "Change Password Page" | translate }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="media">
          <img [src]="profileImageLink" alt="avatar" />
          <div class="media-body">
            <h5 class="mt-0">{{ first_name }}&nbsp;{{ last_name }}</h5>
            <p>
              {{ "E-mail" | translate }} :<a href="#">{{ email }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-3">
          <app-user-sidebar [inputUserId]="hashed_user_id"></app-user-sidebar>
        </div>
        <div class="col-12 col-lg-9">
           <div class="registration-process" *ngIf ="!isLoggedIn">
            <form [formGroup]="changePasswordForm1">
              <div class="from-group mb-3">
                <div class="input-group col-12">
                  <div class="input-group col-sm-12 alert alert-danger" *ngIf ="messageString">
                    <div>
                      {{ messageString }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="from-group mb-4">
                <div class="input-group col-12">
                  <input type="text" name="email" class="form-control" formControlName="email" placeholder="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="submitted && f.email.errors.required">
                      Email is required
                    </div>
                    <div *ngIf="f.email.errors.email">
                      Email must be a valid email address
                    </div>
                  </div>
                </div>
              </div>
              <div class="from-group mb-4">
                <div class="input-group col-12">
                  <input type="text" name="confirmemail" class="form-control" formControlName="confirmemail"
                    placeholder="confirm email" [ngClass]="{
                        'is-invalid': submitted && f.confirmemail.errors
                      }" />
                  <div *ngIf="submitted && f.confirmemail.errors" class="invalid-feedback">
                    <div *ngIf="submitted && f.confirmemail.errors.required">
                      Email is required
                    </div>
                    <div *ngIf="f.confirmemail.errors.email">
                      Email must be a valid email address
                    </div>
                    <div *ngIf="f.confirmemail.errors.mustMatch">
                      Email must match
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <button class="btn btn-secondary swipe-to-top" (click)="changePassword()">
                  {{ "Change" | translate }}
                </button>
              </div>
            </form>
          </div>
          <div class="registration-process" *ngIf="isLoggedIn">
            <div class="from-group mb-4">
              <div class="col-12 col-sm-12">
                <div class="alert alert-success" *ngIf ="messageString">
                  {{messageString}}
                 <!-- <div>An email sent to your email id.</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
</section>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-back-to-top></app-back-to-top>