import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class SendOfferMessageMutation extends Mutation {

  document = gql`
    mutation SendOfferMessageMutation(
      $lock_id: Int!
      $id_token: String!
      $user_email: String!
      $order_id: Int!
      $message: String!
      $communication_window_check_id: Int!
      $invoice_id: Int!
      $offeror_id: Int!
      $offeree_id: Int!
      $user_type: LockInUserType!
    ) {
      addMessage(
        lock_id: $lock_id
        id_token: $id_token
        user_email: $user_email
        order_id: $order_id
        message: $message
        communication_window_check_id: $communication_window_check_id
        invoice_id: $invoice_id
        offeror_id: $offeror_id
        offeree_id: $offeree_id
        user_type: $user_type
      )
    }
  `;
}
