import { Component, OnInit } from '@angular/core';
import { SessionService } from "../../services/session.service";
import { SessionConstants } from "src/app/constants/app.constants";

@Component({
  selector: 'app-accept-cookie',
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.scss']
})
export class AcceptCookieComponent implements OnInit {

  userAccepted = false;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    if(this.sessionService.getLocal(SessionConstants.CONST_ACCEPT_COOKIE) === "true") {
      this.userAccepted = true;
    }
  }

  storeCookieAccepted() {
    this.sessionService.setLocal(SessionConstants.CONST_ACCEPT_COOKIE, "true");
  }

}
