import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class SendContactUsMessageMutation extends Mutation {

  document = gql`
    mutation SendContactUsMessageMutation(
      $id_token: String!
      $user_email: String!
      $message: String!
      $subject: String!
    ) {
      addContactUsMessage(
        id_token: $id_token
        user_email: $user_email
        subject: $subject
        message: $message
      )
    }
  `;
}
