import { Component, OnInit, Input } from "@angular/core";
import { CdkStepper } from "@angular/cdk/stepper";
import { faChevronLeft, faChevronRight } from "@fortawesome/fontawesome-free";

@Component({
  selector: "app-my-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input()
  activeClass = "active";

  @Input() productId = 0;
  @Input() disableTemplateNext = true;
  @Input() disableScheduleNext = true;

  clickActive (index) {
    window.scrollTo(0, 0);
    if(index == 0) {
      this.selectedIndex = index;
    } else if(this.productId != null && this.productId != 0 && index == 1) {
      this.selectedIndex = index;
    } else if(this.productId != null && this.productId != 0 && index == 2 && this.disableTemplateNext === false) {
      this.selectedIndex = index;
    } else if(this.productId != null && this.productId != 0 && index == 3 && this.disableScheduleNext === false) {
      this.selectedIndex = index;
    }
  }

  isNextButtonHidden() {
    return !(this.steps.length === this.selectedIndex + 1);
  }
}
