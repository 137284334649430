import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class ForgetPasswordMutation extends Mutation {
  document = gql`
    mutation forgetPasswordMutation($email: String!) {
      resetUserPassword(user_email: $email)
    }
  `;
}
