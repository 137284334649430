import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})
export class HasProductSchedulesQuery extends Query {
  responseErrorMessage = "";

  document = gql`
    query hasProductSchedulesQuery(
      $productId: Int!,
      $idToken: String!,
      $lenderEmail: String!
      ) {
        hasProductSchedules(
        product_id: $productId
        id_token: $idToken
        lender_email: $lenderEmail
      )
    }
  `;
}
