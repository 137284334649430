import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/pipes/pipes.module";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { LogoComponent } from "./logo/logo.component";
import { ProductCardComponent } from "./product-card/product-card.component";
import { AccountDropdownComponent } from "./account-dropdown/account-dropdown.component";
import { CartDropdownComponent } from "./cart-dropdown/cart-dropdown.component";
import { ProductModalComponent } from "./product-modal/product-modal.component";
import { AcceptCookieComponent } from "./accept-cookie/accept-cookie.component";
import { BackToTopComponent } from "./back-to-top/back-to-top.component";
import { SwitcherComponent } from "./switcher/switcher.component";
import { PageLoaderComponent } from "./page-loader/page-loader.component";
import { NgxSpinnerModule } from "ngx-spinner";
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CurrencyDropdownComponent } from "./currency-dropdown/currency-dropdown.component";
import { LangaugeDropdownComponent } from "./langauge-dropdown/langauge-dropdown.component";
import { DaySchedulerComponent } from "./day-scheduler/day-scheduler.component";
import { DatePickerCalendarComponent } from './date-picker-calendar/date-picker-calendar.component';
import { AddSchedulesComponent } from './add-schedules/add-schedules.component';
import { ActivateProductComponent } from './activate-product/activate-product.component';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { LMarkdownEditorModule } from "ngx-markdown-editor";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownTextEditorComponent } from './markdown-text-editor/markdown-text-editor.component';
import { HomeInfoComponent } from './home-info/home-info.component';

@NgModule({
  declarations: [
    LogoComponent,
    ProductCardComponent,
    AccountDropdownComponent,
    CurrencyDropdownComponent,
    LangaugeDropdownComponent,
    CartDropdownComponent,
    ProductModalComponent,
    AcceptCookieComponent,
    BackToTopComponent,
    SwitcherComponent,
    PageLoaderComponent,
    DaySchedulerComponent,
    DatePickerCalendarComponent,
    AddSchedulesComponent,
    ActivateProductComponent,
    ImageUploadComponent,
    MarkdownTextEditorComponent,
    HomeInfoComponent
  ],
  imports: [
    CommonModule,
    LMarkdownEditorModule,
    NgxSpinnerModule,
    // BrowserAnimationsModule,
        TranslateModule,
    PipesModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    // Specify your library as an import
    SlickCarouselModule,
    FontAwesomeModule
  ],
  exports: [
    LogoComponent,
    ProductCardComponent,
    AccountDropdownComponent,
    CurrencyDropdownComponent,
    LangaugeDropdownComponent,
    CartDropdownComponent,
    ProductModalComponent,
    AcceptCookieComponent,
    BackToTopComponent,
    SwitcherComponent,
    PageLoaderComponent,
    DaySchedulerComponent,
    DatePickerCalendarComponent,
    AddSchedulesComponent,
    ActivateProductComponent,
    ImageUploadComponent,
    MarkdownTextEditorComponent,
    HomeInfoComponent
  ],
})
export class ComponentsModule {}
