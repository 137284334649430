<app-page-loader></app-page-loader>
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>


<div class="container m-3">
    <div class="row">
        <div class="col-12 col-sm-9 content">
            <br />
            <h1 class="has-text-dark">Cookies</h1>
            <p class="has-text-grey-light">
                Last Updated: Jan 06, 2023
            </p>
            <br />
            <p class="box">
                We use cookies, pixels, and other technologies (collectively, "cookies") to recognize your browser or device, learn more about your interests, and provide you with essential features and services and for additional purposes, including:
                <br /><br />
                Recognizing you when you sign-in to use our services. This allows us to provide you with Offer recommendations, display personalized content, enable you to book Offers, and provide other customized features and services.
                <br /><br />
                Keeping track of items stored in your shopping cart.
                <br /><br />
                Conducting research and diagnostics to improve Zigwik's content, products, and services.
                <br /><br />
                Preventing fraudulent activity.
                <br /><br />
                Improving security.
                <br /><br />
                Delivering content, including ads, relevant to your interests on Zigwik sites and third-party sites (see the Interest-Based Ads notice for how we use cookies in serving interest-based ads).
                <br /><br />
                Reporting. This allows us to measure and analyze the performance of our services.
                <br /><br />
                Zigwik's cookies allow you to take advantage of some of Zigwik's essential features. For instance, if you block or otherwise reject our cookies, you will not be able to add items to your Shopping Cart, proceed to Checkout, or use any Zigwik services that require you to sign in.
                <br /><br />
                Approved third parties may also set cookies when you interact with Zigwik services. Third parties include search engines, providers of measurement and analytics services, social media networks, and advertising companies. Third parties use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads, and to perform services on behalf of Zigwik.
                <br /><br />
                You can manage browser cookies through your browser settings. The 'Help' feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, how to block cookies, and when cookies will expire. If you block all cookies on your browser, neither we nor third parties will transfer cookies to your browser. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some features and services may not work.
            </p>
        </div>
    </div>
</div>



<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>

<app-back-to-top></app-back-to-top>
