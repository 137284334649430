<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [withAnonymous]=true></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [withAnonymous]=true></app-header-mobile>

<div class="text-center m-4">
    <p *ngIf="hideMessage == 1" class="has-text-danger">Your email is not verified. Please verify your email id to get access to all our services.</p>
    <p>If you missed the verification link, please click on the below link to resend the verification link for your email.</p>
    <br/>
    <button id="loginButton"
                class="button is-black m-2"
              (click)="resendEmailLink()">
                {{ "Resend verification link" | translate }}
              </button>
  </div>

<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<!-- <app-accept-cookie></app-accept-cookie> -->
<app-back-to-top></app-back-to-top>
<!-- <app-switcher></app-switcher> -->