import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";

@Injectable({
  providedIn: "root",
})

export class CreateAttributeMutation extends Mutation {

  document = gql`
    mutation CreateAttributeMutation(
      $product_id: Int!
      $id_token: String!
      $lender_email: String!
      $attribute_key: String!
      $attribute_value: String!
    ) {
      addProductAttributes(
        product_id: $product_id
        id_token: $id_token
        lender_email: $lender_email
        attribute_key: $attribute_key
        attribute_value: $attribute_value
      )
    }
  `;
}
