import { Injectable } from "@angular/core";
import { Mutation } from "apollo-angular";
import gql from "graphql-tag";
import { BehaviorSubject } from "rxjs";
import { EventConstants } from "src/app/constants/app.constants";
@Injectable({
  providedIn: "root",
})
export class RegisterMaskedUserMutation extends Mutation {
  document = gql`
    mutation RegisterMaskedUserMutation{
    registerMaskedUser{
          id
          masked_id
          email
          phone
      }
      }
    `;
}
