import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "src/app/components/components.module";

import { UserSidebarComponent } from "./user-sidebar/user-sidebar.component";
import { ShopSidebarComponent } from "./shop-sidebar/shop-sidebar.component";
import { BlogSidebarComponent } from "./blog-sidebar/blog-sidebar.component";
import { OfferingsSidebarComponent } from "./offerings-sidebar/offerings-sidebar.component";

@NgModule({
  declarations: [
    BlogSidebarComponent,
    ShopSidebarComponent,
    UserSidebarComponent,
    OfferingsSidebarComponent,
  ],
  imports: [CommonModule, RouterModule, TranslateModule, ComponentsModule],
  exports: [
    BlogSidebarComponent,
    ShopSidebarComponent,
    UserSidebarComponent,
    OfferingsSidebarComponent,
  ],
})
export class SidebarModule {}
