import { Component, OnInit, ViewChild, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import Cropper from "cropperjs";
import { UploadProfileImageMutation } from "src/app/services/graphql/uploadProfileImage.graphql";
import { UploadProductImageMutation } from "src/app/services/graphql/uploadProductImage.graphql";
import { DeleteProductImageMutation } from "src/app/services/graphql/deleteProductImage.graphql";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from "apollo-angular";
import { ToastrService } from 'ngx-toastr';
import { SessionService } from "src/app/services/session.service";
import { GetProductImagesQuery } from "src/app/services/graphql/getProductImages.graphql";
import { EventConstants } from "src/app/constants/app.constants";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageUploadComponent implements OnInit {

  closeResult = '';
  openedModal = null;

  @Input("src")
  public imageSource: string = "";

  @Input("isProfileImage")
  public isProfileImage: boolean = false;

  @Input("productID")
  public productID = 0;

  @Input("aspectRatio")
  public aspectRatio: number = 1;

  @Input("isOfferImage")
  public isOfferImage: boolean = false;

  public imageDestination: string;
  public cropper: Cropper;

  offerImage1 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
  offerImage2 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
  offerImage3 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
  offerImage4 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;

  offerImageId1 = 0;
  offerImageId2 = 0;
  offerImageId3 = 0;
  offerImageId4 = 0;

  productImageLinks = [];

  public constructor(
    private modalService: NgbModal,
    private uploadProfileImageMutation: UploadProfileImageMutation,
    private uploadProductImageMutation: UploadProductImageMutation,
    private deleteProductImageMutation: DeleteProductImageMutation,
    private apollo: Apollo,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private getProductImagesQuery: GetProductImagesQuery
  ) {
    this.imageDestination = "";
   }

  public ngOnInit(): void {
    this.getImages();
  }

  getImages() {
    if(this.productID !== 0 && this.productID !== null) {
      this.apollo
      .query({
        query: this.getProductImagesQuery.document,
        variables: {
          product_id: <number>this.productID
        },
        fetchPolicy: "network-only"
      }).subscribe(
        ({ data }) => {
          this.productImageLinks = data["getProductImages"];

          for (let i = 0; i < 4; i++) {
            if(i in this.productImageLinks) {
              if(i === 0) {
                this.offerImage1 = String(this.productImageLinks[i]["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
                this.offerImageId1 = this.productImageLinks[i]["id"];
              }
              if(i === 1) {
                this.offerImage2 = String(this.productImageLinks[i]["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
                this.offerImageId2 = this.productImageLinks[i]["id"];
              }
              if(i === 2) {
                this.offerImage3 = String(this.productImageLinks[i]["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
                this.offerImageId3 = this.productImageLinks[i]["id"];
              }
              if(i === 3) {
                this.offerImage4 = String(this.productImageLinks[i]["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
                this.offerImageId4 = this.productImageLinks[i]["id"];
              }
            } else {
              if(i === 0) {
                this.offerImage1 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
                this.offerImageId1 = 0;
              }
              if(i === 1) {
                this.offerImage2 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
                this.offerImageId2 = 0;
              }
              if(i === 2) {
                this.offerImage3 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
                this.offerImageId3 = 0;
              }
              if(i === 3) {
                this.offerImage4 = EventConstants.CONST_DEFAULT_OFFER_IMAGE;
                this.offerImageId4 = 0;
              }
            }
          }

          this.productImageLinks.forEach((value, index) => {
            if(index === 0) {
              this.offerImage1 = String(value["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
              this.offerImageId1 = value["id"];
            }
            if(index === 1) {
              this.offerImage2 = String(value["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
              this.offerImageId2 = value["id"];
            }
            if(index === 2) {
              this.offerImage3 = String(value["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
              this.offerImageId3 = value["id"];
            }
            if(index === 3) {
              this.offerImage4 = String(value["link"]).replace(EventConstants.CONST_OFFER_IMAGE_CDN_LINK, EventConstants.CONST_OFFER_IMAGE_STORAGE_BUCKET);
              this.offerImageId4 = value["id"];
            }
          });
        });
    }
  }

 public ngAfterViewInit(): void {
 }
 
  onChange(event) {
    (<HTMLImageElement>document.getElementById("imageCropping")).src = URL.createObjectURL(event.target.files[0]);

    this.cropper = new Cropper(<HTMLImageElement>document.getElementById("imageCropping"), {
      zoomable: true,
      scalable: false,
      minCropBoxWidth: 300,
      minCropBoxHeight: 300,
      minCanvasWidth: 300,
      minCanvasHeight: 300,
      minContainerWidth: 300,
      minContainerHeight: 300,
      viewMode: 1,
      aspectRatio: this.aspectRatio,
      modal: true,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/png");
      }
    });
}

removeImageCropper() {
  (<HTMLImageElement>document.getElementById("imageCropping")).src = "";
  this.cropper.destroy();
}

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  saveImage(modal) {
    document.getElementById("modalImageUploaderButton").classList.add("is-loading");
    (<HTMLInputElement>document.getElementById("imageFileUpload")).value = "";
    function dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

    const contentType = 'image/png';

    if(this.imageDestination === null || this.imageDestination === "") {
      this.toastr.error("No image selected.");
      document.getElementById("modalImageUploaderButton").classList.remove("is-loading");
    } else {

      const img = new Image();
      img.src = this.imageDestination;

      var fileObj = dataURLtoFile(this.imageDestination, "test.png");

      this.imageDestination = null;

      let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getLocalKeyValues("user", "email");
  
      if(this.isProfileImage === true) {
        this.apollo
        .mutate({
          mutation: this.uploadProfileImageMutation.document,
          variables: {
            file: fileObj,
            user_email: email,
            id_token: token
            },
            context: {
              useMultipart: true
           }
        })
        .subscribe(
          ({ data }) => {
            this.toastr.success("Successfully uploaded the profile picture. It might take a while to prepare the image for everyone to see.");
            document.getElementById("modalImageUploaderButton").classList.remove("is-loading");
            this.removeImageCropper();
            modal.close();
          },
          (error) => {
            this.toastr.error(error.message);
            document.getElementById("modalImageUploaderButton").classList.remove("is-loading");
          }
        );
      } else if(this.isOfferImage === true && this.productID !== 0 && this.productID !== null) {
        this.apollo
        .mutate({
          mutation: this.uploadProductImageMutation.document,
          variables: {
            file: fileObj,
            user_email: email,
            id_token: token,
            product_id: this.productID
            },
            context: {
              useMultipart: true
           }
        })
        .subscribe(
          ({ data }) => {
            this.toastr.success("Successfully uploaded the image. It might take a while to prepare the image for everyone to see.");
            document.getElementById("modalImageUploaderButton").classList.remove("is-loading");
            this.getImages();
            this.removeImageCropper();
          },
          (error) => {
            this.toastr.error(error.message);
            document.getElementById("modalImageUploaderButton").classList.remove("is-loading");
          }
        );
      }
       else {
        document.getElementById("modalImageUploaderButton").classList.remove("is-loading");
      }
    }
  }

  deleteImage(imageId: number) {

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
        .mutate({
          mutation: this.deleteProductImageMutation.document,
          variables: {
            product_image_id: imageId,
            user_email: email,
            id_token: token,
            product_id: this.productID
            },
            fetchPolicy: "network-only"
        })
        .subscribe(
          ({ data }) => {
            this.toastr.success("Successfully deleted the image.");
            this.getImages();
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
  }

}
