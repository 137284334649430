import { Injectable } from "@angular/core";
import { RegisterMaskedUserMutation } from "./graphql/registerMaskedUser.graphql";
import { AttachMaskedUserDetailsMutation } from "./graphql/attachMaskedUserDetails.graphql";
import { SendCouponCodeMutation } from "./graphql/sendCouponCode.graphql";
import { GetMaskedUserDetailsQuery } from "./graphql/getMaskedUserDetails.graphql";
import { SessionService } from "./session.service";
import { SessionConstants } from "src/app/constants/app.constants";
import { Apollo } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class RandomUserService {
  constructor(
    private registerMaskedUserMutation: RegisterMaskedUserMutation,
    private attachMaskedUserDetailsMutation: AttachMaskedUserDetailsMutation,
    private getMaskedUserDetailsQuery: GetMaskedUserDetailsQuery,
    private sessionService: SessionService,
    private apollo: Apollo,
    private sendCouponCodeMutation: SendCouponCodeMutation
  ) {}

  attachMaskedUserDetails(user_id, masked_id, email, phone) {
    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var userId = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
      var availableEmail = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "email");
      var newUser = null;
      if(availableEmail === email) {
        return true;
      } else {
        if(availableEmail != null && availableEmail != "") {
          this.registerMaskedUserMutation.mutate().subscribe(({ data }) => {
            newUser = data["registerMaskedUser"];
            this.apollo
            .mutate({
              mutation: this.attachMaskedUserDetailsMutation.document,
              variables: {
                email: email,
                masked_id: data["registerMaskedUser"]["masked_id"],
                user_id: data["registerMaskedUser"]["id"]
              },
          }).subscribe(({ data }) => {
            var result = {"masked_id" : newUser["masked_id"], "id" : newUser["id"], "email" : email, "phone" : null};
            this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, result);
          });      
          });
          return true;
        } else {
          this.apollo
          .mutate({
            mutation: this.attachMaskedUserDetailsMutation.document,
            variables: {
              email: email,
              masked_id: maskedUserData,
              user_id: userId
            },
          }).subscribe(({ data }) => {
            var result = {"masked_id" : maskedUserData, "id" : userId, "email" : email, "phone" : null};
            this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, result);
          });
          return true; 
        }
      }
    }
    return false;
  }

  sendCouponCodeEmail(
    email, 
    coupon_code, 
    discount_percentage, 
    start_date, 
    end_date, 
    offer_id, 
    offer_title, 
    offer_name, 
    category, 
    sub_category1, 
    sub_category2, 
    category_group, 
    for_all_offers, 
    category_id, 
    category_group_id) {


    this.apollo
      .mutate({
        mutation: this.sendCouponCodeMutation.document,
        variables: {
          email: email,
          coupon_code: coupon_code,
          discount_percentage: discount_percentage,
          start_date: start_date,
          end_date: end_date,
          offer_id: offer_id,
          offer_title: offer_title,
          offer_name: offer_name,
          category: category,
          sub_category1: sub_category1,
          sub_category2: sub_category2,
          category_group: category_group,
          for_all_offers: for_all_offers,
          category_id: category_id,
          category_group_id: category_group_id
        },
    }).subscribe(({ data }) => {}); 


  }

}
