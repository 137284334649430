<app-page-loader></app-page-loader>
<app-header [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile [loginCheck]=true [emailVerificationCheck]=true [loginRedirectUrl]="router.url"></app-header-mobile>

<div class="container-fuild">
    <nav aria-label="breadcrumb">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/'">{{ "Home" | translate }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Offer Complain Management" | translate }}
          </li>
        </ol>
      </div>
    </nav>
  </div>

<section class="pro-content order-content">
<div class="container">
    <div class="row">
    <div class="pro-heading-title">
        <h1>
        {{ "Complaint Management" | translate }}
        </h1>
    </div>
    </div>
    <div class="row">
    <div class="col-12 col-lg-3 d-block d-xl-block">
        <app-offerings-sidebar></app-offerings-sidebar>
    </div>
    <div *ngIf="productLockDetails != null && productLockDetails['grievance_window_open_flag'] == true && productLockDetails['lock_type'] != 'offeree_grievance_refund_lock'" class="col-12 col-lg-9">
        <textarea id="commenttext" maxlength="300" matInput placeholder="Leave a comment..." [(ngModel)] = "complaintComment"></textarea><br />
        <button id="raiseComplaintButton" class="is-small button is-primary mt-3" [disabled]="complaintComment == ''" (click)="raiseRefundRequest()">
            {{ "Raise Complaint" | translate }}
          </button>
    </div>
    <div *ngIf="productLockDetails != null && productLockDetails['grievance_window_open_flag'] == false && productLockDetails['lock_type'] != 'offeree_grievance_refund_lock'" class="col-12 col-lg-9">
        At present it is not possible to raise complaint.
    </div>
    <div *ngIf="productLockDetails == null" class="col-12 col-lg-9">
        No booking found.
    </div>
    <div *ngIf="(productLockDetails != null && productLockDetails['lock_type'] == 'offeree_grievance_refund_lock') || grievanceRaised == true" class="col-12 col-lg-9">
      You have already raised an complaint. We will process your refund soon. However, if possible, please clarify your complain with the Offeror as well. We are sure they will be willing to support you.
      <br /><br />
      If you decide to take your complaint back, please click on the below button. <br />
      <button id="raiseRevertComplaintButton" class="is-small button is-primary mt-3" (click)="raiseRevertRefundRequest()">
        {{ "Revert Complaint" | translate }}
      </button>
  </div>
    </div>
</div>
</section>


<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>
<app-back-to-top></app-back-to-top>